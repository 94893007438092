<swe-card header="{{languageService.getItem(241)}}" [(open)]="open" (refreshChange)="load()" (openChange)="load()" [rulelist]="['SweUserStatistics', 'SweUserBookings', 'SweStandbyBooking', 'SweAbscentBooking']" icon="bi-bar-chart-fill">
  <span swe-rightcommand></span>
  <swe-datepager [type]="'booking'" (dateChange)="load()" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <div class="row py-2" *ngIf="!loading">
    <div class="col-12 col-md-6">
      <canvas baseChart
              [type]="charttype"
              [datasets]="shiftdata"
              [labels]="shiftlabels"
              [options]="shiftoptions"
              [plugins]="chartplugins">
      </canvas><!--[colors]="shiftcolors"-->
    </div>
    <div class="col-12 col-md-6">
      <canvas baseChart
              [type]="charttype"
              [datasets]="smsdata"
              [labels]="smslabels"
              [options]="smsoptions"
              [plugins]="chartplugins">
      </canvas><!--[colors]="smscolors"-->
    </div>
    <div class="col-12 mt-5" *ngIf="permissionService.permissions.PushNotifications">
      {{languageService.getItem(1247)}}&nbsp;{{push}} 
    </div>
  </div>
</swe-card>
