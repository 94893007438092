<div class="dropdown d-inline-block align-top">
  <button class="btn btn-swe" [ngClass]="{'btn-warning': changed}" (click)="toggle()" [disabled]="disabled">
    <span style="display:inline-block;width:10px;height:10px;border:1px solid #999999;" [ngStyle]="{'background-color': current.Color}" *ngIf="current&&current.Color"></span>
    {{current.Name}}
  </button>
  <ul class="dropdown-menu py-0" [ngClass]="{'show': open}">
    <li class="dropdown-item swe-click" [ngClass]="{'active': model==item.Id}" *ngFor="let item of items" (click)="select(item)">
      <span style="display:inline-block;width:10px;height:10px;border:1px solid #999999;" [ngStyle]="{'background-color': item.Color}" *ngIf="item.Color"></span>
      <span class="ms-1">{{item.Name}}</span>
    </li>
  </ul>
</div>
