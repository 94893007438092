<div class="mb-{{bottomMargin}}" [ngClass]="{'row': (cols > 0)}">
  <label *ngIf="statusLabel>0" [ngClass]="getLabelClass()">{{label}}</label>
  <div class="dropdown" [ngClass]="getInputClass()">
    <div class="input-group">
      <input [attr.aria-label]="languageService.getItem(14)" type="text" [ngClass]="{'bg-warning': isChanged}" class="form-control" [(ngModel)]="this.user" (ngModelChange)="search(0)" [disabled]="disabled" (keyup)="keyup($event)" (keydown)="keydown($event)" (blur)="lostfocus()" placeholder="{{languageService.getItem(436)}}" />
      <div class="input-group-append swe-click" *ngIf="active" (click)="resetUser()">
        <span class="input-group-text"><i class="bi bi-x-lg"></i></span>
      </div>
    </div>
    <div class="dropdown-menu py-0"  [ngClass]="{'show': isSearching}">
      <div class="list-group">
        <a class="list-group-item list-group-item-warning swe-click" [ngClass]="{'active': current == -1}" style="font-style:italic;" *ngIf="hassearch()" (click)="getsearch()">...{{usersearch}}...</a>
        <a class="list-group-item swe-click" *ngFor="let item of searchresult;let i = index" [ngClass]="{'active': current == i, 'list-group-item-warning': isChosen(item.Id)}" (click)="get(item,false)" (dblclick)="get(item,true)">
          {{item.Firstname}} {{item.Lastname}} ({{item.Username}})
        </a>
        <a class="list-group-item list-group-item-info text-center swe-click" [ngClass]="{'active': current == searchresult.length}" *ngIf="more" (click)="search(1)">{{languageService.getItem(57)}}</a>
        <a class="list-group-item list-group-item-danger text-center swe-click" [ngClass]="{'active': current == searchresult.length+1}" *ngIf="all" (click)="search(2)">{{languageService.getItem(340)}}</a>
        <a class="list-group-item list-group-item-info swe-click" *ngIf="nohits">{{languageService.getItem(58)}}</a>
      </div>
    </div>
  </div>
</div>
