import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { GeneralService } from '../../_services/general.service';
import { DataService } from '../../_services/data.service';
import { DateTimeService } from '../../_services/datetime.service';
import { AlertService } from '../../_services/alert.service';


@Component({
  selector: 'swe-usertimebank',
  templateUrl: './usertimebank.component.html'
})
export class UserTimeBankComponent implements OnInit, OnChanges {
  @Input() id: number;
  @Input() open: boolean;
  @Input() contractId: number;
  @Input() timebankId: number;
  @Input() access: number;

  private _top: number = 3;
  private _multiple: number = 1;
  private _loading: boolean = false;
  private _timebanks: any[] = [];
  private _timebank: any = {};
  private _more: boolean = false;
  private _showFutureIntervals: boolean = false;
  
  constructor(
    public languageService: LanguageService,
    public generalService: GeneralService,
    public dateTimeService: DateTimeService,
    private dataService: DataService,
    private alertService: AlertService
  ) {

  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    if (this.open) {
      this.search();
    }
  }



  //Properties
  public get loading() {
    return this._loading;
  }
  public get timebanks() {
    return this._timebanks;
  }
  public get more() {
    return this._more;
  }
  public get timebank() {
    return this._timebank;
  }
  public set showFutureIntervals(value: boolean) {
    this._showFutureIntervals = value;
  }
  public get showFutureIntervals() {
    return this._showFutureIntervals;
  }



  //Methods
  public search(getmore:boolean = false) {

    this._loading = true;

    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._timebank = {};
    }

    let filter = {
      Top: this._top,
      Multiple: this._multiple,
      ShowFutureIntervals: this._showFutureIntervals
    };

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/contracts/' + this.contractId + '/timebanks/search', 'POST', filter)
      .subscribe((res) => {

        let today = new Date();

        if (!getmore) {
          this._timebank = res;
          res.Intervals.forEach((item) => {

            item.IsCurrent = (new Date(item.Start) <= today && today <= new Date(item.End));
          });
        }
        else {
          res.Intervals.forEach((item) => {

            item.IsCurrent = (new Date(item.Start) <= today && today <= new Date(item.End));

            this._timebank.Intervals.push(item);
          });
        }
       
        this._more = res.More;

        this._loading = false;
      });
  }
  public recalculate() {

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/timebanks/recalculate', 'POST', {})
      .subscribe((res) => {

        this.search();

      });

  }
  


  //Functions
  private init() {

    this.generalService.timebanks.forEach((timebank) => {

      this._timebanks.push({ Id: timebank.Key, Name: timebank.Value });

    });
  }
}
