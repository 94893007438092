import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { GeneralService } from '../../_services/general.service';
import { ListService } from '../../_services/list.service';



@Component({
  selector: 'app-obruledetail',
  templateUrl: './obruledetail.component.html'
})
export class OBRuleDetailComponent implements OnInit {

  private _id: number = null;
  private _contractid: number = 0;
  private _rule: any = null;
  private _dayprefixes: any[] = [];
  private _weekdays: any[] = [];
  private _holidays: any[] = [];
  private _blackholidays: any[] = [];
  private _redholidays: any[] = [];
  private _groups: any[] = [];
  private _timetypes: any[] = [];
  private _types: any[] = [];
  private _contracts: any[] = [];
  private _fromstart: any[] = [];
  private _toend: any[] = [];
  private _newContract: number = 0;
  private _contractname: string = '';
  private _chosenContracts: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private dataService: DataService,
    private alertService: AlertService,
    private listService: ListService,
    private generalService: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    //Reset
    settingService.initView();

    this.route.paramMap.subscribe(params => {

      let lastId:number = this._id;

      this._id = Number(params.get('id'));
      this._contractid = Number(params.get('contractId'));


      if (lastId != null && lastId != this._id) {
        this.load();
      }
      
    });
  }


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this.settingService.lastobrule = this._id;
    });
    this.init();
    this.load();
  }



  //Properties
  public get id() {
    return this._id;
  }
  public get contractid() {
    return this._contractid;
  }
  public get rule() {
    return this._rule;
  }
  public get dayprefixes() {
    return this._dayprefixes;
  }
  public get weekdays() {
    return this._weekdays;
  }
  public get holidays() {
    return this._holidays;
  }
  public get blackholidays() {
    return this._blackholidays;
    }
  public get redholidays() {
      return this._redholidays;
  }
  public get groups() {
    return this._groups;
  }
  public get timetypes() {
    return this._timetypes;
  }
  public get types() {
    return this._types;
  }
  public get contracts() {
    return this._contracts;
  }
  public get fromstart() {
    return this._fromstart;
  }
  public get toend() {
    return this._toend;
  }
  public get contractname() {
    return this._contractname;
  }
  public get newContract() {
    return this._newContract;
  }
  public set newContract(val) {
    this._newContract = val;
  }
  public get chosenContracts() {
    return this._chosenContracts;
  }





  //Methods
  public load() {

    this.dataService.tokenRequest('/api/v1/overtime/' + this._id, 'GET', {})
      .subscribe((res) => {

        if (this._id <= 0 && this._contractid > 0) {
          res.Contracts = this._contractid;
        }

        this._chosenContracts = [];
        if (res.Contracts) {
          res.Contracts.toString().split('|').forEach((contract) => {

            let obj = this.listService.find(this.generalService.contracts, 'Key', contract);
            if (obj) {
              this._chosenContracts.push({ Id: obj.Key, Name: obj.Value });
            }

          });
        }

        this._rule = res;

      });

  }
  public copy() {
    this._id = 0;
    this.save();
  }
  public save() {

    let verb = 'Post';
    let path = '/api/v1/overtime/'
    if (this._id > 0) {
      verb = 'Put';
      path += this._id;
    }

    if (this._rule.Type == 0) {
      this._rule.Type = 1;
    }

    this.dataService.tokenRequest(path, verb, this._rule, 'text', 'response')
      .subscribe((response) => {

        this.alertService.Add({ type: 'success', message: response.body });

        if (response.status == 201) {
          this.router.navigate([response.headers.get('Location').replace('system/overtime', 'admin/overtime')], { replaceUrl: true });
        }
        else {
          this.load();
        }
      });

  };

  public delete() {

    this.dataService.tokenRequest('/api/v1/overtime/' + this._id, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.location.back();
      });
  };
  public addContract(e) {

    let newObj = this.listService.find(this.chosenContracts, 'Id', e);
    if (!newObj) {
      let obj = this.listService.find(this.generalService.contracts, 'Key', e);
      if (obj) {
        this._chosenContracts.push({ Id: obj.Key, Name: obj.Value });
        if (this._rule.Contracts && this._rule.Contracts.toString().length > 0) {
          this._rule.Contracts += '|';
          this._rule.Contracts += e;
        }
        else {
          this._rule.Contracts = e;
        }
      }
    }

    this._contractname = '';
  }






  //Functions
  private init() {

    this.generalService.contracts.forEach((contract) => {

      this._contracts.push({ Id: contract.Key, Name: contract.Value });

    });

    //Day prefix
    this.dataService.tokenRequest('/api/v1/overtime/dayprefixes', 'GET', {})
      .subscribe((res) => {

        this._dayprefixes = [];
        res.forEach((item) => {
          this._dayprefixes.push({Id: item.Key, Name: item.Value});
        });
      });

    //Weekdays
    this.dataService.tokenRequest('/api/v1/overtime/weekdays', 'GET', {})
      .subscribe((res) => {

        this._weekdays = [];
        res.forEach((item) => {
          this._weekdays.push({ Id: item.Key, Name: item.Value });
        });
      });

    //Holidays
    this.dataService.tokenRequest('/api/v1/overtime/holidays', 'GET', {})
      .subscribe((res) => {

        this._holidays = [];
        this._blackholidays = [];
        this._redholidays = [];
        res.forEach((item) => {
          this._holidays.push({ Id: item.Key, Name: item.Value });
          if (item.Extra == '0') {
            this._blackholidays.push({ Id: item.Key, Name: item.Value });
          }
          if (item.Extra == '1') {
            this._redholidays.push({ Id: item.Key, Name: item.Value });
          }
        });
      });

    //Groups
    this.dataService.tokenRequest('/api/v1/overtime/groups', 'GET', {})
      .subscribe((res) => {

        this._groups = [];
        res.forEach((item) => {
          this._groups.push({ Id: item.Key, Name: item.Value });
        });
      });

    //TimeTypes
    this._timetypes = [];
    this.generalService.timetypes.forEach((timetype) => {

      this._timetypes.push({ Id: timetype.Key, Name: timetype.Value });

    });

    //Types
    this._types = [];
    this._types.push({ Id: 1, Name: this.languageService.getItem(1004) });
    this._types.push({ Id: 2, Name: this.languageService.getItem(1005) });

    //From Start
    this._fromstart.push({ Id: 0, Name: 965 });
    this._fromstart.push({ Id: 1, Name: 966 });

    //To End
    this._toend.push({ Id: 0, Name: 965 });
    this._toend.push({ Id: 1, Name: 967 });
  }

}
