import { Component, Input, EventEmitter, Output, OnInit, OnChanges } from '@angular/core';

import { PropertyService } from '../_services/property.service';
import { DateTimeService } from '../_services/datetime.service';
import { LanguageService } from '../_services/language.service';
import { ListService } from '../_services/list.service';



@Component({
  selector: 'swe-calendar',
  templateUrl: './calendar.component.html',
})
export class CalendarComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Output() chosenChange = new EventEmitter<any>();
  @Input() items: any[];
  @Input() hasWeeknumbers: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showDots: boolean = false;
  @Input() dottedDays: any[];

  private _openMonth: boolean = false;
  private _openYear: boolean = false;
  private _years: Array<number> = [];
  private _months: Array<any> = [];
  private _days: any = [,];

  private _currentYear: number;
  private _currentMonth: number;
  private _currentDay: number;
  private _dotsHandled: boolean = false;

  constructor(private propertyService: PropertyService, private dateTimeService: DateTimeService, private languageService: LanguageService, private listService: ListService) {

  }

  ngOnInit() {

    //Years
    let startyear = 1900;
    let endyear = new Date().getFullYear() + 10;
    for (var i = startyear; i < endyear; i++) { this._years.push(i); }

    //Months
    this._months = [];
    for (var i = 0; i < 12; i++) { this._months.push({ Id: i, Name: this.dateTimeService.monthnamebynumber(i, 3) }); }

  }

  ngOnChanges($event) {
   
    //We don't need to rerun init if dots are loaded or shown
    if (!$event.showShifts || $event.dottedDays) {
    this.init();
      if (this.dottedDays) {
        this.handleDottedDays();
        this._dotsHandled = true;
  }
    }
    //Only handle dots if not previosly done.
    if (this.dottedDays && !this._dotsHandled) {
      this.handleDottedDays();
      this._dotsHandled = true;
    }
  }


  /*Properties*/
  public get openYear(): boolean {
    return this._openYear;
  }
  public get years(): Array<number> {
    return this._years;
  }
  public get currentYear(): number {
    return this._currentYear;
  }
  public set currentYear(val) {
    this._currentYear = val;
    this.write();
  }
  public get openMonth(): boolean {
    return this._openMonth;
  }
  public get months(): Array<any> {
    return this._months;
  }
  public get currentMonth(): number {
    return this._currentMonth;
  }
  public set currentMonth(val) {
    this._currentMonth = val;
    this.write();
  }
  public get currentMonthName(): string {
    return this.dateTimeService.monthnamebynumber(this._currentMonth);
  }
  public get days(): any {
    return this._days;
  }

  /*Methods*/
  public toggleMonth() {
    this._openMonth = !this._openMonth;
  }
  public toggleYear() {
    this._openYear = !this._openYear;
  }
  public prev() {
    this._currentMonth--;
    if (this._currentMonth < 0) {
      this._currentMonth = 11;
      this._currentYear--;
    }
    this.write();
    this.modelChange.emit(this.model);
  }
  public next() {
    this._currentMonth++;
    if (this._currentMonth > 11) {
      this._currentMonth = 0;
      this._currentYear++;
    }
    this.write();
    this.modelChange.emit(this.model);
  }
  public select(col) {

    if (this.disabled) { return; }

    this._openMonth = false;
    this._openYear = false;

    let date = col.date;

    let reload = (this._currentMonth && this._currentMonth != date.getMonth());

    this._currentYear = date.getFullYear();
    this._currentMonth = date.getMonth();
    this._currentDay = date.getDate();

    this.write();

    this.chosenChange.emit(col);
    if (reload) {
      this.modelChange.emit(this.model);
    }
  }
  public weeknumber(date) {
    return this.dateTimeService.weeknumber(date);
  }

  /*Functions*/
  private init() {

    //Current
    if (this.model != null) {
      this.model = new Date(this.model);
      this._currentYear = this.model.getFullYear();
      this._currentMonth = this.model.getMonth();
      this._currentDay = this.model.getDate();

      this.write();
    }
    else {
      let current = new Date();
      this._currentYear = current.getFullYear();
      this._currentMonth = current.getMonth();
      this._currentDay = current.getDate();

      this.write(true);
    }
  }
  private write(empty: boolean = false) {

    this._openMonth = false;
    this._openYear = false;

    let current: Date;
    if (empty) {
      this.model = null;
      current = new Date();
    }
    else {
      if (this.model != null) {
        this.model = new Date(this._currentYear, this._currentMonth, this._currentDay, this.model.getHours(), this.model.getMinutes(), this.model.getSeconds());
      }
      else {
        this.model = new Date(this._currentYear, this._currentMonth, this._currentDay);
      }

      if (this.model.getMonth() != this._currentMonth) {
        while (this.model.getMonth() != this._currentMonth) {
          this.model = this.dateTimeService.addDays(this.model, -1);
        }
        this._currentDay = this.model.getDate();
      }

      current = this.model;
    }

    //First monday date
    let first = this.dateTimeService.firstDayInMonth(current);
    while (first.getDay() != 1) {
      first = new Date(first.getTime() - this.dateTimeService.oneday);
    }

    //Last sunday date
    let last = this.dateTimeService.lastDayInMonth(current);
    while (last.getDay() != 0) {
      last = new Date(last.getTime() + this.dateTimeService.oneday);
    }

    this._days = [];
    while (first < last) {

      let cols = [];
      for (var i = 0; i < 7; i++) {
        let d = new Date(first.getTime() + (i * this.dateTimeService.oneday));

        let start = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        let end = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);

        let current: CalendarItem = null;
        for (var j = 0; j < this.items.length; j++) {
          let item = this.items[j];
          let s = new Date(item.Start);
          let e = new Date(item.End);
          if ((start <= s && s < end) || (start < e && e <= end) || (s < start && end < e)) {
            current = item;
            break;
          }
        }

        cols.push({
          id: current != null && current.Id ? current.Id : 0,
          date: d,
          type: current != null && current.TypeId ? current.TypeId : 0,
          color: current != null && current.Color ? current.Color : '',
          outside: d.getMonth() != this._currentMonth
        });
      }

      this._days.push(cols);

      var diff = (new Date(first.getTime() + (6 * this.dateTimeService.oneday)).getTimezoneOffset() - new Date(first.getTime() + (7 * this.dateTimeService.oneday)).getTimezoneOffset()) * 60 * 1000;
      first = new Date(first.getTime() + (7 * this.dateTimeService.oneday) - diff);
    }

  }

  /*
    Sets hasDot based on day and month property of dottedDays
  */
    private handleDottedDays() {
        this._days.forEach(cols => {
            cols.forEach(col => {
              col.dot = this.dottedDays.filter(dot => dot.Day == col.date.getDate() && dot.Month == col.date.getMonth());  
            })
        })
    }
}

export class CalendarItem {
  public Id: number;
  public TypeId: number;
  public Start: Date;
  public End: Date;
  public Color: string = '';
}

