<swe-pageheader [header]="languageService.getItem(59)">
  <span swe-hits>
    <span class="ms-1">(#{{id}})</span>
    <span swe-hits class="dropdown ms-1" *ngIf="permissionService.permissions.Impersonate&&userdata&&userdata.ImpersonateAccess>0">
      <i class="bi bi-swe-fw bi-incognito pt-1 swe-click" (click)="toggle()" data-bs-toggle="dropdown"></i>
      <span class="dropdown-menu dropdown-menu-end p-2 mt-2 text-center" [ngClass]="{'show': impersonatemenu}">
        <swe-element [type]="'System.Password'" [label]="'Pin'" [statusLabel]="3" [(model)]="pincode" (keyup)="keyup($event)" [setFocus]="pinfocus" [enterKeyHint]="'enter'"></swe-element>
      </span>
    </span>
  </span>
</swe-pageheader>
<div class="row d-flex d-md-block py-2">
  <div class="alert alert-danger" *ngIf="permissionService.user.UpdatePassword&&permissionService.user.Id==id">
    {{languageService.getItem(826)}}
  </div>
  <div class="alert alert-danger" *ngIf="id>0 && userdata.NoBelonging">
    {{languageService.getItem(827)}}
  </div>
  <div class="col-12 col-md-4 float-start order-md-0 d-block" [ngClass]="{'order-1': !cards.data, 'order-0': cards.data}">
    <swe-userdata [id]="id" [(open)]="cards.data"  (userdataChange)="manageUserdata($event)"></swe-userdata>
  </div>
  <ng-container *ngIf="permissionService.user.Id!=id||!permissionService.user.UpdatePassword">
    <div class="col-12 col-md-8 float-start order-md-0 d-block" *ngIf="id>0&&permissionService.permissions.EmploymentPlan>0&&userdata.BookingAccess>0" [ngClass]="{'order-1': !cards.employmentplans, 'order-0': cards.employmentplans}">
      <swe-userbookings [id]="id" [(open)]="cards.employmentplans" [type]="1" [userdata]="userdata"></swe-userbookings>
    </div>
    <div class="col-12 col-md-8 float-start order-md-0 d-block" *ngIf="id>0&&!permissionService.permissions.HideShifts&&userdata.BookingAccess>0" [ngClass]="{'order-1': !cards.bookings, 'order-0': cards.bookings}">
      <swe-userbookings [id]="id" [(open)]="cards.bookings" [userdata]="userdata"></swe-userbookings>
    </div>
    <div class="col-12 col-md-8 float-start order-md-0 d-block" *ngIf="id>0&&userdata.RequestAccess>0" [ngClass]="{'order-1': !cards.requests, 'order-0': cards.requests}">
      <swe-generalrequests [userid]="id" [(open)]="cards.requests" [data]="userdata" [new]="userdata.RequestAccess>1&&permissionService.user.Id!=id"></swe-generalrequests>
    </div>
    <div class="col-12 col-md-8 float-start order-md-0 d-block" *ngIf="id>0&&userdata.UserMarkedDateAccess>0" [ngClass]="{'order-1': !cards.availability, 'order-0': cards.availability}">
      <swe-useravailability [id]="id" [(open)]="cards.availability" [userdata]="userdata"></swe-useravailability>
    </div>
    <div class="col-12 col-md-4 float-start order-md-0 d-block" *ngIf="id>0&&permissionService.permissions.ShowRoleLevels&&userdata.Id>-1" [ngClass]="{'order-1': !cards.belongings, 'order-0': cards.belongings}">
      <swe-userbelongings [id]="id" [(open)]="cards.belongings"></swe-userbelongings>
    </div>
    <div class="col-12 col-md-4 float-start order-md-0 d-block" *ngIf="id>0&&userdata.BookingAccess>0&&userdata.StatisticAccess>0&&(permissionService.permissions.Standby>0||permissionService.permissions.Abscent>0||permissionService.permissions.ReplySms)" [ngClass]="{'order-1': !cards.statistics, 'order-0': cards.statistics}">
      <swe-userstatistics [id]="id" [(open)]="cards.statistics"></swe-userstatistics>
    </div>
    <div class="col-12 col-md-4 float-start order-md-0 d-block" *ngIf="id>0&&((userdata.ICalUrl&&userdata.ICalUrl.length>0)||(userdata.ICalLevelUrl&&userdata.ICalLevelUrl.length>0))" [ngClass]="{'order-0': cards.icalendar, 'order-1': !cards.icalendar}">
      <swe-usericalendar [id]="id" [(open)]="cards.icalendar" [userdata]="userdata"></swe-usericalendar>
    </div>
    <ng-container *ngFor="let obj of objecttoobjects; index as i">
      <div class="col-12 col-md-8 float-start order-md-0" *ngIf="id>0&&userdata.Id>-1" [ngClass]="{'order-1': !cards.objects || !cards.objects[i], 'order-0': cards.objects && cards.objects[i]}">
        <swe-objecttoobjects [id]="id" [data]="obj" [open]="!cards.objects ? false : cards.objects[i]"></swe-objecttoobjects>
      </div>
    </ng-container>
    <div class="col-12 col-md-8 float-start order-md-0" *ngIf="id>0&&userdata.ContractAccess>0" [ngClass]="{'order-1': !cards.contract, 'order-0': cards.contract}">
      <swe-usercontract [id]="id" [(open)]="cards.contract" [userdata]="userdata"></swe-usercontract>
    </div>
    <div class="col-12 col-md-8 float-start order-md-0" *ngIf="id>0&&userdata.LogAccess>0" [ngClass]="{'order-1': !cards.logs, 'order-0': cards.logs}">
      <swe-card header="{{languageService.getItem(400)}}" [(open)]="cards.logs" [hasRefresh]="false" [rulelist]="['SweUserLogs']" icon="bi-clock-history">
        <swe-generallogs [id]="id" [(open)]="cards.systemlogs" [type]="'user'" [data]="userdata"></swe-generallogs>
        <swe-generalmessages [id]="id" [(open)]="cards.messagelogs" [type]="'user'" [data]="userdata"></swe-generalmessages>
      </swe-card>
    </div>
  </ng-container>
</div>
<div class="d-md-none d-inline-block">
  <div class="d-block mt-4">
  <app-botmenu [actions]="tabs" *ngIf="userdata.Id">
  </app-botmenu>
</div>
</div>
<!--To make float behavior (fill available space), use [d-block] on row and [float-start] on columns-->

