import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { DataService } from '../_services/data.service';
import { AlertService } from '../_services/alert.service';
import { PropertyService } from '../_services/property.service';
import { TokenService } from '../_services/token.service';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
})
export class RegistrationComponent implements OnInit {

  private _chosenlevelid: number = 0;
  private _levels: any[] = [];
  private _levelid: number = 0;
  private _levelname: string = '';
  private _userimage: any;
  private _profile: any[] = [];
  private _isdataloading: boolean = true;
  private _islevelloading: boolean = true;
  private _issaving: boolean = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService,
    private propertyService: PropertyService,
    private tokenService: TokenService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    this.route.queryParams.subscribe((params) => {
      //Parameters
      this._chosenlevelid = params['l'];
    });

    this.loadLevel();
    this.loadProfile();
  }


  ngOnInit() {

  }



  //Propreties
  public get levels() {
    return this._levels;
  }
  public get levelid() {
    return this._levelid;
  }
  public set levelid(val) {
    this._levelid = val;
  }
  public get levelname() {
    return this._levelname;
  }
  public set levelname(val) {
    this._levelname = val;
  }
  public get userimage() {
    return this._userimage;
  }
  public set userimage(val) {
    this._userimage = val;
  }
  public get profile() {
    return this._profile;
  }
  public get isdataloading() {
    return this._isdataloading;
  }
  public get islevelloading() {
    return this._islevelloading;
  }
  public get issaving() {
    return this._issaving;
  }



  //Save
  public save() {

    if (this._levelid == 0 && this._levelname.length == 0) {

      this.alertService.Add({ type: 'danger', message: this.languageService.getItem(425) });
      return;
    }

    if (!this.propertyService.validate(this._profile)) { return; }

    this._issaving = true;

    let dto = {
      Profile: this._profile,
      LevelId: this._levelid,
      LevelName: this._levelname,
      Image: this._userimage
    };

    this.dataService.basicRequest('/api/v1/users/registration', 'POST', dto)
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res.Text });

        if (res.Token) {
          this.tokenService.saveTokens(res.Token);

          this.permissionService.reload().subscribe();
        }

        let splash = this.permissionService.unauthpermissions.RegistrationSplashPage;
        if (typeof splash != 'undefined' && splash != null && splash.length > 0) {
          window.location.href = splash;
        }
        else {
          this.router.navigate(['/login']);
        }

        this._issaving = false;
      },
        (error) => {
          this._issaving = false;
        });

  }


  //Document Container
  public documentcontainer() {
    return {
      clientOnly: true,
      allowedPrefix: 'png, jpg, jpeg, jfif, gif',
      folder: 'userimages',
      document: '',
      showimage: true
    };
  }


  //Profile
  private loadProfile() {
    this.dataService.basicRequest('/api/v1/users/registration', 'GET', {})
      .subscribe((res) => {

        this._profile = res.Profile;

        this._isdataloading = false;
      });
  }


  //Levels
  private loadLevel() {
    this.dataService.basicRequest('/api/v1/users/registration/levels', 'GET', {})
      .subscribe((res) => {

        this._levels = res;

        if (this._chosenlevelid && this._chosenlevelid > 0) {
          this._levelid = this._chosenlevelid;
        }
        else if (this._levels.length == 1) {
          this._levelid = this._levels[0].Id;
        }

        this._islevelloading = false;
      });
  }

}
