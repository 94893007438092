import { Component, Input, OnChanges} from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';

@Component({
  selector: 'swe-levelrelations',
  templateUrl: './levelrelations.component.html'
})
export class LevelRelationsComponent implements OnChanges {
  @Input() id: number;
  @Input() open: boolean;
  @Input() leveldata: any;

  private _relations: any[] = [];
  private _loading: boolean = false;
  private _top: number = 25;
  private _multiple: number = 1;
  private _more: boolean = false;
  private _parentId: number = 0;
  private _parent: string = '';
  private _parentAccess: number = 0;

  constructor(public languageService: LanguageService, private dataService: DataService, private settingService: SettingService, private router: Router) {

  }

  ngOnChanges() {
    if (this.leveldata) {
      this._parentId = this.leveldata.ParentId;
      this._parent = this.leveldata.Parent;
      this._parentAccess = this.leveldata.ParentAccess;
    }

    if (this.open) {
      this.load();
    }
  }


  //Properties
  public get relations() {
    return this._relations;
  }
  public get loading(): boolean {
    return this._loading;
  }
  public get more(): boolean {
    return this._more;
  }
  public get parentId(): number {
    return this._parentId;
  }
  public get parent(): string {
    return this._parent;
  }
  public get parentAccess(): number {
    return this._parentAccess;
  }


  public load(getmore: boolean = false) {

    if (this.leveldata.RelationsAccess == 0) { return; }

    this._more = false;

    if (getmore) {
      this._multiple = 1 + (this._multiple / 4);
      this._top *= 4;
    }
    else {
      this._multiple = 1;
      this._top = 25;
      this._relations = [];
    }

    let filter = {
      Top: this._top,
      Multiple: this._multiple,
      ParentId: this.id
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/levels/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          res.Levels.forEach(level => {
            this._relations.push(level);
          });

          this._more = res.More;

          this._loading = false;
        }
      });
  }
  public goto(id: number, e) {
    this.router.navigate(['/levels', id]);
  }

}
