import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { ListService } from '../../_services/list.service';
import { DataService } from '../../_services/data.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { AlertService } from '../../_services/alert.service';
import { Location } from '@angular/common';
import { SettingService } from '../../_services/setting.service';
import { GeneralService } from '../../_services/general.service';


@Component({
  selector: 'swe-multiusers',
  templateUrl: './multiusers.component.html'
})
export class MultiUsersComponent implements OnInit {

  private _belongoption: number = 0;
  private _belongoptionroles: number = 0;
  private _belongoptionlevels: number = 0;
  private _belongoptions: any[] = [];
  private _availableroles: any[] = [];
  private _roles: any[] = [];
  private _levels: any[] = [];
  private _activationoption: number = 0;
  private _activationoptions: any[] = [];
  private _mainleveloption: number = 0;
  private _mainleveloptions: any[] = [];
  private _mainlevel: number = 0;
  private _contracts: any[] = [];
  private _contractId: number = 0;
  private _properties: any[] = [];
  private _chosenproperties: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private listService: ListService,
    private viewCacheService: ViewCacheService,
    private alertService: AlertService,
    private settingService: SettingService,
    private generalService: GeneralService,
    private location: Location) {

    //Reset
    settingService.initView();
  }

  ngOnInit() {
    this._belongoptions = [{ Id: 0, Name: 100 }, { Id: 1, Name: 17 }];
    this._activationoptions = [{ Id: 0, Name: 465 }, { Id: 1, Name: 467 }, { Id: 2, Name: 468 }];
    this._mainleveloptions = [{ Id: 0, Name: 465 }, { Id: 1, Name: 100 }, { Id: 2, Name: 17 }];
    this.load();
  }


  //Properties
  public get belongoption() {
    return this._belongoption;
  }
  public set belongoption(val) {
    this._belongoption = val;
  }
  public get belongoptionroles() {
    return this._belongoptionroles;
  }
  public set belongoptionroles(val) {
    this._belongoptionroles = val;
  }
  public get belongoptionlevels() {
    return this._belongoptionlevels;
  }
  public set belongoptionlevels(val) {
    this._belongoptionlevels = val;
  }
  public get belongoptions() {
    return this._belongoptions;
  }
  public get availableroles() {
    return this._availableroles;
  }
  public get roles() {
    return this._roles;
  }
  public set roles(val) {
    this._roles = val;
  }
  public get levels() {
    return this._levels;
  }
  public get activationoption() {
    return this._activationoption;
  }
  public set activationoption(val) {
    this._activationoption = val;
  }
  public get activationoptions() {
    return this._activationoptions;
  }
  public get mainleveloption() {
    return this._mainleveloption;
  }
  public set mainleveloption(val) {
    this._mainleveloption = val;
  }
  public get mainleveloptions() {
    return this._mainleveloptions;
  }
  public get mainlevel() {
    return this._mainlevel;
  }
  public set mainlevel(val) {
    this._mainlevel = val;
  }
  public get contracts() {
    return this._contracts;
  }
  public get contractId() {
    return this._contractId;
  }
  public set contractId(val) {
    this._contractId = val;
  }
  public get properties() {
    return this._properties;
  }
  public get chosenproperties() {
    return this._chosenproperties;
  }
  //public set chosenproperties(val) {
  //  this._chosenproperties = val;
  //}





  //Methods
  public addLevel(e) {
    let index = this._levels.map(function (level) { return level.Id; }).indexOf(e.item.Id);

    if (index == -1) {
      e.item.active = true;
      this._levels.push(e.item);
      this.listService.sort(this._levels, ['Path', 'Name'], true);
    }
  }
  public removeLevel(level) {
    let index = this._levels.indexOf(level);
    if (index > -1) {
      this._levels.splice(index, 1);
    }
  }
  public confirmsave() {
    let confirmtext = '';

    if (this._roles.length) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(472).replace('{0}', this._roles.length);
    }
    if (this._levels.length) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(473).replace('{0}', this._levels.length);
    }
    if (this._chosenproperties.length > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(428).replace('{0}', this._chosenproperties.length);
    }
    if (this._activationoption > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(474);
    }
    if (this._mainlevel > 0 || this._mainleveloption == 2) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(1054);
    }
    if (this._contractId > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(1067);
    }

    return this.languageService.getItem(471).replace('{0}', confirmtext);
  }
  public save() {
    let users:any = this.viewCacheService.get('multi_users');

    if (users) {

      let profile: any[] = [];
      this._chosenproperties.forEach(property => {
        profile.push({ Property: property.Id, Value: property.Val });
      });


      let mainlevel = -1;
      if (this._mainleveloption == 2) { mainlevel = 0; } //Remove
      if (this._mainleveloption == 1 && this._mainlevel) { mainlevel = this._mainlevel; } //Add

      let filter = {
        IdList: users.value,
        Activate: this._activationoption,
        MainLevel: mainlevel,
        ContractId: this._contractId ? this._contractId : 0,
        AddBelonging: (this._belongoption == 0),
        Roles: this._roles,
        Levels: this._levels.map(function (level) { return level.Id; }),
        Profiles: profile
      };

      this.dataService.tokenRequest('/api/v1/users/multi', 'POST', filter, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.alertService.Add({ message: this.languageService.getItem(464), type: 'danger' });
    }
  }
  public delete() {
    let users: any = this.viewCacheService.get('multi_users');

    if (users) {
      let filter = {
        IdList: users.value
      };

      this.dataService.tokenRequest('/api/v1/users/multi/delete', 'POST', filter, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.alertService.Add({ message: this.languageService.getItem(464), type: 'danger' });
    }
  }



  //Functions
  private load() {

    this.dataService.tokenRequest('/api/v1/general/roles/user/', 'GET', {})
      .subscribe((res) => {

        this._availableroles = res;
      });

    this.dataService.tokenRequest('/api/v1/properties/type/multiuser', 'GET', {})
      .subscribe((res) => {

        let groupbyList: any[] = [];
        res.forEach((item) => {
          let groupby = this.listService.find(groupbyList, 'Name', item.Category);
          if (groupby) {
            groupby.Items.push({ Id: item.Id, Name: item.Name });
          }
          else {
            groupbyList.push({ Name: item.Category, Items: [{ Id: item.Id, Name: item.Name }] });
          }
        });

        this._properties = groupbyList;
      });

    this.generalService.contracts.forEach((contract) => {

      this._contracts.push({ Id: contract.Key, Name: contract.Value });

    });
  }

}
