import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GeneralService } from "../_services/general.service";

@Component({
  selector: 'app-botmenu',
  templateUrl: './botmenu.component.html'
})
export class BotMenuComponent implements OnInit {
  /*
    Example action:
    {
      users: {
        navigate: true,
        access: true,
        icon: 'bi-people-fill',
        label: 'Users',
      },
      shifts: {
        navigate: false,
        access: false,
        icon: 'bi-list-ul',
        label: 'Shifts'
      }
      objects: [
       {
        navigate: false,
        access: true,
        icon: 'bi-bookmark-fill',
        label: 'Object 1'
       }
      ]
    }
  */

  @Input() actions: any;
  private _shownActions: any[] = [];
  private _dropdownActions: any[] = [];
  private _keys: string[] = [];
  private _standalone: any;

  ngOnInit() {
    this.splitActions();
    this._standalone = ('standalone' in window.navigator) && (window.navigator['standalone']);
  }

  constructor(public generalService: GeneralService) {

  }

  public get standalone() {
    return this._standalone;
  }
  public get shownActions() {
    return this._shownActions;
  }

  public get dropdownActions() {
    return this._dropdownActions;
  }

  /*
    splits the actions in to two arrays one for shown action and one for dropdown actions
  */
  private splitActions() {
    let counter = 0;
    Object.keys(this.actions).forEach(key => {
      if (Array.isArray(this.actions[key])) {
        for (let i = 0; i < this.actions[key].length; i++) {
          this.actions[key][i].index = i;
          counter = this.addActions(counter, this.actions[key][i], key);
        }
      }
      else {
        counter = this.addActions(counter, this.actions[key], key);
      }
    });

    if (this._dropdownActions.length == 1) {
      this._shownActions = this._shownActions.concat(this._dropdownActions);
      this._dropdownActions = [];
    }
  }

  /*
    Evaluates if the user has required access and if it should be added to the dropdown or the shown actions.
  */
  private addActions(counter, action, key) {
    if (action.access) {
      this._keys.push(key);
        counter++;
      if (counter < 6) {
        this._shownActions.push(action);
        this._shownActions[this._shownActions.length - 1].key = key;
      }
      else {
        this._dropdownActions.push(action);
        this._dropdownActions[this._dropdownActions.length - 1].key = key;
      }
    }
    return counter;
  }

  /*
    Navigates to the card specified within the action
  */
  public navigate(action, index) {
    this._keys.forEach(key => {
      if (Array.isArray(this.actions[key])) {
        this.actions[key].forEach(x => x.navigate = false);
        if (index >= 0) {
          this.actions[key][index].navigate = action == key;
        }
      }
      else {
        this.actions[key].navigate = action == key;
      }
    });
    window.scrollTo(0, 0);
  }

  /*
    Applies active status to the action
  */
  public isActive(key, index) {
    if (Array.isArray(this.actions[key])) {
      return this.actions[key][index].navigate ? 'active' : '';
    }
    return this.actions[key].navigate ? 'active' : '';
  }

}
