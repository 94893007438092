<swe-card [header]="languageService.getItem(893)" [open]="false" [hasCollapse]="true" [hasRefresh]="false" (openChange)="load()">
  <div>
    <swe-element [type]="'System.String'" [label]="'Namn'" [statusLabel]="4" [customCols]="8" [(model)]="timebank.Name" [access]="1"></swe-element>
    <swe-element [type]="'System.String'" [label]="'Intervall'" [statusLabel]="4" [customCols]="8" [(model)]="timebank.IntervalType" [access]="1"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="'Intervall (längd)'" [statusLabel]="4" [customCols]="8" [(model)]="timebank.IntervalLength" [access]="1"></swe-element>
    <swe-element [type]="'System.String'" [label]="'Hänsyn till dygnsbryt'" [statusLabel]="4" [customCols]="8" [(model)]="timebank.BreakType" [access]="1"></swe-element>
    <swe-element [type]="'System.Double'" [label]="'Tid som motsvarar heltid'" [statusLabel]="4" [customCols]="8" [(model)]="timebank.Max" [access]="1"></swe-element>
    <swe-element [type]="'System.Double'" [label]="'Max underskott av tid som flyttad till nästa period'" [statusLabel]="4" [customCols]="8" [(model)]="timebank.MinBalance" [access]="1"></swe-element>
    <swe-element [type]="'System.Double'" [label]="'Max överskott av tid som flyttad till nästa period'" [statusLabel]="4" [customCols]="8" [(model)]="timebank.MaxBalance" [access]="1"></swe-element>
  </div>
</swe-card>
