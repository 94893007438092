import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-lostpassword',
  templateUrl: './lostpassword.component.html',
})
export class LostPasswordComponent implements OnInit {

  private _mode: number = 0;
  private _modequery: number = 0;
  private _key: string = '';
  private _keyquery: string = '';
  private _username: string = '';
  private _password: string = '';
  private _passwordrepeat: string = '';

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) {

    this.route.paramMap.subscribe((params) => {
      //Path
      this._key = params.get('key');

      this._mode = this._key ? 1 : 0;
    });

    this.route.queryParams.subscribe((params) => {
      //Querystring
      this._keyquery = params['p'];

      this._modequery = this._keyquery ? 1 : 0;
    });
  }


  ngOnInit() {

  }


  //Properties
  public get mode() {
    let m = this._mode | this._modequery;
    return m;
  }
  public get username() {
    return this._username;
  }
  public set username(val) {
    this._username = val;
  }
  public get password() {
    return this._password;
  }
  public set password(val) {
    this._password = val;
  }
  public get passwordrepeat() {
    return this._passwordrepeat;
  }
  public set passwordrepeat(val) {
    this._passwordrepeat = val;
  }



  //Methods
  public send() {
    this.authenticationService.lostpasswordsend(this._username)
      .subscribe((res) => {
        this.alertService.Add({ type: 'success', message: res });
      });
  }
  public change() {
    if (typeof this._password == "undefined" || this._password.length == 0 || typeof this._passwordrepeat == "undefined" || this._passwordrepeat.length == 0) {
      this.alertService.Add({ type: 'danger', message: this.languageService.getItem(449) });
      return;
    }
    else if (this._password != this._passwordrepeat) {
      this.alertService.Add({ type: 'danger', message: this.languageService.getItem(448) });
      return;
    }

    let k = this._key;
    if (k == null || k.length == 0) {
      k = this._keyquery;
    }

    this.authenticationService.lostpasswordchange(k, this._password)
      .subscribe((res) => {
        this.alertService.Add({ type: 'success', message: this.languageService.getItem(453) });
      });
  }

}
