<swe-card header="{{languageService.getItem(953)}} ({{usercontracts.length -1}})" [(open)]="open" [hasRefresh]="true" (refreshChange)="reload()" [rulelist]="['SweUserContract: Read']" icon="bi-file-earmark-text-fill">
  <swe-element [type]="'System.List'" [label]="languageService.getItem(1292)" [statusLabel]="1" [(model)]="currentId" (modelChange)="manageContracts()" [items]="usercontracts"></swe-element>
  <swe-card [color]="'swe'" [hasCollapse]="false" [hasRefresh]="false">
    <div class="mb-3">
      <div class="row mb-3" *ngIf="userdata.ContractAccess>2&&current.Id!=0">
        <label class="col-{{this.permissionService.permissions.ProfileLabel}} col-form-label">
          <span>{{languageService.getItem(953)}}</span>
        </label>
        <label class="col-form-label col-{{12 - this.permissionService.permissions.ProfileLabel}}">
          <a [routerLink]="'/admin/contracts/' + current.ContractId">{{current.Name}}</a>
        </label>
      </div>
      <div class="row mb-3" *ngIf="userdata.ContractAccess>2&&current.Id!=0&&current.Url&&current.Url.length>0&&current.DigitalAccess>1">
        <label class="col-{{this.permissionService.permissions.ProfileLabel}} col-form-label">
          <span>{{languageService.getItem(807)}}</span>
        </label>
        <label class="col-form-label col-{{12 - this.permissionService.permissions.ProfileLabel}}">
          <a href="{{current.Url}}" target="_blank">{{current.Url}}</a>
        </label>
      </div>
      <swe-element [type]="'System.ContractSearch'" [label]="languageService.getItem(953)" [statusLabel]="1" [container]="{ loadOnClick: true }" [(model)]="current.ContractId" [display]="current.Name" [disabled]="userdata.ContractAccess<2||currentId>0" *ngIf="currentId==0"></swe-element>
      <swe-element [type]="'System.Date'" [label]="languageService.getItem(1191)" [statusLabel]="1" [(model)]="current.Start" [container]="{hideReset:true}" [disabled]="userdata.ContractAccess<2"></swe-element>
      <swe-element [type]="'System.Date'" [label]="languageService.getItem(1192)" [statusLabel]="1" [(model)]="current.End" [container]="{hideReset:true}" [disabled]="userdata.ContractAccess<2"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(958)" [statusLabel]="1" [(model)]="current.BreakpointWeekday" [items]="weekdays" [disabled]="userdata.ContractAccess<2"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(959)" [statusLabel]="1" [(model)]="current.BreakpointHour" [items]="hours" [disabled]="userdata.ContractAccess<2"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(1204)" [statusLabel]="1" [(model)]="current.StaffType" [items]="current.StaffTypeList" [disabled]="userdata.ContractAccess<2||current.StaffTypeList.length==1" *ngIf="current.StaffTypeList && current.StaffTypeList.length>0"></swe-element>
      <swe-element [type]="'System.List'" [label]="languageService.getItem(1207)" [statusLabel]="1" [(model)]="current.SalaryType" [items]="current.SalaryTypeList" [disabled]="userdata.ContractAccess<2||current.SalaryTypeList.length==1" *ngIf="current.SalaryTypeList && current.SalaryTypeList.length>0"></swe-element>
      <div class="d-grid gap-2">
        <swe-adminrule [rulelist]="['SweUserContract: Write']"></swe-adminrule>
        <button class="btn btn-primary" (click)="save()" *ngIf="userdata.ContractAccess>1">{{languageService.getItem(16)}}</button>
        <swe-adminrule [rulelist]="['SweUserContract: Delete']"></swe-adminrule>
        <swe-confirm [body]="languageService.getItem(1189)" (acceptChange)="delete()" *ngIf="currentId>0&&userdata.ContractAccess>2">
          <button class="btn btn-danger">{{languageService.getItem(17)}}</button>
        </swe-confirm>
      </div>
    </div>
    <swe-usertimebank [id]="id" [(open)]="cards.timebank" [contractId]="current.ContractId" [timebankId]="current.TimeBankId" [access]="userdata.TimeBankAccess" *ngIf="currentId>0&&current.TimeBankId>0&&userdata.TimeBankAccess>0"></swe-usertimebank>
    <swe-userlas [id]="id" [(open)]="cards.las" [lasId]="current.LasId" [access]="userdata.LasAccess" *ngIf="currentId>0&&current.LasId>0&&userdata.LasAccess>0"></swe-userlas>
    <swe-useremployments [id]="id" [(open)]="cards.employment" [contractStart]="current.Start" [contractEnd]="current.End" [contractId]="current.ContractId" [userContractId]="current.Id" [access]="userdata.EmploymentAccess" (reloadContract)="reload(true)" *ngIf="currentId>0&&userdata.EmploymentAccess>0"></swe-useremployments>
  </swe-card>
</swe-card>
