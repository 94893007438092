import { Component, OnInit, OnDestroy } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { DateTimeService } from '../_services/datetime.service';
import { AlertService } from '../_services/alert.service';
import { DataService } from '../_services/data.service';
import { Router, ActivatedRoute } from '@angular/router';

declare const tla: any;

@Component({
  selector: 'app-timeclock',
  templateUrl: './timeclock.component.html',
})
export class TimeClockComponent implements OnInit, OnDestroy {

  private _time: string;
  private _intervalId: any;
  private _mode: number = 0; //0 = TimeClock, 1 = fingerprint
  private _header: string = '';
  private _text: string = '';
  private _buttontext: string = '';
  private _debug: boolean = false;
  private _debuglist: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private datetimeService: DateTimeService,
    private dataService: DataService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.alertService.bigtext = true;
    this.alertService.all = true;
  }


  ngOnInit() {
    this._intervalId = setInterval(() => {
      this._time = this.datetimeService.format(new Date(), 'HH:mm:ss');
    }, 1000);

    let params = this.route.snapshot.queryParams;
    for (let name in params) {
      if (name.toLowerCase() == 'debug') {
        if (params[name].toLowerCase() == 'true' || params[name].toLowerCase() == '1') {
          this._debug = true;
        }
      }
    }

    this.setTexts();
    this.start();

    //this._mode = 1;
    //this.call('00004575796355', 0);
  }

  ngOnDestroy() {
    clearInterval(this._intervalId);
  }



  //Properties
  public get time() {
    return this._time;
  }
  public get mode() {
    return this._mode;
  }
  public get header() {
    return this._header;
  }
  public get text() {
    return this._text;
  }
  public get buttontext() {
    return this._buttontext;
  }
  public get isDebug() {
    return this._debug;
  }
  public get debuglist() {
    return this._debuglist;
  }



  //Methods
  public changemode() {
    if (this._mode == 1) {
      this._mode = 0;
    }
    else {
      this._mode = 1;
    }

    this.setTexts();
  }


  //Functions
  private call(externalid, id) {
    this.debug('Init call function');

    let dto = {
      ExternalId: externalid,
      Id: id
    };

    this.debug('External id:' + dto.ExternalId);
    this.debug('Id:' + dto.Id);

    let url = '/api/v1/timereports/timeclock';
    if (this._mode == 1) {
      url += '/users';
    }

    this.dataService.basicRequest(url, 'POST', dto, 'text', 'response')
      .subscribe((response) => {
        this.debug('Successfull call is made');

        if (this._mode == 0) {
          this.alertService.Add({ message: response.body, type: 'success' });
        }
        else {
          let res = JSON.parse(response.body);

          let url = 'http://localhost.local/app/index.php?' + this.manageName(res.Firstname + ' ' + res.Lastname) + '&' + res.Id;
          let win = window.open(url, '_self', 'screenX=1,screenY=1,left=1,top=1,menubar=0,location=0');
        }
        
      });
  }
  private manageName(str) {
    let res = '';

    for (let i = 0; i < str.length; i++) {
      let charVal = str.charCodeAt(i);
      res += charVal.toString();
      if (i < str.length - 1) {
        res += '-';
      }
    }

    return res;
  }
  private debug(line: string) {
    if (this._debug) {
      this._debuglist.unshift(line);
    }
  }


  private setTexts() {
    if (this._mode == 0) {
      this._header = this.languageService.getItem(1045);
      this._text = this.languageService.getItem(1046);
      this._buttontext = this.languageService.getItem(1052);
    }
    else {
      this._header = this.languageService.getItem(1052);
      this._text = this.languageService.getItem(1053);
      this._buttontext = this.languageService.getItem(711);
    }
  }


  //Brightec
  private start() {
    this.debug('Init start function');

    tla.ev.registercb(tla.ev.Alltype, (event) => {

      this.debug('Brightec Event thrown');

      var estat = tla.ev.eventstatus(event);  // Get the status of incomming event
      var eva = tla.ev.eventpayload(event);   // Get the payload of incomming event, content varies with event id

      this.debug('Brightec status: ' + estat);
      this.debug('Brightec payload: ' + eva);

      // check for rfid/fob identification event
      if (tla.ev.eventidCheck(event, tla.ev.Rfid)) {
        if (estat == "ok") {
          this.call(eva, 0);
        }
        else {
          this.alertService.Add({ message: this.languageService.getItem(1048), type: 'danger' });
        }
      }

      if (this._mode == 0) {
        // check for fingerprint identification event
        if (tla.ev.eventidCheck(event, tla.ev.Fingerprint)) {
          if (estat == "ok") {
            this.call('', eva);
          }
          else {
            this.alertService.Add({ message: this.languageService.getItem(1049), type: 'danger' });
          }
        }

        // Check for user message
        if (tla.ev.eventidCheck(event, tla.ev.Usermessage)) {
          if (estat == "ok") {
            this.call(eva, 0);
          }
          else {
            this.alertService.Add({ message: eva, type: 'danger' });
          }
        }
      }
    });
  }
  

}
