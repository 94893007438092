<div class="mb-3" [ngClass]="{'row': (cols > 0)}">
  <label *ngIf="statusLabel>0" [ngClass]="getLabelClass()">{{label}}</label>
  <div [ngClass]="getInputClass()">
    <ng-container *ngFor="let property of items; index as i">
      <div *ngIf="!property.Disabled">
        <i class="bi bi-trash-fill bi-swe-pull-right swe-click" (click)="remove(i)"></i>
        <swe-element [bottomMargin]="0" [(model)]="property.Id" (modelChange)="propertyChosen($event, i)" [label]="languageService.getItem(478)" [items]="properties" [type]="'System.GroupByList'"></swe-element>
        <swe-element [bottomMargin]="0" [(model)]="property.Option" [label]="languageService.getItem(478)" [items]="property.Options" [type]="'System.List'" *ngIf="showOptions"></swe-element>
        <swe-element [(model)]="property.Val" [label]="languageService.getItem(478)" [type]="property.Type" [items]="manageReload(property)" (enterChange)="refresh()"></swe-element>
      </div>
    </ng-container>
    <span class="swe-click" (click)="add()"><i class="bi bi-plus-circle-fill"></i>&nbsp;{{languageService.getItem(100)}}</span>
  </div>
</div>
