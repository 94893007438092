import { Component, OnChanges, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { PropertyService } from '../../_services/property.service';
import { PermissionService } from '../../_services/permission.service';


@Component({
  selector: 'swe-userstatistics',
  templateUrl: './userstatistics.component.html'
})
export class UserStatisticsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: number;
  @Input() open: boolean;

  private unsubsribe$ = new Subject<void>();
  private _charttype: ChartType = 'doughnut';
  private _shiftdata: ChartDataset[] = [];
  private _smsdata: ChartDataset[] = [];
  private _shiftlabels: string[] = [];
  private _smslabels: string[] = [];
  private _shiftcolors: any = [];
  private _smscolors: any = [];
  private _shiftoptions: ChartOptions = {};
  private _smsoptions: any = {};
  private _loading: boolean;
  private _push: number = 0;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService,
    private settingService: SettingService,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.load();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {
    this._shiftlabels = [
      this.languageService.getItem(242) + ': ',
      this.languageService.getItem(243) + ': ',
      this.languageService.getItem(247) + ': '
    ];

    this._shiftcolors = ['#198754', '#ffc107', '#dc3545'];

    this._shiftoptions = {
      plugins: {
        legend: {
          position: 'top',
          align: 'start'
        }
      },
      aspectRatio: 2,
      maintainAspectRatio: false
      
    }

    this._smslabels = [
      this.languageService.getItem(245) + ': ',
      this.languageService.getItem(244) + ': ',
      this.languageService.getItem(246) + ': '
    ];

    this._smscolors = ['#198754', '#ffc107', '#dc3545'];

    this._smsoptions = {
      plugins: {
        legend: {
          position: 'top',
          align: 'start'
        }
      },
      aspectRatio: 2,
      maintainAspectRatio: false
    }
  }

  ngOnChanges() {
    if (this.open) {
      this.load();
    }
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get charttype() {
    return this._charttype;
  }
  public get shiftdata() {
    return this._shiftdata;
  }
  public get smsdata() {
    return this._smsdata;
  }
  public get shiftlabels() {
    return this._shiftlabels;
  }
  public get smslabels() {
    return this._smslabels;
  }
  public get shiftcolors() {
    return this._shiftcolors;
  }
  public get smscolors() {
    return this._smscolors;
  }
  public get shiftoptions() {
    return this._shiftoptions;
  }
  public get smsoptions() {
    return this._smsoptions;
  }
  public get push() {
    return this._push;
  }
  public get chartplugins() {
    return [{
      afterDraw(chart) {
        let totalData = -1;
        let hasData = chart.config.data.datasets.length > 0;
        if (hasData) {
          totalData = chart.config.data.datasets[0].data.reduce(function (
            a,
            b
          ) {
            return a + b;
          },
            0);
        }
        if (totalData === 0) {
          const {
            chartArea: { left, top, right, bottom },
            ctx
          } = chart;
          ctx.save(); // Save the current canvas state

          // Calculate the center of the chart
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;

          // Calculate the radii of the inner and outer circles of the doughnut
          const outerRadius = Math.min(right - left, bottom - top) / 2;
          const innerRadius = outerRadius - 18; // Adjust this value as needed

          // Calculate the positions for the starting and ending points of the line
          const lineStartX = centerX;
          const lineStartY = centerY - outerRadius;
          const lineEndX = centerX;
          const lineEndY = centerY - innerRadius;

          // Draw the outer arc (grey doughnut ring)
          ctx.beginPath();
          ctx.arc(centerX, centerY, outerRadius, 0, 2 * Math.PI);
          ctx.fillStyle = 'rgba(216, 216, 216, 1)';
          ctx.fill();

          // Draw the inner arc (to clear the inner circle)
          ctx.beginPath();
          ctx.arc(centerX, centerY, innerRadius, 0, 2 * Math.PI);
          ctx.fillStyle = 'rgba(255, 255, 255, 1)'; // Fill with white to clear the inner circle
          ctx.fill();

          // Draw the white line break from outer circle to inner circle
          ctx.beginPath();
          ctx.moveTo(lineStartX, lineStartY);
          ctx.lineTo(lineEndX, lineEndY);
          ctx.lineWidth = 2; // Adjust the line width as needed
          ctx.strokeStyle = 'rgba(255, 255, 255, 1)'; // White color
          ctx.stroke();

          ctx.restore(); // Restore the canvas state
        }
      }
    }];
  }




  /*Methods*/
  public load() {

    if (!this.open) { return; }

    this._loading = true;

    let filter = {
      Start: this.settingService.start('booking'),
      End: this.settingService.end('booking'),
      CheckIn: this.settingService.booking.checkin,
      Reservation: this.settingService.booking.reservation,
      ReplaceableAsNoSlot: this.settingService.booking.replaceableasnoslot,
      LevelList: this.settingService.levelList(2),
      StatusList: this.settingService.booking.status
    };

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/statistics', 'POST', filter)
      .subscribe(res => {

        this._shiftdata = [{ data: [res.Shifts, res.Standby, res.Abscent], backgroundColor: this._shiftcolors }];
        this._shiftlabels = [
          this.languageService.getItem(242) + ': ' + res.Shifts,
          this.languageService.getItem(243) + ': ' + res.Standby,
          this.languageService.getItem(247) + ': ' + res.Abscent
        ];

        this._smsdata = [{ data: [res.Yes, res.Sms, res.No], backgroundColor: this._smscolors }];
        this._smslabels = [
          this.languageService.getItem(245) + ': ' + res.Yes,
          this.languageService.getItem(244) + ': ' + res.Sms,
          this.languageService.getItem(246) + ': ' + res.No
        ];

        this._push = res.Push;

        this._loading = false;
      });

    
  }
  

}
