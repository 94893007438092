<swe-card [header]="languageService.getItem(2)" [(open)]="open" (refreshChange)="search(false)" (openChange)="search(false)" [rulelist]="['SweLevelUsers']" icon="bi-people-fill">
  <span swe-hits *ngIf="open">({{hits}})</span>
  <swe-filteritems></swe-filteritems>
  <div class="list-group mt-2">
    <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-warning swe-scroll-to': user.Id==settingService.lastuser}" *ngFor="let user of users" (click)="openUser(user.Id, $event)">
      <div class="col-3 col-md-1">
        <img class="swe-circle-image" alt="{{user.Firstname}} {{user.Lastname}}" sweImage imageFolder="userimages" [imageFile]="user.Image" imageWidth="32" imageHeight="32" *ngIf="user.Image.length>0">
        <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="user.Image.length==0">
          <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
          <span class="bi-swe-stack-1x bi-swe-letter">{{user.Firstname[0]}}</span>
        </span>
      </div>
      <div class="col-9 col-md-11 d-flex flex-wrap">
        <div class="col-12 col-md-3">{{user.Username}}</div>
        <div class="col-12 col-md-3">{{user.Firstname}} {{user.Lastname}}</div>
        <div class="col-12 col-md-3">{{user.Cell}}</div>
        <div class="col-12 col-md-3">{{user.Email}}</div>
      </div>
    </a>
    <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
</swe-card>
