<swe-card [header]="languageService.getItem(2)" [open]="false" [hasCollapse]="true" [hasRefresh]="true" (refreshChange)="search()" (openChange)="search()">
  <swe-datepager [type]="'employment'" (dateChange)="search()" [disabled]="isloading" [hideTime]="true"></swe-datepager>
  <div class="btn-group align-top ms-1">
    <button class="btn btn-swe" type="button" (click)="exclude=!exclude">
      <span><i class="bi" [ngClass]="{'bi-check-square': exclude, 'bi-square': !exclude}"></i>&nbsp;{{languageService.getItem(1075)}}</span>
    </button>
  </div>
  <swe-list [data]="users" [loading]="isloading">
    <ng-container *ngFor="let user of users">
      <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-danger': user.Employments.length==0}" [attr.title]="getTitle(user)" *ngIf="user.Employments.length>0||!exclude" (click)="goto(user.Id, $event)">
        <div class="col-12 col-md-6">{{user.FullName}}</div>
        <div class="col-12 col-md-6">
          <div class="col-12 d-flex flex-wrap" *ngFor="let employment of user.Employments">
            <div class="col-12 col-md-6">{{employment.Name}}</div>
            <div class="col-6 col-md-3">{{dateTimeService.format(employment.Start)}}</div>
            <div class="col-6 col-md-3">{{dateTimeService.format(employment.End)}}</div>
          </div>
        </div>
      </a>
    </ng-container>
  </swe-list>
</swe-card>
