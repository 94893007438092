<swe-card [header]="languageService.getItem(575)" [open]="false" [hasCollapse]="true" [hasRefresh]="true" refreshChange="load()" (openChange)="load()">
  <button swe-rightcommand class="btn btn-success bi-swe-pull-right mb-2" (click)="goto(0)" *ngIf="permissionService.user.IsSuper"><i class="bi bi-plus-lg bi-swe-fw swe-click"></i>{{languageService.getItem(100)}}</button>

  <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let rule of timerules" (click)="goto(rule.Id)" [ngClass]="{'list-group-item-danger': !rule.IsActive}">
    <div class="col-6 col-md-6">{{rule.Desc}}</div>
    <div class="col-6 col-md-6 d-flex flex-wrap">
      <div class="col-12 col-md-4">{{rule.Type == 0 ? languageService.getItem(599) : languageService.getItem(600)}}</div>
      <div class="col-12 col-md-4">{{manageWeekday(rule.BreakWeekday)}}</div>
      <div class="col-12 col-md-4">{{manageHour(rule.BreakHour)}}</div>
    </div>
  </a>
</swe-card>
