import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { tap, catchError, shareReplay, finalize, timeout } from 'rxjs/operators';

import { TokenService } from "./token.service";
import { AlertService } from "./alert.service";

@Injectable({ providedIn: 'root' })
export class DataService {

  private isloading = false;
  private islogout = false;
  private _isdebugging = false;
  private imageCache = {};
  private _culture = null;

  constructor(
    private tokenService: TokenService,
    private alertService: AlertService,
    private http: HttpClient
  ) {
    
  }

  public set culture(val) { this._culture = val; }


  public basicRequest(url: string, method: string, parameter?: any, responsetype: any = 'json', observe: any = 'body'): Observable<any> {

    let httpOptions = {
      headers : {
        "Authorization": this.tokenService.authBasic,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "Pragma": "no-cache"
      },
      responseType: responsetype,
      observe: observe
    };

    if (this._culture) {
      httpOptions.headers['Accept-Language'] = this._culture;
    }
    
    return this.sendRequest(url, method, parameter, httpOptions);
  }

  public tokenRequest(url: string, method: string, parameter?: any, responsetype: any = 'json', observe: any = 'body'): Observable<any> {

    let httpOptions = {
      headers: {
        "Authorization": this.tokenService.authBearer,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "Pragma": "no-cache"
      },
      responseType: responsetype,
      observe: observe
    };

    if (localStorage.getItem('debug')) {
      httpOptions.headers["Debug"] = 'true';
    }

    if (this._culture) {
      httpOptions.headers['Accept-Language'] = this._culture;
    }

    return this.sendRequest(url, method, parameter, httpOptions);
  }
  public imageRequest(url: string): Observable<any> {

    if (this.imageCache[url]) {
      return this.imageCache[url];
    }

    let httpOptions : any = {
      headers: {
        "Authorization": this.tokenService.authBearer,
        "Content-Type": "application/json",
        
      },
      responseType: "blob"
    };


    this.imageCache[url] = this.http.get<any>(url, httpOptions).pipe(
      shareReplay(1),
      catchError(error => {
        delete this.imageCache[url];
        return EMPTY;
      })
    );

    return this.imageCache[url];
  }


  //Properties
  public get IsLoading() {
    return this.isloading;
  }
  public set IsLoading(val) {
    this.isloading = val;
  }
  public get IsLogout() {
    return this.islogout;
  }
  public set IsLogout(val) {
    this.islogout = val;
  }


  private sendRequest(url: string, method: string, parameter: any, httpOptions: any) : Observable<any> {

    //Delay loading indicator
    let status = { isloading: true };
    let timeout = setTimeout((url) => {
      if (status.isloading && !this.islogout) {
        this.isloading = true;
        console.log('The call takes a long time (' + url + ')');
      }
    }, 2000, [url]);

    if (method.toUpperCase() == "GET") {
      return this.http.get<any>(url, httpOptions).pipe(
        tap(res => {
          this.success(status);
        }),
        catchError(error => {
          if (error.status != 401) {
            return throwError(this.failure(status, error));
          }
          else {
            return EMPTY;
          }
        }),
        finalize(() => {
          this.isloading = status.isloading = false;
        })
      );
    }
    else if (method.toUpperCase() == "POST") {
      return this.http.post<any>(url, parameter, httpOptions).pipe(
        tap(res => {
          this.success(status);
        }),
        catchError(error => {
          if (error.status != 401) {
            return throwError(this.failure(status, error));
          }
          else {
            return EMPTY;
          }
        }),
        finalize(() => {
          this.isloading = status.isloading = false;
        })
      );
    }
    else if (method.toUpperCase() == "PUT") {
      return this.http.put<any>(url, parameter, httpOptions).pipe(
        tap(res => {
          this.success(status);
        }),
        catchError(error => {
          if (error.status != 401) {
            return throwError(this.failure(status, error));
          }
          else {
            return EMPTY;
          }
        }),
        finalize(() => {
          this.isloading = status.isloading = false;
        })
      );
    }
    else if (method.toUpperCase() == "DELETE") {
      return this.http.delete<any>(url, httpOptions).pipe(
        tap(res => {
          this.success(status);
        }),
        catchError(error => {
          if (error.status != 401) {
            return throwError(this.failure(status, error));
          }
          else {
            return EMPTY;
          }
        }),
        finalize(() => {
          this.isloading = status.isloading = false;
        })
      );
    }

  }



  /*Mange Result*/
  private success(status: { isloading: boolean }) {

    if (localStorage.getItem('debug') && !this._isdebugging) {
      this._isdebugging = true;

      //Delay debug call
      setTimeout(() => {
        this.getdebug();
      }, 1000);
    }

    this.isloading = status.isloading = false;
  }
  private failure(status: { isloading: boolean }, error) {

    this.isloading = status.isloading = false;

    this.alertService.Add({ message: error.error, type: 'danger' });

    let reason = '';
    if (error.headers) {
      reason = error.headers.get("SweReason");
    }

    if ((reason == null || reason.length == 0)) {
      if (error.error) {
        reason = error.error;
      }
      else {
        reason = error.status;
      }
    }

    return reason || '';
    
  }


  //Debug
  private getdebug() {

    let httpOptions: any = {
      Authorization: "Bearer " + this.tokenService.authBearer,
      Debug: 'true'
    };

    return this.http.get<any>('/api/v1/security/debug', httpOptions)
      .subscribe((res:any) => {

        res.forEach((item) => {

          let type = 'info';
          if (item.State == 0) {
            type = 'info';
          }
          else if (item.State == 1) {
            type = 'success';
          }
          else if (item.State == 2) {
            type = 'warning';
          }
          else if (item.State == 3) {
            type = 'danger';
          }

          this.alertService.Add({ type: type, message: item.Message });
        });

        this._isdebugging = false;
      });
  }

}
