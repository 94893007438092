import { Injectable } from '@angular/core';
import { ListService } from './list.service';

@Injectable({ providedIn: 'root' })
export class ViewCacheService {

  private _cache: any[] = [];

  constructor(private listService: ListService) { }


  //Methods
  public get(name: string) : ViewCacheItem {
    let item: ViewCacheItem = this.listService.find(this._cache, 'name', name, true);

    return item;
  }
  public add(name: string, value: any) {
    let item: ViewCacheItem = this.listService.find(this._cache, 'name', name);
    if (item) {
      item.value = value;
    }
    else {
      this._cache.push({ name: name, value: value });
    }
  }
}

export class ViewCacheItem {
  public name: string;
  public value: any;
}
