<swe-card header="{{languageService.getItem(6)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="true">
  <swe-card [header]="languageService.getItem(1321)" [color]="'swe'" [open]="true" [hasRefresh]="false" [hasCollapse]="true">
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1322)" [statusLabel]="1" [(model)]="settings.PeriodFee" [access]="permissionService.user.IsSuper?3:1"></swe-element>
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1323)" [statusLabel]="1" [(model)]="settings.ConsultantFee" [access]="permissionService.user.IsSuper?3:1"></swe-element>
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1324)" [statusLabel]="1" [(model)]="settings.SmsFee" [access]="permissionService.user.IsSuper?3:1"></swe-element>
  </swe-card>
  <swe-card [header]="languageService.getItem(1325)" [color]="'swe'" [open]="true" [hasRefresh]="false" [hasCollapse]="true">
    <swe-element [type]="'System.List'" [label]="languageService.getItem(1326)" [statusLabel]="1" [(model)]="settings.Period" [items]="periods" [access]="permissionService.user.IsSuper?3:1"></swe-element>
    <swe-element [type]="'System.Boolean'" [label]="languageService.getItem(1328)" [statusLabel]="1" [(model)]="settings.AdvanceBilling" [access]="permissionService.user.IsSuper?3:1" [hint]="languageService.getItem(1353)"></swe-element>
    <swe-element [type]="'System.List'" [label]="languageService.getItem(1327)" [statusLabel]="1" [(model)]="settings.SmsPeriod" [items]="periods" [optional]="true" [optionalValue]="0" [access]="permissionService.user.IsSuper?3:1"></swe-element>
  </swe-card>
  <swe-card [header]="languageService.getItem(1329)" [color]="'swe'" [open]="true" [hasRefresh]="false" [hasCollapse]="true">
    <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1330)" [statusLabel]="1" [(model)]="settings.IncludeVolume" [access]="permissionService.user.IsSuper?3:1"></swe-element>
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1331)" [statusLabel]="1" [(model)]="settings.ExtraObjectFee" [access]="permissionService.user.IsSuper?3:1"></swe-element>
  </swe-card>
  <swe-card [header]="languageService.getItem(1332)" [color]="'swe'" [open]="true" [hasRefresh]="false" [hasCollapse]="true">
    <swe-element [type]="'System.String'" [label]="languageService.getItem(1333)" [statusLabel]="1" [(model)]="settings.IndexName" [access]="permissionService.user.IsSuper?3:1"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1334)" [statusLabel]="1" [(model)]="settings.BaseIndexMonth" [access]="permissionService.user.IsSuper?3:1"></swe-element>
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1335)" [statusLabel]="1" [(model)]="settings.BaseIndexValue" [access]="permissionService.user.IsSuper?3:1"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1336)" [statusLabel]="1" [(model)]="settings.CurrentIndexMonth" [access]="permissionService.user.IsSuper?3:1"></swe-element>
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1337)" [statusLabel]="1" [(model)]="settings.CurrentIndexValue" [access]="permissionService.user.IsSuper?3:1"></swe-element>
  </swe-card>
  <swe-card [header]="languageService.getItem(1338)" [color]="'swe'" [open]="true" [hasRefresh]="false" [hasCollapse]="true">
    <swe-element [type]="'System.Int32'" [label]="languageService.getItem(1339)" [statusLabel]="1" [(model)]="settings.CustomerNr" [access]="permissionService.user.IsSuper?3:1"></swe-element>
    <swe-element [type]="'System.Date'" [label]="languageService.getItem(1340)" [statusLabel]="1" [(model)]="settings.DateTimeFirstInvoice" [access]="permissionService.user.IsSuper?3:1"></swe-element>
    <swe-element [type]="'System.String'" [label]="languageService.getItem(1341)" [statusLabel]="1" [(model)]="settings.InvoiceNote" [access]="permissionService.user.IsSuper?3:1"></swe-element>
  </swe-card>
  <div class="d-grid gap-2" *ngIf="permissionService.user.IsSuper">
    <button class="btn btn-primary" (click)="save()"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
  </div>
</swe-card>
