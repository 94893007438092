import { Component, Input, OnChanges } from '@angular/core';
import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';

@Component({
  selector: 'swe-levelsettings',
  templateUrl: './levelsettings.component.html'
})
export class LevelSettingsComponent implements OnChanges {
  @Input() id: number;
  @Input() open: boolean;
  @Input() leveldata: any;

  private _newRegistrationEmail: string = "";
  private _changeEmail: string = "";
  private _changeSms: string = "";
  private _access: number = 0;
  private _settingaccess: number = 0;
  
  constructor(public languageService: LanguageService, private dataService: DataService, private alertService: AlertService, private settingService: SettingService) {

  }

  ngOnChanges() {
    if (this.leveldata) {
      this._newRegistrationEmail = this.leveldata.NewRegistrationEmail;
      this._changeEmail = this.leveldata.BookingChangeEmail;
      this._changeSms = this.leveldata.BookingChangeSms;
      this._access = this.leveldata.Access;
      this._settingaccess = this.leveldata.SettingAccess;
    }
  }

  //Properties
  public get newRegistrationEmail() { return this._newRegistrationEmail; }
  public set newRegistrationEmail(val) { this.leveldata.NewRegistrationEmail = this._newRegistrationEmail = val; }
  public get changeEmail() { return this._changeEmail; }
  public set changeEmail(val) { this.leveldata.BookingChangeEmail = this._changeEmail = val; }
  public get changeSms() { return this._changeSms; }
  public set changeSms(val) { this.leveldata.BookingChangeSms = this._changeSms = val; }
  public get access() { return this._access; }
  public get settingaccess() { return this._settingaccess; }
  
  //Methods
  public save() {
    this.settingService.viewrefresh('levelsettings_save', this.leveldata);
  }


  //Functions
}
