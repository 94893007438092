import { Component, Input, EventEmitter, Output, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';

import { LanguageService } from '../_services/language.service';

@Component({
  selector: 'swe-confirm',
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent implements OnInit, OnChanges {
  @Output() acceptChange = new EventEmitter<any>();
  @Output() declineChange = new EventEmitter<any>();
  @Input() isConfirm: boolean = true;
  @Input() isAlert: boolean = false;
  @Input() okText: string = '';
  @Input() yesText: string = '';
  @Input() noText: string = '';
  @Input() header: string = '';
  @Input() body: string = '';
  @Input() isvisible: boolean = false;
  @Input() hideClose: boolean = false;
  @Input() event: any = null;
  @Input() isbutton: boolean = true;
  @Input() affectedEntities: string[] = null;
  @Input() affectedHeader: string;
  @ViewChild('btnAccept') focusElement: ElementRef;

  private _visible: boolean = false;

  constructor(public languageService: LanguageService) {

  }

  ngOnInit() {
    if (!this.okText) {
      this.okText = this.languageService.getItem(189);
    }
    if (!this.yesText) {
      this.yesText = this.languageService.getItem(190);
    }
    if (!this.noText) {
      this.noText = this.languageService.getItem(146);
    }
    if (!this.header) {
      this.header = '';
    }
    if (!this.body) {
      this.body = '';
    }

    
  }

  ngOnChanges() {
    if (this._visible != this.isvisible) {
      this._visible = this.isvisible;

      if (this._visible) {
        this.manageFocus();
      }
    }
  }


  //Properties
  public get visible() {
    return this._visible;
  }


  //Methods
  public open(e) {
    e.stopPropagation();

    if (this.isConfirm) {
      this._visible = true;
      this.manageFocus();
    }
    else {
      this.acceptChange.emit(this.event ? this.event : true);
    }
  }
  public close(e) {
    e.stopPropagation();
    this._visible = false;
    this.declineChange.emit(true);
  }
  public accept(e) {
    e.stopPropagation();
    this._visible = false;
    this.acceptChange.emit(this.event ? this.event : true);
  }
  public decline(e) {
    e.stopPropagation();
    this._visible = false;
    this.declineChange.emit(this.event ? this.event : true);
  }



  //Functions
  private manageFocus() {
    setTimeout(() => {
      if (this.focusElement) {
        let element = this.focusElement.nativeElement;
        element.focus();
        if (element.select) {
          element.select();
        }
      }
    }, 0); //Create a macrotask that will run in the next VM turn
  }

}
