import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { DataService } from '../../../_services/data.service';
import { SettingService } from '../../../_services/setting.service';



@Component({
  selector: 'swe-contracttimerules',
  templateUrl: './contracttimerules.component.html'
})
export class ContractTimeRulesComponent implements OnInit {

  @Input() id: number;

  private _timerules: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private dataService: DataService,
    private router: Router
  ) {

    
    
  }


  ngOnInit() {
    
  }



  //Properties
  public get timerules() {
    return this._timerules;
  }



  //Methods
  public load() {

    this.dataService.tokenRequest('/api/v1/system/timerules/contracts/' + this.id, 'GET', {})
      .subscribe((res) => {

        this._timerules = res;
      });

  }
  public manageWeekday(day) {
    let name = [
      this.languageService.getItem(388),
      this.languageService.getItem(389),
      this.languageService.getItem(390),
      this.languageService.getItem(391),
      this.languageService.getItem(392),
      this.languageService.getItem(393),
      this.languageService.getItem(394),
    ][day];

    return name;
  }
  public manageHour(hour) {
    let res = hour;
    if (hour < 10) {
      res = '0' + res;
    }

    return res + ':00';
  }
  public goto(atkid: number) {

    if (this.permissionService && this.permissionService.user.IsSuper) {
      this.router.navigate(['/systemadmin/timerules/' + atkid + '/contract/' + this.id]);
    }
  }
}
