import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { SettingService } from '../../_services/setting.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';



@Component({
  selector: 'app-employments',
  templateUrl: './employments.component.html'
})
export class EmploymentsComponent implements OnInit {

  private _employments: any[] = [];
  private _newemployment: any = {};
  
  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private settingService: SettingService,
    private alertService: AlertService,
    private dataService: DataService
  ) {
    //Reset
    settingService.initView();

  }


  ngOnInit() {
    this.loademployments();
  }



  //Properties
  public get employments() {
    return this._employments;
  }
  public get newemployment() {
    return this._newemployment;
  }




  //Methods
  public loademployments() {

    this._newemployment = { Id: 0, Name: '' };

    this.dataService.tokenRequest('/api/v1/contracts/employmentcategories', 'GET', {})
      .subscribe((res) => {
        this._employments = res;
      });
  }
  public saveemployment(employment) {

    let verb = 'POST';
    let path = '/api/v1/contracts/employmentcategories/';
    if (employment.Id > 0) {
      verb = 'PUT';
      path += employment.Id;
    }

    this.dataService.tokenRequest(path, verb, employment, 'text', 'response')
      .subscribe(response => {
        if (response) {
          this.alertService.Add({ message: response.body, type: 'success' });

          this.loademployments();
        }

      });

  }
  public deleteemployment(id) {
    this.dataService.tokenRequest('/api/v1/contracts/employmentcategories/' + id, 'DELETE', {}, 'text')
      .subscribe(res => {
        if (res) {

          this.alertService.Add({ message: res, type: 'success' });

          this.loademployments();
        }
      });
  }


  //Functions
  

}
