import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { ListService } from '../_services/list.service';



@Component({
  selector: 'swe-color',
  templateUrl: './color.component.html',
})
export class ColorComponent implements OnInit {
  @Input() label: string;
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() items: any = [];
  @Input() changed: boolean;
  @Input() disabled: boolean;

  private _open: boolean;
  private _current: any = {};

  constructor(private languageService: LanguageService, private listService: ListService) {

  }

  ngOnInit() {

    if (this.model && this.items) {
      this._current = this.listService.find(this.items, 'Id', this.model); 
    }

    if (!this._current || !this._current.Name) {
      this._current = {
        Name: this.languageService.getItem(739)
      };
    }
  }

  /*Properties*/
  public get open(): boolean {
    return this._open;
  }
  public get current(): any {
    return this._current;
  }
  

  /*Methods*/
  public toggle() {
    this._open = !this._open;
  }
  public select(item: any) {
    this._open = false;
    this._current = item;
    this.model = item.Id;
    this.modelChange.emit(this.model);
  }



  /*Functions*/

}
