import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { SettingService } from '../../../_services/setting.service';
import { DataService } from '../../../_services/data.service';
import { AlertService } from '../../../_services/alert.service';
import { DateTimeService } from '../../../_services/datetime.service';
import { GeneralService } from '../../../_services/general.service';



@Component({
  selector: 'swe-economystatistics',
  templateUrl: './economystatistics.component.html'
})
export class EconomyStatisticsComponent implements OnInit {

  private _statistics: any[] = [];
  private _summary: StatSummary = { Sms: 0, ActiveLevels: 0, InActiveLevels: 0, ActiveUsers: 0, InActiveUsers: 0 };

  private _loading: boolean = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public dateTimeService: DateTimeService,
    public generalService: GeneralService,
    private settingService: SettingService,
    private dataService: DataService
  ) {
    //Reset
    settingService.initView();

  }


  ngOnInit() {
    this.search();
  }



  //Properties
  public get statistics() {
    return this._statistics;
  }
  public get summary() {
    return this._summary;
  }
  public get loading() {
    return this._loading;
  }




  //Methods
  public search() {
    this._loading = true;

    let filter = {
      Start: this.settingService.start('booking'),
      End: this.settingService.end('booking')
    };

    this.dataService.tokenRequest('/api/v1/statistics/search/', 'POST', filter)
      .subscribe((res) => {

        this._statistics = res;

        this._summary = { Sms: 0, ActiveLevels: 0, InActiveLevels: 0, ActiveUsers: 0, InActiveUsers: 0 };

        this._statistics.forEach((stat) => {
          this._summary.Sms += stat.Sms;
          this._summary.ActiveLevels += stat.ActiveLevels;
          this._summary.InActiveLevels += stat.InActiveLevels;
          this._summary.ActiveUsers += stat.ActiveUsers;
          this._summary.InActiveUsers += stat.InActiveUsers;
        });

        this._loading = false;
      });
  }




  //Functions
  

}

export class StatSummary {
  public Sms: Number;
  public ActiveLevels: Number;
  public InActiveLevels: Number;
  public ActiveUsers: Number;
  public InActiveUsers: Number;
}
