<div class="dropdown d-inline-block align-top" (keydown)="keydown($event)">
  <button class="btn" [ngClass]="{'btn-warning': changed, 'btn-swe': !changed}" (click)="toggle()" (focus)="show()" [disabled]="disabled" *ngIf="!edit">{{formatDate}}</button>
  <input type="text" class="swe-textbox-as-button" [(ngModel)]="formatDate" [ngClass]="{'bg-warning': changed}" [disabled]="disabled" (click)="toggle()" *ngIf="edit" #txtDate />
  <div class="dropdown-menu py-0" [ngClass]="{'show': open, 'swe-contents': open&&alwaysshowcontents}">
    <table class="table table-responsive table-bordered mb-0">
      <thead>
        <tr>
          <td (click)="prev()" class="swe-click"><i class="bi bi-swe-fw bi-caret-left-fill"></i></td>
          <th colspan="6" class="text-center">
            <a (click)="toggleMonth()" *ngIf="!openMonth">{{currentMonthName}}</a>
            <select [attr.aria-label]="languageService.getItem(528)" class="form-control-static" [(ngModel)]="currentMonth" (ngModelChange)="toggleMonth()" *ngIf="openMonth">
              <option *ngFor="let month of months" [value]="month.Id">{{month.Name}}</option>
            </select>
            &nbsp;
            <a (click)="toggleYear()" *ngIf="!openYear">{{currentYear}}</a>
            <select [attr.aria-label]="languageService.getItem(694)" class="form-control-static" [(ngModel)]="currentYear" (ngModelChange)="toggleYear()" *ngIf="openYear">
              <option *ngFor="let year of years" [value]="year">{{year}}</option>
            </select>
          </th>
          <td (click)="next()" class="swe-click"><i class="bi bi-swe-fw bi-caret-right-fill"></i></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of days">
          <td class="small swe-bg-grayed-out" *ngIf="row">{{weeknumber(row[0].date)}}</td>
          <td *ngFor="let col of row" class="swe-click text-end" [ngClass]="{'bg-primary': col.current, 'swe-grayed-out': col.outside}" (click)="select(col.date)">{{col.date.getDate()}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="swe-element-clickable-area swe-element-clickable-area-md" *ngIf="open" (click)="close()"></div>
