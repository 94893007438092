<div class="dropdown d-inline-block align-top">
  <button class="btn" [ngClass]="{'btn-warning': changed, 'btn-swe': !changed}" (click)="toggle()" (focus)="show()" *ngIf="!open" [disabled]="disabled">
    {{formatTime}}
  </button>
  <div *ngIf="open">
    <input [attr.aria-label]="label" type="text" class="form-control d-inline-block swe-time" [(ngModel)]="formatTime" (blur)="blur()" (keyup)="keyup($event)" #txtTime />
    <div class="dropdown-menu py-0" [ngClass]="{'show': open}">
      <div class="list-group swe-scrollable swe-click">
        <a class="list-group-item" [ngClass]="{'list-group-item-info': item.current}" *ngFor="let item of list;index as i" (click)="select(item.time)" id="{{labelWithoutWhitespace}}_{{i}}">{{item.time}}</a>
      </div>
    </div>
  </div>
</div>
<div class="swe-element-clickable-area swe-element-clickable-area-md" *ngIf="open" (click)="close()"></div>
