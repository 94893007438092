import { Component, Input, OnChanges } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { DataService } from '../_services/data.service';
import { LanguageService } from '../_services/language.service';
import { SettingService } from '../_services/setting.service';


@Component({
  selector: 'swe-smsstatistics',
  templateUrl: './smsstatistics.component.html',
})
export class SmsStatisticsComponent implements OnChanges {
  @Input() start: string;
  @Input() end: string;
  private _chartdata: ChartDataset[] = [];
  private _chartlabels: string[] = [];
  private _data: any;
  private _showInterval: boolean;
  private _chartoptions: ChartOptions = {
    responsive: true,
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 50
      }
    },
    aspectRatio: 6,
  };
  private _chartlegend: boolean = true;
  private _open: boolean = false;
  private _selectedStart: string;
  private _precision: number = 0;
  constructor(private dataService: DataService, public languageService: LanguageService, private settingService: SettingService) {
  }

  ngOnChanges(e) {
    if (this._open && e.start || e.end) {
      this.loadStatistics();
    }
  }


  public get chartdata() {
    return this._chartdata;
  }
  public get chartlabels() {
    return this._chartlabels;
  }
  public get showInterval() {
    return this._showInterval;
  }
  public set showInterval(val) {
    this._showInterval = val;
  }
  public get chartoptions() {
    return this._chartoptions;
  }
  public get chartlegend() {
    return this._chartlegend;
  }

  public get selectedStart() {
    return this._selectedStart;
  }
  public set selectedStart(val) {
    this._selectedStart = val;
  }

  public get precision() {
    return this._precision;
  }
  public set precision(val) {
    this._precision = val;
  }
  public get chartplugins() {
    return [{
      beforeDraw(chart) {
        console.log(chart);
        const ctx = chart.ctx;
        const canvas = chart.canvas;

        //ctx.save();
        ctx.fillStyle = '#FFFFFF';

        ctx.fillRect(0, 0, canvas.clientWidth, canvas.clientHeight);
        //ctx.restore();
      }
    }];
  }


  public toggleOpen() {
    this._open = !this._open;
    if (this._open) {
      this.loadStatistics();
    }
  }

  public get interval() {
    if (this._precision == 0) { return this.languageService.getItem(528); }
    else if (this._precision == 1) { return this.languageService.getItem(527); }
    else if (this._precision == 2) { return this.languageService.getItem(526); }
  }

  public changePrecision(precision) {
    if (precision == this._precision) {
      return;
    }
    this._precision = precision;

    this.manageChart();
  }
  public manageChart() {
    let groups;
    this._chartdata = [];
    this._chartlabels = [];
    switch (this._precision) {
      case 0:
        groups = this._data.reduce((x, y) => {
          (x[y.Month] = x[y.Month] || []).push(y);
          return x;
        }, {});
        break;
      case 1:
        groups = this._data.reduce((x, y) => {
          (x[y.Week] = x[y.Week] || []).push(y);
          return x;
        }, {});
        break;
      case 2:
        groups = this._data;
        break;
    }
    let incoming = [];
    let outgoing = [];
    let total = [];
    let credits = [];

    if (this._precision == 2) {
      groups.forEach(x => {
        this._chartlabels.push(x.Date);
        incoming.push(x.Incoming);
        outgoing.push(x.Outgoing);
        total.push(x.Total);
        credits.push(x.Credits);
      })
    } else {
      Object.keys(groups).forEach(x => {
        this._chartlabels.push(x);
        let i = 0; let o = 0; let t = 0; let c = 0;
        groups[x].forEach(y => {
          i += y.Incoming;
          o += y.Outgoing;
          t += y.Total;
          c += y.Credits;
        });
        incoming.push(i);
        outgoing.push(o);
        total.push(t);
        credits.push(c);
      });
    }
    this._chartdata[0] = { label: `${this.languageService.getItem(1369)} (${incoming.reduce((pv, cv) => pv + cv, 0)})`, data: incoming };
    this._chartdata[1] = { label: `${this.languageService.getItem(1370)} (${outgoing.reduce((pv, cv) => pv + cv, 0)})`, data: outgoing };
    this._chartdata[2] = { label: `${this.languageService.getItem(1371)} (${total.reduce((pv, cv) => pv + cv, 0)})`, data: total };
    this._chartdata[3] = { label: `${this.languageService.getItem(1372)} (${credits.reduce((pv, cv) => pv + cv, 0)})`, data: credits };
  }


  public loadStatistics() {
    this.dataService.basicRequest(`api/v1/messages/sms/statistics?start=${this.start.split('T')[0]}&end=${this.end.split('T')[0]}`, 'GET').subscribe(res => {
      if (res) {
        this._data = res;
        this.manageChart();
      }
    })
  }
}
