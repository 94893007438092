import { Component, Input, OnInit } from '@angular/core';

import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';

@Component({
  selector: 'swe-reportcell',
  templateUrl: './reportcell.component.html'
})
export class ReportCellComponent implements OnInit {

  @Input() data: any;
  @Input() header: any;
  @Input() bold: boolean = false;

  private _lines: any[] = [];

  constructor(
    private permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingService
  ) {

  }

  ngOnInit() {
    this.load();
  }



  //Properties
  public get lines() {
    return this._lines;
  }


  //Methods
  public goto(url, e) {

    e.stopPropagation();

    window.open(url, '_blank');
  }
  public downloadprofile(uniquename, originalname, e) {

    e.stopPropagation();

    this.dataService.imageRequest('/api/v1/files/ProfileDocuments/' + uniquename)
      .subscribe(res => {

        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = (e) => {

          let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

          let hiddenElement = document.createElement('a');
          //if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
          //  //IE
          //  navigator.msSaveOrOpenBlob(res, originalname);
          //}
          //else
          if (typeof hiddenElement.download != 'undefined' && !isSafari) {
            //Support HTML5 download attribute (Chrome 14+, Firefox20+, IE13+, Opera15+)
            hiddenElement.href = reader.result.toString();
            hiddenElement.target = '_blank';
            hiddenElement.download = originalname;

            document.body.appendChild(hiddenElement);

            hiddenElement.click();
          }
          else {
            let url = '';
            try {
              let fileArr = reader.result.toString().split(';');
              let blob = this.settingService.b64toBlob(fileArr[1].replace("base64,", ""), fileArr[0].replace("data:", ""));

              url = URL.createObjectURL(blob);
            }
            catch (e) {
              //Final solution
              let is_chrome_ios = /CriOS\/[\d]+/.test(navigator.userAgent);
              url = is_chrome_ios ? reader.result.toString() : reader.result.toString().replace(/^data:[^;]*;/, 'data:attachment/file;');
            }

            var popup = window.open(url, '_blank');
            if (!popup) {
              window.location.href = url;
            }
          }
        }

      });
  }


  //Functions
  private load() {
    if (this.data != null) {

      if (!Array.isArray(this.data)) {
        this.data = [this.data];
      }

      this.data.forEach((line, index) => {

        let linerow: LineSettings = {
          Value: line,
          Bold: false,
          Type: this.header.property.Type,
          Error: false,
          Url: ''
        };

        if (line != null) {

          //Partly Sum
          if (line[0] == '\t') {
            linerow.Bold = true;
          }

          //Error
          if (line == 'SweError') {
            linerow.Error = true;
            linerow.Value = '';
          }

          let possiblelink = line.toString().indexOf('http');

          //Types
          if (this.header.property.ShowImage) {
            //Image
            let arr = line.toString().split('|');
            if (arr.length == 2) {
              linerow.Value = arr[1];
              linerow.Url = arr[0];
              linerow.Type = 'System.Image';
            }
          }
          else if (this.header.property.Type == 'Internetplatsen.Document') {
            //Document
            let arr = line.split('|');
            linerow.Value = arr[0];
            linerow.Url = arr[1];
          }
          else if (possiblelink == 0 || (possiblelink > 0 && line.toString()[possiblelink - 1] == '|')) {
            //Link
            let arr = line.split('|');
            linerow.Value = arr[0];
            linerow.Url = arr[0];
            if (arr.length > 1) {
              linerow.Url = arr[1];
            }
            linerow.Type = 'System.Link';
          }
          else {
            //Text
            linerow.Url = linerow.Value;
            linerow.Value = this.managewidth(linerow.Value);
          }

        }

        this._lines.push(linerow);
      });
      
    }
  }
  private managewidth(line) {

    let max = this.permissionService.permissions.MaxCharReport;

    if (max > 0 && line.length > max) {
      return line.substring(0, max) + '...';
    }

    return line;
  }
}

export class LineSettings {
  public Value: any;
  public Bold: boolean;
  public Type: string;
  public Error: boolean;
  public Url: string;
}
