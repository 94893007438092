<swe-card [header]="'LAS'" [open]="false" [hasCollapse]="true" [hasRefresh]="false" (openChange)="load()">
  <div>
    <swe-element [type]="'System.String'" [label]="'Namn'" [statusLabel]="4" [customCols]="8" [(model)]="las.Name" [access]="1"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="'Intervall för kvalificering (år)'" [statusLabel]="4" [customCols]="8" [(model)]="las.QualificationInterval" [access]="1"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="'Gräns för kvalificering (dagar)'" [statusLabel]="4" [customCols]="8" [(model)]="las.QualificationMax" [access]="1"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="'Intervall för inlasning VIK (år)'" [statusLabel]="4" [customCols]="8" [(model)]="las.VikInterval" [access]="1"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="'Gräns för inlasning VIK (dagar)'" [statusLabel]="4" [customCols]="8" [(model)]="las.VikMax" [access]="1"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="'Intervall för inlasning AVA (år)'" [statusLabel]="4" [customCols]="8" [(model)]="las.AvaInterval" [access]="1"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="'Gräns för inlasning AVA (dagar)'" [statusLabel]="4" [customCols]="8" [(model)]="las.AvaMax" [access]="1"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="'Intervall för inlasning VIK+AVA (år)'" [statusLabel]="4" [customCols]="8" [(model)]="las.VikAvaInterval" [access]="1"></swe-element>
    <swe-element [type]="'System.Int32'" [label]="'Gräns för inlasning VIK+AVA (dagar)'" [statusLabel]="4" [customCols]="8" [(model)]="las.VikAvaMax" [access]="1"></swe-element>
  </div>
</swe-card>
