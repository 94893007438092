<swe-pageheader></swe-pageheader>
<div class="row justify-content-md-center py-2">
  <div class="col-12 col-md-3">
    <swe-card header="{{languageService.getItem(424)}}" [hasRefresh]="false" [hasCollapse]="false">
      <div class="mb-3">
        <img sweImage alt="Logo" title="Logo" style="width:100%;" imageFolder="images" [imageFile]="permissionService.unauthpermissions.Logo" *ngIf="permissionService.unauthpermissions.Logo&&permissionService.unauthpermissions.Logo.length>0">
        <a href="https://www.swedetime.com" target="_blank" *ngIf="!permissionService.unauthpermissions.Logo||permissionService.unauthpermissions.Logo.length==0"><img alt="SwedeTime" src="assets/images/swedetime.png" /></a>
      </div>
      <ng-container *ngIf="!isdataloading&&!islevelloading">
        <swe-element [type]="'System.List'" [statusLabel]="2" [label]="languageService.getItem(14) + ' *'" [(model)]="levelid" [items]="levels" *ngIf="levels.length>1"></swe-element>
        <swe-element [type]="'System.String'" [statusLabel]="2" [label]="languageService.getItem(14) + ' *'" [(model)]="levelname" *ngIf="levels.length==0"></swe-element>
        <swe-element [type]="'Internetplatsen.Document'" [statusLabel]="2" [label]="languageService.getItem(195)" [(model)]="usermage" [container]="documentcontainer()" *ngIf="permissionService.unauthpermissions.RegistrationImage"></swe-element>
        <swe-profile [statusLabel]="2" [(model)]="profile"></swe-profile>
        <div class="d-grid gap-2">
          <button class="btn btn-primary" (click)="save()" [disabled]="issaving">{{languageService.getItem(935)}}</button>
        </div>
      </ng-container>
      <div class="float-end">
        <a href="https://www.swedetime.com" target="_blank" *ngIf="permissionService.unauthpermissions.Logo&&permissionService.unauthpermissions.Logo.length>0"><img alt="SwedeTime" src="assets/images/swedetime.png" /></a>
      </div>
    </swe-card>
  </div>
</div>
