import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { ListService } from '../../_services/list.service';
import { DataService } from '../../_services/data.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { AlertService } from '../../_services/alert.service';
import { Location } from '@angular/common';
import { SettingService } from '../../_services/setting.service';
import { GeneralService } from '../../_services/general.service';
import { DateTimeService } from '../../_services/datetime.service';


@Component({
  selector: 'swe-copybookings',
  templateUrl: './copybookings.component.html'
})
export class CopyBookingsComponent implements OnInit {

  private _bookings: any;
  private _repeatoption: number = 1;
  private _repeatoptions: any[] = [];
  private _start: Date;
  private _adjustoption: number = 0;
  private _adjustoptions: any[] = [];
  private _endoption: number = 0;
  private _endtimes: number = 1;
  private _enddate: Date;
  private _level: number = 0;
  private _status: number = 0;
  private _includeusers: boolean;
  private _removeATK = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private listService: ListService,
    private viewCacheService: ViewCacheService,
    private alertService: AlertService,
    private settingService: SettingService,
    private dateTimeService: DateTimeService,
    private location: Location) {

    //Reset
    settingService.initView();
  }

  ngOnInit() {

    this._bookings = this.viewCacheService.get('multi_bookings');

    this._repeatoptions = [
      { Id: 1, Name: this.languageService.getItem(363) },
      { Id: 2, Name: this.languageService.getItem(364) },
      { Id: 3, Name: this.languageService.getItem(365) },
      { Id: 4, Name: this.languageService.getItem(366) },
      { Id: 5, Name: this.languageService.getItem(367) },
      { Id: 6, Name: this.languageService.getItem(368) },
      { Id: 7, Name: this.languageService.getItem(369) },
      { Id: 8, Name: this.languageService.getItem(370) },
      { Id: 9, Name: this.languageService.getItem(371) },
      { Id: 10, Name: this.languageService.getItem(372) }
    ];

    this._start = this.dateTimeService.firstDayInWeek(this.settingService.booking.start);

    this._adjustoptions = [
      { Id: 0, Name: '0 ' + this.languageService.getItem(254) },
      { Id: 1, Name: '1 ' + this.languageService.getItem(254) },
      { Id: 2, Name: '2 ' + this.languageService.getItem(254) },
      { Id: 3, Name: '3 ' + this.languageService.getItem(254) },
      { Id: 4, Name: '4 ' + this.languageService.getItem(254) },
      { Id: 5, Name: '5 ' + this.languageService.getItem(254) },
      { Id: 6, Name: '6 ' + this.languageService.getItem(254) }
    ];

    this._enddate = this.settingService.booking.end;
  }


  //Properties
  public get repeatoption() {
    return this._repeatoption;
  }
  public set repeatoption(val) {
    this._repeatoption = val;
  }
  public get repeatoptions() {
    return this._repeatoptions;
  }
  public get start() {
    return this._start;
  }
  public set start(val) {
    this._start = val;
  }
  public get adjustoption() {
    return this._adjustoption;
  }
  public set adjustoption(val) {
    this._adjustoption = val;
  }
  public get adjustoptions() {
    return this._adjustoptions;
  }
  public get endoption() {
    return this._endoption;
  }
  public set endoption(val) {
    this._endoption = val;
  }
  public get endtimes() {
    return this._endtimes;
  }
  public set endtimes(val) {
    this._endtimes = val;
  }
  public get enddate() {
    return this._enddate;
  }
  public set enddate(val) {
    this._enddate = val;
  }
  public get level() {
    return this._level;
  }
  public set level(val) {
    this._level = val;
  }
  public get status() {
    return this._status;
  }
  public set status(val) {
    this._status = val;
  }
  public get includeusers() {
    return this._includeusers;
  }
  public set includeusers(val) {
    this._includeusers = val;
  }

  public get removeATK() {
    return this._removeATK;
  }
  public set removeATK(val) {
    this._removeATK = val;
  }



  //Methods
  public adjustMonday(e) {
    this._start = this.dateTimeService.firstDayInWeek(new Date(e));
  }
  public copy() {
    
    if (this._bookings) {

      let filter = {
        IdList: this._bookings.value,
        Repeat: this._repeatoption,
        Date: this.dateTimeService.formatWithTimeZone(this._start),
        DateAdjust: this._adjustoption,
        Status: this._status,
        Level: this._level,
        IncludeUsers: this._includeusers,
        RemoveATK: this._removeATK
      };

      if (this._endoption == 0) {
        filter["Frequency"] = this._endtimes;
      }
      else {
        filter["EndDate"] = this._enddate;
      }

      this.dataService.tokenRequest('/api/v1/bookings/copy', 'POST', filter, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.alertService.Add({ message: this.languageService.getItem(373), type: 'danger' });
    }
  }
}
