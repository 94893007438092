import { Component, OnChanges, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { DateTimeService } from '../../_services/datetime.service';
import { PermissionService } from '../../_services/permission.service';
import { ListService } from '../../_services/list.service';
import { AlertService } from '../../_services/alert.service';
import { GeneralService } from '../../_services/general.service';
import { ListUtility } from '../../_utilities/list.utility';



@Component({
  selector: 'swe-levelbookings',
  templateUrl: './levelbookings.component.html'
})
export class LevelBookingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: number;
  @Input() open: boolean;
  @Input() type: number = -1;
  @Input() leveldata: any;

  private unsubsribe$ = new Subject<void>();
  private _bookings: any;
  private _more: boolean = false;
  private _top: number = 25;
  private _multiple: number = 1;
  private _sum: number = 0;
  private _sumStandby: number = 0;
  private _sumAbscent: number = 0;
  private _sumDecline: number = 0;
  private _title: string = '';
  private _employmentplan: boolean = false;
  private _loading: boolean = false;
  private _listutility: ListUtility = new ListUtility();
  private _allBookingIdList: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private dateTimeService: DateTimeService,
    private settingService: SettingService,
    private listService: ListService,
    private alertService: AlertService,
    private generalService: GeneralService,
    private router: Router) {

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {
    this._listutility.toggleaccess = false;
    this._listutility.dataaccessname = 'MarkAccess';
    this._listutility.dataaccess = 1;
  }

  ngOnChanges() {
    if (this.open) {
      this.search();
    }
    this._title = (this.type == 1) ? this.languageService.getItem(698) : this.languageService.getItem(4);
    this._employmentplan = (this.type == 1) && this.permissionService.permissions.EmploymentPlan > 0;
  }

  /*Properties*/
  public get bookings() {
    return this._bookings;
  }
  public get more(): boolean {
    return this._more;
  }
  public get sum(): number {
    return this._sum;
  }
  public get sumStandby(): number {
    return this._sumStandby;
  }
  public get sumAbscent(): number {
    return this._sumAbscent;
  }
  public get sumDecline(): number {
    return this._sumDecline;
  }
  public get loading(): boolean {
    return this._loading;
  }
  public get title() {
    return this._title;
  }
  public get employmentplan() {
    return this._employmentplan;
  }
  public get listutility() {
    return this._listutility;
  }
  public get allBookingIdList() {
    return this._allBookingIdList;
  }



  /*Methods*/
  public search(getmore: boolean = false) {

    if (!this.open) { return; }

    if (!this.dateTimeService.validation(this.settingService.booking.start, this.settingService.booking.end)) { return; }

    this._more = false;

    if (getmore) {
      this._multiple = 1 + (this._multiple / 4);
      this._top *= 4;
    }
    else {
      this._multiple = 1;
      this._top = 25;
      this._bookings = [];
      this._allBookingIdList = [];

      this._sum = 0;
      this._sumStandby = 0;
      this._sumAbscent = 0;
      this._sumDecline = 0;
    }

    let filter = {
      Top: this._top,
      Multiple: this._multiple,
      //Bookings
      Start: this.settingService.start('booking'),
      End: this.settingService.end('booking'),
      IntervalType: this.settingService.booking.intervaltype,
      Weekdays: this.settingService.booking.weekdays,
      ShiftType: this.type, 
      //Users
      Search: this.settingService.user.search,
      UserList: this.settingService.userList(),
      RoleList: this.settingService.user.roles.join(),
      Active: this.settingService.user.activity,
      //Levels
      LevelId: this.id
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/bookings/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          let isBooking = true;
          if (this.type && this.type == 1) {
            isBooking = false;
          }

          res.Bookings.forEach(booking => {

            if ((this.permissionService.permissions.EmploymentPlan == 0) || (isBooking && !booking.EmploymentPlan) || (!isBooking && booking.EmploymentPlan)) {
              let start = new Date(booking.Start);
              let end = new Date(booking.End);

              booking.dateheader = this.dateTimeService.header(start, end, false);

              this._allBookingIdList.push(booking.Id);

              this._bookings.push(booking);
            }
          });

          this._sum += res.Sum;
          this._sumStandby += res.SumStandby;
          this._sumAbscent += res.SumAbscent;
          this._sumDecline += res.SumDecline;

          this._more = res.More;
        }

        this._loading = false;
      });
  }
  public goto(id: number, e) {
    e.stopPropagation();

    let url = '/bookings/' + id;
    if (id == 0) {
      url += '/level/' + this.id;
      url += this._employmentplan ? '/employmentplan/1' : '/employmentplan/0';
    }

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
            

  //Functions
  
}
