import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { GeneralService } from '../../_services/general.service';
import { DataService } from '../../_services/data.service';
import { DateTimeService } from '../../_services/datetime.service';
import { AlertService } from '../../_services/alert.service';
import { PermissionService } from '../../_services/permission.service';


@Component({
  selector: 'swe-useremployments',
  templateUrl: './useremployments.component.html'
})
export class UserEmploymentsComponent implements OnInit, OnChanges {
  @Input() access: number = 0;
  @Input() contractId: number = 0;
  @Input() id: number;
  @Input() open: boolean;
  @Input() userContractId: number = 0;
  @Input() contractStart: string;
  @Input() contractEnd: string;
  @Output() reloadContract = new EventEmitter();

  private _allemploymentcategories: any[] = [];
  private _contractcategories: any[] = [];
  private _employmentcategories: any[] = [];
  private _employments: any[] = [];
  private _employmenttypes: any[] = [];
  private _loading: boolean = false;

  constructor(
    public languageService: LanguageService,
    public generalService: GeneralService,
    public dateTimeService: DateTimeService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService
  ) {

  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    if (this.open) {
      this.loademployments();
    }
  }



  //Properties
  public get loading() {
    return this._loading;
  }
  public get employmenttypes() {
    return this._employmenttypes;
  }
  public get employmentcategories() {
    return this._employmentcategories;
  }
  public get employments() {
    return this._employments;
  }



  //Methods
  public isValid(employment) {
    const contractstart = new Date(this.contractStart.split('T')[0]);
    const contractend = new Date(this.contractEnd.split('T')[0]);
    const employmentstart = new Date(employment.Start.split('T')[0]);
    const employmentend = new Date(employment.End.split('T')[0]);

    if ((employmentstart < contractstart || employmentend > contractend || employmentend > contractend || employmentend < employmentstart)) {
      return false;
    }
    return true;
  }
  public loademployments() {
    if (this.open) {
      const currentCategory = this._contractcategories.find(x => x.Id == this.contractId);
      if (currentCategory && currentCategory.Values.some(x => x != null)) {
        this._employmentcategories = this._allemploymentcategories.filter(all => {
          return currentCategory.Values.find(x => x === all.Id);
        });
      }
      else {
        this._employmentcategories = this._allemploymentcategories;
      }
      this.dataService.tokenRequest('/api/v1/users/' + this.id + '/contracts/' + this.userContractId + '/employments', 'GET', {})
        .subscribe((res) => {
          if (res) {
            this._employments = res;
            for (let employment in res) {
              if (res[employment].Category) {
                const category = res[employment].Category;
                if (!this._employmentcategories.some(x => x.Id === category)) {
                  this._employmentcategories.push(this._allemploymentcategories.find(x => x.Id === category))
                }
              }
            }

          }
        });

    }
  }
  public saveemployment(employment) {

    if (employment.Start == null || employment.Start.length == 0) {
      this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1008) });
      return;
    }
    if (employment.End == null || employment.End.length == 0) {
      employment.End = this.dateTimeService.formatWithTimeZone(new Date(2100, 0, 1));
    }

    if (new Date(employment.Start) > new Date(employment.End)) {
      this.alertService.Add({ type: 'danger', message: this.languageService.getItem(662) });
      return;
    }

    employment.UserContractId = this.userContractId;

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/contracts/' + this.userContractId + '/employments/' + employment.Id, 'PUT', employment, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.loademployments();
      });

  }
  public createdigital(employment) {

    this.dataService.tokenRequest('/api/v1/users/' + employment.Id + '/digitalcontracts/', 'POST', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.reloadContract.emit(true);
      });
  }

  public deleteemployment(employmentid) {

    this.dataService.tokenRequest('/api/v1/users/' + this.id + '/contracts/employments/' + employmentid, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.loademployments();
      });

  }


  //Functions
  private init() {

    this.generalService.employmenttypes.forEach((employmenttype) => {
      this._employmenttypes.push({ Id: employmenttype.Key, Name: employmenttype.Value });
    });

    this.generalService.employmentcategories.forEach((category) => {
      this._employmentcategories.push({ Id: category.Key, Name: category.Value })
    });
    this._allemploymentcategories = this._employmentcategories;

    this.generalService.contractCategories.forEach((category) => {
      this._contractcategories.push({ Id: category.Key, Values: category.Value });
    })
  }
}
