import { Injectable } from '@angular/core';

import { DataService } from '../_services/data.service';
import { AlertService } from './alert.service';
import { LanguageService } from './language.service';
import { Observable, of } from 'rxjs';

import { switchMap, take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PropertyService  {

  private _propertiesAll: any[] = null;

  constructor(private dataService: DataService, private alertService: AlertService, private languageService: LanguageService) {
  }

  resolve() {
     return this.getPropertiesAll();
  }

  /*Properties*/
  public get propertiesAll() {
    return this._propertiesAll;
  }
  public getProperty(id: number): any {

    let property = {};
    for (var i = 0; i < this._propertiesAll.length; i++) {
      if (this._propertiesAll[i].Id == id) {
        property = this._propertiesAll[i];
        break;
      }
    }

    return property;
  }
  public getPropertyValueDependency(id: number): any {

    let property = {};

    for (var i = 0; i < this._propertiesAll.length; i++) {
      if (this._propertiesAll[i].DependencyId == id) {
        property = this._propertiesAll[i];
        break;
      }
    }

    return property;
  }
  public getPropertyByCoreValue(core: string): any {

    let property = null;

    for (var i = 0; i < this._propertiesAll.length; i++) {
      if (this._propertiesAll[i].Core == core) {
        property = this._propertiesAll[i];
        break;
      }
    }

    return property;
  }


  public reload() {
    this._propertiesAll = null;
    return this.dataService.basicRequest('/api/v1/properties', 'GET').pipe(tap(res => {
      if (res) {
        this._propertiesAll = res;
      }
    }));
  }

  /*Validation*/
  public validate(profileList: any[]): boolean {

    let res = true;

    profileList.forEach((profile) => {
      profile.Invalid = false;

      if (res && profile.Access > 1) {
        let property = this.getProperty(profile.Property);

        if (profile.IsVisible && !property.IsSecret && !property.IsOptional && (profile.Value == null || profile.Value.length == 0)) {
          //Not optional
          this.alertService.Add({ message: this.languageService.getItem(66).replace('{0}', profile.Name), type: 'danger' });
          res = false;
          profile.Invalid = true;
        }
        else if (property.Length > 0 && profile.Value != null && (profile.Value.length > property.Length || profile.Value.length > 600)) {
          //exceeds max length
          const msg = this.languageService.getItem(732).replace('{0}', property.Name).replace('{1}', property.Length > 0 && property.Length <= 600 ? property.Length : 600);
          this.alertService.Add({ message: msg, type: 'danger' });
          res = false;
          profile.Invalid = true;
        }
        else if (property.RegEx.length > 0 && profile.Value != null && profile.Value.length > 0 && profile.Value.match(property.RegEx) == null) {
          //Wrong format
          let msg = this.languageService.getItem(67).replace('{0}', profile.Name);
          if (property.Description && property.Description.length > 0) {
            msg += ' ' + this.languageService.getItem(960).replace('{0}', property.Description);
          }
          this.alertService.Add({ message: msg, type: 'danger' });
          res = false;
          profile.Invalid = true;
        }
      }

    });

    return res;
  }
  public validateEmail(email: string, name:string = '') {
    if (email != null && email.length > 0 && email.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$') == null) {
      //Wrong format
      let msg = this.languageService.getItem(67).replace('{0}', name);
      this.alertService.Add({ message: msg, type: 'danger' });
      return false;
    }

    return true;
  }


  //Methods
  public getPropertiesByType(type:string) {

    if (this._propertiesAll.length == 0) { return null; }

    let properties: any[] = [];
    this._propertiesAll.forEach((property) => {
      if (property['CatType'] == type) {
        properties.push(property);
      }
    });

    return properties;
  }


  /*Functions*/
  private getPropertiesAll(): Observable<any> {
    if (this._propertiesAll) {
      return of(this.propertiesAll);
    }
    return this.dataService.basicRequest('/api/v1/properties', 'GET').pipe(take(1), switchMap(res => {
      if (res) {
        this._propertiesAll = res;
      }
      return of(this._propertiesAll);
    }));
  }
}
