import { Component, Input, EventEmitter, Output, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DateTimeService } from '../_services/datetime.service';
import { LanguageService } from '../_services/language.service';
import { SettingService } from '../_services/setting.service';



@Component({
  selector: 'swe-datepager',
  templateUrl: './datepager.component.html',
})
export class DatePagerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() startlocal: Date; //Optional
  @Input() endlocal: Date; //Optional
  @Input() hideTime: boolean;
  @Input() type: string;
  @Input() disabled: boolean;
  @Output() dateChange = new EventEmitter<any>();
  @Output() startlocalChange = new EventEmitter<any>();
  @Output() endlocalChange = new EventEmitter<any>();

  private unsubsribe$ = new Subject<void>();
  private unsubsribe2$ = new Subject<void>();
  private _showdate: boolean = false;
  private _showinterval: boolean = false;
  private _current: string = "";
  private _lastdate: string = "";
  

  constructor(private dateTimeService: DateTimeService, public languageService: LanguageService, private settingService: SettingService) {

    settingService.onViewRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe((refresh) => {

      if (refresh.type == 'datepager_reload') {
        this.manageDate();
      }
      else if (refresh.type == 'datepager_prev') {
        this.prev();
      }
      else if (refresh.type == 'datepager_next') {
        this.next();
      }

    });

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe2$))
      .subscribe(() => {
        this.manageDate();
      });
    
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
    this.unsubsribe2$.next();
    this.unsubsribe2$.complete();
  }

  ngOnInit() {

    this.manageDate();
  }

  ngOnChanges() {
    
  }

  
  /*Properties*/
  public set datetimespan(val) {
    if (this.type == 'report') {
      this.settingService.report.datetimespan = val;
      return;
    }
    this.settingService.general.datetimespan = val;
  }
  public get datetimespan() {
    if (this.type == 'report') {
      return this.settingService.report.datetimespan;
    }
    return this.settingService.general.datetimespan;
  }
  public get interval() {
    if (this.datetimespan == 1) { return this.languageService.getItem(526); }
    else if (this.datetimespan == 2) { return this.languageService.getItem(527); }
    else if (this.datetimespan == 3) { return this.languageService.getItem(528); }
    else if (this.datetimespan == 4) { return this.languageService.getItem(693); }
    else if (this.datetimespan == 5) { return this.languageService.getItem(694); }
    else if (this.datetimespan == 6) { return this.languageService.getItem(1395); }
    else { return this.languageService.getItem(762); }
  }
  public get showdate() {
    return this._showdate;
  }
  public get showinterval() {
    return this._showinterval;
  }

  public set showinterval(val)
  {
    this._showinterval = val;
  }
  public get start(): Date {

    let start = null;

    if (this.type == 'booking') {
      start = this.settingService.booking.start;
    }
    else if (this.type == 'usermarkeddate') {
      start = this.settingService.user.availabilitystart;
    }
    else if (this.type == 'employment') {
      start = this.settingService.user.employmentstart;
    }
    else if (this.type == 'message') {
      start = this.settingService.message.start;
    }
    else if (this.type == 'log') {
      start = this.settingService.log.start;
    }
    else if (this.type == 'holiday') {
      start = this.settingService.holiday.start;
    }
    else if (this.type == 'report') {
      start = this.settingService.report.start;
    }
    else {
      start = this.startlocal;
    }

    if (this.hideTime) {
      
    }

    return start;
  }
  public set start(date: Date) {
    if (this.type == 'booking') {
      this.settingService.booking.start = new Date(date);
    }
    else if (this.type == 'usermarkeddate') {
      this.settingService.user.availabilitystart = new Date(date);
    }
    else if (this.type == 'employment') {
      this.settingService.user.employmentstart = new Date(date);
    }
    else if (this.type == 'message') {
      this.settingService.message.start = new Date(date);
    }
    else if (this.type == 'log') {
      this.settingService.log.start = new Date(date);
    }
    else if (this.type == 'holiday') {
      this.settingService.holiday.start = new Date(date);
    }
    else if (this.type == 'report') {
      this.settingService.report.start = new Date(date);
    }
    else {
      this.startlocal = new Date(date);
    }
  }
  public get end(): Date {

    let end = null;

    if (this.type == 'booking') {
      end = this.settingService.booking.end;
    }
    else if (this.type == 'usermarkeddate') {
      end = this.settingService.user.availabilityend;
    }
    else if (this.type == 'employment') {
      end = this.settingService.user.employmentend;
    }
    else if (this.type == 'message') {
      end = this.settingService.message.end;
    }
    else if (this.type == 'log') {
      end = this.settingService.log.end;
    }
    else if (this.type == 'holiday') {
      end = this.settingService.holiday.end;
    }
    else if (this.type == 'report') {
      end = this.settingService.report.end;
    }
    else {
      end = this.endlocal;
    }

    if (this.hideTime) {
      
    }

    return end;
  }
  public set end(date: Date) {
    if (this.type == 'booking') {
      this.settingService.booking.end = new Date(date);
    }
    else if (this.type == 'usermarkeddate') {
      this.settingService.user.availabilityend = new Date(date);
    }
    else if (this.type == 'employment') {
      this.settingService.user.employmentend = new Date(date);
    }
    else if (this.type == 'message') {
      this.settingService.message.end = new Date(date);
    }
    else if (this.type == 'log') {
      this.settingService.log.end = new Date(date);
    }
    else if (this.type == 'holiday') {
      this.settingService.holiday.end = new Date(date);
    }
    else if (this.type == 'report') {
      this.settingService.report.end = new Date(date);
    }
    else {
      this.endlocal = new Date(date);
    }
  }
  public get currentdate() {

    return this._current;
  }

  /*Methods*/
  public openDate() {
    this._showdate = true;
  }
  public closeDate() {
    this._showdate = false;

    //Update parent
    //this.dateChange.emit();
    this.manageDate();
  }

  public today() {
    let today = new Date();
    if (this.hideTime) {
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    }

    this.setDate(today);
    
    //Update parent
    //this.dateChange.emit();
    this.manageDate();
  }
  public prev() {
    if (this.datetimespan == 5) {
      //Year
      let start = this.start;

      this.start = new Date(start.getFullYear() - 1, 0, 1);
      this.end = new Date(start.getFullYear() - 1, 11, 31 + this.extraday());
    }
    else if (this.datetimespan == 4) {
      //Quarter
      let start = this.start;

      let first = new Date(start.getFullYear(), start.getMonth() - 3, 1);
      let last = new Date(first.getFullYear(), first.getMonth() + 3, first.getDate() - 1 + this.extraday(), first.getHours(), first.getMinutes());

      this.start = first;
      this.end = last;
    }
    else if (this.datetimespan == 3) {
      //Month
      let start = this.start;

      let first = new Date(start.getFullYear(), start.getMonth() - 1, 1);
      let last = new Date(first.getFullYear(), first.getMonth() + 1, first.getDate() - 1 + this.extraday(), first.getHours(), first.getMinutes());

      this.start = first;
      this.end = last;
    }
    else if (this.datetimespan == 6) {
      //OfficeWeek
      let start = this.start;

      let first = new Date(start.getFullYear(), start.getMonth(), start.getDate() - 7);
      let last = new Date(first.getFullYear(), first.getMonth(), first.getDate() + 4 + this.extraday(), first.getHours(), first.getMinutes());

      this.start = first;
      this.end = last;
    }
    else {
      //Week or Day
      this.managestep(false);
    }

    //Update parent
    //this.dateChange.emit();
    this.manageDate();
  }
  public next() {
    if (this.datetimespan == 5) {
      //Year
      let start = this.start;

      this.start = new Date(start.getFullYear() + 1, 0, 1);
      this.end = new Date(start.getFullYear() + 1, 11, 31 + this.extraday());
    }
    else if (this.datetimespan == 4) {
      //Quarter
      let start = this.start;

      let first = new Date(start.getFullYear(), start.getMonth() + 3, 1);
      let last = new Date(first.getFullYear(), first.getMonth() + 3, first.getDate() - 1 + this.extraday(), first.getHours(), first.getMinutes());

      this.start = first;
      this.end = last;
    }
    else if (this.datetimespan == 3) {
      //Month
      let start = this.start;

      let first = new Date(start.getFullYear(), start.getMonth() + 1, 1);
      let last = new Date(first.getFullYear(), first.getMonth() + 1, first.getDate() - 1 + this.extraday(), first.getHours(), first.getMinutes());

      this.start = first;
      this.end = last;
    }
    else if (this.datetimespan == 6) {
      //OfficeWeek
      let start = this.start;

      let first = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 7);
      let last = new Date(first.getFullYear(), first.getMonth(), first.getDate() + 4 + this.extraday(), first.getHours(), first.getMinutes());

      this.start = first;
      this.end = last;
    }
    else {
      //Week or Day
      this.managestep(true);
    }

    //Update parent
    //this.dateChange.emit();
    this.manageDate();
  }
  public day() {
    let day = this.spanstart();

    this.start = new Date(day.getFullYear(), day.getMonth(), day.getDate());
    this.end = new Date(day.getFullYear(), day.getMonth(), day.getDate() + this.extraday());

    this.datetimespan = 1;
    this._showinterval = false;

    //Update parent
    //this.dateChange.emit();
    this.manageDate();
  }
  public week() {
    let oneday = this.spanstart();

    oneday = this.dateTimeService.firstDayInWeek(oneday);

    this.start = new Date(oneday.getFullYear(), oneday.getMonth(), oneday.getDate());
    this.end = new Date(oneday.getFullYear(), oneday.getMonth(), oneday.getDate() + 6 + this.extraday());

    this.datetimespan = 2;
    this._showinterval = false;

    //Update parent
    //this.dateChange.emit();
    this.manageDate();
  }
  public officeweek() {
    let oneday = this.spanstart();

    oneday = this.dateTimeService.firstDayInWeek(oneday);

    this.start = new Date(oneday.getFullYear(), oneday.getMonth(), oneday.getDate());
    this.end = new Date(oneday.getFullYear(), oneday.getMonth(), oneday.getDate() + 4 + this.extraday());

    this.datetimespan = 6;
    this._showinterval = false;

    //Update parent
    //this.dateChange.emit();
    this.manageDate();    
  }
  public month() {
    let oneday = this.spanstart();

    let first = this.dateTimeService.firstDayInMonth(oneday);
    let last = new Date(first.getFullYear(), first.getMonth() + 1, first.getDate() - 1 + this.extraday(), first.getHours(), first.getMinutes());

    this.start = first;
    this.end = last;

    this.datetimespan = 3;
    this._showinterval = false;

    //Update parent
    //this.dateChange.emit();
    this.manageDate();
  }
  public quarter() {
    let oneday = this.spanstart();

    var first = this.dateTimeService.firstDayInQuarter(oneday);
    var last = new Date(first.getFullYear(), first.getMonth() + 3, first.getDate() - 1 + this.extraday(), first.getHours(), first.getMinutes());

    this.start = first;
    this.end = last;

    this.datetimespan = 4;
    this._showinterval = false;

    //Update parent
    //this.dateChange.emit();
    this.manageDate();
  }
  public year() {
    let oneday = this.spanstart();

    this.start = new Date(oneday.getFullYear(), 0, 1);
    this.end = new Date(oneday.getFullYear(), 11, 31 + this.extraday());

    this.datetimespan = 5;
    this._showinterval = false;

    //Update parent
    //this.dateChange.emit();
    this.manageDate();
  }


  //Functions
  private manageDate() {

    let result = '';

    let start = this.start;
    let end = this.end;

    if (!this.dateTimeService.isValid(start) || !this.dateTimeService.isValid(end)) {
      return null;
    }

    if (this.isday(start, end)) {
      this.datetimespan = 1;
    }
    else if (this.isweek(start, end)) {
      if (this.datetimespan != 2) {
        this.datetimespan = 2;
      }
    }
    else if (this.isofficeweek(start, end)) {
      this.datetimespan = 6;
    }
    else if (this.ismonth(start, end)) {
      this.datetimespan = 3;
    }
    else if (this.isquarter(start, end)) {
      this.datetimespan = 4;
    }
    else if (this.isyear(start, end)) {
      this.datetimespan = 5;
    }
    else {
      //Custom
      this.datetimespan = 0;
    }

    //Whole days eg. 00:00 - 00:00 or no time
    let wholedays = this.iswholedays(start, end);

    //Adjust end when using time and interval is whole days
    if (!this.hideTime && wholedays) {
      end = new Date(end.getTime() - this.dateTimeService.oneday);
    }

    //Day (start)
    if ((!this.hideTime && !wholedays) || start.getDate() != end.getDate() || start.getMonth() != end.getMonth() || start.getFullYear() != end.getFullYear()) {
      result += start.getDate();
    }

    //Month (start)
    if ((!this.hideTime && !wholedays) || start.getMonth() != end.getMonth() || start.getFullYear() != end.getFullYear()) {
      result += ' ';
      result += this.dateTimeService.monthname(start, 3);
    }

    //Year (start)
    if (start.getFullYear() != end.getFullYear()) {
      result += ' ';
      result += start.getFullYear();
    }

    //Time (start)
    if (!this.hideTime) {
      if (!wholedays) {
        result += ' ';
        if (start.getHours() < 10) {
          result += '0';
        }
        result += start.getHours();
        result += ':';
        if (start.getMinutes() < 10) {
          result += '0';
        }
        result += start.getMinutes();
      }
    }

    //Divider
    if (result.length > 0) {
      result += ' - ';
    }

    //Time (end)
    if (!this.hideTime) {
      if (!wholedays) {
        if (end.getHours() < 10) {
          result += '0';
        }
        result += end.getHours();
        result += ':';
        if (end.getMinutes() < 10) {
          result += '0';
        }
        result += end.getMinutes();
        result += ' ';
      }
    }

    //Day (end)
    result += end.getDate();

    //Month (start)
    result += ' ';
    result += this.dateTimeService.monthname(end, 3);

    //Year (end)
    result += ' ';
    result += end.getFullYear();


    this._current = result;

    if (this._lastdate.length > 0 && this._lastdate != this._current) {
      //Update parent
      this.startlocalChange.emit(this.startlocal);
      this.endlocalChange.emit(this.endlocal);
      this.dateChange.emit();
    }
    this._lastdate = this._current;
    
  }
  private setDate(date: Date) {

    if (this.datetimespan == 1) {
      //Day
      this.start = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      this.end = new Date(date.getFullYear(), date.getMonth(), date.getDate() + this.extraday());
    }
    else if (this.datetimespan == 2) {
      //Week
      let monday = this.dateTimeService.firstDayInWeek(date);
      this.start = monday;
      this.end = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 6 + this.extraday());
    }
    else if (this.datetimespan == 6) {
      //OfficeWeek
      let monday = this.dateTimeService.firstDayInWeek(date);
      this.start = monday;
      this.end = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 4 + this.extraday());
    }
    else if (this.datetimespan == 3) {
      //Month
      let first = new Date(date.getFullYear(), date.getMonth(), 1);
      let last = new Date(first.getFullYear(), first.getMonth() + 1, first.getDate() - 1 + this.extraday());

      this.start = first;
      this.end = last;
    }
    else if (this.datetimespan == 4) {
      //Quarter
      let first = this.dateTimeService.firstDayInQuarter(date);
      let last = new Date(first.getFullYear(), first.getMonth() + 3, first.getDate() - 1 + this.extraday());

      this.start = first;
      this.end = last;
    }
    else if (this.datetimespan == 5) {
      //Year
      this.start = new Date(date.getFullYear(), 0, 1);
      this.end = new Date(date.getFullYear(), 11, 31 + this.extraday());
    }
    else {
      //Custom
      let start = this.start.getTime();
      let end = this.end.getTime();
      let diff = (end - start + this.dateTimeService.oneday) / this.dateTimeService.oneday;

      this.start = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      this.end = new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff - 1);
    }
  }
  private managestep(forward: boolean) {

    //Extra day in dateview
    let extra = 0;
    if (this.hideTime) {
      extra = 1; //Add one day
    }

    //Times (original)
    let start = this.start;
    let end = this.end;

    //UTC
    let startUtcTicks = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes());
    let endUtcTicks = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate() + extra, end.getHours(), end.getMinutes());
    let diffUtcTicks = endUtcTicks - startUtcTicks;

    //Diff
    if (forward) {
      startUtcTicks += diffUtcTicks;
      endUtcTicks += diffUtcTicks;
    }
    else {
      startUtcTicks -= diffUtcTicks;
      endUtcTicks -= diffUtcTicks;
    }

    //Locale
    let startLocale = new Date(startUtcTicks);
    let endLocale = new Date(endUtcTicks);
    
    //Offset diff
    let startOffset = (startLocale.getTimezoneOffset() / 60);
    if (startOffset != 0) {
      startLocale = new Date(startLocale.getTime() + startOffset * this.dateTimeService.onehour);
    }
    let endOffset = (endLocale.getTimezoneOffset() / 60);
    if (endOffset != 0) {
      endLocale = new Date(endLocale.getTime() + endOffset * this.dateTimeService.onehour);
    }

    if (this.hideTime) {
      //let startUtcOffset = (startLocale.getTimezoneOffset());
      //let endUtcOffset = (endLocale.getTimezoneOffset());
      //let offsetdiffminutes = (endUtcOffset - startUtcOffset);

      //endLocale = new Date(endLocale.getFullYear(), endLocale.getMonth(), endLocale.getDate() - extra, endLocale.getHours(), endLocale.getMinutes() + offsetdiffminutes);
      endLocale = new Date(endLocale.getFullYear(), endLocale.getMonth(), endLocale.getDate() - extra, endLocale.getHours(), endLocale.getMinutes());
    }

    //Result
    this.start = startLocale;
    this.end = endLocale;
  }


  //Is Functions
  private iswholedays(start: Date, end: Date): boolean {
    return (start.getHours() == 0 && start.getMinutes() == 0 && end.getHours() == 0 && end.getMinutes() == 0);
  }
  private isday(start, end): boolean {
    end = this.adjustend(end);
    return (this.diff(end, start) == this.dateTimeService.oneday) && this.wholedays(start, end);
  }
  private isweek(start: Date, end: Date): boolean {
    end = this.adjustend(end);
    return (start.getDay() == 1) && (this.diff(end, start) == (7 * this.dateTimeService.oneday)) && this.wholedays(start, end);
  };
  private isofficeweek(start: Date, end: Date): boolean {
    end = this.adjustend(end);
    return (start.getDay() == 1) && (this.diff(end, start) == (5 * this.dateTimeService.oneday)) && this.wholedays(start, end);
  };
  private ismonth(start: Date, end: Date): boolean {
    end = this.adjustend(end);

    let startOffset = start.getTimezoneOffset();
    let endOffset = end.getTimezoneOffset();

    if (startOffset < endOffset) {
      end = new Date(end.getTime() + ((endOffset - startOffset) * this.dateTimeService.oneminute));
    }

    return (start.getDate() == 1) && (end.getDate() == 1) && (this.diff(end, start) < (32 * this.dateTimeService.oneday)) && this.wholedays(start, end);
  };
  private isquarter(start: Date, end: Date): boolean {
    end = this.adjustend(end);
    return (start.getDate() == 1) && (end.getDate() == 1) && (this.diff(end, start) > (89 * this.dateTimeService.oneday)) && (this.diff(end, start) < (93 * this.dateTimeService.oneday)) && this.wholedays(start, end) && this.quartermonth(start);
  };
  private isyear(start: Date, end: Date): boolean {
    end = this.adjustend(end);
    return (start.getDate() == 1) && (end.getDate() == 1) && (this.diff(end, start) > (364 * this.dateTimeService.oneday)) && (this.diff(end, start) < (367 * this.dateTimeService.oneday)) && this.wholedays(start, end) && this.yearmonth(start);
  };

  //Help functions
  private spanstart() {
    let start = this.start.getTime();
    let end = this.end.getTime();

    let day = new Date();

    if (start > day.getTime() || day.getTime() > end) {
      //Current span doesn't include today
      day = new Date(start);
    }

    return day;
  }
  private extraday(): number {
    return !this.hideTime ? 1 : 0;
  }
  private adjustend(end: Date): Date {
    if (!this.hideTime) {
      return end;
    }
    else {
      return new Date(end.getTime() + this.dateTimeService.oneday);
    }
  }
  private wholedays(start: Date, end: Date) : boolean {
    return this.hideTime || this.iswholedays(start, end);
  }
  private quartermonth(date: Date): boolean {
    let month = date.getMonth();
    return month == 0 || month == 3 || month == 6 || month == 9;
  }
  private yearmonth(date: Date): boolean {
    let month = date.getMonth();
    return month == 0;
  }
  private diff(end: Date, start: Date) {

    if (!this.hideTime) {
      let startOffset = start.getTimezoneOffset();
      let endOffset = end.getTimezoneOffset();

      return (end.getTime() - start.getTime()) - ((endOffset - startOffset) * this.dateTimeService.oneminute);
    }
    else {
      return (end.getTime() - start.getTime());
    }
  }
}
