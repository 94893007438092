<swe-pageheader [header]="languageService.getItem(410)"></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-start">
    <swe-card header="{{languageService.getItem(3)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false" [rulelist]="['SweMultiLevel']">
      <!--LevelGroup-->
      <swe-element [(model)]="levelgroup" [label]="languageService.getItem(702)" [statusLabel]="2" [items]="generalService.levelgroups" [optional]="true" [type]="'System.List'"></swe-element>
      <!--Parent-->
      <swe-element [(model)]="parent" (modelChange)="levelChosen($event)" [label]="languageService.getItem(235)" [statusLabel]="2" [type]="'System.LevelSearch'"></swe-element>
      <!--Employment categories-->
      <ng-container *ngIf="permissionService.permissions.ExternalCategories > 1">
        <swe-element [(model)]="employmentCategoryOption" [label]="languageService.getItem(1060)" [statusLabel]="2" [items]="employmentCategoryOptions" [type]="'System.RadioList'"></swe-element>
        <swe-element [(model)]="selectedEmploymentCategories" [label]="languageService.getItem(1060)" [items]="employmentCategories" [container]="{max: 8}" [type]="'System.FilteredMultiple'" *ngIf="employmentCategories && employmentCategories.length > 0"></swe-element>
      </ng-container>
      <!--Properties-->
      <swe-properties [label]="languageService.getItem(415)" [statusLabel]="2" [properties]="properties" [items]="chosenproperties"></swe-properties>
      <div class="d-grid gap-2">
        <!--Buttons-->
        <swe-confirm [body]="confirmsave()" (acceptChange)="save()">
          <button class="btn btn-primary"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(657)}}</button>
        </swe-confirm>
        <swe-confirm [body]="languageService.getItem(660)" (acceptChange)="delete()" *ngIf="permissionService.permissions.LevelProfile > 2">
          <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(658)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
</div>  
