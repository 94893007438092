import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { AlertService } from '../_services/alert.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { Router } from '@angular/router';
import { DateTimeService } from '../_services/datetime.service';


@Component({
  selector: 'app-help',
  templateUrl: './help.component.html'
})
export class HelpComponent implements OnInit {

  private _documents: any[] = [];
  private _file: string = '';
  private _news: any[];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public dateTimeService: DateTimeService,
    private dataService: DataService,
    private alertService: AlertService,
    private settingService: SettingService,
    private viewCacheService: ViewCacheService,
    private router: Router
  ) {

  }

  ngOnInit() {

    this.init();
  }



  //Properties
  public get documents() {
    return this._documents;
  }
  public get file() {
    return this._file;
  }
  public set file(val) {
    this._file = val;
  }
  public get news() {
    return this._news;
  }



  //Methods
  public download(filename) {

    this.dataService.imageRequest('/api/v1/files/help/' + filename)
      .subscribe(res => {

        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = (e) => {

          let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

          let hiddenElement = document.createElement('a');
          //if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
          //  //IE
          //  navigator.msSaveOrOpenBlob(res, filename);
          //}
          //else
          if (typeof hiddenElement.download != 'undefined' && !isSafari) {
            //Support HTML5 download attribute (Chrome 14+, Firefox20+, IE13+, Opera15+)
            hiddenElement.href = reader.result.toString();
            hiddenElement.target = '_blank';
            hiddenElement.download = filename;

            document.body.appendChild(hiddenElement);

            hiddenElement.click();
          }
          else {
            let url = '';
            try {
              let fileArr = reader.result.toString().split(';');
              let blob = this.settingService.b64toBlob(fileArr[1].replace("base64,", ""), fileArr[0].replace("data:", ""));

              url = URL.createObjectURL(blob);
            }
            catch (e) {
              //Final solution
              let is_chrome_ios = /CriOS\/[\d]+/.test(navigator.userAgent);
              url = is_chrome_ios ? reader.result.toString() : reader.result.toString().replace(/^data:[^;]*;/, 'data:attachment/file;');
            }

            var popup = window.open(url, '_blank');
            if (!popup) {
              window.location.href = url;
            }
          }
        }

      });
  }
  public delete(filename) {

    this.dataService.tokenRequest('/api/v1/general/documents/Help/' + filename, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.init();

        this.alertService.Add({ type: 'success', message: res });
      });
  }
  public documentcontainer() {
    return {
      clientOnly: false,
      allowedPrefix: 'png, jpg, jpeg, jfif, gif, txt, pdf, doc, docx, ppt, pptx, xls, xlsx, eps, tif, odt, ods, odp',
      folder: 'help',
      document: this._file,
      showimage: false,
      hidedownload: true
    };
  }
  public upload() {
    if (this._file.length > 0) {

      let content = this._file.split('|');

      let dto = {
        Name: content[0],
        Data: content[1]
      };

      this.dataService.tokenRequest('/api/v1/general/documents/Help', 'PUT', dto, 'text')
        .subscribe((res) => {

          this.init();

          this.alertService.Add({ type: 'success', message: res });
        });

    }
  }
  public search() {

    this._news = [];
    
    let filter = {
      Top: 0,
      Multiple: 1,
      ShowAt: 8, //Help
      Start: new Date().toLocaleString(),
      End: new Date().toLocaleString()
    };

    this.dataService.tokenRequest('/api/v1/news/search', 'POST', filter)
      .subscribe(res => {

        res.News.forEach((item) => {

          if (item.Link != item.LinkName) {
            item.LinkUrl = item.Link + '|' + item.LinkName;
          }
          else {
            item.LinkUrl = item.Link;
          }

          item.TempIsPublic = item.IsPublic;

          this._news.push(item);
        });

      });

  }
  public openLink(obj, e) {

    e.stopPropagation();

    window.open(obj.Link, '_blank');
  }
  public goto(id) {

    if (id == 0) {
      this.viewCacheService.add('news_show', 8);
    }

    this.router.navigate(['/admin/news', id]);

  }
  public wash(s: string) {
    if (s && s.length > 0) {
      return s.replace(/\n/g, '<br>');
    }
    return s;
  }



  //Functions
  private init() {

    this.dataService.tokenRequest('/api/v1/general/documents/Help', 'Get', {})
      .subscribe((res) => {

        this._documents = res;
      });

    this.search();
  }


}
