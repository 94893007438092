import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { SettingService } from '../../_services/setting.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';



@Component({
  selector: 'app-economy',
  templateUrl: './economy.component.html'
})
export class EconomyComponent implements OnInit {

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private settingService: SettingService,
    private alertService: AlertService,
    private dataService: DataService
  ) {
    //Reset
    settingService.initView();

  }


  ngOnInit() {

  }



  //Properties





  //Methods





  //Functions
  

}
