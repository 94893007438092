import { Component, Input, EventEmitter, Output } from '@angular/core';

import { LanguageService } from '../_services/language.service';



@Component({
  selector: 'swe-radio',
  templateUrl: './radio.component.html',
})
export class RadioComponent {
  @Input() label: string; 
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() items: any = [];
  @Input() changed: boolean;
  @Input() disabled: boolean;
  @Input() extra: string;

  public values = [];

  constructor(public languageService: LanguageService) {

  }
  



  /*Properties*/
  public get labelNoWhiteSpace() {
    return this.label.replace(' ', '_');
  }


  /*Methods*/
  public manageChange(id:number) {
    this.model = id.toString();
    this.modelChange.emit(this.model);
  }



  /*Functions*/
  
}
