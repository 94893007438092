import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SettingService } from '../_services/setting.service';
import { PermissionService } from '../_services/permission.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

  
  constructor(
    public permissionService: PermissionService,
    private settingService: SettingService,
    private router: Router
  ) {

    //Reset
    settingService.initView();
  }

  ngOnInit() {

    if (this.permissionService.permissions.Dashboard == 0) {
      this.router.navigate(['/']);
    }

  }


  //Properties
  


  
}
