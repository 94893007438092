<swe-pageheader [header]="languageService.getItem(4)">
  <span swe-hits>&nbsp;(#{{id}})</span>
</swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 float-start">
    <div class="mb-2" [ngClass]="{'btn-group-vertical d-block mx-auto': settingService.isDevice(['xs','sm']), 'btn-group': !settingService.isDevice(['xs','sm'])}">
      <button class="btn" [ngClass]="{'swe-disabled': step.Index>higheststep, 'btn-primary': currentstep.Index==step.Index, 'btn-outline-primary': currentstep.Index!=step.Index}" *ngFor="let step of steps" (click)="movestep(step)" [disabled]="step.Index>higheststep||(currentstep.Index==laststep.Index&&step.Index!=laststep.Index)">
        {{step.Index+1}}. {{step.Name}}
      </button>
    </div>
  </div>
  <div class="col-12 col-md-4 float-start">
    <swe-card [header]="currentstep.Header" [hasRefresh]="false" [hasCollapse]="false">
      <!--Step Level-->
      <ng-container *ngIf="!permissionService.permissions.HideLevels&&currentstep.Id=='Level'">
        <!--Level-->
        <div class="mb-3 row">
          <div class="fst-italic mb-2">{{languageService.getItem(1158)}}</div>
          <div class="swe-row-icon">
            <div class="swe-col-icon">
              <i class="bi bi-swe-fw bi-swe bi-diagram-3-fill" [attr.title]="languageService.getItem(14)"></i>
            </div>
            <div class="swe-col-content">
              <swe-levelsearch [(model)]="booking.LevelId" [displayname]="booking.LevelName" (modelChange)="levelchosen($event)" [onlyone]="true" [type]="'Booking'" [disabled]="booking.Status==60||booking.LevelAccess<2" [storeAtClient]="true" [loadOnClick]="true" ></swe-levelsearch>
              <span class="small" *ngIf="booking.LevelPath&&booking.LevelPath.length>0"><i>({{booking.LevelPath}})</i></span>
            </div>
          </div>
        </div>
        <!--Profiles-->
        <ng-container *ngIf="currentstep.Key==-1">
          <swe-profile [(model)]="booking.Profile" [id]="id" [tabgroupid]="currentstep.Key"></swe-profile>
        </ng-container>
      </ng-container>
      <!--Step DateTimes-->
      <ng-container *ngIf="currentstep.Id=='DateTime'">
        <!--DateTimes-->
        <div class="mb-3 row">
          <div class="fst-italic mb-2">{{languageService.getItem(1161)}}</div>
          <div class="swe-row-icon" [ngClass]="{'swe-invalid-profile border-danger text-danger': !validation.datetime}">
            <div class="swe-col-icon">
              <i class="bi bi-swe-fw bi-swe bi-clock-fill" [attr.title]="languageService.getItem(10)+' '+languageService.getItem(430)+' '+languageService.getItem(11)"></i>
            </div>
            <div class="swe-col-content">
              <swe-datetime [(start)]="booking.Start" [(end)]="booking.End" [labelStart]="languageService.getItem(10)" [labelEnd]="languageService.getItem(11)" [hideTime]="!permissionService.permissions.ShiftTime" [disabled]="booking.StartAccess<2" (startChange)="manageDateTime($event)" (endChange)="manageDateTime($event)" [markChanges]="true"></swe-datetime>
            </div>
          </div>
        </div>
        <!--IncludedBreak-->
        <div class="row" *ngIf="booking.BreakAccess>0&&permissionService.permissions.HasBreaks">
          <div class="fst-italic mb-2">{{languageService.getItem(1162)}}</div>
          <div class="swe-row-icon" [ngClass]="{'swe-invalid-profile border-danger text-danger': !validation.break}">
            <div class="swe-col-icon">
              <i class="bi bi-swe-fw bi-swe bi-cup-hot-fill" [attr.title]="languageService.getItem(785)"></i>
            </div>
            <div class="swe-col-content">
              <swe-breaklist [(model)]="booking.Breaks" [(mealbreak)]="booking.MealBreak" [mealbreakAccess]="permissionService.permissions.MealBreakAccess" [disabled]="booking.BreakAccess<2" [disabledStart]="booking.BreakStartAccess<2" [minStart]="booking.Start" [maxStart]="booking.End" [markChanges]="true"></swe-breaklist>
            </div>
          </div>
        </div>
        <!--TimeType-->
        <div class="row" *ngIf="timetypes.length>0&&booking.TimeTypeAccess>0">
          <div class="fst-italic mb-2">{{languageService.getItem(1163)}}</div>
          <div class="swe-row-icon" [ngClass]="{'swe-invalid-profile border-danger text-danger': !validation.timetype}">
            <div class="swe-col-icon">
              <i class="bi bi-swe-fw bi-swe bi-journal" [attr.title]="languageService.getItem(1121)"></i>
            </div>
            <div class="swe-col-content">
              <swe-element [(model)]="booking.TimeType" [label]="languageService.getItem(1121)" [type]="'System.List'" [items]="timetypes" [disabled]="booking.TimeTypeAccess<2"></swe-element>
            </div>
          </div>
        </div>
        <!--Series-->
        <div *ngIf="booking.SeriesAccess>0||booking.Serie.Id>0">
          <swe-card [header]="languageService.getItem(529)" [color]="'swe'" [open]="false" [hasRefresh]="false">
            <swe-element [(model)]="booking.Serie.Type" (modelChange)="changeSerieType($event)" [statusLabel]="2" [label]="languageService.getItem(251)" [items]="serieTypeList" [type]="'System.List'" [disabled]="booking.Serie.Id>0"></swe-element>
            <swe-element [(model)]="serieOption" [label]="languageService.getItem(252)" [items]="serieOptionList" [type]="'System.RadioList'" [disabled]="!isSerie||booking.Serie.Id>0"></swe-element>
            <swe-element [(model)]="booking.Serie.Days" [container]="container" [statusLabel]="2" [label]="languageService.getItem(629)" [items]="serieDayList" [type]="'System.MultiList'" [disabled]="!isSerie||booking.Serie.Id>0"></swe-element>
            <div class="mb-3">
              <label>{{languageService.getItem(255)}}</label>
              <div class="form-check">
                <input type="radio" class="form-check-input swe-click" [(ngModel)]="endOption" name="endoptions" [value]="1" [attr.aria-label]="languageService.getItem(255)" [disabled]="!isSerie||booking.Serie.Id>0" />
                <swe-element [(model)]="booking.Serie.End" [label]="languageService.getItem(255)" [type]="'System.Date'" [disabled]="!isSerie||booking.Serie.Id>0||endOption==0"></swe-element>
              </div>
              <div class="form-check">
                <input type="radio" class="form-check-input swe-click" [(ngModel)]="endOption" name="endoptions" [value]="0" [attr.aria-label]="languageService.getItem(255)" [disabled]="!isSerie||booking.Serie.Id>0" />
                <input type="number" class="form-control w-25 d-inline" [(ngModel)]="booking.Serie.Times" [attr.aria-label]="languageService.getItem(697)" [disabled]="!isSerie||booking.Serie.Id>0||endOption==1" />
                <label class="form-check-label ms-2">{{languageService.getItem(697)}}</label>
              </div>
            </div>
          </swe-card>
        </div>
        <!--Profiles-->
        <ng-container *ngIf="currentstep.Key==-2">
          <swe-profile [(model)]="booking.Profile" [id]="id" [tabgroupid]="currentstep.Key"></swe-profile>
        </ng-container>
      </ng-container>
      <!--Step Other-->
      <ng-container *ngIf="currentstep.Id=='Other'&&otheraccess">
        <!--Header-->
        <div class="row" *ngIf="booking.HeaderAccess>0">
          <div class="fst-italic mb-2">{{languageService.getItem(1166)}}</div>
          <div class="swe-row-icon" [ngClass]="{'swe-invalid-profile border-danger text-danger': !validation.header}">
            <div class="swe-col-icon">
              <i class="bi bi-swe-fw bi-swe bi-quote bi-swe-rotate-180" [attr.title]="header.Name"></i>
            </div>
            <div class="swe-col-content">
              <swe-element [(model)]="booking.Header" [label]="header.Name" [propertyId]="booking.HeaderProperty" [placeholder]="header.Name" [disabled]="booking.HeaderAccess<2"></swe-element>
            </div>
          </div>
        </div>
        <!--SlotAmountMax-->
        <div class="row" *ngIf="booking.MaxAccess>0">
          <div class="fst-italic mb-2">{{languageService.getItem(1167)}}</div>
          <div class="swe-row-icon">
            <div class="swe-col-icon">
              <i class="bi bi-swe-fw bi-swe bi-people-fill" [attr.title]="languageService.getItem(888)"></i>
            </div>
            <div class="swe-col-content">
              <swe-element [(model)]="booking.Max" [label]="max.Name" [propertyId]="booking.MaxProperty" [type]="'System.Int32'" [style]="'width:60px'" [disabled]="booking.MaxAccess<2"></swe-element>
            </div>
          </div>
        </div>
        <!--Owner-->
        <div class="mb-3 row" *ngIf="permissionService.permissions.Owner>0">
          <div class="fst-italic mb-2">{{languageService.getItem(1168)}}</div>
          <div class="swe-row-icon">
            <div class="swe-col-icon">
              <i class="bi bi-swe-fw bi-swe bi-key-fill" [attr.title]="languageService.getItem(654)"></i>
            </div>
            <div class="swe-col-content">
              <swe-usersearch [(model)]="booking.UserId" [displayname]="booking.User" (modelChange)="userchosen($event)" [onlyone]="true" [type]="'Owner'" [disabled]="booking.Status==60||permissionService.permissions.Owner<2"></swe-usersearch>
            </div>
          </div>
        </div>
        <!--AutoBook-->
        <div class="row" *ngIf="booking.AutoAccess>0">
          <div class="fst-italic mb-2">{{languageService.getItem(1169)}}</div>
          <div class="swe-row-icon">
            <div class="swe-col-icon">
              <i class="bi bi-swe-fw bi-swe bi-magic" [attr.title]="languageService.getItem(797)"></i>
            </div>
            <div class="swe-col-content">
              <swe-element [(model)]="booking.Auto" [label]="languageService.getItem(797)" [type]="'System.Boolean'" [container]="{markChanges: true}"></swe-element>
            </div>
          </div>
        </div>
        <!--UserEmployments-->
          <div *ngIf="booking.UserEmploymentAccess>1" class="row">
            <swe-adminrule [rulelist]="['SweShiftUserEmploymentCategory']"></swe-adminrule>
            <div class="fst-italic mb-2">{{languageService.getItem(1256)}}</div>
            <div class="swe-col-content">
              <swe-element [type]="'System.List'" [label]="languageService.getItem(1065)" [(model)]="booking.EmploymentCategoryId" [items]="employmentcategories" [optional]="true" [access]="booking.UserEmploymentAccess"></swe-element>
            </div>
        </div>
        <!--Profiles-->
        <ng-container *ngIf="currentstep.Key==-3">
          <swe-profile [(model)]="booking.Profile" [id]="id" [tabgroupid]="currentstep.Key"></swe-profile>
        </ng-container>
      </ng-container>
      <!--Step Activities-->
      <ng-container *ngIf="permissionService.permissions.Activity>0&&currentstep.Id=='Activity'">
        <div class="fst-italic mb-2">{{languageService.getItem(1188)}}</div>
        <button class="btn btn-primary mb-2" *ngIf="permissionService.permissions.Activity>1&&activities&&activities.length>0&&!activities[0].isedit" (click)="editActivity(activities[0], $event)"><i class="bi bi-swe-fw bi-plus-lg"></i>&nbsp;{{languageService.getItem(1203)}}</button>
        <div class="list-group">
          <ng-container *ngFor="let activity of activities">
            <div class="list-group-item list-group-item-action d-flex flex-wrap swe-click" (click)="editActivity(activity, $event)" *ngIf="permissionService.permissions.Activity>0 && !activity.isedit && activity.Id!=0">
              <div class="col-4 col-md-4">{{activity.dateheader}}</div>
              <div class="col-8 col-md-4"><div style="display:inline-block;width:10px;height:10px;border:1px solid #999999;" [ngStyle]="{'background-color': activity.Color}"></div>&nbsp;{{activity.Type}}</div>
              <div class="col-12 col-md-3">{{activity.Text}}</div>
              <div class="col-12 col-md-1"><i class="bi bi-swe-fw bi-swe bi-trash-fill bi-swe-pull-right swe-click" (click)="deleteActivity(activity,$event)" *ngIf="permissionService.permissions.Activity>1"></i></div>
            </div>
            <swe-card [header]="languageService.getItem(395)" [color]="'swe'" [open]="true" [hasRefresh]="false" [hasCollapse]="false" *ngIf="activity.isedit">
              <span swe-rightcommand><i class="bi bi-x-lg bi-swe-pull-right swe-click" (click)="editActivity(activity, $event)"></i></span>
              <swe-datetime [(start)]="activity.Start" [(end)]="activity.End" [labelStart]="languageService.getItem(396)" [labelEnd]="languageService.getItem(397)" [hideTime]="!permissionService.permissions.ShiftTime"></swe-datetime>
              <swe-element [(model)]="activity.TypeId" [statusLabel]="2" [label]="languageService.getItem(398)" [items]="generalService.activitytypes" [type]="'System.ColorList'"></swe-element>
              <swe-element [(model)]="activity.Text" [statusLabel]="2" [label]="languageService.getItem(399)" [type]="'System.String'"></swe-element>
              <div class="d-grid gap-2">
                <button class="btn btn-primary" (click)="saveActivity(activity, $event)"><i class="bi bi-swe-fw bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
                <button class="btn btn-danger" (click)="deleteActivity(activity, $event)"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
              </div>
            </swe-card>
          </ng-container>
        </div>
        <!--Profiles-->
        <ng-container *ngIf="currentstep.Key==-4">
          <swe-profile [(model)]="booking.Profile" [id]="id" [tabgroupid]="currentstep.Key"></swe-profile>
        </ng-container>
      </ng-container>
      <!--Step Properties-->
      <ng-container *ngFor="let propertygroup of propertytabgroups">
        <ng-container *ngIf="currentstep.Id=='Properties'&&currentstep.Key==propertygroup.Id">
          <swe-profile [(model)]="booking.Profile" [id]="id" [tabgroupid]="currentstep.Key"></swe-profile>
        </ng-container>
      </ng-container>
      <!--Step Status-->
      <ng-container *ngIf="!permissionService.permissions.HideStatus&&currentstep.Id=='Status'&&permissionService.permissions.HasStatusOrdered">
        <!--Status-->
        <ng-container *ngIf="statusCreated">
          <div class="fst-italic mb-2">{{languageService.getItem(1174)}}</div>
          <button class="btn mb-4" [ngClass]="{'btn-outline-primary': booking.Status!=10, 'btn-primary': booking.Status==10}" (click)="booking.Status=10">{{languageService.getItem(1183)}}</button>
        </ng-container>
        <ng-container *ngIf="statusOrdered">
          <div class="fst-italic mb-2">{{languageService.getItem(1173)}}</div>
          <button class="btn mb-4" [ngClass]="{'btn-outline-primary': booking.Status!=20, 'btn-primary': booking.Status==20}" (click)="booking.Status=20">{{languageService.getItem(1182)}}</button>
        </ng-container>
      </ng-container>
      <!--Step Result-->
      <ng-container *ngIf="currentstep.Id=='Result'">
        <!--Result-->
        <div class="fst-italic mb-2">{{languageService.getItem(1177)}}</div>
        <div class="d-grid gap-2 mt-4">
          <button class="btn btn-primary" (click)="gotoHome()"><i class="bi bi-swe-fw bi-house-door"></i>&nbsp;{{languageService.getItem(1178)}}</button>
          <button class="btn btn-primary" (click)="gotoShift()"><i class="bi bi-swe-fw bi-calendar-date"></i>&nbsp;{{languageService.getItem(1179)}}</button>
        </div>
      </ng-container>
      <!--Buttons-->
      <div class="d-grid gap-2 mt-4">
        <!--Next-->
        <button class="btn btn-primary" *ngIf="currentstep.Index<secondlaststep.Index" (click)="next()"><i class="bi bi-swe-fw bi-arrow-right-circle"></i>&nbsp;{{languageService.getItem(1180)}}</button>
        <!--Send-->
        <button class="btn btn-primary" [disabled]="permissionService.permissions.HasStatusOrdered&&booking.Status==0" *ngIf="currentstep.Index==secondlaststep.Index" (click)="save()"><i class="bi bi-swe-fw bi-send"></i>&nbsp;{{languageService.getItem(1181)}}</button>
      </div>
    </swe-card>
  </div>
</div>
<!--To make float behavior (fill available space), use [d-block] on row and [float-start] on columns-->
