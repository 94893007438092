import { Component } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { GeneralService } from '../../_services/general.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { DataService } from '../../_services/data.service';


@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html'
})
export class ImpersonateComponent  {

  private _userid: number = 0;
  private _pincode: string = '';

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private settingService: SettingService,
    private dataService: DataService
  ) {
    //Reset
    settingService.initView();
  }


  //Properties
  public get userid() {
    return this._userid;
  }
  public set userid(val) {
    this._userid = val;
  }
  public get pincode() {
    return this._pincode;
  }
  public set pincode(val) {
    this._pincode = val;
  }



  //Methods
  public impersonate() {
    let id: any = this._userid;
    if (id == 0) {
      id = this.permissionService.isimpersonate();
    }

    this.authenticationService.impersonate(id, this._pincode)
      .subscribe((res) => {
        this._userid = 0;

        setTimeout(() => {
          this.alertService.Add({ type: 'success', message: this.languageService.getItem(317) + ': ' + this.permissionService.user.Firstname + ' ' + this.permissionService.user.Lastname });
        }, 1000);
      });
  }
  public impersonatecore() {
    this.authenticationService.impersonate(-2, this._pincode)
      .subscribe((res) => {
        this._userid = 0;

        setTimeout(() => {
          this.alertService.Add({ type: 'success', message: this.languageService.getItem(317) + ': ' + this.permissionService.user.Firstname + ' ' + this.permissionService.user.Lastname });
        }, 1000);
      });
  }

  
}
