import { Component, OnInit } from '@angular/core';
import { PermissionService } from '../../../_services/permission.service';
import { LanguageService } from '../../../_services/language.service';
import { ViewCacheService } from '../../../_services/viewcache.service';
import { GeneralService } from '../../../_services/general.service';
import { DataService } from '../../../_services/data.service';
import { AlertService } from '../../../_services/alert.service';
import { Location } from '@angular/common';



@Component({
  selector: 'swe-multiobrules',
  templateUrl: './multiobrules.component.html'
})
export class MultiObRulesComponent implements OnInit {
  private _contractOptions: any[] = [];
  private _contractOption: number = 0;
  private _selectedContracts: any[] = [];
  private _selectedRules: any[];
  constructor(public permissionService: PermissionService, public languageService: LanguageService,
    private viewCacheService: ViewCacheService, private generalService: GeneralService, private dataService: DataService, private alertService: AlertService, private location: Location) {
  }

  ngOnInit() {
    this._contractOptions = [{ Id: 0, Name: 100 }, { Id: 1, Name: 17 }];
    this._selectedRules = this.viewCacheService.get('multi_obrules').value;

  }

  public get contracts() {
    return this.generalService.contracts.map(x => ({ Id: x.Key, Name: x.Value }));
  }

  public get contractOptions(): any[] {
    return this._contractOptions;
  }

  public get contractOption(): number {
    return this._contractOption;
  }

  public set contractOption(value: number) {
    this._contractOption = value;
  }

  public get selectedContracts(): any[] {
    return this._selectedContracts;
  }

  public set selectedContracts(value: any[]) {
    this._selectedContracts = value;
  }

  public confirmsave() {
    let confirmText = ''

    if (this._selectedContracts.length > 0) {
      confirmText += this.languageService.getItem(1438);
    }
    return this.languageService.getItem(1437).replace('{0}', confirmText);
  }

  public save() {
    let filter = {
      IdList: this._selectedRules,
      Contracts: this._selectedContracts,
      ContractOption: this._contractOption
    };


    this.dataService.tokenRequest('/api/v1/overtime/multi', 'POST', filter, 'text')
      .subscribe((res) => {
        this.alertService.Add({ message: res, type: 'success' });
        this.location.back();
      });
  }
}
