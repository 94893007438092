import { Component, Input, OnChanges, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { PropertyService } from '../../_services/property.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { ListService } from '../../_services/list.service';
import { DateTimeService } from '../../_services/datetime.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { ListUtility } from '../../_utilities/list.utility';


@Component({
  selector: 'swe-bookingavailableusers',
  templateUrl: './bookingavailableusers.component.html'
})
export class BookingAvailableUsersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: number;
  @Input() open: boolean;
  @Input() bookingdata: any = {};
  @Input() availabledata: any = {};
  
  private unsubsribe$ = new Subject<void>();
  private _availableusers: any[] = [];
  private _multiple: number = 1;
  private _more: boolean = false;
  private _all: boolean = false;
  private _loading: boolean;
  private _checknotificationemail: boolean = this.permissionService.permissions.NotifyOnBookEmail == 1 ? true : false;
  private _checknotificationsms: boolean = this.permissionService.permissions.NotifyOnBookSms == 1 ? true : false;
  private _usermenu: boolean = false;
  private _availablefiltermenu: boolean = false;
  private _showremind: boolean = false;
  private _markaccess: boolean = false;
  private _sortId: number = 0;
  private _affectserie: boolean = false;
  private _exclude: boolean = this.permissionService.permissions.HideMarked;
  private _notred: number = 0;
  private _remind: any;
  private _checkremind: boolean = false;
  private _activerow: number = -1;
  private _listutility: ListUtility = new ListUtility();
  //Popup
  private _userIdList: any[] = [];
  private _popup: any[] = [{ Visible: false, Event: null, Option: 3 }];
  private _bookingEnd: Date;
  private _bookingStart: Date;

  constructor(public languageService: LanguageService, public permissionService: PermissionService, public generalService: GeneralService, public propertyService: PropertyService, private viewCacheService: ViewCacheService, private dataService: DataService, private dateTimeService: DateTimeService, private alertService: AlertService, public settingService: SettingService, private listService: ListService, private route: ActivatedRoute, private router: Router) {
    
    settingService.initView(2 | 16 | 512, ['User'], [{ type: 'user', key: 'levelgroups' }, { type: 'user', key: 'levels' }]);

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.load();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

    ngOnInit() {
    let remindsms = this.permissionService.permissions.RemindSms;
    this._showremind = (remindsms > 0);
    this._checkremind = (this._showremind && (remindsms > 1 && this.bookingdata.RemindSmsDefault != 1) || this.bookingdata.RemindSmsDefault == 2);

    this._listutility.dataaccessname = 'MarkAccess';
    this._listutility.dataaccess = 1;

    this._bookingStart = new Date(this.bookingdata.Start);
    this._bookingEnd = new Date(this.bookingdata.End);

  }

  ngOnChanges() {
    if (this.open) {
      
      this.load();
    }
  }


  /*Properties*/
  public get loading() {
    return this._loading;
  }

  public get remindString() {
    return this._remind ? this._remind.split('+')[0].replace('T', ' ') : '';
  }
  public get availableusers() {
    return this._availableusers;
  }
  public get markaccess() {
    return this._markaccess;
  }
  public get checknotificationemail() {
    return this._checknotificationemail;
  }
  public set checknotificationemail(val) {
    this._checknotificationemail = val;
  }
  public get checknotificationsms() {
    return this._checknotificationsms;
  }
  public set checknotificationsms(val) {
    this._checknotificationsms = val;
  }
  public get usermenu() {
    return this._usermenu;
  }
  public set usermenu(val) {
    this._usermenu = val;
  }
  public get availablefiltermenu() {
    return this._availablefiltermenu;
  }
  public set availablefiltermenu(val) {
    this._availablefiltermenu = val;
  }
  public get showremind() {
    return this._showremind;
  }
  public get notred() {
    return this._notred;
  }
  public get affectserie() {
    return this._affectserie;
  }
  public set affectserie(val) {
    this._affectserie = val;
  }
  public get exclude() {
    return this._exclude;
  }
  public set exclude(val) {
    this._exclude = val;
  }
  public get more() {
    return this._more;
  }
  public get all() {
    return this._all;
  }
  public get remind() {
    return this._remind;
  }
  public set remind(val) {
    this._remind = val;
  }
  public get checkremind() {
    return this._checkremind;
  }
  public set checkremind(val) {
    this._checkremind = val;
  }
  public get sortId() {
    return this._sortId;
  }
  public get activerow() {
    return this._activerow;
  }
  public set activerow(val) {
    this._activerow = val;
  }
  public get listutility() {
    return this._listutility;
  }
  //Popup
  public get userIdList() {
    return this._userIdList;
  }
  public get popup() {
    return this._popup;
  }
  public set popup(val) {
    this._popup = val;
  }

  public get bookingEnd() {
    return this._bookingEnd;
  }

  public get bookingStart() {
    return this._bookingStart;
  }

  /*Methods*/
  public load(getmore: boolean = false, all: boolean = false) {

    this._more = false;
    this._all = false;
    this._loading = true;
    this._usermenu = false;
    this._availablefiltermenu = false;

    let users = [...this._availableusers];

    let top = this.permissionService.permissions.NumberOfResources;
    if (all) {
      top = 0;
      users = [];
      this._notred = 0;
    }

    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._listutility.checkcounter = 0;
      this._listutility.ischecked = false;
      users = [];
      this._notred = 0;
    }

    let filter = {
      BookingId: this.id,
      Top: top,
      Multiple: this._multiple,
      Sort: this._sortId,
      Search: this.settingService.user.search,
      UserList: this.settingService.userList(),
      RoleList: this.settingService.user.roles.join(),
      Active: 1,
      Start: this.bookingdata.Start,
      End: this.bookingdata.End,
      Available: this.settingService.booking.availability,
      IgnoreGroupSort: true,
      AffectsAllShiftsInSerie: this._affectserie,
      Employment: this.settingService.user.employment,
      EmploymentCategories: this.settingService.user.employmentcategories,
      FrameworkContracts: this.settingService.user.frameworkcontracts,
      Contract: this.settingService.user.contract,
      Contracts: this.settingService.user.contracts,
      //Properties
      PropertyList: this.settingService.property.properties,
      UseOR: this.settingService.property.useor
    };

    this.dataService.tokenRequest('/api/v1/users/search', 'POST', filter)
      .subscribe(res => {
        if (res) {
          

          this._markaccess = res.MarkAccess;

          
          res.Users.forEach((user) => {

            if (this.permissionService.permissions.AvailableProfile) {
              this.manageProfile(user.Profile);
            }

            if (!user.Warning) {
              this._notred++;
            }

            if (user.AvailabilityStart != '0001-01-01T00:00:00') {
              user.AvailabilityStart = new Date(user.AvailabilityStart);
            }
            if (user.AvailabilityEnd != '0001-01-01T00:00:00') { 
              user.AvailabilityEnd = new Date(user.AvailabilityEnd);
            }
            users.push(user);

          });

          //Smoother client movement
          this._availableusers = users;

          this._listutility.rows = this._availableusers;
          this._more = res.More;
          this._all = (res.More && this._availableusers.length > 0);

          this._loading = false;
        }
      });

    let start = new Date(this.bookingdata.Start);
    this._remind = this.dateTimeService.formatWithTimeZone(new Date(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes() - this.permissionService.permissions.RemindDelay));

  }
  public openuser(user, e) {
    e.stopPropagation();

    let url = '/users/' + user.Id;
    
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public gotoAvailability(availabilityId, e) {
    e.stopPropagation();

    let url = '/usermarkeddates/' + availabilityId;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public cut(str: string) {

    let max = 10;

    if (str.length > max) {
      str = str.substring(0, max);

      if (str.split(' ').length > 3) {
        str = str.substring(0, str.lastIndexOf(' '));
      }

      str += "...";
    }

    return str;
  }
  public bookoption(type) {
    let res = false;

    this._availableusers.forEach((user) => {

      if (!res && user.Type != type) {
        res = true;
      }

    });

    return res;
  }
  public action(option) {

    let users = [];
    this._availableusers.forEach((user) => {
      if (user.checked && user.MarkAccess > 0) {
        users.push(user.Id);
      }
    });

    if (users.length > 0) {

      this.viewCacheService.add('multi_users', users);

      this.router.navigate(['/users/action/' + option + '/']);
    }
  }



  //Book and Cancellation
  public savelist(e, booktype) {

    this._usermenu = false;

    let users = [];
    let timereports = [];
    this._availableusers.forEach((user) => {
      if (user.checked) {
        if (user.Access > 1 || booktype == 2 || booktype == 3) {
          //Standby and Abscent booking is valid even when access is to low
          if (!this._exclude || !user.Warning) {
            users.push(user);
            timereports.push(user.Id);
          }
        }
        else {
          this.alertService.Add({ message: this.languageService.getItem(175), type: 'danger' });
        }
      }
    });

    if (booktype < 3 || !this.permissionService.permissions.DetailWhenAbscent) {
      this.synchronouslysave(e, booktype, users, 0);
    }
    else {
      //Abscent
      this._popup[0].Visible = true;
      this._popup[0].Event = 3;
      this._popup[0].Option = e;

      this._userIdList = timereports;
    }
  }
  public save(e, user, booktype) {
    this._usermenu = false;

    this.synchronouslysave(e, booktype, [user], 0);
  }
  



  //Send
  public send(type: number, serie: number, e) {

    e.stopPropagation();

    let users = [];
    this._availableusers.forEach((user) => {
      if (user.checked && user.MarkAccess > 0 && (!this._exclude || !user.Warning)) {
        users.push(user);
      }
    });

    let url = '';
    if (type == 1) {
      url = '/messages/send/email/id/' + this.bookingdata.Id + '/serie/' + serie + '/body/40/subject/41';
      this.viewCacheService.add('message_users', users);
    }
    else if (type == 3) {
      url = '/messages/send/sms/id/' + this.bookingdata.Id + '/serie/' + serie + '/body/40';
      this.viewCacheService.add('message_users', users);
    }
    else if (type == 6) {
      //Request 3 => System + Sms
      url = '/requests/send/3/id/' + this.bookingdata.Id + '/serie/' + serie + '/body/4';
      this.viewCacheService.add('request_users', users);
    }

    this.router.navigate([url]);
  }
  public sort(id) {

    if (this._sortId == id) {
      this._sortId *= -1;
    }
    else if ((this._sortId * -1) == id) {
      this._sortId = 0;
    }
    else {
      this._sortId = id;
    }

    this._loading = false;
    this.load(false);
  }
  public reloadAfterBook() {
    //The event will also cause a reload on this component
    this.settingService.viewrefresh('availableusers_save');
  }



  //Functions
  private synchronouslysave(e, booktype, users, counter) {

    let user = users[counter];
    
    let filter = {
      Type: booktype,
      Slots: this.bookingdata.Slots,
      ManualNotify: true,
      ManualNotifyEmail: this._checknotificationemail,
      ManualNotifySms: this._checknotificationsms,
      RemoveATK: this.bookingdata.RemoveATK
    };


    if (e > 1) {
      filter["Series"] = e;
    }


    if (this._checkremind) {
      filter["Remind"] = this._remind;
    }


    this.dataService.tokenRequest('/api/v1/bookings/' + this.id + '/users/' + user.Id, 'POST', filter, 'text')
      .subscribe(res => {

        let type = 'success';
        if (res != this.languageService.getItem(34)) {
          type = 'warning';
        }

        this.alertService.Add({ message: res, type: type });

        counter++;
        if (counter < users.length) {
          this.synchronouslysave(e, booktype, users, counter);
        }
        else {
          //The event will also cause a reload on this component
          this.settingService.viewrefresh('availableusers_save');
        }
      });

  }
  private manageProfile(profileList: any[]) {

    profileList.forEach((profile) => {

      let property = this.propertyService.getProperty(profile.Property);
      if (property) {
        profile.Type = property.Type;
        if (profile.Type == 'System.DateTime' || profile.Type == 'System.Date' || profile.Type == 'System.Time') {
          if (property.Format && property.Format.length > 0) {
            profile.FormatValue = this.dateTimeService.format(profile.Value, property.Format);
          }
        }
        else if (profile.Type == 'System.Double') {
          profile.FormatValue = this.generalService.formatdecimal(profile.Value);
        }
        else if (profile.Type == 'System.List' || profile.Type == 'System.MultiList') {
          let res = '';

          if (profile.Value != null) {
            profile.Value.split('|').forEach((v) => {

              property.Items.forEach((pi) => {

                if (pi.Id == parseInt(v)) {

                  if (res.length > 0) {
                    res += '| ';
                  }
                  res += pi.Name;
                }

              });

            });
          }

          profile.FormatValue = res;

        }
        else {
          profile.FormatValue = profile.Value;
        }
      }

    });

  }
  
}
