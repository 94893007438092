<swe-card header="{{header}}" *ngIf="object" [(open)]="open" (refreshChange)="load()" [focus]="true" (saveChange)="save()" [hasSave]="object.Access>1">
  <span swe-rightcommand>
    <span class="swe-click" (click)="create($event)" *ngIf="id>0"><i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right"></i></span>
  </span>
  <!--Category-->
  <div *ngIf="!loading&&categories.length>1">
    <swe-element [type]="'System.List'" [label]="languageService.getItem(161)" [statusLabel]="1" [(model)]="object.ObjectId" [items]="categories"></swe-element>
  </div>
  <!--Profile-->
  <div *ngIf="!loading">
    <swe-profile [(model)]="object.Profile"></swe-profile>
  </div>
  <!--Buttons-->
  <div class="d-grid gap-2" *ngIf="!loading">
    <button class="btn btn-primary" (click)="save()" *ngIf="object.Access>1"><i class="bi bi-swe-fw bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
    <swe-confirm *ngIf="object.Id>0&&object.Access>2" [body]="languageService.getItem(689)" (acceptChange)="delete()">
      <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
    </swe-confirm>
  </div>
</swe-card>
