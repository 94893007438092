<swe-card header="{{languageService.getItem(241)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="true">
  <swe-datepager [type]="'booking'" (dateChange)="search()" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <div class="table-responsive">
    <table class="table">
    <thead>
      <tr>
        <th>{{languageService.getItem(1313)}}</th>
        <th>{{languageService.getItem(1314)}}</th>
        <th>{{languageService.getItem(1315)}}</th>
        <th>{{languageService.getItem(1316)}}</th>
        <th>{{languageService.getItem(1317)}}</th>
        <th>{{languageService.getItem(1318)}}</th>
        <th>{{languageService.getItem(1319)}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let stat of statistics">
        <td>{{dateTimeService.format(stat.Start, 'yyyy-MM-dd')}}</td>
        <td>{{dateTimeService.format(stat.End, 'yyyy-MM-dd')}}</td>
        <td>{{stat.Sms}}</td>
        <td>{{stat.ActiveLevels}}</td>
        <td>{{stat.InActiveLevels}}</td>
        <td>{{stat.ActiveUsers}}</td>
        <td>{{stat.InActiveUsers}}</td>
      </tr>
    </tbody>
    <tfoot *ngIf="statistics.length>1">
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td class="fw-bold"><div>{{summary.Sms}}</div><div>&mu;&nbsp;{{generalService.formatdecimal(summary.Sms/statistics.length)}}</div></td>
        <td class="fw-bold"><div>{{summary.ActiveLevels}}</div><div>&mu;&nbsp;{{generalService.formatdecimal(summary.ActiveLevels/statistics.length)}}</div></td>
        <td class="fw-bold"><div>{{summary.InActiveLevels}}</div><div>&mu;&nbsp;{{generalService.formatdecimal(summary.InActiveLevels/statistics.length)}}</div></td>
        <td class="fw-bold"><div>{{summary.ActiveUsers}}</div><div>&mu;&nbsp;{{generalService.formatdecimal(summary.ActiveUsers/statistics.length)}}</div></td>
        <td class="fw-bold"><div>{{summary.InActiveUsers}}</div><div>&mu;&nbsp;{{generalService.formatdecimal(summary.InActiveUsers/statistics.length)}}</div></td>
      </tr>
    </tfoot>
  </table>
</div>
</swe-card>
