import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { DateTimeService } from '../../_services/datetime.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { AlertService } from '../../_services/alert.service';
import { PermissionService } from '../../_services/permission.service';


@Component({
  selector: 'swe-messagereceivers',
  templateUrl: './messagereceivers.component.html'
})
export class MessageReceiversComponent implements OnInit {

  @Input() id: number = 0;
  @Input() category: string = '';

  private _receivers: any[] = [];
  private _loading: boolean = false;
  private _checkcounter: number = 0;
  private _ischecked: boolean = false;
  private _actionmenu: boolean = false;
  private _resendAllowed: boolean = false;

  constructor(
    public languageService: LanguageService,
    public dateTimeService: DateTimeService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService
  ) {

    
  }

  ngOnInit() {
    this._resendAllowed = (this.category == 'outgoing');

    this.load();
  }

  /*Properties*/
  public get receivers() {
    return this._receivers;
  }
  public get loading() {
    return this._loading;
  }
  public get ischecked() {
    return this._ischecked;
  }
  public get actionmenu() {
    return this._actionmenu;
  }
  public set actionmenu(val) {
    this._actionmenu = val;
  }
  public get resendAllowed() {
    return this._resendAllowed;
  }



  //Methods
  public reload() {
    this.load();
  }
  public resend() {

    if (!this._resendAllowed) { return; }

    this._actionmenu = false;
    this._ischecked = false;

    let resendlist = [];
    this._receivers.forEach((receiver) => {
      if (receiver.checked) {
        resendlist.push(receiver.Id);
      }
    });

    if (resendlist.length > 0) {
      this.dataService.tokenRequest('/api/v1/messages/' + this.id + '/resend', 'POST', resendlist, 'text')
        .subscribe((res) => {

          this.alertService.Add({ type: 'success', message: res });

          this.load();

        });
    }
  }




  //Check Users
  public toggle(row, e) {

    e.stopPropagation();

    if (!this._resendAllowed) { return; }

    row.checked = !row.checked;

    this._checkcounter = this._checkcounter + (row.checked ? 1 : -1);

    this._ischecked = this._checkcounter > 0;
  }
  public checkall(option) {

    if (!this._resendAllowed) { return; }

    this._receivers.forEach((receiver) => {
      receiver.checked = option;
    });

    this._checkcounter = option ? this._receivers.length : 0;
    this._ischecked = option;
  }


  //Functions
  private load() {

    this._loading = true;

    this._receivers = [];
    
    this.dataService.tokenRequest('/api/v1/messages/' + this.category + '/' + this.id + '/receivers', 'GET', {})
      .subscribe(res => {
        if (res) {

          this._receivers = res;

          this._loading = false;
        }
      });
  }
  
}
