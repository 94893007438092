import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { DateTimeService } from '../../_services/datetime.service';



@Component({
  selector: 'swe-homecheckin',
  templateUrl: './homecheckin.component.html',
})
export class HomeCheckInComponent implements OnInit {

  private _loading: boolean;
  private _nextcheckin: any = {};
  private _nextcheckout: any = {};

  constructor(
    public permissionService: PermissionService,
    public languageService: LanguageService,
    private dataService: DataService,
    private alertService: AlertService,
    private dateTimeService: DateTimeService
  ) {

  }

  ngOnInit() {
    this.loadCheckIn();
    this.loadCheckOut();
  }

  
  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get nextcheckin() {
    return this._nextcheckin;
  }
  public get nextcheckout() {
    return this._nextcheckout;
  }



  /*Methods*/
  public checkin() {

    this._loading = true;

    let dto: any = {
      CheckIn: true
    };

    this.dataService.tokenRequest('/api/v1/timereports/checkincheckout/', 'POST', dto, 'text')
      .subscribe(res => {
        if (res) {
          this.alertService.Add({ message: res, type: 'success' });
          this.permissionService.reloadUser().subscribe((res) => {
            this.loadCheckIn();
            this.loadCheckOut();
            this._loading = false;
          });
        }
      });
  }
  public checkout() {
    this._loading = true;

    let dto: any = {
      CheckOut: true
    };

    this.dataService.tokenRequest('/api/v1/timereports/checkincheckout/', 'POST', dto, 'text')
      .subscribe(res => {
        if (res) {
          this.alertService.Add({ message: res, type: 'success' });
          this.permissionService.reloadUser().subscribe((res) => {
            this.loadCheckIn();
            this.loadCheckOut();
            this._loading = false;
          });
        }
      });
  }




  //Functions
  private loadCheckIn() {
    this._nextcheckin = {};

    this.dataService.tokenRequest('/api/v1/timereports/checkin', 'GET', {})
      .subscribe((res) => {

        if (res.Id > 0) {
          this._nextcheckin = res;
          this._nextcheckin.datetimeheader = this.dateTimeService.header(new Date(res.Start), new Date(res.End), false);
        }
      });
  }
  private loadCheckOut() {
    this._nextcheckout = {};

    this.dataService.tokenRequest('/api/v1/timereports/checkout', 'GET', {})
      .subscribe((res) => {

        if (res.Id > 0) {
          this._nextcheckout = res;
          this._nextcheckout.datetimeheader = this.dateTimeService.header(new Date(res.Start), new Date(res.End), false);
        }
      });
  }

}
