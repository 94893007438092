import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { SettingService } from '../../../_services/setting.service';
import { DataService } from '../../../_services/data.service';
import { AlertService } from '../../../_services/alert.service';
import { DateTimeService } from '../../../_services/datetime.service';
import { GeneralService } from '../../../_services/general.service';



@Component({
  selector: 'swe-economysettings',
  templateUrl: './economysettings.component.html'
})
export class EconomySettingsComponent implements OnInit {

  private _settings: any = {};
  private _periods: any[] = [];


  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public dateTimeService: DateTimeService,
    public generalService: GeneralService,
    private settingService: SettingService,
    private dataService: DataService,
    private alertService: AlertService
  ) {
    //Reset
    settingService.initView();

  }


  ngOnInit() {
    this.init();
    this.load();
  }



  //Properties
  public get settings() {
    return this._settings;
  }
  public get periods() {
    return this._periods;
  }




  //Methods
  public load() {

    this.dataService.tokenRequest('/api/v1/economy/settings/', 'GET', {})
      .subscribe((res) => {

        this._settings = res;

      });
  }
  public save() {

    this.dataService.tokenRequest('/api/v1/economy/settings/', 'POST', this._settings, 'text', 'response')
      .subscribe((response) => {

        this.alertService.Add({ type: 'success', message: response.body });

        this.load();
        
      });

  }




  //Functions
  private init() {

    this._periods = [];
    this._periods.push({ Id: 1, Name: this.languageService.getItem(528) });
    this._periods.push({ Id: 2, Name: this.languageService.getItem(693) });
    this._periods.push({ Id: 3, Name: this.languageService.getItem(694) });
  }

}
