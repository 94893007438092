import { Component, Input, Output } from '@angular/core';

import { SettingService } from '../_services/setting.service';

@Component({
  selector: 'swe-filtercard',
  templateUrl: 'filtercard.component.html',
})
export class FilterCardComponent {
  @Input() header: string = '';
  @Input() type: string = '';
  @Input() key: string = '';

  private _open: boolean = false;
  
  constructor(
    private settingService: SettingService
  ) {
    
  }

  /*Properties*/
  public get open(): boolean {
    return this._open;
  }
  public get diff(): boolean {
    let diff: boolean = false;
    if (this.key) {
      this.key.split('|').forEach((item) => {
        let isdiff = this.settingService.isDiff(this.type, item);
        if (isdiff) {
          diff = true;
        }
      });
    }

    return diff;
  }
  public get diffamount(): number {
    let res = 0;

    if (this.key) {
      
      this.key.split('|').forEach((item) => {
        let isdiff = this.settingService.isDiff(this.type, item);
        if (isdiff) {
          let diff = this.settingService.getDiff(this.type, item);

          if (Array.isArray(diff)) {
            res = diff.filter(item => { return !item.Disabled; }).length;
          }
          else {
            res = 1;
          }
        }
      });
    }

    return res;
  }


  /*Methods*/
  public toggle() {
    this._open = !this._open;
  }
}
