import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

import { PermissionService } from '../_services/permission.service';
import { LanguageService } from '../_services/language.service';
import { DateTimeService } from '../_services/datetime.service';

@Component({
  selector: 'swe-breaklist',
  templateUrl: './breaklist.component.html',
})
export class BreakListComponent implements OnInit {
  @Input() markChanges: boolean;
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() modelStart: any;
  @Input() disabled: boolean;
  @Input() disabledStart: boolean;
  @Input() minStart: any;
  @Input() maxStart: any;
  @Input() reset: boolean;
  @Input() hasBreakInfo: boolean;
  @Input() onlyOne: boolean = false;
  @Input() readonly: boolean = false;
  @Input() mealbreakAccess: number = 0;
  @Input() mealbreak: boolean = false;
  @Input() alwaysShowStart: boolean = false;
  @Input() ignoreConstraints: boolean = false;
  @Output() mealbreakChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetChange: EventEmitter<any> = new EventEmitter<any>();

  private _break: any;
  private _breakStart: any;
  private _isRedo: boolean = false;
  private _original: any;

  constructor(
    public permissionService: PermissionService,
    public languageService: LanguageService
  ) {

  }

  ngOnInit() {

    if (Array.isArray(this.model) && this.model.length > 0) {
      let array: any[] = this.model;
      this._break = array[array.length - 1].Break;
      this._breakStart = array[array.length - 1].BreakStart;
    } else if (Array.isArray(this.model)) {
      this.model.push({Break: 0});
    }

    if (this._breakStart == null) {
      this._breakStart = '1971-01-01T00:00:00';
    }
    if (this._break == null) {
      this._break = 0;
    }
  }


  //Properties
  public get isRedo() {
    return this._isRedo;
  }

  public manageChange() {
    this.modelChange.emit(this.model);
  }


  //Methods
  public add() {
    if (this.mealbreak) {
      return;
    }

    let item = { Break: this._break, BreakStart: this._breakStart };

    if (Array.isArray(this.model)) {
      this.model.push(item);
    }
    this.modelChange.emit(this.model);

  }
  public remove(obj) {
    if (this.mealbreak) {
      return;
    }
    if (Array.isArray(this.model)) {
      const index = this.model.indexOf(obj);
      if (index > -1) {
        this.model.splice(index, 1);
      }
    }
    this.modelChange.emit(this.model);

  }
  public redo() {
    if (this.mealbreak) {
      return;
    }
    this._isRedo = true;

    this.resetChange.emit(this.model);

    setTimeout(() => {
      this._isRedo = false;
    }, 1000);
  }
  public manageMealbreak() {
    this.mealbreak = !this.mealbreak;
    if (this.mealbreak && Array.isArray(this.model) && this.model.length > 0) {
      this.model = [];
      this._original = this.model[0];
      this.model[0] = { Break: 0 };
    }
    else if (Array.isArray(this.model) && this.model.length > 0) {
      if (this._original) {
        this.model[0] = this._original;
      }
      else {
        this.model[0] = { Break: 0 };
      }
    }
    this.mealbreakChange.emit(this.mealbreak);
  }
}
