import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { LanguageService } from './language.service';
import { AlertService } from './alert.service';


@Injectable({ providedIn: 'root' })
export class DateTimeService {

  //Class members;
  private _onesecond = 1000;
  private _oneminute = (60 * this._onesecond);
  private _onehour = (60 * this._oneminute);
  private _oneday = (24 * this._onehour);
  private _oneweek = (7 * this._oneday);
    

  constructor(private languageService: LanguageService, private alertService: AlertService, private datePipe: DatePipe) {

  }

  //Formats
  public isValid(date) {
    return date instanceof Date && !isNaN(date.getTime());
  }
  public format(date: Date, _format): string {
    //if (!(date instanceof Date)) {
    //  //Not date object but perhaps a datetime string
    //  date = new Date(date);
    //}

    //if (this.isValid(date)) {
      if (_format && _format.length > 0) {
        return this.datePipe.transform(date, _format);
      }
      else {
        return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm');
      }
    //}
    //return null;
  }
  public formatWithTimeZone(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ssZ').replace(/([+\-]\d\d)(\d\d)$/, "$1:$2"); //IE and Safari fix
  }


  //Validation
  public validation(start, end, equalNotAllowed = false) {
    if (typeof start == "undefined" || start == null) {
      this.alertService.Add({ message: this.languageService.getItem(420), type: 'danger' });
      return false;
    }
    else if (typeof end == "undefined" || end == null) {
      this.alertService.Add({ message: this.languageService.getItem(421), type: 'danger' });
      return false;
    }
    else {
      if (!equalNotAllowed && new Date(start) > new Date(end)) {
        this.alertService.Add({ message: this.languageService.getItem(422), type: 'danger' });
        return false;
      }
      if (equalNotAllowed && new Date(start) >= new Date(end)) {
        this.alertService.Add({ message: this.languageService.getItem(422), type: 'danger' });
        return false;
      }
    }

    return true;
  }
  

  //Weeks
  public weekdayname (date, length) {
    let name = [this.languageService.getItem(394),
      this.languageService.getItem(388),
      this.languageService.getItem(389),
      this.languageService.getItem(390),
      this.languageService.getItem(391),
      this.languageService.getItem(392),
      this.languageService.getItem(393)][date.getDay()];

    if (typeof name == "undefined") {
      name = '';
    }

    if (typeof length != "undefined" && length > 0) {
      name = name.substring(0, length);
    }

    return name;
  };
  public weeknumber(date) {

    //Week 1 contain January 4
    let jan4 = new Date(date.getFullYear(), 0, 4);

    //First monday
    let mon1 = this.firstDayInWeek(jan4);

    //Monday in current week
    let monDate = this.firstDayInWeek(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));

    //Diff
    let diff = monDate.getTime() - mon1.getTime();

    let weekNumber = 0;
    if (diff / this._oneweek >= 52 || diff / this._oneweek <= 0) {
      //Weeknumber 53 or 1
      //Use 1 jan next year and test day of week
      let jan1 = new Date(date.getFullYear() + 1, 0, 1);

      if (jan1.getDay() > 0 && jan1.getDay() < 5) {
        //Monday to Thursday
        weekNumber = 1;
      }
      else {
        //Friday to Sunday
        weekNumber = 53;
      }
    }
    else {
      //Weeknumber
      weekNumber = Math.floor(diff / this._oneweek) + 1;
    }

    return weekNumber;
  };

  
  //Months
  public monthname(date: Date, length?:number): string {
    let name = this.monthnames[date.getMonth()];
    if (typeof name == "undefined") {
      name = '';
    }

    if (typeof length != "undefined" && length > 0) {
      name = name.substring(0, length);
    }

    return name;
  }
  public monthnamebynumber(number, length?: number): string {
    let name = this.monthnames[number];
    if (typeof name == "undefined") {
      name = '';
    }

    if (typeof length != "undefined" && length > 0) {
      name = name.substring(0, length);
    }

    return name;
  }

  
  //Add
  public addMonths(date, months) {
    return new Date(date.getFullYear(), date.getMonth() + months, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  }
  public addWeeks(date, weeks) {
    return new Date(date.getTime() + this._oneweek * weeks);
  }
  public addDays(date, days) {
    return new Date(date.getTime() + this._oneday * days);
  }
  public addHours(date, hours) {
    return new Date(date.getTime() + this._onehour * hours);
  }
  public addMinutes(date, minutes) {
    return new Date(date.getTime() + this._oneminute * minutes);
  }
  public addSeconds(date, seconds) {
    return new Date(date.getTime() + this._onesecond * seconds);
  }

  //First
  public firstDayInWeek(date: Date): Date {
    while (date.getDay() != 1) {

      date = this.addDays(date, -1);
    }

    return date;
  }
  public firstDayInMonth(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  public firstDayInQuarter(date: Date): Date {
    var month = 0;
    if (date.getMonth() > 8) {
      month = 9;
    }
    else if (date.getMonth() > 5) {
      month = 6;
    }
    else if (date.getMonth() > 2) {
      month = 3;
    }

    return new Date(date.getFullYear(), month, 1);
  }

  //Last
  public lastDayInWeek(date: Date): Date {
    while (date.getDay() != 0) {

      date = this.addDays(date, 1);
    }

    return date;
  }
  public lastDayInMonth(date: Date): Date {
    var firstinnextmonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);

    return new Date(firstinnextmonth.getFullYear(), firstinnextmonth.getMonth(), firstinnextmonth.getDate() - 1);
  }
  public lastDayInQuarter(date: Date): Date {
    var month = 2;
    if (date.getMonth() > 8) {
      month = 11;
    }
    else if (date.getMonth() > 5) {
      month = 8;
    }
    else if (date.getMonth() > 2) {
      month = 5;
    }

    var firstinnextmonth = new Date(date.getFullYear(), month + 1, 1);

    return new Date(firstinnextmonth.getFullYear(), firstinnextmonth.getMonth(), firstinnextmonth.getDate() - 1);
  }

  //Date
  public toDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  //Header
  public header(start: Date, end: Date, onlytime: boolean = false, includeyear: boolean = false): string {
    let header = '';

    let onemonth = (31 * 24 * 60 * 60 * 1000);

    let startoffset = start.getTimezoneOffset();
    let endoffset = end.getTimezoneOffset();
    let tmp_oneday = this._oneday;
    if (startoffset != endoffset) {
      tmp_oneday += (endoffset - startoffset) * 60 * 1000;
    }

    let wholedays = (start.getHours() == 0 && start.getMinutes() == 0 && end.getHours() == 0 && end.getMinutes() == 0);
    let endForEqual = wholedays ? new Date(end.getTime() - this._onesecond) : end;
    let equalyear = (start.getFullYear() == endForEqual.getFullYear());
    let equalmonth = equalyear && (start.getMonth() == endForEqual.getMonth());
    let equalday = equalmonth && (start.getDate() == endForEqual.getDate());

    if (wholedays) {
      //Day event
      if ((end.getTime() - start.getTime()) == tmp_oneday) {
        //One day
        header = '';

        if (!onlytime) {
          header += this.weekdayname(start, 2);
          header += ' ';
        }
        header += start.getDate();
        header += ' ';
        header += this.monthname(start).substring(0, 3);
      }
      else {
        //Many days
        end = new Date(end.getTime() - tmp_oneday);

        header = '';
        if (!onlytime) {
          header += this.weekdayname(start, 2);
          header += ' ';
        }
        header += start.getDate();
        if (!equalmonth) {
          header += ' ';
          header += this.monthname(start).substring(0, 3);
        }
        if ((!equalyear && ((end.getTime() - start.getTime()) > onemonth)) || includeyear) {
          header += ' ';
          header += start.getFullYear();
        }
        header += ' - ';
        header += end.getDate();
        header += ' ';
        header += this.monthname(end).substring(0, 3);
        if ((end.getTime() - start.getTime()) > onemonth) {
          header += ' ';
          header += end.getFullYear();
        }
      }
    }
    else {
      //Time event
      if (onlytime && equalday) {
        header = this.format(start, 'HH:mm') + ' - ' + this.format(end, 'HH:mm');
      }
      else {
        header = '';

        /*Date and Month*/
        if (!onlytime) {
          header += this.weekdayname(start, 2);
          header += ' ';
          header += start.getDate();
          header += ' ';
          header += this.monthname(start).substring(0, 3);

          /*Year*/
          if (((end.getTime() - start.getTime()) > onemonth) || includeyear) {
            header += ' ';
            header += start.getFullYear();
          }
          header += ' ';
        }
        /*Time (start)*/
        header += this.format(start, 'HH:mm');
        header += ' - ';
        /*Date and Month (if different)*/
        if (!equalday) {
          header += end.getDate();
          header += ' ';
          header += this.monthname(end).substring(0, 3);
        }
        /*Year (if different)*/
        if (!equalyear && ((end.getTime() - start.getTime()) > onemonth)) {
          header += ' ';
          header += end.getFullYear();
        }
        /*Time (end)*/
        header += ' ';
        header += this.format(end, 'HH:mm');
      }
    }

    return header;
  }


  //Properties
  public get onesecond() { return this._onesecond; }
  public get oneminute() { return this._oneminute; }
  public get onehour() { return this._onehour; }
  public get oneday() { return this._oneday; }
  public get oneweek() { return this._oneweek; }





  private get monthnames(): string[] {
    let monthnames = [
      this.languageService.getItem(216),
      this.languageService.getItem(217),
      this.languageService.getItem(218),
      this.languageService.getItem(219),
      this.languageService.getItem(220),
      this.languageService.getItem(221),
      this.languageService.getItem(222),
      this.languageService.getItem(223),
      this.languageService.getItem(224),
      this.languageService.getItem(225),
      this.languageService.getItem(226),
      this.languageService.getItem(227)
    ];

    return monthnames;
  }



}
