import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { ListService } from '../../_services/list.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { SettingService } from '../../_services/setting.service';
import { AlertService } from '../../_services/alert.service';


@Component({
  selector: 'app-adminproperties',
  templateUrl: './adminproperties.component.html'
})
export class AdminPropertiesComponent implements OnInit {

  private _groupedby: any = {};
  private _properties: any[] = [];
  private _loading: boolean = false;


  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private listService: ListService,
    private viewCacheService: ViewCacheService,
    private router: Router,
    private alertService: AlertService,
    private settingService: SettingService
  ) {
    //Reset
    settingService.initView();
  }


  ngOnInit() {

    this.init();

  }



  //Properties
  public get groupedby() {
    return this._groupedby;
  }
  public get properties() {
    return this._properties;
  }
  public get isloading() {
    return this._loading;
  }



  //Methods
  public open(e, id: number, category: number = 0) {

    if (id > 0) {
      let opencards = 0;
      let index = 0;
      Object.values(this._groupedby).forEach((item) => {
        if (item['open']) {
          opencards += Math.pow(2, index);
        }
        index++;
      });

      this.viewCacheService.add('admin_properies_opencards', opencards);
    }

    let url = '/admin/properties/' + id;
    if (category > 0) {
      url += '/category/' + category;
    }

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }


  public sort(group, index, moveUp, e) {

    e.stopPropagation();
    if (index == 0 && moveUp) { return; }
    if (index == group.length - 1 && !moveUp) { return; }

    let otherIndex = moveUp ? (index - 1) : (index + 1);

    let currentProperty = group[index];
    let otherProperty = group[otherIndex];

    if (currentProperty.Sort == otherProperty.Sort) {
      //Separate sort position
      currentProperty.Sort = currentProperty.Sort + (moveUp ? -1 : 1);
      otherProperty = null;
    }
    else {
      //Switch sort position
      let tmpSort = currentProperty.Sort;
      currentProperty.Sort = otherProperty.Sort;
      otherProperty.Sort = tmpSort;
    }

      this.dataService.tokenRequest('/api/v1/properties/' + currentProperty.Id + '/sort/' + currentProperty.Sort, 'Put', null, 'text', 'response')
      .subscribe((response) => {
          if (otherProperty != null) {
              this.dataService.tokenRequest('/api/v1/properties/' + otherProperty.Id + '/sort/' + otherProperty.Sort, 'Put', null, 'text', 'response')
                  .subscribe((response2) => {
                      group = group.sort((a, b) => a.Sort - b.Sort);
                    this.alertService.Add({ type: 'success', message: response2.body });
                  });

          } else {
              group = group.sort((a, b) => a.Sort - b.Sort);
              this.alertService.Add({ type: 'success', message: response.body });

          }
      });

  }

  //Functions
  private init() {

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/properties/type/edit', 'GET', {})
      .subscribe((res) => {

        this._properties = res;
        this._groupedby = this.listService.groupByInt(res, 'CatTypeId', undefined, 'Category');

        this._loading = false;

        let opencards: any = this.viewCacheService.get('admin_properies_opencards');
        if (opencards != null) {
          let index = 0;
          Object.values(this._groupedby).forEach((item) => {
            let bit = Math.pow(2, index);
            if ((opencards.value & bit) == bit) {
              item['open'] = true;
            }
            index++;
          });
        }
        
      });
  }
}
