import { Injectable, EventEmitter } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MenuService {

  //Events
  public onMenuChange$ = new EventEmitter<any>();
  public onSearchChange$ = new EventEmitter<any>();


  /*Menu********************/
  private showMenu = false;
  public openMenu() {
    this.showMenu = true;
    this.onMenuChange$.emit(this.showMenu);
  }
  public toggleMenu() {
    this.showMenu = !this.showMenu;
    this.onMenuChange$.emit(this.showMenu);
  }
  public closeMenu() {
    this.showMenu = false;
    this.onMenuChange$.emit(this.showMenu);
  }
  public get ShowMenu() {
    return this.showMenu;
  }
  /*************************/

  /*Show********************/
  private showSearch = false;
  public openSearch() {
    this.showSearch = true;
    this.onSearchChange$.emit(this.showSearch);
  }
  public toggleSearch() {
    this.showSearch = !this.showSearch;
    this.onSearchChange$.emit(this.showSearch);
  }
  public closeSearch() {
    this.showSearch = false;
    this.onSearchChange$.emit(this.showSearch);
  }
  public get ShowSearch() {
    return this.showSearch;
  }
  /*************************/

}
