import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';

@Component({
  selector: 'swe-filteredcheckbox',
  templateUrl: './filteredcheckbox.component.html',
})
export class FilteredCheckboxComponent implements OnInit {
  @Input() label: string = '';
  @Input() statusLabel: number = 0; //0 = Hide, 1 = Standard, 2 = Grouped, 3 = Inline
  @Input() displayname: string = '';
  @Input() model: any;
  @Input() items: any[];
  @Input() disabled: boolean = false;
  @Input() container: any = {};

  @Output() modelChange = new EventEmitter<any>();

  private _searchString: string = '';
  private _filteredItems: any[] = [];
  private _prevSelected: any | any[] = [];
  constructor(
    public languageService: LanguageService,
    private permissionService: PermissionService
  ) {

  }

  ngOnInit() {
    if (!this.model) {
      this._filteredItems = this.items.sort((a,b) => a.Name.toLowerCase() > b.Name.toLowerCase() ?  1 : -1);
      return;
    }
    const selectedArr = Array.isArray(this.model) ? this.model : this.model.split('|').map(Number);
    const [visibleSelected, visible] = this.partition(this.items, (x => selectedArr.includes(x.Id)));
    visibleSelected.sort((a, b) => a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1);
    visible.sort((a, b) => a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1);
    this._filteredItems = visibleSelected.concat(visible);

  }

  onModelChange() {
    this.restoreSelectedItems();
    this.modelChange.emit(this.model);
    this._prevSelected = this.model; //keep track of items to potentially restore
  }

  /*
    Restores items that are selected but not currently in view due to search input
  */
  private restoreSelectedItems() {

    const isArray = Array.isArray(this._prevSelected);
    if (!isArray) {
      this._prevSelected = this._prevSelected.split("|");
      this.model = this.model.split("|");
    }
    let restoredItems = [];
    if (this._searchString !== '') {
      this._prevSelected.forEach(id => {
        if (this._filteredItems.filter(item => item.Id == id).length == 0) {
          restoredItems.push(id);
        }
      })
    }
    this.model = this.model.concat(restoredItems);
    if (!isArray) {
      this.model = this.model.join('|');
    }
  }

  /*Properties*/
  public get cols(): number {

    if (this.statusLabel == 1) {
      //Standard
      return this.permissionService.permissions.ProfileLabel;
    }
    else if (this.statusLabel == 2) {
      //Grouped
      return 0;
    }
    else if (this.statusLabel == 3) {
      //Inline
      return 3;
    }

    return 0;
  }
  public get searchString() {
    return this._searchString;
  }
  public set searchString(value: string) {
    this._searchString = value;
  }

  public get filteredItems() {
    return this._filteredItems;
  }
  private partition(array, isValid) {
  return array.reduce(([pass, fail], elem) => {
    return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
  }, [[], []]);
}

  public search() {
    if (!this.model) {
      this._filteredItems = this.items.filter(x => x.Name.toLowerCase().includes(this._searchString.toLowerCase())).sort((a, b) => a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1);
      return;
    }
    const selectedArr = Array.isArray(this.model) ? this.model : this.model.split('|').map(Number);
    const searchItems = this.items.filter(x => x.Name.toLowerCase().includes(this._searchString.toLowerCase()));
    const [visibleSelected, visible] = this.partition(searchItems, (x => selectedArr.includes(x.Id)));
    visibleSelected.sort((a, b) => a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1);
    visible.sort((a, b) => a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1);
    this._filteredItems = visibleSelected.concat(visible);

  }

}
