import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';

@Component({
  selector: 'swe-contractsearch',
  templateUrl: './contractsearch.component.html',
})
export class ContractSearchComponent implements OnInit, OnChanges {
  @Input() markChanges: boolean;
  @Input() changed: boolean;
  @Input() label: string;
  @Input() statusLabel: number = 0; //0 = Hide, 1 = Standard, 2 = Grouped, 3 = Inline
  @Input() displayname: string = ''; 
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() disabled: boolean = false;
  @Input() chosen: any[] = [];
  @Input() onlyone: boolean = false;
  @Input() type: string;
  @Input() bottomMargin: number = 0;
  @Input() hasContractSearch: boolean = false;
  @Input() loadOnClick: boolean = false;

  private _current: number;
  private _more: boolean;
  private _all: boolean;
  private _nohits: boolean;
  private _warning: string = '';
  private _active: boolean;
  private _multiple: number = 1;
  private _isSearching: boolean;
  private _searchresult: any[] = [];
  private _contract: string = '';
  private _lastcontract: string = '';
  private _contractsearch: string = '';
  private _original: number;
  private _search: string = '';

  //Timeouts
  private _lostfocustimeout: any;
  private _searchtimeout: any;

  constructor(
    public languageService: LanguageService,
    private permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingService
  ) {

  }
    

  ngOnInit() {
    if (this.displayname && this.displayname.length > 0) {
      this._contract = this._lastcontract = this.displayname;
    }

    if (this.onlyone && this.model) {
      this._original = this.model;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.displayname && this.displayname.length > 0) {
      if (!this.model || this.model == 0) {
        this.displayname = '';
      }

      this._contract = this._lastcontract = this.displayname;
    }
  }


  /*Properties*/
  public get cols(): number {

    if (this.statusLabel == 1) {
      //Standard
      return this.permissionService.permissions.ProfileLabel;
    }
    else if (this.statusLabel == 2) {
      //Grouped
      return 0;
    }
    else if (this.statusLabel == 3) {
      //Inline
      return 3;
    }

    return 0;
  }
  public get current() {
    return this._current;
  }
  public get more() {
    return this._more;
  }
  public get all() {
    return this._all;
  }
  public get nohits() {
    return this._nohits;
  }
  public get warning() {
    return this._warning;
  }
  public get active() {
    return this._active;
  }
  public get contract() {
    return this._contract;
  }
  public set contract(value:string) {
    this._contract = value;
    this._search = value;
  }
  public get isSearching() {
    return this._isSearching;
  }
  public get searchresult() {
    return this._searchresult;
  }
  public get contractsearch() {
    return this._contractsearch;
  }
  public get isChanged() {
    return this.changed;
  }



  /*Methods*/
  public isChosen(id: number) {
    return this.chosen.map(item => item.Id).indexOf(id) > -1;
  }
  public getLabelClass() {
    if (this.cols == 0) {
      return "";
    }
    return 'col-form-label col-' + this.cols;
  }

  public getInputClass() {
    if (this.cols == 0) {
      return "";
    }
    return 'col-' + (12 - this.cols);
  }

  public searchOnClick() {
    this._search = '*';
    this.search(0);
  }
  public search(getmore: number) {
    
    //If you already have a timout, clear it
    if (this._lostfocustimeout) { clearTimeout(this._lostfocustimeout); }

    //If you already have a timout, clear it
    if (this._searchtimeout) { clearTimeout(this._searchtimeout); }

    
    //Start new timeout
    this._searchtimeout = setTimeout(() => {

      this._active = true;

      if (this._search.length == 0) {
        this.resetContract();
        return;
      }

      let top = 5;
      if (getmore == 2) {
        //All
        top = 0;
        this._searchresult = [];
      }
      else if (getmore == 1) {
        //More
        this._multiple++;
      }
      else {
        this._multiple = 1;
        this._searchresult = [];
      }

      this._isSearching = true;
      this._current = 0;

      let filter = {
        Name: this._search,
        Top: top,
        Multiple: this._multiple
      };

      this.dataService.tokenRequest('/api/v1/contracts/search', 'POST', filter)
        .subscribe(res => {

          this._all = (res.More && this._searchresult.length > 0);

          res.Contracts.forEach((item) => {
            this._searchresult.push(item);
          });

          if (this.hasContractSearch && this._contract != "*") {
            this._contractsearch = this._contract;
            this._current = -1;
          }
          else {
            this._contractsearch = '';
            this._current = 0;
          }

          this._nohits = (res.Contracts.length == 0);


          this._more = res.More;
          this._searchresult.sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : -1);
        });

    }
      , 500);

  }
  public resetContract() {
    this._lastcontract = '';
    this._contract = '';
    this.model = 0;
    this._isSearching = false;
    this._active = false;
  }
  public lostfocus() {
    //If you already have a timout, clear it
    if (this._lostfocustimeout) { clearTimeout(this._lostfocustimeout); }

    //Start new timeout
    this._lostfocustimeout = setTimeout(() => {
      this._contract = (this.onlyone) ? this._lastcontract : '';
      this._isSearching = false;
      this._active = false;
      if (this._contract.length == 0) {
        this._warning = '';
      }

    }, 500);
  }
  public get(item, exitsearch) {
    
    this._lastcontract = item.Name;

    if (this.onlyone) {
      this._contract = item.Name;
      this._isSearching = false;
      this._active = false;

      this.model = item.Id;
      this.modelChange.emit(this.model);
    }
    else {
      this.modelChange.emit({ item: item, exit: exitsearch });
    }

    this.settingService.user.search = '';

    if (exitsearch) {
      this._isSearching = false;
    }

    if (this.onlyone && this.markChanges) {
      this.changed = (typeof this._original != 'undefined' && this._original != this.model);
    }

    //If you already have a timout, clear it
    if (this._lostfocustimeout) { clearTimeout(this._lostfocustimeout); }

  }
  public keyup(e: KeyboardEvent) {

    let currentRow = this._current;

    if (e.keyCode == 40) {
      //Arrow key down
      if (currentRow < this._searchresult.length - 1) {
        currentRow++;
      }
      else if (this._all && currentRow == this._searchresult.length) {
        currentRow++;
      }
      else if (this._more && currentRow == this._searchresult.length - 1) {
        currentRow++;
      }
      else {
        currentRow = -1;
      }
    }
    else if (e.keyCode == 38) {
      //Arrow key up
      if (currentRow > -1) {
        currentRow--;
      }
      else if (this._all) {
        currentRow = this._searchresult.length + 1;
      }
      else if (this._more) {
        currentRow = this._searchresult.length
      }
      else {
        currentRow = this._searchresult.length - 1;
      }
    }
    else if (e.keyCode == 13) {
      //Enter
      if (this._all && currentRow == this._searchresult.length + 1) {
        this.search(2);
      }
      else if (this._more && currentRow == this._searchresult.length) {
        this.search(1);
      }
      else if (!this._nohits || this.hasContractSearch) {
        if (this.current == -1) {
          this.getsearch();
        }
        else {
          this.get(this._searchresult[currentRow], !e.ctrlKey);
        }
      }
    }

    this._current = currentRow;
  }
  public keydown(e: KeyboardEvent) {

    let currentRow = this._current;

    if (e.keyCode == 9 && this._contract.length > 0) {
      //Tab
      if (this.current == -1) {
        this.getsearch();
      }
      else {
        this.get(this._searchresult[currentRow], !e.ctrlKey);
      }
    }
  }
  public getsearch() {
    this.settingService.user.search = this._contractsearch;
    this.settingService.user.users = [];

    this.settingService.refresh();

    this._isSearching = false;
    this._active = false;
  };

  public hassearch() {
    let res = this.hasContractSearch && this._contractsearch.length > 0;
    
    return res;
  }

}
