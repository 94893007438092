import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { DateTimeService } from '../../_services/datetime.service';
import { SettingService } from '../../_services/setting.service';
import { ListService } from '../../_services/list.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { GeneralService } from '../../_services/general.service';
import { AlertService } from '../../_services/alert.service';



@Component({
  selector: 'app-systemruledetail',
  templateUrl: './systemruledetail.component.html'
})
export class SystemRuleDetailComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _id: number = 0;
  private _keywords: any[] = [];
  private _rule: any = {};
  private _rulekeyword: string = '';
  private _settings: any[] = [];
  private _setting: any;
  private _roles: any[] = [];
  private _levelgroups: any[] = [];
  private _levels: any[] = [];
  private _properties: any[] = [];
  private _groupbyList: any[] = [];
  private _otherrules: any[] = [];
  private _currentindex: number = -1;
  private _currentkeyword: any;
  private _isloading: boolean;
  //Items
  private _keywordList: any[] = [];
  private _categoryList: any[] = [];
  private _accessList: any[] = [];
  private _roleList: any[] = [];
  private _levelgroupList: any[] = [];
  private _levelList: any[] = [];
  private _childList: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private dataService: DataService,
    private dateTimeService: DateTimeService,
    private listService: ListService,
    public generalService: GeneralService,
    private viewCacheService: ViewCacheService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {

    //Reset
    settingService.initView();

    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
    });

    let otherrules = this.viewCacheService.get('systemrules');
    if (otherrules) {
      otherrules.value.forEach((item) => {
        this._otherrules.push(item);
      });
    }
    else {
      this._otherrules = [this._id];
    }
    this._currentindex = this._otherrules.indexOf(this._id);
    let currentkeyword = this.viewCacheService.get('systemrulekeyword');
    if (currentkeyword) {
      this._currentkeyword = currentkeyword.value;
    }

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        this.save(this._id);
      }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    this.loadSettings();
    this.loadKeywords();
    this.loadRoles();
    this.loadLevelGroups();
    this.loadLevels();
    this.loadProperties();

    //Items
    this.getCategories();
    this.getChildOptions();
  }


  //Properties
  public get id() {
    return this._id;
  }
  public get rule() {
    return this._rule;
  }
  public get setting() {
    return this._setting;
  }
  public get otherrules() {
    return this._otherrules;
  }
  public get groupbyList() {
    return this._groupbyList;
  }
  public get currentindex() {
    return this._currentindex;
  }
  //Items
  public get keywordList() {
    return this._keywordList;
  }
  public get categoryList() {
    return this._categoryList;
  }
  public get accessList() {
    return this._accessList;
  }
  public get roleList() {
    return this._roleList;
  }
  public get levelgroupList() {
    return this._levelgroupList;
  }
  public get levelList() {
    return this._levelList;
  }
  public get childList() {
    return this._childList;
  }
  public get isloading() {
    return this._isloading;
  }



  //Methods
  private getCategories() {

    let categories = this.listService.formatArray(this.generalService.propertycategories, ['Object', 'ObjectToObject'], 'Extra');

    this._categoryList = [];
    categories.forEach((category) => {
      this._categoryList.push({ Id: category.Key, Name: category.Value });
    });
  }
  private getChildOptions() {
    this._childList = [];

    this._childList.push({ Id: 0, Name: this.languageService.getItem(146) });
    this._childList.push({ Id: 1, Name: this.languageService.getItem(147) });
    this._childList.push({ Id: 2, Name: this.languageService.getItem(148) });
    this._childList.push({ Id: 3, Name: this.languageService.getItem(149) });
  }
  private getProperties() {
    this._groupbyList = [];
    this._properties.forEach((item) => {
      let groupby = this.listService.find(this._groupbyList, 'Name', item.Extra);
      if (groupby) {
        groupby.Items.push({ Id: item.Key, Name: item.Value });
      }
      else {
        this._groupbyList.push({ Name: item.Extra, Items: [{ Id: item.Key, Name: item.Value }] });
      }
    });

  }
  public save(id) {

    let verb = 'Post';
    let path = '/api/v1/system/rules/'
    if (id > 0) {
      verb = 'Put';
      path += id;
    }

    let rule = { ...this._rule };
    if (rule.Property && rule.Property.length > 0) {
      rule.Property = rule.Property.join('|');
    }
     
    this.dataService.tokenRequest(path, verb, rule, 'text', 'response')
      .subscribe((response) => {

        this.permissionService.reload().subscribe();

        this.alertService.Add({ type: 'success', message: response.body });

        if (response.status == 201) {
          this.router.navigate([response.headers.get('Location').replace('system/rules', 'systemadmin/rules')], { replaceUrl: true })
            .finally(() => {
              this.loadRule();
            });
        }
        else {
          this.loadSettings();
        }
      });

  }
  public delete() {

    this.dataService.tokenRequest('/api/v1/system/rules/' + this.id, 'DELETE', {}, 'text')
      .subscribe((res) => {
        this.alertService.Add({ type: 'success', message: res });

        this.location.back();
      });

  }
  public prev() {
    if (this._currentindex > 0) {
      this._currentindex--;
      this._id = this._otherrules[this._currentindex];

      this.loadSettings();
      this.loadKeywords();

      this.router.navigate(['/systemadmin/rules', this._id], { replaceUrl: true });
    }
  }
  public next() {
    if (this._currentindex < this._otherrules.length - 1) {
      this._currentindex++;
      this._id = this._otherrules[this._currentindex];

      this.loadSettings();
      this.loadKeywords();

      this.router.navigate(['/systemadmin/rules', this._id], { replaceUrl: true });
    }
  }
  public keywordChange(e) {
    this.changesetting(e);
  }



  //Functions
  private loadSettings() {

    this.dataService.tokenRequest('/api/v1/system/rules/settings', 'GET', {})
      .subscribe((settings) => {

        this._settings = settings;
        if (this._rulekeyword.length > 0) {
          this.changesetting(this._rulekeyword);
        }

        
      });
  }
  private loadKeywords() {

    this.dataService.tokenRequest('/api/v1/system/rules/keywords', 'GET', {})
      .subscribe((list) => {
        this._keywords = list;

        this._keywordList = [];
        this._keywords.forEach((key) => {
          this._keywordList.push({ Id: key.Keyword, Name: key.Keyword });
        });

        this.loadRule();
      });

  }
  private loadRule() {

    this._isloading = true;

    this.dataService.tokenRequest('/api/v1/system/rules/' + this._id, 'GET', {})
      .subscribe((theRule) => {

        this._rule = theRule;
        if (theRule.Id == 0 && this._currentkeyword != null) {
          theRule.Keyword = this._currentkeyword;
        }

        if (this._rule.Property && this._rule.Property.length > 0) {
          let array = this._rule.Property.split('|');
          this._rule.Property = [];
          array.forEach((item) => {
            this._rule.Property.push(parseInt(item));
          });
        }

        this._rulekeyword = theRule.Keyword;
        if (this._settings.length > 0) {
          this.changesetting(this._rulekeyword);
        }

        this._isloading = false;
      });
  }
  private loadRoles() {
    this.dataService.tokenRequest('/api/v1/system/rules/roles', 'GET', {})
      .subscribe((list) => {

        this._roles = list;

        this._roleList = [];
        this._roles.forEach((role) => {
          this._roleList.push({ Id: role.Id, Name: role.Name });
        });
      });
  }
  private loadLevelGroups() {
    this.dataService.tokenRequest('/api/v1/system/rules/levelgroups', 'GET', {})
      .subscribe((list) => {

        this._levelgroups = list;

        this._levelgroupList = [];
        this._levelgroups.forEach((levelgroup) => {
          this._levelgroupList.push({ Id: levelgroup.Key, Name: levelgroup.Value });
        });
      });
  }
  private loadLevels() {
    this.dataService.tokenRequest('/api/v1/system/rules/levels', 'GET', {})
      .subscribe((list) => {

        this._levels = list;

        this._levelList = [];
        this._levels.forEach((level) => {
          this._levelList.push({ Id: level.Id, Name: level.Name });
        });
      });
  }
  private loadProperties() {
    this.dataService.tokenRequest('/api/v1/system/rules/properties', 'GET', {})
      .subscribe((list) => {

        this._properties = list;
        this.getProperties();
      });
  }
  private changesetting(keyword) {

    let found = false;

    this._settings.forEach((setting) => {

      if (!found) {
        if (setting.Keyword == keyword) {

          this._setting = setting;

          if (this._rule.Access == 3) {
            if (!this._setting.ShowDelete) {
              if (this._setting.ShowWrite) {
                this._rule.Access = 2;
              }
              else {
                this._rule.Access = 1;
              }
            }
          }
          else if (this._rule.Access == 2) {
            if (!this._setting.ShowWrite) {
              this._rule.Access = 1;
            }
          }

          if (!this._setting.ShowMyself) {
            this._rule.OnlyYou = false;
          }

          if (!this._setting.ShowRole) {
            this._rule.OtherRole = '';
          }

          if (!this._setting.ShowLevel) {
            this._rule.InclUnauth = false;
            this._rule.Child = 0;
            this._rule.OtherLevelGroup = '';
            this._rule.OtherLevel = '';
          }

          if (!this._setting.ShowProperty) {
            this._rule.Property = '';
          }

          this._accessList = [];

          this._accessList.push({ Id: 0, Name: '' });
          this._accessList.push({ Id: 1, Name: 'Read' });
          if (this._setting && this._setting.ShowWrite) {
            this._accessList.push({ Id: 2, Name: 'Write' });
          }
          if (this._setting && this._setting.ShowDelete) {
            this._accessList.push({ Id: 3, Name: 'Delete' });
          }

          found = true;
        }
      }
    });
  }
}
