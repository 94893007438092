import { Injectable, NgZone } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ServerEventService {

  //Class members
  private eventSource: EventSource;

  //Contructor
  constructor(
    private zone: NgZone
  ) {

  }

  //Create EventSource instance
  private getEventSource(): EventSource {
    return new EventSource('api/v1/system/events', { withCredentials: true });
  }


  //Connect
  public connect(): Observable<Event> {

    //Create new instance
    this.eventSource = this.getEventSource();

    return new Observable((subscriber: Subscriber<Event>) => {

      this.manageSubscriber(subscriber);

    });
  }

  private manageSubscriber(subscriber: Subscriber<Event>) {

    this.eventSource.onerror = error => {

      this.close();
      this.eventSource = this.getEventSource();
      this.manageSubscriber(subscriber);
      //this.zone.run(() => subscriber.error(error));
    };

    this.eventSource.onmessage = event => {
      this.zone.run(() => subscriber.next(event));
    };

  }



  //Close
  public close(): void {

    if (!this.eventSource) {
      return;
    }

    this.eventSource.close();
    this.eventSource = null;
  }
}

