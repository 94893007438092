import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { DateTimeService } from '../../_services/datetime.service';
import { SettingService } from '../../_services/setting.service';
import { ListService } from '../../_services/list.service';
import { ViewCacheService } from '../../_services/viewcache.service';


@Component({
  selector: 'app-timerules',
  templateUrl: './timerules.component.html'
})
export class TimeRulesComponent implements OnInit {

  private _timerules: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    public dateTimeService: DateTimeService,
    private dataService: DataService,
    private router: Router
  ) {
    //Reset
    settingService.initView();
  }


  ngOnInit() {
    this.load();
  }



  //Properties
  public get timerules() {
    return this._timerules;
  }



  //Methods
  public load() {

    this.dataService.tokenRequest('/api/v1/system/timerules', 'GET', {})
      .subscribe((res) => {

        this._timerules = res;
      });

  }
  public manageWeekday(day) {
    let name = [
      this.languageService.getItem(388),
      this.languageService.getItem(389),
      this.languageService.getItem(390),
      this.languageService.getItem(391),
      this.languageService.getItem(392),
      this.languageService.getItem(393),
      this.languageService.getItem(394),
    ][day];

    return name;
  }
  public manageHour(hour) {
    let res = hour;
    if (hour < 10) {
      res = '0' + res;
    }

    return res + ':00';
  }
  public goto(id: number) {
    this.router.navigate(['/systemadmin/timerules', id]);
  }


}
