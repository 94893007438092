import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { ListService } from '../../_services/list.service';
import { SettingService } from '../../_services/setting.service';
import { KeyValue } from '@angular/common';
import { ListUtility } from '../../_utilities/list.utility';
import { ViewCacheService } from '../../_services/viewcache.service';



@Component({
  selector: 'app-obrules',
  templateUrl: './obrules.component.html'
})
export class OBRulesComponent implements OnInit {

  private _rules: any = {};
  private _ruleList: any[] = [];
  private _loading: boolean = false;
  private _actionmenu: boolean = false;
  private _listutility: ListUtility = new ListUtility();


  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private listService: ListService,
    private router: Router,
    private settingService: SettingService,
    private viewCacheService: ViewCacheService
  ) {
    //Reset
    settingService.initView();
  }


  ngOnInit() {

    this._listutility.toggleaccess = this.permissionService.permissions.MultiObRules; //todo change
    this._listutility.hasdataaccess = false;
    this.load();
  }



  //Properties
  public get listutility() {
    return this._listutility;
  }
  public get rules() {
    return this._rules;
  }
  public get ruleList() {
    return this._ruleList;
  }
  public get isloading() {
    return this._loading;
  }

  public get actionmenu() {
    return this._actionmenu;
  }

  public set actionmenu(val) {
    this._actionmenu = val;
  }

  //Comparer
  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }


  //Methods
  public load() {

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/overtime/groups', 'Get', {})
      .subscribe((groups) => { 

        let grouplist: any[] = [];
        groups.forEach((group) => {
          grouplist.push({ Id: group.Key, Name: group.Value, Sort: group.Extra });
        });

        this.dataService.tokenRequest('/api/v1/overtime', 'Get', {})
          .subscribe((res) => {

            this._ruleList = res;
            this._listutility.rows = this._ruleList;
            this._rules = this.listService.groupByInt(res, 'GroupId', grouplist);

            this._loading = false;
          });
      });
  }
  public goto(id: number, e) {
    const url = `/admin/overtime/${id}`;
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
      return;
    }
    this.router.navigate([url]);
  }

  public getToolTip(rule)
  {
    let tooltip = ''
    if (rule.Contracts.length > 1) { }
    tooltip += this.languageService.getItem(953) + ':\n'; //Contracts
    rule.Contracts.forEach((contract) => {
      tooltip += contract + '\n';
    });

    return tooltip;
  }

  public action(option) {
    console.log(option);
    let rules = [];
    this._ruleList.forEach((rule) => {
      if (rule.checked) {
        rules.push(rule.Id);
      }
    });

    if (rules.length > 0) {

      this.viewCacheService.add('multi_obrules', rules);

      this.router.navigate(['/admin/overtime/action/' + option + '/']);
    }
  }
}
