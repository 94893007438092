<swe-card [header]="languageService.getItem(574)" [open]="false" [hasCollapse]="true" [hasRefresh]="true" (refreshChange)="load()" (openChange)="load()">
  <button swe-rightcommand class="btn btn-success bi-swe-pull-right mb-2" (click)="goto(0)" *ngIf="access>1"><i class="bi bi-plus-lg bi-swe-fw swe-click"></i>{{languageService.getItem(100)}}</button>
  <swe-list [data]="ruleList" [loading]="isloading">
    <div *ngFor="let groupby of rules | keyvalue : originalOrder">
      <a class="list-group-item list-group-item-action d-flex flex-wrap bg-light">
        <h2>{{groupby.value.header}}<span class="ms-2" *ngIf="groupby.value.items.length>0">(#{{groupby.value.items[0].GroupId}})</span></h2>
      </a>
      <ng-container *ngFor="let rule of groupby.value.items; index as i">
        <div class="list-group-item list-group-item-action d-flex flex-wrap">

          <a (click)="access>1 && goto(rule.Id)" class="row col-11 text-decoration-none text-black swe-click">
            <div class="col-12 col-md-8">{{rule.Desc}}</div>
            <div class="col-6 col-md-2">{{rule.Type}}</div>
            <div class="col-6 col-md-2">{{rule.Level}}<span *ngIf="rule.Level&&rule.Contract&&rule.Level.length>0&&rule.Contract.length>0">, </span>{{rule.Contract}}</div>
          </a>
          <button class="btn" [attr.aria-label]="languageService.getItem(1224)" [attr.title]="languageService.getItem(1224)" (click)="unlinkObRule(rule.Id, groupby.key, i)" *ngIf="access>2&&(rule.Level||rule.Contracts.length>0)">
            <i class="bi bi-dash-circle bi-swe"></i>
          </button>
        </div>
      </ng-container>
    </div>
  </swe-list>
</swe-card>
