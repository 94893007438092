import { Component, OnInit, OnDestroy } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { ListService } from '../../_services/list.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-import',
  templateUrl: './import.component.html'
})
export class ImportComponent implements OnInit, OnDestroy  {

  private _subscriptions: Subscription[] = [];
  private _file: any;
  private _encodings: any[] = [];
  private _encoding: string = '';
  private _headerAsFirstRow: boolean = true;
  private _delimeters: any[] = [];
  private _delimeter: string = ';';
  private _dataobjects: any[] = [];
  private _dataobject: string = '';
  private _matchproperties: any[] = [];
  private _matchproperty: string = '0';
  private _properties: any[] = [];
  private _success: boolean = false;
  private _errors: any[] = [];
  private _header: any[] = [];
  private _body: any[] = [];
  private _reloadfile: boolean = false;
  private _saving: boolean = false;


  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private listService: ListService,
    private alertService: AlertService,
    private settingService: SettingService
  ) {

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        if (this.permissionService.permissions.Import > 0 && this._body.length > 0) {
          this.save();
        }
      })); 

    //Reset
    settingService.initView();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {

    this.init();

  }


  //Properties
  public get file() {
    return this._file;
  }
  public set file(val) {
    this._file = val;
  }
  public get encoding() {
    return this._encoding;
  }
  public set encoding(val) {
    this._encoding = val;
  }
  public get encodings() {
    return this._encodings;
  }
  public get headerAsFirstRow() {
    return this._headerAsFirstRow;
  }
  public set headerAsFirstRow(val) {
    this._headerAsFirstRow = val;
  }
  public get delimeters() {
    return this._delimeters;
  }
  public get delimeter() {
    return this._delimeter;
  }
  public set delimeter(val) {
    this._delimeter = val;
  }
  public get dataobjects() {
    return this._dataobjects;
  }
  public get dataobject() {
    return this._dataobject;
  }
  public set dataobject(val) {
    this._dataobject = val;
  }
  public get matchproperties() {
    return this._matchproperties;
  }
  public get matchproperty() {
    return this._matchproperty;
  }
  public set matchproperty(val) {
    this._matchproperty = val;
  }
  public get properties() {
    return this._properties;
  }
  public get success() {
    return this._success;
  }
  public get errors() {
    return this._errors;
  }
  public get header() {
    return this._header;
  }
  public get body() {
    return this._body;
  }
  public get reloadfile() {
    return this._reloadfile;
  }
  public set reloadfile(val) {
    this._reloadfile = val;
  }
  public get issaving() {
    return this._saving;
  }





  //Methods
  public reload(e) {
    this._reloadfile = true;
  }
  public parse(e) {
    //Reset
    this._reloadfile = false;
    this._success = false;
    this._errors = [];
    this._header = [];
    this._body = [];

    //New values
    if (this._file != null) {

      //Remove linefeed
      let file = this._file.replace(/\r/g, '');

      let delimeter = (this._delimeter == 'tab') ? '\t' : this._delimeter;
      let modified = '';
      let insidedoublequote = false;
      for (var i = 0; i < file.length; i++) {

        if (file[i] == '"') {
          insidedoublequote = !insidedoublequote;
          modified += file[i];
        }
        else if (insidedoublequote && file[i] == '\n') {
          modified += '|';
        }
        else {
          modified += file[i];
        }
      }
      file = modified;

      file.split('\n').forEach((row, index) => {

        if (row.length > 0) {
          let headercolumns = row.split(delimeter);
          if (index == 0 && this._headerAsFirstRow) {
            //Header
            this._header = this.matchheader(headercolumns);
          }
          else {
            if (index == 0) {
              //Header
              for (let i = 0; i < headercolumns.length; i++) {
                this._header.push({ Id: 0 });
              }
            }

            //Body
            let excludedelimeter = false;
            let column = '';
            let columns = [];
            for (let i = 0; i < row.length; i++) {
              let char = row[i];

              if (char == '"') {
                excludedelimeter = !excludedelimeter;
              }
              else if (char == delimeter && !excludedelimeter) {
                columns.push(column);
                column = '';
              }
              else {
                column += char;
              }

            }

            //Add last column
            columns.push(column);

            this._body.push(columns);
          }
        }
      });
    }
  }
  public save() {

    this._saving = true;
    this._errors = new Array(this._body.length);
    this._success = false;

    let dto = {
      UniqueId: this._matchproperty ? this._matchproperty : '0',
      Type: this._dataobject,
      Header: this._header.map(function (header) { return header.Id }),
      Body: this._body
    };

    this.dataService.tokenRequest('/api/v1/general/import', 'POST', dto, 'text')
      .subscribe((res) => {
        this.alertService.Add({ type: 'success', message: res });
        this._success = true;
        this._saving = false;
      },
      (err) => {

        err.split('|').forEach((row) => {

          let keyvalue = row.split('#');
          this._errors[keyvalue[0]] = decodeURIComponent(keyvalue[1]).replace(/[+]/g, ' ');
        });

        this._saving = false;
      });
  }



  //Functions
  private init() {

    this._encodings = [
      { Id: '', Name: this.languageService.getItem(926) },
      { Id: 'ISO-8859-1', Name: 'Latin-1' }
    ];

    this._delimeters = [
      { Id: ';', Name: this.languageService.getItem(646) },
      { Id: ',', Name: this.languageService.getItem(647) },
      { Id: 'tab', Name: this.languageService.getItem(648) }
    ];

    //Get Properties for import
    this.dataService.tokenRequest('/api/v1/properties/type/import', 'GET', {})
      .subscribe((list) => {

        //Properties-----------------------------
        this._properties = [
          {
              Name: '',
            Items: [
              { Id: 0, Name: '' }
            ]
          },
          {
            Name: this.languageService.getItem(652),
            Items: [
              { Id: -1, Name: this.languageService.getItem(82) },
              { Id: -2, Name: this.languageService.getItem(3) },
              { Id: -3, Name: this.languageService.getItem(235) },
              { Id: -4, Name: this.languageService.getItem(236) },
              { Id: -5, Name: this.languageService.getItem(2) },
              { Id: -6, Name: this.languageService.getItem(728) }
            ]
          }];

        if (this.permissionService.permissions.EmploymentPlan > 0) {
          this._properties[1].Items.push({ Id: -7, Name: this.languageService.getItem(698) });
        }
        if (this.permissionService.permissions.Owner > 0) {
          this._properties[1].Items.push({ Id: -8, Name: this.languageService.getItem(654) });
        }
        //---------------------------------------

        this._dataobjects = [];
        let bookingCategory = '';
        list.forEach((property) => {
          //Properties
          let groupbyProperties = this.listService.find(this._properties, 'Name', property.Category);
          if (groupbyProperties) {
            groupbyProperties.Items.push({ Id: property.Id, Name: property.Name });
          }
          else {
            this._properties.push({ Name: property.Category, Items: [{ Id: property.Id, Name: property.Name }] });
          }
          if (bookingCategory == '' && property.CatType == 'Booking') {
            bookingCategory = property.Category;
          }
          //Matchproperties
          let groupbyMatchProperties = this.listService.find(this._matchproperties, 'Name', property.Category);
          if (groupbyMatchProperties) {
            groupbyMatchProperties.Items.push({ Id: property.Id, Name: property.Name });
          }
          else {
            this._matchproperties.push({ Name: property.Category, Items: [{ Id: property.Id, Name: property.Name }] });
          }

          //DataObjects
          if (this._dataobjects.map(function (cat) { return cat.Id }).indexOf(property.CatType) == -1) {
            this._dataobjects.push({ Id: property.CatType, Name: property.Category });
          }
        });

        if (this.permissionService.permissions.HasBreaks) {
          let groupBooking = this.listService.find(this._properties, 'Name', bookingCategory);
          if (groupBooking) {
            groupBooking.Items.push({ Id: -9, Name: this.languageService.getItem(1364) });
            if (this.permissionService.permissions.BreakStart) {
              groupBooking.Items.push({ Id: -10, Name: this.languageService.getItem(1363) });
            }
          }
          else {

            this._properties.push({ Name: bookingCategory, Items: [{ Id: -9, Name: this.languageService.getItem(1364) }] });
            let groupBooking = this.listService.find(this._properties, 'Name', bookingCategory);
            if (this.permissionService.permissions.BreakStart) {
              groupBooking.Items.push({ Id: -10, Name: this.languageService.getItem(1363) });
            }
          }
        }
        if (this._dataobjects.length > 0) {
          this._dataobject = this._dataobjects[0].Id;
        }

      });

  }
  private matchheader(header) : any[] {
    let result = [];
    
    header.forEach((column) => {

      column = column.replace(/"/g, '');

      let match = 0;
      for (let i = 0; i < this._properties.length; i++) {
        let property = this._properties[i];
        if (property.Items) {
          for (let j = 0; j < property.Items.length; j++) {
            if (property.Items[j].Name == column) {
              match = property.Items[j].Id;
              break;
            }
          }
          if (match != 0) {
            break;
          }
        }
        
      }
      result.push({ Id: match, Name: match > 0 ? '' : column });
    });

    return result;
  }

}
