import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';



@Component({
  selector: 'app-holidaydetail',
  templateUrl: './holidaydetail.component.html'
})
export class HolidayDetailComponent implements OnInit {

  private _id: number = 0;
  private _holiday: any = {};

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private settingService: SettingService
  ) {
    //Reset
    settingService.initView();

    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this.load();
    });
  }

  ngOnInit() {
    
  }



  //Properties
  public get id() {
    return this._id;
  }
  public get holiday() {
    return this._holiday;
  }



  //Methods
  public load() {

    
    this.dataService.tokenRequest('/api/v1/holidays/' + this._id, 'Get', {})
      .subscribe((res) => {

        this._holiday = res;
      });
  }
  public save() {

    let verb = 'Post';
    let path = '/api/v1/holidays/'
    if (this._id > 0) {
      verb = 'Put';
      path += this._id;
    }

    this.dataService.tokenRequest(path, verb, this._holiday, 'text', 'response')
      .subscribe((response) => {

        this.alertService.Add({ type: 'success', message: response.body });

        if (response.status == 201) {
          this.router.navigate([response.headers.get('Location').replace('system/holidays', 'admin/holidays')], { replaceUrl: true });
        }

        this.load();
        
      });

  }
  public delete() {

    this.dataService.tokenRequest('/api/v1/holidays/' + this._id, 'Delete', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });

        this.location.back();
      });

  }




  //Functions
  
}
