<swe-card header="{{languageService.getItem(31)}}" [(open)]="open" (refreshChange)="load()" (openChange)="load()" [rulelist]="['SweBookingUsers', 'SweHasAvailableProfile','SweAvailableUsersLevelDependency','SweAvailableUsersLevelAncestorsDependency','SweAvailableUsers','SweAvailableUsersMark']" icon="bi-person-fill-add">
  <span swe-hits *ngIf="open&&availableusers.length>0">({{notred}}/{{availableusers.length}})</span>
  <swe-infotext [text]="languageService.getItem(531)" [preventdrowning]="true"></swe-infotext>
  <div class="btn-group" *ngIf="availableusers.length>0&&markaccess">
    <div class="dropdown">
      <!--CheckAll-->
      <button [attr.aria-label]="languageService.getItem(842)" class="btn btn-swe btn-swe-primary" [ngClass]="{'active': listutility.allchecked}" (click)="listutility.checkall(!listutility.allchecked)">
        <span><i class="bi bi-check-lg"></i></span>
      </button>
      <!--Functionlist-->
      <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="usermenu=!usermenu" [attr.disabled]="listutility.ischecked ? null : true">
        <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': usermenu && listutility.ischecked}">
        <li class="dropdown-item swe-click" *ngIf="bookingdata.Status<60&&bookoption(1)">
          <swe-confirmserie [isbutton]="false" (acceptChange)="savelist($event, 1)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
            <i class="bi bi-swe-fw bi-person-plus-fill"></i>&nbsp;{{languageService.getItem(281)}}
          </swe-confirmserie>
        </li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Replaceable&&bookingdata.Status<60&&bookoption(0)">
          <swe-confirmserie [isbutton]="false" (acceptChange)="savelist($event, 0)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
            <i class="bi bi-swe-fw bi-swe-bookingtype-replaceable"></i>&nbsp;{{languageService.getItem(423)}}
          </swe-confirmserie>
        </li>
        <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.Standby==2||permissionService.permissions.Standby==1&&bookingdata.Filled==bookingdata.Max)&&bookingdata.Status<60&&bookoption(2)">
          <swe-confirmserie [isbutton]="false" (acceptChange)="savelist($event, 2)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
            <i class="bi bi-swe-fw bi-person-fill"></i>&nbsp;{{languageService.getItem(282)}}
          </swe-confirmserie>
        </li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Abscent>1&&!permissionService.permissions.AbscentWhenBooked&&bookingdata.Status<60&&bookoption(3)">
          <swe-confirmserie [isbutton]="false" (acceptChange)="savelist($event, 3)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
            <i class="bi bi-swe-fw bi-person-fill"></i>&nbsp;{{languageService.getItem(283)}}
          </swe-confirmserie>
        </li>
        <li class="dropdown-divider"></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.ReplySms"><a (click)="send(6, 0, $event)"><i class="bi bi-swe-fw bi-send"></i>&nbsp;{{languageService.getItem(178)}}</a></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.ReplySms&&bookingdata.SerieId>0"><a (click)="send(6, bookingdata.SerieId, $event)"><i class="bi bi-swe-fw bi-send"></i>&nbsp;{{languageService.getItem(196)}}</a></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Email>0"><a (click)="send(1, bookingdata.SerieId, $event)"><i class="bi bi-swe-fw bi-envelope-fill"></i>&nbsp;{{languageService.getItem(321)}}</a></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Sms>0"><a (click)="send(3, bookingdata.SerieId, $event)"><i class="bi bi-swe-fw bi-phone-fill"></i>&nbsp;{{languageService.getItem(320)}}</a></li>
        <li class="dropdown-divider"></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiUser>0"><a (click)="action('multi', $event)"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(410)}}</a></li>
      </ul>
    </div>
  </div>
  <div class="btn-group ms-2">
    <div class="dropdown">
      <!--Filter-->
      <button [attr.aria-label]="languageService.getItem(614)" class="btn btn-swe" (click)="availablefiltermenu=!availablefiltermenu" *ngIf="!listutility.ischecked">
        <span><i class="bi bi-swe-fw bi-funnel-fill"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': availablefiltermenu && !listutility.ischecked}">
        <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.AvailableType % 2)>=1"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(101)" name="availableoptions" [value]="0" [(ngModel)]="settingService.booking.availability" (ngModelChange)="load()" />&nbsp;{{languageService.getItem(101)}}</label></li>
        <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.AvailableType % 4)>=2"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(171)" name="availableoptions" [value]="1" [(ngModel)]="settingService.booking.availability" (ngModelChange)="load()" />&nbsp;{{languageService.getItem(171)}}</label></li>
        <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.AvailableType % 16)>=8"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(344)" name="availableoptions" [value]="3" [(ngModel)]="settingService.booking.availability" (ngModelChange)="load()" />&nbsp;{{languageService.getItem(344)}}</label></li>
        <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.AvailableType % 8)>=4"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(172)" name="availableoptions" [value]="2" [(ngModel)]="settingService.booking.availability" (ngModelChange)="load()" />&nbsp;{{languageService.getItem(172)}}</label></li>
        <li class="dropdown-item swe-click" *ngIf="(permissionService.permissions.AvailableType % 32)>=16"><label class="mb-0"><input type="radio" [attr.aria-label]="languageService.getItem(962)" name="availableoptions" [value]="4" [(ngModel)]="settingService.booking.availability" (ngModelChange)="load()" />&nbsp;{{languageService.getItem(962)}}</label></li>
        <li class="dropdown-divider"></li>
        <li class="dropdown-item swe-click" *ngIf="bookingdata.SerieId>0"><a (click)="affectserie=!affectserie;load();availablefiltermenu=false;"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg': affectserie}"></i>{{languageService.getItem(802)}}</a></li>
        <li class="dropdown-item swe-click"><a (click)="exclude=!exclude;availablefiltermenu=false"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':exclude}"></i>{{languageService.getItem(683)}}</a></li>
      </ul>
    </div>
  </div>
  <div class="btn-group ms-2">
    <!--Email Notification-->
    <button [attr.aria-label]="languageService.getItem(1222)" [attr.title]="languageService.getItem(1222)" class="btn btn-swe" (click)="checknotificationemail=!checknotificationemail" *ngIf="permissionService.permissions.NotifyOnBookEmail>0">
      <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checknotificationemail, 'bi-check-square': checknotificationemail}"></i>
      <i class="bi bi-swe-fw bi-swe bi-envelope-fill"></i>
    </button>
    <!--SMS Notification-->
    <button [attr.aria-label]="languageService.getItem(1221)" [attr.title]="languageService.getItem(1221)" class="btn btn-swe" (click)="checknotificationsms=!checknotificationsms" *ngIf="permissionService.permissions.NotifyOnBookSms>0">
      <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checknotificationsms, 'bi-check-square': checknotificationsms}"></i>
      <i class="bi bi-swe-fw bi-swe bi-phone-fill"></i>
    </button>
  </div>
  <!--Reminder SMS-->
  <div class="btn-group ms-2" *ngIf="bookingdata.RemindAccess">
    <button [attr.aria-label]="languageService.getItem(1223)" [attr.title]="languageService.getItem(1223) + remindString" class="btn btn-swe" (click)="checkremind=!checkremind">
      <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checkremind, 'bi-check-square': checkremind}"></i>
      <i class="bi bi-swe-fw bi-swe bi-chat-fill"></i>
    </button>
    <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(538)" [(model)]="remind" [bottomMargin]="0"></swe-element>
  </div>
  <div class="btn-group ms-2" *ngIf="all">
    <button class="btn btn-danger" (click)="load(false, true)">{{languageService.getItem(340)}}</button>
  </div>
  <swe-filteritems></swe-filteritems>

  <ng-container *ngIf="permissionService.permissions.AvailableProfile&&settingService.isDevice(['md','lg','xl','xxl'])">
    <!--Table-->
    <swe-table [data]="availableusers" [loading]="loading">
      <table class="table" *ngIf="availableusers.length>0">
        <thead>
          <tr>
            <td style="border-top:0; border-bottom:0;"></td>
            <td style="border-top:0; border-bottom:0;"></td>
            <td style="border-top:0; border-bottom:0;"></td>
            <th style="border-top:0; border-bottom:0;" class="swe-click" *ngFor="let header of availableusers[0].Profile" (click)="sort(header.Property)">
              {{propertyService.getProperty(header.Property).Name}}
              <i class="bi bi-caret-up-fill" *ngIf="sortId==header.Property"></i>
              <i class="bi bi-caret-down-fill" *ngIf="(sortId*-1)==header.Property"></i>
            </th>
            <td style="border-top:0; border-bottom:0;"></td>
            <td style="border-top:0; border-bottom:0;"></td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let user of availableusers;index as i">
            <tr class="swe-click" [ngClass]="{'list-group-item-info': user.checked, 'list-group-item-danger': user.Warning&&!user.checked, 'list-group-item-warning': user.Access==1&&!user.checked}" *ngIf="!exclude||!user.Warning" [attr.title]="user.WarningTexts">
              <td style="width:50px;" class="swe-click" [ngStyle]="{'cursor': user.MarkAccess>0 ? 'pointer':'not-allowed'}" (click)="user.MarkAccess>0&&listutility.toggle(user,$event)" (mouseover)="user.hover=true" (mouseout)="user.hover=false">
                <span class="bi-swe-stack bi-swe-letter-circle-small" [ngClass]="{'d-none': !(user.checked||(user.MarkAccess>0&&user.hover))}">
                  <i class="bi bi-check-circle-fill bi-swe-stack-2x"></i>
                </span>
                <span class="bi-swe-stack bi-swe-letter-circle-small" [ngClass]="{'d-none': !(!user.checked && !(user.MarkAccess>0&&user.hover) && user.Image.length==0)}">
                  <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
                  <strong class="bi-swe-stack-1x bi-swe-letter">{{user.Firstname[0]}}</strong>
                </span>
                <span [ngClass]="{'d-none': !(!user.checked && !(user.MarkAccess>0&&user.hover) && user.Image.length>0)}">
                  <img class="swe-circle-image" alt="{{user.Firstname}} {{user.Lastname}}" sweImage imageFolder="userimages" [imageFile]="user.Image" imageWidth="32" imageHeight="32">
                </span>
              </td>
              <td style="width:95px;">
                <span class="align-middle" *ngIf="bookingdata.Status<60 && !user.checked && user.Access>1">
                  <swe-confirmserie [isbutton]="false" (acceptChange)="save($event,user,1)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
                    <a href="javascript:void(0);" class="swe-no-link swe-no-focus" (focus)="activerow=i" (blur)="activerow=-1" [attr.title]="languageService.getItem(281) + ' ' +  user.Firstname + ' ' + user.Lastname"><i class="bi bi-swe-fw bi-swe-2x bi-plus-circle-fill bi-swe-pull-left swe-click" [ngClass]="{'swe-grayed-out': user.hover2||(i==activerow)}" (mouseover)="user.hover2=true" (mouseout)="user.hover2=false"></i></a>
                  </swe-confirmserie>
                </span>
              </td>
              <td style="width:70px;">
                <span class="align-middle me-1" *ngIf="user.HasSms">
                  <i class="bi bi-swe-fw bi-chat bi-swe" [attr.title]="languageService.getItem(205)"></i>
                </span>
                <span class="align-middle" *ngIf="user.HasRequest">
                  <i class="bi bi-swe-fw bi-send bi-swe" [attr.title]="languageService.getItem(1078)"></i>
                </span>
                <span class="align-middle" *ngIf="user.HasPush">
                  <i class="bi bi-swe-fw bi-phone-vibrate-fill bi-swe" [attr.title]="languageService.getItem(1258)"></i>
                </span>
              </td>
              <td *ngFor="let profile of user.Profile" (click)="openuser(user, $event)" [ngSwitch]="profile.Type">
                <span *ngSwitchDefault>{{profile.FormatValue}}</span>
                <span *ngSwitchCase="'System.Boolean'"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-square': profile.FormatValue, 'bi-square': !profile.FormatValue}"></i></span>
              </td>
              <td>
              <div [ngClass]="swe-click" [attr.title]="user.AvailabilityComment||languageService.getItem(173)" (click)="gotoAvailability(user.AvailabilityId, $event)" *ngIf="user.AvailabilityStart<=bookingStart&&user.AvailabilityEnd>=bookingEnd">
                <span style="white-space:nowrap;" *ngIf="user.AvailabilityId>0"><i class="bi bi-calendar-check bi-swe-fw"></i>&nbsp;{{cut(user.AvailabilityComment)}}</span>
              </div>
              <div [ngClass]="swe-click" [attr.title]="dateTimeService.format(user.AvailabilityStart) + ' - ' + dateTimeService.format(user.AvailabilityEnd)" (click)="gotoAvailability(user.AvailabilityId, $event)" *ngIf="user.AvailabilityStart>bookingStart||user.AvailabilityEnd<bookingEnd">
                <span style="white-space:nowrap;" *ngIf="user.AvailabilityId>0" ><i class="bi bi-calendar-check bi-swe-fw text-warning"></i>&nbsp;{{cut(user.AvailabilityComment)}}</span>
              </div>
              </td>
              <td align="right" [attr.title]="user.PriorityTexts">
                <span *ngIf="user.Priority!=0">{{user.Priority}}</span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </swe-table>
  </ng-container>
  <ng-container *ngIf="!permissionService.permissions.AvailableProfile||settingService.isDevice(['xs','sm'])">
    <!--List-->
    <swe-list [data]="availableusers" [loading]="loading">
      <ng-container *ngFor="let user of availableusers;index as i">
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-info': user.checked, 'list-group-item-danger': user.Warning&&!user.checked, 'list-group-item-warning': user.Access==1&&!user.checked}" *ngIf="!exclude||!user.Warning" [attr.title]="user.WarningTexts">
          <div class="col-2 col-sm-1" (click)="user.MarkAccess>0&&listutility.toggle(user,$event)" (mouseover)="settingService.isDevice(['md','lg','xl', 'xxl'])&&user.hover=true" (mouseout)="settingService.isDevice(['md','lg','xl','xxl'])&&user.hover=false">
            <span class="bi-swe-stack bi-swe-letter-circle-small" [ngClass]="{'d-none': !(user.checked||(user.MarkAccess>0&&user.hover))}">
              <i class="bi bi-check-circle-fill bi-swe-stack-2x"></i>
            </span>
            <span class="bi-swe-stack bi-swe-letter-circle-small" [ngClass]="{'d-none': !(!user.checked && !(user.MarkAccess>0&&user.hover) && user.Image.length==0)}">
              <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
              <span class="bi-swe-stack-1x bi-swe-letter">{{user.Firstname[0]}}</span>
            </span>
            <span [ngClass]="{'d-none': !(!user.checked && !(user.MarkAccess>0&&user.hover) && user.Image.length>0)}">
              <img class="swe-circle-image" alt="{{user.Firstname}} {{user.Lastname}}" sweImage imageFolder="userimages" [imageFile]="user.Image" imageWidth="32" imageHeight="32">
            </span>
          </div>
          <div class="col-4 col-sm-2 col-md-1">
            <span class="align-middle me-1" *ngIf="bookingdata.Status<60 && !user.checked && user.Access>1">
              <swe-confirmserie [isbutton]="false" (acceptChange)="save($event,user,1)" [isSerie]="bookingdata.SerieId>0" [affectAllOnly]="bookingdata.AffectAllOnly">
                <a href="javascript:void(0);" class="swe-no-link swe-no-focus" (focus)="activerow=i" (blur)="activerow=-1" [attr.title]="languageService.getItem(281) + ' ' +  user.Firstname + ' ' + user.Lastname"><i class="bi bi-swe-fw bi-swe-2x bi-plus-circle-fill bi-swe-pull-left swe-click" [ngClass]="{'swe-grayed-out': user.hover2||(activerow==i)}" (mouseover)="user.hover2=true" (mouseout)="user.hover2=false"></i></a>
              </swe-confirmserie>
            </span>
            <span class="align-middle me-1" *ngIf="user.HasSms">
              <i class="bi bi-swe-fw bi-chat bi-swe" [attr.title]="languageService.getItem(205)"></i>
            </span>
            <span class="align-middle" *ngIf="user.HasRequest">
              <i class="bi bi-swe-fw bi-send bi-swe" [attr.title]="languageService.getItem(1078)"></i>
            </span>
            <span class="align-middle" *ngIf="user.HasPush">
              <i class="bi bi-swe-fw bi-phone-vibrate-fill bi-swe" [attr.title]="languageService.getItem(1258)"></i>
            </span>
          </div>
          <div class="col-6 col-sm-9 col-md-10 swe-click" (click)="openuser(user, $event)">
            {{user.Firstname}} {{user.Lastname}}
          </div>
        </a>
      </ng-container>
    </swe-list>
  </ng-container>
  <div class="d-grid gap-2">
    <button class="btn btn-primary" *ngIf="more" (click)="load(true)">{{languageService.getItem(57)}}</button>
  </div>
</swe-card>

<!--Profile when Book as Abscent-->
<swe-abscentdata [buttontext]="languageService.getItem(283)" [createNew]="true" [idList]="userIdList" [bookingid]="bookingdata.Id" [notifyEmail]="checknotificationemail" [notifySms]="checknotificationsms" [(visible)]="popup[0].Visible" [option]="popup[0].Option" (doneChange)="reloadAfterBook()"></swe-abscentdata>
