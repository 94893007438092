<swe-pageheader [header]="languageService.getItem(1442)"></swe-pageheader>
<div class="row py-2">
  <div class="col-12 col-md-4">
    <swe-card [open]="true" [header]="languageService.getItem(6)" [hasCollapse]="false" [hasRefresh]="true" [hasSave]="true" (refreshChange)="load()" (saveChange)="save()" (activeChange)="change($event)" [tabs]="tabs" [active]="1" *ngIf="permissionService.permissions.ExternalProviders>0">
      <swe-element [statusLabel]="1" [(model)]="provider.Name" [access]="2" [disabled]="true" [label]="languageService.getItem(1235)" [type]="'System.String'" *ngIf="tabs.length==1"></swe-element>
      <ng-container *ngFor="let key of provider.Keys">
        <swe-element [statusLabel]="1" [(model)]="key.Value" [access]="2" [label]="key.Name" [type]="key.Options.length>0 ? 'System.List' : 'System.String'" [items]="key.Options" [optional]="true" [optionalValue]="''"></swe-element>
      </ng-container>
      <swe-card [open]="true" [color]="'swe'" [header]="languageService.getItem(156)" [hasCollapse]="true" [hasRefresh]="false" *ngIf="provider.MappedProperties.length>0">
        <ng-container *ngFor="let property of provider.MappedProperties">
          <swe-element [statusLabel]="1" [(model)]="property.Property" [access]="2" [label]="property.Name" [items]="provider.Properties" [optional]="true" [optionalValue]="''" [type]="'System.List'"></swe-element>
        </ng-container>
      </swe-card>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save()"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
      </div>
    </swe-card>
  </div>
</div>
