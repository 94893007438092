import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';


@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
})
export class AdminLoginComponent implements OnInit {

  

  constructor(
    private authenticationService: AuthenticationService
  ) {

  }


  ngOnInit() {

    this.authenticationService.adminloginsso();
    
  }
  
}
