import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html'
})
export class ContractsComponent implements OnInit {

  private _contracts: any[] = [];
  private _frameworkcontracts: any[] = [];
  private _newframeworkcontract: any = {};
  private _loading: boolean = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService,
    private settingService: SettingService,
    private router: Router
  ) {
    //Reset
    settingService.initView();
  }


  ngOnInit() {

    this.load();

  }



  //Properties
  public get contracts() {
    return this._contracts;
  }
  public get frameworkcontracts() {
    return this._frameworkcontracts;
  }
  public get newframeworkcontract() {
    return this._newframeworkcontract;
  }
  public get isloading() {
    return this._loading;
  }




  //Methods
  public load() {

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/contracts', 'GET', {})
      .subscribe((res) => {

        this._contracts = res;
        this._loading = false;
      });

    this.dataService.tokenRequest('/api/v1/contracts/frameworkcontracts', 'GET', {})
      .subscribe((res) => {

        this._frameworkcontracts = res;
      });
  }
  public goto(id) {
    this.router.navigate(['/admin/contracts', id]);
  }
  public saveframeworkcontract(frameworkcontract) {
    let verb = 'POST';
    let path = '/api/v1/contracts/frameworkcontracts/';
    if (frameworkcontract.Id > 0) {
      verb = 'PUT';
      path += frameworkcontract.Id;
    }

    this.dataService.tokenRequest(path, verb, frameworkcontract, 'text', 'response')
      .subscribe(response => {
        if (response) {
          this.alertService.Add({ message: response.body, type: 'success' });

          this._newframeworkcontract = { Id: 0, Name: '', Desc: '' };

          this.load();
        }

      });
  }



}
