<swe-card header="{{languageService.getItem(676)}}" [open]="true" [hasRefresh]="true" (refreshChange)="load()" [hasCollapse]="false" [rulelist]="['SweHasCheckIn']">
  <swe-datepager [(startlocal)]="start" [(endlocal)]="end" (dateChange)="load()" [disabled]="isloading" [hideTime]="true"></swe-datepager>
  <div>
    <swe-shiftschart [rows]="shiftchartrows"
                     [comparedata]="shiftchartcomparedata"
                     [start]="start"
                     [end]="endWholeDay"
                     [showhour]="showhour"
                     [showgroupby]="permissionService.permissions.ShowDashboardTimelineGroupBy"
                     [show]="permissionService.permissions.ShowDashboardTimelineAvailability?3:1">
    </swe-shiftschart>
  </div>
</swe-card>

