import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor, HttpResponse } from "@angular/common/http";
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, switchMap, filter, take, finalize } from 'rxjs/operators';

import { TokenService } from "../_services/token.service";
import { AuthenticationService } from "../_services/authentication.service";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private tokenService: TokenService, private authenticationService: AuthenticationService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(request).pipe(
      catchError(error => {

        //Check to see if refreshtoken failed
        //In that case we want to logout user and to redirect it to login page
        if (request.url.includes("refreshtoken")) {
          this.authenticationService.redirectToLogin();

          return throwError(error);
        }

        if (error.status == 401) {

          if (this.refreshTokenInProgress) {
            //Wait for other RefreshToken request and use the new AuthToken when it is ready 
            return this.refreshTokenSubject.pipe(
              filter(token => token !== null),
              take(1),
              switchMap(jwt => {
                return next.handle(this.addNewAuthToken(request));
              })
            );
          }
          else {
            //Reset InProgress
            this.refreshTokenInProgress = true;

            //Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);

            //Call RefreshToken and use the new Authtoken to resend the request
            return this.authenticationService.useRefreshToken().pipe(
              switchMap((res: any) => {
                this.refreshTokenInProgress = false;
                this.tokenService.refreshTokens(res);
                this.refreshTokenSubject.next(res);
                return next.handle(this.addNewAuthToken(request));
              }),
              catchError(error => {
                this.refreshTokenInProgress = false;
                if (error.status == 401) {
                  this.authenticationService.redirectToLogin();
                }

                return throwError(error);
              }),
              finalize(() => {
                this.refreshTokenInProgress = false;
              })
            );

          }

        }
        else {
          this.refreshTokenInProgress = false;
          return throwError(error);
        }

      })
    );
  }


  private addNewAuthToken(request) {

    //Clone request and set new Auth Token
    return request.clone({
      setHeaders: {
        Authorization: this.tokenService.authBearer
      }
    });
  }
}
