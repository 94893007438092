import { Component, Input, EventEmitter, Output, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';

import { LanguageService } from '../_services/language.service';

@Component({
  selector: 'swe-popup',
  templateUrl: './popup.component.html',
})
export class PopUpComponent implements OnInit, OnChanges {
  @Output() doneChange = new EventEmitter<any>();
  @Output() closeChange = new EventEmitter<any>();
  @Output() visibleChange = new EventEmitter<any>();
  @Input() doneText: string = '';
  @Input() header: string = '';
  @Input() visible: boolean = false;
  @Input() hideClose: boolean = false;
  @Input() event: any = null;
  @Input() manualClose: boolean = false;
  @ViewChild('btnDone') focusElement: ElementRef;

  constructor(public languageService: LanguageService) {

  }

  ngOnInit() {
    if (!this.doneText) {
      this.doneText = this.languageService.getItem(16); //Save
    }
    if (!this.header) {
      this.header = '';
    }
  }

  ngOnChanges() {
    if (this.visible) {
      this.manageFocus();
    }
  }


  //Properties


  //Methods
  public open(e) {
    e.stopPropagation();

    this.visible = true;
    this.manageFocus();
  }
  public close() {
    this.closeChange.emit(true);
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
  public done() {
    this.doneChange.emit(this.event ? this.event : true);
    if (!this.manualClose) {
      this.visible = false;
      this.visibleChange.emit(this.visible);
    }
  }



  //Functions
  private manageFocus() {
    setTimeout(() => {
      if (this.focusElement) {
        let element = this.focusElement.nativeElement;
        element.focus();
        if (element.select) {
          element.select();
        }
      }
    }, 0); //Create a macrotask that will run in the next VM turn
  }

}
