<swe-card header="{{data.Value}}" toolbarClass="mt-1" [(open)]="open" (refreshChange)="load(false)" (openChange)="load(false)" *ngIf="permissionService.hasObject(data.Key,id)>0" [rulelist]="['SweHasObjects']" icon="bi-bookmark-fill">
  <span swe-hits *ngIf="open">&nbsp;({{hits}}<span *ngIf="more">+</span>)</span>
  <button *ngIf="permissionService.hasObject(data.Key)>1" swe-rightcommand class="bi-swe-pull-right btn btn-success text-white rounded mb-2" (click)="goto({Id:0}, $event)"><i class="bi bi-plus-lg bi-swe-fw swe-click"></i>{{languageService.getItem(100)}}</button>
  <!--Table-->
  <div class="table-responsive">
    <table class="table" *ngIf="objects.length>0">
      <thead>
        <tr>
          <th style="border-top:0; border-bottom:0;" *ngIf="objects[0].OneToOne">&nbsp;</th>
          <th style="border-top:0; border-bottom:0;" *ngFor="let header of objects[0].Profile">
            {{propertyService.getProperty(header.Property).Name}}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of objects">
          <tr style="border-top:0; border-bottom:0;" (click)="row.Access>1&&goto(row, $event)" [ngClass]="{'swe-click': (row.Access>1)}" *ngIf="row.Access>0">
            <td *ngIf="row.OneToOne">{{row.ObjectName}}</td>
            <td *ngFor="let profile of row.Profile" [ngSwitch]="profile.Type">
              <span *ngSwitchDefault>{{profile.FormatValue}}</span>
              <span *ngSwitchCase="'System.Link'"><a [target]="'_blank'" [href]="profile.Url">{{profile.FormatValue}}</a></span>
              <span *ngSwitchCase="'System.Boolean'"><i class="bi bi-fw" [ngClass]="{'bi-check-square': profile.FormatValue, 'bi-square': !profile.FormatValue}"></i></span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</swe-card>
