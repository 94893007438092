<div [ngClass]="{'d-grid gap-2': isbutton, 'd-inline': !isbutton}" (click)="open($event)">
  <!-- Trigger modal -->
  <ng-content>
  </ng-content>
</div>

<!-- Modal -->
<div class="modal fade" [ngClass]="{'d-block show': visible}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{header}}</h5>
        <a href="javascript:void(0);" class="swe-no-link" (click)="close($event)" *ngIf="!hideClose"><i class="bi bi-swe-fw bi-x-lg bi-swe-pull-right"></i></a>
      </div>
      <div class="modal-body">
        <p>{{body}}</p>
        <div *ngIf="affectedEntities&&affectedEntities.length>0">
          <p>{{affectedHeader}}</p>
          <ul class="list-group overflow-auto" style="max-height:200px">
            <li *ngFor="let entity of affectedEntities" class="list-group-item">
              {{entity.Name}}
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <ng-content select="[extraOptions]"></ng-content>
        <button type="button" class="btn btn-primary" (click)="accept($event)" *ngIf="isAlert" #btnAccept>{{okText}}</button>
        <button type="button" class="btn btn-primary" (click)="accept($event)" *ngIf="!isAlert" #btnAccept>{{yesText}}</button>
        <button type="button" class="btn btn-secondary" (click)="decline($event)" *ngIf="!isAlert">{{noText}}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show" *ngIf="visible"></div>
