import { Component, OnInit, Input } from '@angular/core';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { DataService } from '../../../_services/data.service';
import { SettingService } from '../../../_services/setting.service';
import { GeneralService } from '../../../_services/general.service';
import { Router } from '@angular/router';
import { DateTimeService } from '../../../_services/datetime.service';



@Component({
  selector: 'swe-contractusers',
  templateUrl: './contractusers.component.html'
})
export class ContractUsersComponent implements OnInit {

  @Input() id: number = 0;

  private _users: any = [];
  private _loading: boolean = false;
  private _exclude: boolean = false;
  private _open: boolean = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    public dateTimeService: DateTimeService,
    private dataService: DataService,
    private generalService: GeneralService,
    private router: Router
  ) {

    
    
  }


  ngOnInit() {
    
  }



  //Properties
  public get users() {
    return this._users;
  }
  public get isloading() {
    return this._loading;
  }
  public get exclude() {
    return this._exclude;
  }
  public set exclude(val) {
    this._exclude = val;
  }


  //Methods
  public search() {
    this._open = !this._open;
    if (!this._open) {
      return;
    }

    this._loading = true;

    let filter = {
      Id: this.id,
      Start: this.settingService.start('employment'),
      End: this.settingService.end('employment')
    };

    this.dataService.tokenRequest('/api/v1/contracts/users/', 'Post', filter)
      .subscribe((res) => {

        this._users = res;

        this._loading = false;
      });

  }
  public goto(userid: number, e) {

    e.stopPropagation();

    let url = '/users/' + userid;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public getTitle(user) {
    if (user.Employments.length == 0) {
      return this.languageService.getItem(1074);
    }

    return '';
  }
  public manageExclude(e) {

  }



  //Functions
  
}
