import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { DateTimeService } from '../_services/datetime.service';
import { SettingService } from '../_services/setting.service';


@Component({
  selector: 'app-messagedetail',
  templateUrl: './messagedetail.component.html'
})
export class MessageDetailComponent implements OnInit {

  
  private _category: string = '';
  private _id: number = 0;
  

  constructor(
    public languageService: LanguageService,
    public dateTimeService: DateTimeService,
    public settingService: SettingService,
    private route: ActivatedRoute
  ) {

    this.route.paramMap.subscribe(params => {
      this._category = params.get('category');
      this._id = parseInt(params.get('id'));

      
    });

    settingService.initView();

  }

  ngOnInit() {
    
  }

  /*Properties*/
  public get id() {
    return this._id;
  }
  public get category() {
    return this._category;
  }
  



  //Methods



  //Functions
  
  
}
