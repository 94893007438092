import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { LanguageService } from '../_services/language.service';

@Component({
  selector: 'swe-table',
  templateUrl: 'table.component.html',
})
export class TableComponent implements OnInit, OnChanges {
  @Input() data: any[] = [];
  @Input() loading: boolean;

  private _table: any[] = null;

  constructor(public languageService: LanguageService) {

  }

  ngOnInit() {
    this._table = null;
  }

  ngOnChanges() {

    if (typeof this.loading != 'undefined') {
      if (this.loading) {
        this._table = null;
      }
      else {
        this._table = this.data;
      }
    }
    else {
      this._table = null;

      let timeout = setTimeout(() => {
        this._table = this.data;
      }, 500);
    }
  }

  /*Properties*/
  public get table() { return this._table; }
  

  /*Methods*/

}
