import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../_services/language.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { PermissionService } from '../_services/permission.service';
import { DateTimeService } from '../_services/datetime.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { AlertService } from '../_services/alert.service';
import { group } from 'console';
import { GridSettings } from './grid/timelinegrid.component';


@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html'
})
export class TimelineComponent implements OnInit, OnDestroy {

  private unsubsribe$ = new Subject<void>();
  private _showhour: boolean;
  private _content = [];
  private _holidays = [];
  private _more: boolean = false;
  private _all: boolean = false;
  private _top: number = 25;
  private _multiple: number = 1;
  private _hits: number = 0;
  private _loading: boolean = false;
  private _newprecision:boolean = false;
  private _allchecked: boolean = false;
  private _ischecked: boolean = false;
  private _isshiftchecked: boolean = false;
  private _dofilter: boolean = false;
  private _actionmenu: boolean = false;
  private _toggleaccess: boolean = false;
  private _marked: number = 0;
  private _markedgroup: number = 0;
  private _precisionmenu: boolean = false;
  private _optionmenu: boolean = false;
  private _notifyemail: boolean = this.permissionService.permissions.NotifyOnBookEmail == 1 ? true : false;
  private _notifysms: boolean = this.permissionService.permissions.NotifyOnBookSms == 1 ? true : false;
  private _groupby: number = null;
  private _menucollapsed: boolean = true;
  private _changedSettings: GridSettings = null;
  private _showRequest: boolean = false;
  private _markedSerie: number = 0;
  private _isdragged: boolean = false;
  private _addMenu: boolean = false;
  //Popup
  private _timereportIdList: any[] = [];
  private _popup: any[] = [{ Visible: false, Event: null, Option: 3 }];



  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private dataService: DataService,
    private dateTimeService: DateTimeService,
    private viewCacheService: ViewCacheService,
    private alertService: AlertService,
    private router: Router
  ) {

    settingService.initView(
      1 | 2 | 8 | 16 | 32 | 512,
      ['Booking', 'User', 'Level']
    );

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {

    this._toggleaccess = (this.permissionService.permissions.MultiShift > 0);

    this._groupby = this.settingService.timeline.groupby;
    this.search();
  }



  //Properties
  public get addMenu() {
    return this._addMenu;
  }
  public set addMenu(val) {
    this._addMenu = val;
  }
  public get showRequest() {
    return this._showRequest;
  }
  public get showhour() {
    return this._showhour;
  }
  public get content() {
    return this._content;
  }
  public get holidays() {
    return this._holidays;
  }
  public get more(): boolean {
    return this._more;
  }
  public get all(): boolean {
    return this._all;
  }
  public get hits(): number {
    return this._hits;
  }
  public get loading(): boolean {
    return this._loading;
  }
  public get newprecision(): boolean {
    return this._newprecision;
  }
  public get allchecked() {
    return this._allchecked;
  }
  public get ischecked() {
    return this._ischecked;
  }
  public get isshiftchecked() {
    return this._isshiftchecked;
  }
  public get dofilter() {
    return this._dofilter;
  }
  public set dofilter(val) {
    this._dofilter = val;
  }
  public get actionmenu() {
    return this._actionmenu;
  }
  public set actionmenu(val) {
    this._actionmenu = val;
  }
  public get toggleaccess() {
    return this._toggleaccess;
  }
  public get precisionmenu() {
    return this._precisionmenu;
  }
  public set precisionmenu(val) {
    this._precisionmenu = val;
  }
  public get optionmenu() {
    return this._optionmenu;
  }
  public set optionmenu(val) {
    this._optionmenu = val;
  }
  public get marked() {
    return this._marked;
  }
  public get notifyemail() {
    return this._notifyemail;
  }
  public set notifyemail(val) {
    this._notifyemail = val;
  }
  public get notifysms() {
    return this._notifysms;
  }
  public set notifysms(val) {
    this._notifysms = val;
  }
  public get groupby() {
    return this._groupby;
  }
  public get menucollapsed() {
    return this._menucollapsed;
  }
  public set menucollapsed(val) {
    this._menucollapsed = val;
  }
  //Popup
  public get timereportIdList() {
    return this._timereportIdList;
  }
  public get popup() {
    return this._popup;
  }
  public set popup(val) {
    this._popup = val;
  }
  //Changed GridSettings
  public get changedSettings() {
    return this._changedSettings;
  }
  public set changedSettings(val) {
    this._changedSettings = val;
  }

  public get markedSerie() {
    return this._markedSerie;
  }
  public get isdragged() {
    return this._isdragged;
  }


  //AddMenu
  public addnews(e) {
    e.stopPropagation();

    this.viewCacheService.add('news_show', 4);

    this.router.navigate(['/admin/news/0']);

  }
  public addusermarkeddate(e) {
    e.stopPropagation();

    this.router.navigate(['/usermarkeddates/0/redirect/2']);

  }
  public addbooking(e) {
    e.stopPropagation();

    let url = '/bookings/';
    if (this.permissionService.permissions.ShiftWizard) {
      url += 'wizard/';
    }
    url += '0';
    this.router.navigate([url]);

  }



  //Methods
  public changedBySettings(e) {
    this._ischecked = (e.settings.resourcecheckcounter > 0);
    this._isshiftchecked = (e.settings.shiftcheckcounter > 0);
    this._markedgroup = e.settings.markedgroup;
    this._marked = e.settings.marked;
    this._markedSerie = e.settings.markedSerie;
    this._isdragged = e.settings.isdragged;

    if (e.reload) {
      this.search();
    }
  }

  public handleSearchEvent($event) {
    this.search($event.getMore);
  }

  public search(getmore: boolean = false, group = null) {

    if (group != null) {
      this._groupby = group;
    }

    if (!this.dateTimeService.validation(this.settingService.booking.start, this.settingService.booking.end)) { return; }

    this._dofilter = false;

    let start = new Date(this.settingService.start('booking'));
    let end = new Date(this.settingService.end('booking'));
    let days = (end.getTime() - start.getTime()) / (24 * 60 * 60 * 1000);
    this._showhour = (Math.round(days) == 1);

    this._more = false;
    this._all = false;

    let content: any[] = [...this._content];
    if (getmore) {
      this._multiple = 1 + (this._multiple / 4);
      this._top *= 4;
    }
    else {
      this._multiple = 1;
      this._top = this.permissionService.permissions.NumberOfResources;
      if (this._top == 0) {
        this._top = 25;
      }
      content = []; this._content = [];
    }

    let filter = {
      Top: this._top,
      Multiple: this._multiple,
      //Bookings
      Start: this.settingService.start('booking'),
      End: this.settingService.end('booking'),
      IntervalType: this.settingService.booking.intervaltype,
      Weekdays: this.settingService.booking.weekdays,
      StatusList: this.settingService.booking.status,
      Personal: this.settingService.booking.personal,
      ShiftType: this.settingService.booking.shifttype,
      Reservation: this.settingService.booking.reservation,
      ReplaceableAsNoSlot: this.settingService.booking.replaceableasnoslot,
      CheckIn: this.settingService.booking.checkin,
      TypeList: this.settingService.booking.bookingtype.join(),
      OwnerId: this.settingService.booking.owner,
      ActivityOption: this.settingService.booking.activityoption,
      ActivityTypes: this.settingService.booking.activitytypes,
      TimeTypes: this.settingService.booking.timetypes,
      ShiftLevelGroups: this.settingService.booking.levelgroups.join(),
      ShiftLevelList: this.settingService.levelList(2),
      //Users
      UserSearch: this.settingService.user.search,
      UserList: this.settingService.userList(),
      RoleList: this.settingService.user.roles.join(),
      Active: this.settingService.user.activity,
      UserLevelGroups: this.settingService.user.levelgroups.join(),
      UserLevelList: this.settingService.levelList(1),
      StartAvailable: this.settingService.start('booking'),
      EndAvailable: this.settingService.end('booking'),
      Available: this.settingService.user.availability,
      AvailableTypes: this.settingService.user.availabilitylist.join(),
      Employment: this.settingService.user.employment,
      EmploymentCategories: this.settingService.user.employmentcategories,
      FrameworkContracts: this.settingService.user.frameworkcontracts,
      Contract: this.settingService.user.contract,
      Contracts: this.settingService.user.contracts,
      Booking: this.settingService.user.bookingtype,
      //Properties
      PropertyList: this.settingService.property.properties,
      UseOR: this.settingService.property.useor,
      //Timeline
      BookingId: this._marked,
      GroupById: this._markedgroup,
      GroupBy: this.settingService.timeline.groupby,
      Show: this.settingService.timeline.timelineshow,
      IncludeRef: this.settingService.timeline.includereferences,
      ShowProfile: this.settingService.timeline.showprofile,
      SortBy: this.settingService.timeline.sortby
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/bookings/groupbysearch', 'POST', filter)
      .subscribe(res => {
        if (res) {

          res.GroupBys.forEach((groupby,index) => {

            content.push(groupby);
          });

          this._content = content; //Make a smoother update of calendar
          this._holidays = res.Holidays;
          this._hits = this.settingService.timeline.groupby == 0 ? this._content.length - 1 : this._content.length;
          this._more = res.More;
          this._all = (!res.More && this._content.length > 0);
        }

        this._loading = false;
        this._newprecision = false;
      });
    this._ischecked = false;
  }
  public changePrecision(precision: number) {
    this._newprecision = true;
    this._precisionmenu = false;
    this.settingService.timeline.precision = precision;
    this.search();
  }
  public show(option: number) {

    if ((this.settingService.timeline.timelineshow & option) == option) {
      //Is Checked => Uncheck
      this.settingService.timeline.timelineshow -= option;
    }
    else {
      //Is Unchecked => Check
      this.settingService.timeline.timelineshow += option;
    }

    /*
    if (this.settingService.timeline.timelineshow == -1) {
      //None, show current option
      this.settingService.timeline.timelineshow = option;
    }
    else if (this.settingService.timeline.timelineshow == 0) {
      //Both, show opposite option
      this.settingService.timeline.timelineshow = (option == 1) ? 2 : 1;
    }
    else if (this.settingService.timeline.timelineshow == 1) {
      //Only shift, show both or none
      this.settingService.timeline.timelineshow = (option == 1) ? -1 : 0;
    }
    else if (this.settingService.timeline.timelineshow == 2) {
      //Only usermarkeddate, show both or none
      this.settingService.timeline.timelineshow = (option == 1) ? 0 : -1;
    }
    */

    this.search(false);
  }
  public isChecked(option: number) {
    return ((this.settingService.timeline.timelineshow & option) == option);
  }
  public manageMultiMode() {
    this.settingService.timeline.multimode = !this.settingService.timeline.multimode;
    if (this.settingService.timeline.multimode) {
      //Change to MultiMode
      this.settingService.timeline.bookingmode = false;
      this.resetBookingMode();
    }
    else {
      //Regret MultiMode
      this._allchecked = false;
      this.resetMultiMode();
    }

    //this.settingService.settingrefresh('timeline', 'multimode');
  }
  public manageBookingMode() {
    this.settingService.timeline.bookingmode = !this.settingService.timeline.bookingmode;
    if (this.settingService.timeline.bookingmode) {
      //Change to BookingMode
      this.settingService.timeline.multimode = false;
      this._allchecked = false;
      this.resetMultiMode();
    }
    else {
      //Regret BookingMode
      this.resetBookingMode();
    }

    //this.settingService.settingrefresh('timeline', 'bookingmode');
  }
  public togglecheck() {
    //When a change occures, the GridComponent will be notified

    this._allchecked = !this._allchecked;
    if (this._allchecked) {
      this.settingService.timeline.multimode = true;
      if (this.settingService.timeline.bookingmode) {
        this.settingService.timeline.bookingmode = false;
        this.resetBookingMode();
        this._allchecked = false;
    }
    }

    this._isshiftchecked = (this._allchecked);
  }
  public action(option) {
    let bookings = [];
    let first = new Date();
    let last = new Date();

    this._content.forEach((groupby) => {
      groupby.Bookings.forEach((booking) => {
        if (booking.ismarked && booking.Access > 1) {
          if (bookings.length == 0) {
            first = booking.Start;
          }
          last = booking.Start;

          bookings.push(booking.Id);
        }
      });
    });

    if (bookings.length > 0) {
      this.viewCacheService.add('multi_bookings', bookings);
      this.viewCacheService.add('multi_bookings_first', first);
      this.viewCacheService.add('multi_bookings_last', last);

      this.router.navigate(['/bookings/action/' + option + '/']);
    }
  }
  public saveBooking(booktype) {

    let users = [];
    let bookings = [];

    let statuserror = false;
    this._content.forEach((groupby) => {
      groupby.Bookings.forEach((booking) => {
        if (booking.ismarked && booking.Access > 1) {
          if (booking.Status == 60) {
            statuserror = true;
          }
          else if (booking.Timereport > 0) {
            users.push(booking.Timereport);
            bookings.push({ Id: booking.Id, UserId: groupby.GroupById });
          }
        }
      });
    });

    if (statuserror) {
      this.alertService.Add({ message: this.languageService.getItem(1229), type: 'danger' });
      return;
    }

    if (booktype < 3 || !this.permissionService.permissions.DetailWhenAbscent) {
      this.synchronouslysave(booktype, bookings, 0);
    }
    else {
      this._popup[0].Visible = true;
      this._popup[0].Event = 3;
      this._popup[0].Option = 1;

      this._timereportIdList = users;
    }
  }
  public reloadAfterBook() {
    this.search(false);
  }
  public send(type, serie, e) {
    let bookings = [];

    this._content.forEach((groupby) => {
      groupby.Bookings.forEach((booking) => {
        if (booking.ismarked && booking.Access > 1) {
          bookings.push(booking.Id);
        }
      });
    });

    if (bookings.length > 0) {

      let msg = {
        Auto: true,
        Type: type,
        Serie: serie
      };

      let searchavailable = Math.pow(2, this.permissionService.permissions.SearchAvailable);
      let filter = {
        Start: this.settingService.start('booking'),
        End: this.settingService.end('booking'),
        Available: ((this.permissionService.permissions.AvailableType & searchavailable) == searchavailable) ? this.permissionService.permissions.SearchAvailable : 0
      };

      let bookingmsg = {
        BookingList: bookings,
        Message: msg,
        Filter: filter
      };

      this.dataService.tokenRequest('/api/v1/bookings/message', 'POST', bookingmsg, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.search(false);
        });
    }

    if (typeof e != 'undefined') {
      e.stopPropagation();
    }

  }

  public sendRequest(isSerie: boolean) {
    const serie = isSerie ? this.markedSerie : 0;
    const url = `/requests/send/3/id/${this._marked}/serie/${serie}/body/4`;
    let userIds = '';
    this._content.forEach(row => {
      if (row.selected) {
        userIds += row.GroupById + ',';
      }
    });
    if (!userIds) {
      return;
    }

    const filter = {
      userList: userIds.slice(0, -1),
      top: this._top
    };
    this.dataService.tokenRequest('/api/v1/users/search', 'POST', filter)
      .subscribe(res => {
        if (res) {
          this.viewCacheService.add('request_users', res.Users);
          this.router.navigate([url]);
        }
      });

  }

  public showRequestBtn($event) {
    this._showRequest = $event;
  }


  //Functions
  private synchronouslysave(booktype, bookings, counter) {

    let booking = bookings[counter];
    if (!booking) {
      return;
    }

    let filter = {
      Type: booktype
    };

    this.dataService.tokenRequest('/api/v1/bookings/' + booking.Id + '/users/' + booking.UserId, 'POST', filter, 'text')
      .subscribe(res => {

        this.alertService.Add({ message: res, type: 'success' });

        counter++;
        if (counter < bookings.length) {
          this.synchronouslysave(booktype, bookings, counter);
        }
        else {
          this.search(false);
        }
      });

  }

  private resetBookingMode() {

    if (this._marked > 0) {

      let settings: any = {};
      settings.marked = this._marked = 0;
      settings.markedgroup = this._markedgroup = 0;
      this._changedSettings = settings;

      this.search();
    }
  }
  private resetMultiMode() {
    
    this._content.forEach((row) => {
      row.Bookings.forEach((booking) => {
        booking.ismarked = false;
      });
    });

    this._isshiftchecked = false;
  }
}
