import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { KeyValue, Location } from '@angular/common';

import { LanguageService } from '../../../_services/language.service';
import { DataService } from '../../../_services/data.service';
import { ListService } from '../../../_services/list.service';



@Component({
  selector: 'swe-contractarticles',
  templateUrl: './contractarticles.component.html'
})
export class ContractArticlesComponent implements OnInit {

  @Input() id: number = 0;
  private _allArticles: any[];
  private _validArticles: any[];
  private _otherArticles: any;
  private _isLoading: boolean = false;
  private _typeFilter: number = 0;
  private _types: any[] = [];
  private _groups: any[] = [];
  private _isOpen: boolean = false;
  constructor(
    public languageService: LanguageService,
    private listService: ListService,
    private dataService: DataService,
    private router: Router,
  ) { }

  public get isOpen() {
    return this._isOpen;
  }
  public set isOpen(val) {
    this._isOpen = val;
  }
  public get types() {
    return this._types;
  }
  public get typeFilter() {
    return this._typeFilter;
  }
  public set typeFilter(val) {
    this._typeFilter = val;
  }
  public get validArticles() {
    return this._validArticles;
  }
  public get otherArticles() {
    return this._otherArticles;
  }
  public get isLoading() {
    return this._isLoading;
  }
  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }
  ngOnInit() {
    this._types.push({ Id: 0, Name: 1342 });
    this._types.push({ Id: 1, Name: 1344 });
    this._types.push({ Id: 2, Name: 1343 });

    this._groups = [
      { Id: 1, Name: this.languageService.getItem(1345), Sort: 1 },
      { Id: 2, Name: this.languageService.getItem(1346), Sort: 2 },
      { Id: 3, Name: this.languageService.getItem(1347), Sort: 3 },
      { Id: 4, Name: this.languageService.getItem(1348), Sort: 4 },
      { Id: 5, Name: this.languageService.getItem(1349), Sort: 5 }
    ]
  }

  public filter() {
    this._validArticles = this._allArticles.filter(x => x.ContractValidation == 0 && (this._typeFilter == 0 || this._typeFilter == x.Type));
    this._otherArticles = this.listService.groupByInt(this._allArticles.filter(x => x.ContractValidation != 0 && (this._typeFilter == 0 || this._typeFilter == x.Type)), 'ContractValidation', this._groups);
    this._otherArticles = Object.keys(this._otherArticles).length > 0 ? this._otherArticles : null;
  }
  //Methods
  public load() {
    if (!this._isOpen) {
      return;
    }
    this._isLoading = true;
    this.dataService.basicRequest(`/api/v1/contracts/${this.id}/articles`, 'GET').subscribe(res => {
      this._allArticles = res;
      this._validArticles = res.filter(x => x.ContractValidation == 0 && (this._typeFilter == 0 || this._typeFilter == x.Type));
      this._otherArticles = this.listService.groupByInt(res.filter(x => x.ContractValidation != 0 && (this._typeFilter == 0 || this._typeFilter == x.Type)), 'ContractValidation', this._groups);
      this._otherArticles = Object.keys(this._otherArticles).length > 0 ? this._otherArticles : null;
      this._isLoading = false;
    });
  }
  public openToggle(group) {
    if (group.value.IsOpen === null) {
      group.value.IsOpen = true;
    }
    group.value.IsOpen = !group.value.IsOpen;
  }
  public goto(articleId: number, e) {

    e.stopPropagation();

    let url = '/admin/articles/' + articleId;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }


}
