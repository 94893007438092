<swe-pageheader [header]="languageService.getItem(400)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<swe-fixedheader [datePagerOpt]="{type: 'log', hideTime: false }" [all]="all" (searchEvent)="handleSearchEvent($event)">
</swe-fixedheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false">
      <span swe-rightcommand>
        <i class="bi bi-swe-fw bi-swe-pull-right bi-download swe-click" [attr.title]="languageService.getItem(851)" (click)="download()"></i>
      </span>
      <swe-list [data]="logs" [loading]="loading">
        <a class="list-group-item list-group-item-action d-flex flex-wrap" *ngFor="let log of logs" [ngClass]="{'list-group-item-warning': log.OriginalId > 0}">
          <div class="col-12 col-md-2">{{log.Object}}</div>
          <div class="col-12 col-md-1">{{log.Type}}</div>
          <div class="col-12 col-md-5">
            <span *ngFor="let value of log.Values" class="me-1" [ngClass]="{'bg-warning': value.Diff}">{{value.Key}}={{value.Value}},</span>
          </div>
          <div class="col-12 col-md-2">{{log.CreatedBy}} <span style="white-space:nowrap;" class="small" *ngIf="log.OriginalId>0"><i>({{languageService.getItem(317)}}: {{log.OriginalId}})</i></span></div>
          <div class="col-12 col-md-2">{{dateTimeService.format(log.Created,'yyyy-MM-dd HH:mm:ss')}}</div>
        </a>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
    </swe-card>
  </div>
</div>
