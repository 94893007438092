<swe-card header="{{title}}" [(open)]="open" (refreshChange)="search(false)" (openChange)="search(false)" [rulelist]="['SweBookingLevels: Write']" icon="bi-list-ul">
  <span swe-hits *ngIf="sum>0&&!employmentplan">({{generalService.formatdecimal(sum)}}h)</span>
  <span swe-rightcommand class="bg-success text-white rounded bi-swe-pull-right"><i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="goto(0,$event)"></i></span>
  <swe-datepager [type]="'booking'" (dateChange)="search(false)" [disabled]="loading" [hideTime]="true"></swe-datepager>
  <div class="btn-group ms-1 align-top" *ngIf="more">
    <button class="btn btn-primary" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
  <swe-filteritems></swe-filteritems>
  <swe-list [data]="bookings" [loading]="loading">
    <div class="list-group mb-3">
      <a class="list-group-item list-group-item-action d-flex swe-click" [ngClass]="{'list-group-item-warning swe-scroll-to': booking.Id==settingService.lastbooking}" *ngFor="let booking of bookings" (click)="goto(booking.Id,$event)">
        <div class="col-6 col-md-2">
          <i class="bi bi-swe-2x bi-check-circle-fill" *ngIf="booking.checked"></i>
          <i class="bi bi-swe-border swe-icon-color" *ngIf="!booking.checked&&!employmentplan&&booking.Status>10" [attr.title]="booking.StatusText" [ngClass]="{'bi-arrow-up-right-square': booking.Status==20,'bi-key-fill': booking.Status==30,'bi-clock-fill': booking.Status==40,'bi-check-lg': booking.Status==50,'bi-lock-fill': booking.Status==60,'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
          <i class="bi bi-swe-border bi-square swe-icon-color" *ngIf="!booking.checked&&!employmentplan&&booking.Status==10" [attr.title]="booking.StatusText" [ngClass]="{'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
          <i class="bi bi-swe-border bi-link-45deg" *ngIf="!booking.checked&&booking.Serie>0&&!employmentplan" [attr.title]="languageService.getItem(203)"></i>
          <i class="bi bi-swe-border bi-list-ul" *ngIf="!booking.checked&&booking.HasActivities>0&&!employmentplan" [attr.title]="languageService.getItem(741)"></i>
          <i class="bi bi-swe-border bi-shield-shaded" *ngIf="!booking.checked&&booking.EmploymentPlan" [attr.title]="languageService.getItem(698)"></i>
          <i class="bi bi-swe-border bi-chat-fill" *ngIf="!booking.checked&&booking.Sms&&!employmentplan" [attr.title]="languageService.getItem(205)"></i>
          <i class="bi bi-swe-border bi-eye-fill" *ngIf="!booking.checked&&booking.IsWatching&&!employmentplan" [attr.title]="languageService.getItem(339)"></i>
          <i class="bi bi-swe-border bi-swe-bookingtype-replaceable" *ngIf="!booking.checked&&booking.HasReplaceable&&!employmentplan" [attr.title]="languageService.getItem(423)"></i>
          <i class="bi bi-swe-border bi-swe-bookingtype-standby" *ngIf="!booking.checked&&booking.HasStandby&&!employmentplan" [attr.title]="languageService.getItem(279)"></i>
          <i class="bi bi-swe-border bi-swe-bookingtype-abscent" *ngIf="!booking.checked&&booking.HasAbscent&&!employmentplan" [attr.title]="languageService.getItem(280)"></i>
          <i class="bi bi-swe-border bi-swe-bookingtype-decline" *ngIf="!booking.checked&&booking.HasDecline&&!employmentplan" [attr.title]="languageService.getItem(1106)"></i>
        </div>
        <div class="col-6 col-md-10 d-flex flex-wrap">
          <div class="col-12 col-md-6">{{booking.dateheader}}</div>
          <div class="col-12 col-md-6">{{booking.Header}}</div>
        </div>
      </a>
      <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
    </div>
  </swe-list>
  <swe-generalarticles [id]="id" [open]="false" [islevel]="true" [salaryaccess]="leveldata.ArticleSalaryAccess" [invoiceaccess]="leveldata.ArticleInvoiceAccess" [idList]="allBookingIdList" [cardcolor]="'swe'" *ngIf="type==0&&allBookingIdList.length>0&&permissionService.permissions&&permissionService.permissions.Articles>0"></swe-generalarticles>
</swe-card>
