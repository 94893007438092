<div class="row d-block py-2">
  <div class="col-12 col-md-6 float-start">
    <swe-homenews *ngIf="permissionService.permissions.News>0"></swe-homenews>
    <swe-homebookings *ngIf="permissionService.permissions.AvailableShift||permissionService.permissions.ReplaceableShift||permissionService.permissions.YourShifts>0"></swe-homebookings>
  </div>
  <div class="col-12 col-md-3 float-start">
    <swe-homecheckin *ngIf="permissionService.permissions.CheckIn>1"></swe-homecheckin>
  </div>
  <div class="col-12 col-md-3 float-start">
    <swe-homeshortcuts *ngIf="permissionService.permissions.Shortcuts>0"></swe-homeshortcuts>
  </div>
</div>
<div class="row d-block py-2" *ngIf="permissionService.permissions.Stats>0">
  <div class="col-12 col-md-9 float-start">
    <swe-adminrule [rulelist]="['SweHasStats','SweHasBookings','SweLevels']"></swe-adminrule>
  </div>
  <div class="col-12 col-md-9 float-start">
    <swe-homestatistics *ngIf="!permissionService.permissions.ShowDashboardTimeline"></swe-homestatistics>
  </div>
  <div class="col-12 col-md-12 float-start">
    <swe-homechart *ngIf="permissionService.permissions.ShowDashboardTimeline"></swe-homechart>
  </div>
</div>
<!--To make float behavior (fill available space), use [d-block] on row and [float-start] on columns-->
