<div (click)="open($event)">
  <!-- Trigger modal -->
  <ng-content select="[swe-popup-trigger]">
  </ng-content>
</div>

<!-- Modal -->
<div class="modal fade" [ngClass]="{'d-block show': visible}">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{header}}</h5>
        <a href="javascript:void(0);" class="swe-no-link" (click)="close()" *ngIf="!hideClose"><i class="bi bi-swe-fw bi-x-lg bi-swe-pull-right"></i></a>
      </div>
      <div class="modal-body">
        <ng-content select="[swe-popup-body]">
        </ng-content>
      </div>
      <div class="modal-footer">
        <ng-content select="[footer-extra]">

        </ng-content>
        <button type="button" class="btn btn-primary" (click)="done()" #btnDone>{{doneText}}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show" *ngIf="visible"></div>
