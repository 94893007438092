import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../../_services/language.service';
import { SettingService } from '../../_services/setting.service';
import { DataService } from '../../_services/data.service';

@Component({
  selector: 'swe-levelusers',
  templateUrl: './levelusers.component.html'
})
export class LevelUsersComponent implements OnDestroy {
  @Input() id: number;
  @Input() open: boolean;

  private unsubsribe$ = new Subject<void>();
  private _users = [];
  private _more: boolean = false;
  private _top: number = 25;
  private _multiple: number = 1;
  private _hits: number = 0;

  constructor(public languageService: LanguageService, private settingService: SettingService, private dataService: DataService, private router: Router) {

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }


  //Properties
  public get users() {
    return this._users;
  }

  public get more(): boolean {
    return this._more;
  }

  public get hits(): number {
    return this._hits;
  } 



  //Methods
  public search(getmore: boolean = false) {

    if (getmore) {
      this._multiple = 1 + (this._multiple / 4);
      this._top *= 4;
    }
    else {
      this._multiple = 1;
      this._top = 25;
      this._users = [];
    }

    let filter = {
      Top: this._top,
      Multiple: this._multiple,
      Search: this.settingService.user.search,
      UserList: this.settingService.userList(),
      RoleList: this.settingService.user.roles.join(),
      Active: this.settingService.user.activity,
      //Levels
      LevelId: this.id
    };

    this.dataService.tokenRequest('/api/v1/users/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          this._more = res.More;

          res.Users.forEach(user => {
            this._users.push(user);
          });

          this._hits = this._users.length;

        }
      });
  }

  public openUser(id: number, e) {
    let url = '/users/' + id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }

}
