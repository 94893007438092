<swe-card [header]="languageService.getItem(860)" [(open)]="open" (refreshChange)="load(false)" (openChange)="load(false)" [rulelist]="['SweLevels']" icon="bi-diagram-2-fill">
  <div class="btn-group ms-1 align-top" *ngIf="more">
    <button class="btn btn-primary" (click)="load(true)">{{languageService.getItem(57)}}</button>
  </div>
  <div class="list-group">
    <a class="list-group-item list-group-item-action d-flex flex-wrap bg-light">
      <h2>{{languageService.getItem(235)}}</h2>
    </a>
    <a class="list-group-item list-group-item-action d-flex swe-click" (click)="parentAccess>1&&goto(parentId,$event)" *ngIf="parentId>0" [ngStyle]="{'cursor': parentAccess>1 ? 'pointer':'not-allowed'}">
      {{parent}}
    </a>
    <div class="list-group-item list-group-item-action d-flex" *ngIf="parentId<=0">
      {{languageService.getItem(806)}}
    </div>
    <a class="list-group-item list-group-item-action d-flex flex-wrap bg-light">
      <h2>{{languageService.getItem(972)}}</h2>
    </a>
    <a class="list-group-item list-group-item-action d-flex swe-click" *ngFor="let level of relations" (click)="level.Access>1&&goto(level.Id,$event)" [ngStyle]="{'cursor': level.Access>1 ? 'pointer':'not-allowed'}">
      <div class="col-3 col-md-1">
        <img class="swe-circle-image" alt="{{level.Name}}" sweImage imageFolder="levelimages" [imageFile]="level.Image" imageWidth="32" imageHeight="32" *ngIf="level.Image.length>0" />
        <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="level.Image.length==0">
          <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
          <span class="bi-swe-stack-1x bi-swe-letter">{{level.Name[0]}}</span>
        </span>
      </div>
      <div class="col-9 col-md-11 d-flex flex-wrap">
        <span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}
      </div>
    </a>
    <button class="btn btn-primary" *ngIf="more" (click)="load(true)">{{languageService.getItem(57)}}</button>
  </div>
</swe-card>
