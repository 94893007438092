import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { ListService } from '../../_services/list.service';
import { DataService } from '../../_services/data.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { GeneralService } from '../../_services/general.service';


@Component({
  selector: 'swe-multiobjects',
  templateUrl: './multiobjects.component.html'
})
export class MultiObjectsComponent implements OnInit {

  private _properties: any[] = [];
  private _chosenproperties: any[] = [];
  private _category: number = 0;
  private _header: string = '';

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private listService: ListService,
    private viewCacheService: ViewCacheService,
    private alertService: AlertService,
    private settingService: SettingService,
    private location: Location,
    private route: ActivatedRoute
  ) {

    //Reset
    settingService.initView();
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      //Category
      this._category = Number(params.get('category'));
      //Header
      let found: boolean = false;
      this.generalService.propertycategories.forEach((item) => {
        if (!found) {
          if (item.Key == this._category) {
            this._header = item.Value;
            found = true;
          }
        }
      });
    });

    this.load();
  }


  //Properties
  public get properties() {
    return this._properties;
  }
  public get chosenproperties() {
    return this._chosenproperties;
  }
  public get header() {
    return this._header;
  }




  //Methods
  public confirmsave() {
    let confirmtext = '';

    if (this._chosenproperties.length > 0) {
      if (confirmtext.length > 0) {
        confirmtext += ' ' + this.languageService.getItem(430) + ' '; //and
      }
      confirmtext += this.languageService.getItem(428).replace('{0}', this._chosenproperties.length);
    }
    
    return this.languageService.getItem(776).replace('{0}', confirmtext);
  }
  public save() {
    let objects:any = this.viewCacheService.get('multi_objects');

    if (objects) {

      let profile: any[] = [];
      this._chosenproperties.forEach(property => {
        profile.push({ Property: property.Id, Value: property.Val });
      });

      let filter = {
        IdList: objects.value,
        Profiles: profile
      };

      this.dataService.tokenRequest('/api/v1/objects/multi', 'POST', filter, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.alertService.Add({ message: this.languageService.getItem(774), type: 'danger' });
    }
  }
  public delete() {
    let objects: any = this.viewCacheService.get('multi_objects');

    if (objects) {
      let filter = {
        IdList: objects.value
      };

      this.dataService.tokenRequest('/api/v1/objects/multi/delete', 'POST', filter, 'text')
        .subscribe((res) => {
          this.alertService.Add({ message: res, type: 'success' });
          this.location.back();
        });
    }
    else {
      this.alertService.Add({ message: this.languageService.getItem(774), type: 'danger' });
    }
  }
  


  //Functions
  private load() {

    this.dataService.tokenRequest('/api/v1/properties/type/multiobject', 'GET', {})
      .subscribe((res) => {

        let list: any[] = [];
        res.forEach((item) => {
         
          if (item.CatTypeId == this._category) {
            list.push(item);
          }
         
        });

        this._properties = [{ Name: this._header, Items: list }];
      });
  }

}
