import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { PropertyService } from '../_services/property.service';
import { SettingService } from '../_services/setting.service';
import { DateTimeService } from '../_services/datetime.service';
import { MenuService } from '../_services/menu.service';

@Component({
  selector: 'swe-properties',
  templateUrl: 'properties.component.html',
})
export class PropertiesComponent implements OnInit  {
  @Input() label: string;
  @Input() statusLabel: number = 0; //0 = Hide, 1 = Standard, 2 = Grouped, 3 = Inline
  @Input() items: any[] = [];
  @Output() itemsChange = new EventEmitter<any>();
  @Input() properties: any[] = [];
  @Input() showOptions: boolean = false;



  constructor(
    public languageService: LanguageService,
    public propertyService: PropertyService,
    private permissionService: PermissionService,
    private dateTimeService: DateTimeService,
    private settingService: SettingService,
    private menuService: MenuService
  ) {
    
  }


  ngOnInit() {
    
  }


  /*Properties*/
  public get cols(): number {

    if (this.statusLabel == 1) {
      //Standard
      return this.permissionService.permissions.ProfileLabel;
    }
    else if (this.statusLabel == 2) {
      //Grouped
      return 0;
    }
    else if (this.statusLabel == 3) {
      //Inline
      return 3;
    }

    return 0;
  }
  


  /*Methods*/
  public getLabelClass() {
    if (this.cols == 0) {
      return "";
    }
    return 'col-form-label col-' + this.cols;
  }

  public getInputClass() {
    if (this.cols == 0) {
      return "";
    }
    return 'col-' + (12 - this.cols);
  }
  public add() {
    this.items.push({
      Id: 0,
      Val: '',
      Option: -1,
      Type: 'System.String',
      Items: [],
      Options: [],
      CatType: '',
      Disabled: false
    });

    this.itemsChange.emit(this.items);
  }
  public remove(index) {
    this.items.splice(index, 1);
  }
  public getOptions(propertyObj: any) {
    let options: any[] = []; 

    let property = this.propertyService.getProperty(propertyObj.Id);
    if (property.Type == "System.DateTime" || property.Type == "System.Date" || property.Type == "System.Time" ) {
      options.push({ Id: 4, Name: this.languageService.getItem(167) });
      options.push({ Id: 5, Name: this.languageService.getItem(168) });
      options.push({ Id: 6, Name: this.languageService.getItem(169) });
      options.push({ Id: 7, Name: this.languageService.getItem(170) });
      propertyObj.Option = 4;
    }
    else if (property.Type == "System.Double") {
      options.push({ Id: 4, Name: this.languageService.getItem(167) });
      options.push({ Id: 5, Name: this.languageService.getItem(168) });
      options.push({ Id: 6, Name: this.languageService.getItem(169) });
      options.push({ Id: 7, Name: this.languageService.getItem(170) });
      propertyObj.Option = 4;
    }
    else if (property.Type == "System.Int32") {
      options.push({ Id: 4, Name: this.languageService.getItem(167) });
      options.push({ Id: 5, Name: this.languageService.getItem(168) });
      options.push({ Id: 6, Name: this.languageService.getItem(169) });
      options.push({ Id: 7, Name: this.languageService.getItem(170) });
      propertyObj.Option = 4;
    }
    else if (property.Type == "System.Boolean") {
      options.push({ Id: 4, Name: this.languageService.getItem(167) });
      options.push({ Id: 5, Name: this.languageService.getItem(168) });
      propertyObj.Option = 4;
    }
    else if (property.Type == "System.List") {
      options.push({ Id: 4, Name: this.languageService.getItem(167) });
      options.push({ Id: 5, Name: this.languageService.getItem(168) });
      propertyObj.Option = 4;
    }
    else if (property.Type == "System.MultiList") {
      options.push({ Id: 0, Name: this.languageService.getItem(163) });
      options.push({ Id: 1, Name: this.languageService.getItem(164) });
      propertyObj.Option = 0;
    }
    else {
      options.push({ Id: 0, Name: this.languageService.getItem(163) });
      options.push({ Id: 1, Name: this.languageService.getItem(164) });
      options.push({ Id: 2, Name: this.languageService.getItem(165) });
      options.push({ Id: 3, Name: this.languageService.getItem(166) });
      options.push({ Id: 4, Name: this.languageService.getItem(167) });
      options.push({ Id: 5, Name: this.languageService.getItem(168) });
      options.push({ Id: 6, Name: this.languageService.getItem(169) });
      options.push({ Id: 7, Name: this.languageService.getItem(170) });
      propertyObj.Option = 0;
    }

    return options;
  }
  public propertyChosen(e, index) {

    //Competence
    if (e < 0) { e *= -1; }

    let property = this.propertyService.getProperty(e);
    if (property) {
      let item = this.items[index];
      if (item) {
        item.Type = property.Type;
        item.CatType = property.CatType;
        item.Items = property.Items;
        if (item.Type == 'System.DateTime') {
          item.Val = this.dateTimeService.formatWithTimeZone(new Date());
        }
        else if (item.Type == 'System.Date') {
          let now = new Date();
          item.Val = this.dateTimeService.formatWithTimeZone(new Date(now.getFullYear(), now.getMonth(), now.getDate(),0,0));
        }
        else if (item.Type == 'System.Time') {
          item.Val = this.dateTimeService.formatWithTimeZone(new Date());
        }
        else {
          item.Val = '';
        }
        item.Options = this.getOptions(item);
      }
    }
  }
  public manageReload(filteritem) {
    if (filteritem.ReloadItems) {
      let property = this.propertyService.getProperty(filteritem.Id);
      if (property) {
        filteritem.Items = property.Items;
      }
      filteritem.ReloadItems = false;
    }
    else {
      return filteritem.Items;
    }
  }

  public refresh() {
    if (!this.settingService.pinned) {
      this.menuService.closeSearch();
    }
    this.settingService.refresh();
  }


  //Functions
  private load() {

  }
}
