<swe-pageheader [header]="languageService.getItem(410)"></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-start">
    <swe-card header="{{languageService.getItem(52)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false" [rulelist]="['SweMultiTimereport']">
      <!--Reported Time-->
      <div class="mb-3">
        <label>{{languageService.getItem(1310)}}</label>
        <div>
          <swe-datetime [(start)]="start" [(end)]="end" [labelStart]="languageService.getItem(1310)" [hideDate]="true"></swe-datetime>
        </div>
      </div>
      <ng-container *ngIf="positiveTimeTypes.length > 0">
        <swe-element [(model)]="positiveTimeType" [statusLabel]="2" [label]="languageService.getItem(1307)" [type]="'System.List'" [items]="positiveTimeTypes" [optional]="true" [optionalValue]="0" *ngIf="start||end"></swe-element>
        <swe-element [(model)]="negativeTimeType" [statusLabel]="2" [label]="languageService.getItem(1308)" [type]="'System.List'" [items]="negativeTimeTypes" [optional]="true" [optionalValue]="0" *ngIf="start||end"></swe-element>
        <swe-element [(model)]="timeType" [statusLabel]="2" [label]="languageService.getItem(1121)" [type]="'System.List'" [items]="positiveTimeTypes" [optional]="true" [optionalValue]="0"></swe-element>
      </ng-container>
      <!--Status-->
      <swe-element [(model)]="status" [label]="languageService.getItem(413)" [statusLabel]="2" [items]="generalService.timereportstatus" [optional]="true" [type]="'System.List'" [disabled]="removeStatus"></swe-element>
      <swe-element type="System.Boolean" [(model)]="removeStatus" [label]="languageService.getItem(177)" [statusLabel]="1"></swe-element>
      <!--External Id-->
      <swe-adminrule [rulelist]="['SweExternalUserEmployments']"></swe-adminrule>
      <swe-element [(model)]="externalcategory" [label]="languageService.getItem(517)" [statusLabel]="2" [items]="externalcategories" [optional]="true" [type]="'System.List'" *ngIf="permissionService.permissions.ExternalCategories>0"></swe-element>
      <!--Salary Sent-->
      <swe-adminrule [rulelist]="['SweTimereportSalary']"></swe-adminrule>
      <swe-element [(model)]="salarysent" [label]="languageService.getItem(1145)" [statusLabel]="2" [items]="yesOrNoList" [optional]="true" [type]="'System.List'" *ngIf="permissionService.permissions.Salary>1"></swe-element>
      <!--Invoice Sent-->
      <swe-adminrule [rulelist]="['SweTimereportInvoice']"></swe-adminrule>
      <swe-element [(model)]="invoicesent" [label]="languageService.getItem(1146)" [statusLabel]="2" [items]="yesOrNoList" [optional]="true" [type]="'System.List'" *ngIf="permissionService.permissions.Invoice>1"></swe-element>
      <!--Properties-->
      <swe-properties [label]="languageService.getItem(415)" [statusLabel]="2" [properties]="properties" [items]="chosenproperties" *ngIf="properties.length>0"></swe-properties>
      <div class="d-grid gap-2">
        <!--Buttons-->
        <swe-confirm [body]="confirmsave()" (acceptChange)="save()">
          <button class="btn btn-primary"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(667)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
</div>  
