import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { LanguageService } from '../../_services/language.service';
import { GeneralService } from '../../_services/general.service';


@Component({
  selector: 'swe-usericalendar',
  templateUrl: './usericalendar.component.html'
})
export class UserICalendarComponent implements OnChanges {
  @Input() id: number;
  @Input() open: boolean;
  @Input() userdata: any;

  private _icalurl: string = "";
  private _icallevelurl: string = "";

  constructor(
    public languageService: LanguageService,
    public generalService: GeneralService,
    private sanitizer: DomSanitizer
  ) {
    
  }


  ngOnChanges() {
    if (this.userdata) {
      this._icalurl = this.userdata.ICalUrl;
      this._icallevelurl = this.userdata.ICalLevelUrl;
    }
  }


  /*Properties*/
  public get icalurl() {
    if (this._icalurl) {
      return this._icalurl;
    }
    return "";
  }
  public get icallevelurl() {
    if (this._icallevelurl) {
      return this._icallevelurl;
    }
    return "";
  }


  /*Methods*/
  public sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  

}
