import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { Tab } from '../../layout/card.component';
import { DateTimeService } from '../../_services/datetime.service';
import { SettingService } from '../../_services/setting.service';
import { ListService } from '../../_services/list.service';
import { PropertyService } from '../../_services/property.service';



@Component({
  selector: 'swe-homebookings',
  templateUrl: './homebookings.component.html',
})
export class HomeBookingsComponent implements OnInit {

  private _bookings: any[];
  private _open: boolean;
  private _loading: boolean;
  private _more: boolean = false;
  private _top: number = 3;
  private _multiple: number = 1;
  private _header: string = '';
  private _tabs: Tab[] = [];
  private _active: number = 0;
  //Details
  private _detail: any = {};
  private _detailprofileready: boolean = false;

  constructor(
    public permissionService: PermissionService,
    public languageService: LanguageService,
    public settingService: SettingService,
    private dataService: DataService,
    private dateTimeService: DateTimeService,
    private listService: ListService,
    private propertyService: PropertyService,
    private router: Router)
  {

    
  }

  ngOnInit() {

    setTimeout(() => {

      if (this.permissionService.permissions.AvailableShift) {
        this._tabs.push({ 'id': 0, 'name': this.languageService.getItem(431) })
      }
      else if (this.permissionService.permissions.ReplaceableShift) {
        this._tabs.push({ 'id': 0, 'name': this.languageService.getItem(423) })
      }
      else {
        this._active = 1;
      }

      let yourshifts = this.permissionService.permissions.YourShifts;
      if (yourshifts > 0) {
        this._tabs.push({ 'id': 1, 'name': this.languageService.getItem(847) })
        if (yourshifts == 2) {
          this._active = 1;
        }
      }

      if (this.permissionService.permissions.AllOrders > 0) {
        this._tabs.push({ 'id': 2, 'name': this.languageService.getItem(1185) })
      }

      if (this.permissionService.permissions.YourOrders > 0) {
        this._tabs.push({ 'id': 3, 'name': this.languageService.getItem(1186) })
      }

      this._header = this.languageService.getItem(4);
      if (this._tabs.length == 1) {
        this._header = this._tabs[0].name;
      }
      

      this.search();
    }, 500);
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get open() {
    return this._open;
  }
  public get more(): boolean {
    return this._more;
  }
  public get header() {
    return this._header;
  }
  public get tabs() {
    return this._tabs;
  }
  public get active() {
    return this._active;
  }
  public set active(value) {
    this._active = value;
  }
  public get bookings() {
    return this._bookings;
  }
  public get detail() {
    return this._detail;
  }
  public get detailprofileready() {
    return this._detailprofileready;
  }




  /*Methods*/
  public search(getmore:boolean = false) {

    this._more = false;

    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._top = 3;
      this._bookings = [];
    }

    let filter = {
      Top: this._top,
      Multiple: this._multiple,
      //Bookings
      Start: new Date,
      End: this.dateTimeService.addDays(new Date(), 365)
    };

    if (this._active == 0) {
      if (this.permissionService.permissions.AvailableShift) {
        filter["ReplaceableAsNoSlot"] = this.permissionService.permissions.Replaceable; //Replaceable doesn't count as slot
        filter["Reservation"] = 2; //Not fully booked
      }
      else if (this.permissionService.permissions.Replaceable) {
        filter["TypeList"] = '0'; //Only Replaceable
      }
      filter["StatusList"] = [20];
      filter["ShiftLevelGroups"] = this.settingService.booking.levelgroups.join();
    }
    else if (this._active == 1) {
      filter["Personal"] = 1;
      filter["TypeList"] = '0,1'; //Only Replaceable and Normal
      filter["ExcludeEmptyShiftWhenBookingTypeFiltrering"] = true;
    }
    else if (this._active == 2) {
      //Nothing
    }
    else if (this._active == 3) {
      filter["OwnerId"] = this.permissionService.user.Id;
    }

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/bookings/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          res.Bookings.forEach(booking => {

            let start = new Date(booking.Start);
            let end = new Date(booking.End);

            booking.dateheader = this.dateTimeService.header(start, end, false);

            this._bookings.push(booking);
          });

          this._more = res.More;

          if (!this.open) {
            this._open = this._bookings.length > 0;
          }
        }

        this._loading = false;
      });
  }
  public manageChange(e) {
    this._active = e;
    this.search();
  }
  public goto(id: number) {
    
    this.router.navigate(['/bookings', id]);
  }
  public gotoList() {

    //Clear
    this.settingService.booking.replaceableasnoslot = false;
    this.settingService.booking.bookingtype = [];
    this.settingService.booking.reservation = '0';
    this.settingService.booking.personal = '0';
    this.settingService.booking.status = [];

    if (this._active == 0) {
      if (this.permissionService.permissions.AvailableShift) {
        this.settingService.booking.replaceableasnoslot = this.permissionService.permissions.Replaceable; //Replaceable doesn't count as slot
        this.settingService.booking.reservation = '2'; //Not fully booked
      }
      else if (this.permissionService.permissions.Replaceable) {
        this.settingService.booking.bookingtype = [0]; //Only Replaceable
      }
      this.settingService.booking.status = [20];
    }
    else if (this._active == 1) {
      this.settingService.booking.personal = '1';
    }
    else if (this._active == 2) {
      //Nothing
    }
    else if (this._active == 3) {
      this.settingService.booking.owner = this.permissionService.user.Id;
    }

    let currentTime = new Date();
    let currentDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), 0, 0, 0);
    let threemonthDate = new Date(currentTime.getFullYear(), currentTime.getMonth() + 3, currentTime.getDate(), 0, 0, 0);

    this.settingService.booking.start = currentDate;
    this.settingService.booking.end = threemonthDate;


    this.settingService.checkWatchdog();

    this.router.navigate(['/bookings']);
  }




  //Details
  public showdetail(booking, e) {

    e.stopPropagation();

    this.resetdetail(e);

    this._detail.row = booking.Id;
    this._detail.width = 400;
    this._detail.booking = booking;

    /*Detail Header*/
    let headeritems = 1; //Icons and dateheader
    if (!this.permissionService.permissions.HideLevels) {
      headeritems++; //Levels
    }
    headeritems++; //Header
    if (!this.permissionService.permissions.HideAmount || this._detail.booking.Serie > 0) {
      headeritems++; //Amount or Serie
    }
    /**************/

    this._detailprofileready = false;

    this.dataService.tokenRequest('/api/v1/bookings/' + booking.Id, 'GET', {})
      .subscribe((res) => {

        let extraitems = 1; //Block-Header
        extraitems++; //Bottom-Margin

        let profileheight = (21 * headeritems) + (23 * (res.Profile.length + extraitems));
        if (this._detail.height < profileheight) {
          this._detail.height = profileheight;
        }

        res.Profile.forEach((profile) => {
          let property = this.propertyService.getProperty(profile.Property);
          if (property) {
            profile.Type = property.Type;
            if (property.Type == 'System.DateTime' || property.Type == 'System.Date' || property.Type == 'System.Time') {
              profile.Value = this.dateTimeService.format(profile.Value, property.Format);
            }
            else if (property.Type == 'System.List' || property.Type == 'System.MultiList') {
              if (profile.Value != null && profile.Value.length > 0) {
                let res = '';
                profile.Value.split('|').forEach((val) => {

                  property.Items.forEach((item) => {
                    if (item.Id == val) {

                      if (res.length > 0) {
                        res += ' | ';
                      }

                      res += item.Name;
                    }
                  });
                });
                profile.Value = res;
              }
            }
          }
        });
        this._detail.profile = res.Profile;

        this._detailprofileready = true;
      });

    this.dataService.tokenRequest('/api/v1/bookings/' + booking.Id + '/users', 'Get', {})
      .subscribe((res) => {

        this._detail.users = this.listService.groupByInt(
          res.Users,
          'Type',
          [
            { Id: 1, Name: this.languageService.getItem(30), Sort: 0 },
            { Id: 0, Name: this.languageService.getItem(423), Sort: 1 },
            { Id: 2, Name: this.languageService.getItem(279), Sort: 2 },
            { Id: 3, Name: this.languageService.getItem(280), Sort: 3 },
            { Id: 4, Name: this.languageService.getItem(1106), Sort: 4 }
          ]);

        let userlength = Object.keys(this._detail.users).length;

        let extraitems = userlength; //Block-Header 
        extraitems += userlength; //Bottom-Margin

        let userheight = (20 * headeritems) + (30 * (res.Users.length + extraitems));
        if (this._detail.height < userheight) {
          this._detail.height = userheight;
        }
      });

    return false;
  }
  public resetdetail(e) {

    e.stopPropagation();

    this._detail = {
      row: -1,
      left: 0,
      top: 0,
      height: 0,
      width: 0,
      booking: {},
      profile: [],
      users: []
    };

    return false;
  }



  //Functions
  

}
