import { Component, Input, OnInit } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { DataService } from '../_services/data.service';

@Component({
  selector: 'swe-history',
  templateUrl: './history.component.html',
})
export class HistoryComponent implements OnInit {

  @Input() propertyId: number = 0;
  @Input() objectId: number = 0;
  @Input() type: string = '';
  @Input() preventdrowning: boolean = false;

  private _showhistory: boolean = false;
  private _history: any[] = [];

  constructor(
    public languageService: LanguageService,
    public dataService: DataService
  ) {
    
  }

  ngOnInit() {
    
  }


  /*Properties*/
  public get showhistory(){
    return this._showhistory;
  }
  public get history() {
    return this._history;
  }




  /*Methods*/
  public getHistory() {
    this._showhistory = !this._showhistory;
    if (this._showhistory) {
      
      this.dataService.tokenRequest('/api/v1/properties/history/' + this.objectId + '/' + this.propertyId, 'Get', {})
        .subscribe((res) => {
          this._history = res;
        });
    }
  }



  /*Functions*/

}
