<swe-card (refreshChange)="load()" [header]="header" [(open)]="isOpen" [hasRefresh]="hasRefresh" [hasCollapse]="!alwaysOpen" (openChange)="load()">
  <span swe-rightcommand>
    <a class="swe-click swe-no-link" (click)="close()"><i class="bi bi-x-lg bi-swe-pull-right" *ngIf="hasClose"></i></a>
    <a class="swe-click swe-no-link" (click)="goTo()"><i class="bi bi-pencil-fill bi-swe-pull-right" *ngIf="hasEdit&&!isBaseGuide"></i></a>
  </span>  <div id="carousel" class="carousel-dark" *ngIf="contents.length > 0 && isOpen && !isLoading">
    <div class="carousel-inner text-center">
      <div *ngFor="let content of contents; index as i;" class="carousel-item text-center" [ngClass]="{'active': i == activeIndex}">
        <div [innerHTML]="content" class="d-inline-block text-start mx-5" style="max-width: 400px">
        </div>
      </div>
    </div>
    <div *ngIf="contents.length > 1">
      <button class="carousel-control-prev" style="top:40px" role="button" (click)="prev()">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </button>
      <button class="carousel-control-next" style="top:40px" role="button" (click)="next()">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </button>
    </div>
  </div>
  <ul class="list-group list-unstyled list-group-horizontal list-inline mx-auto justify-content-center" *ngIf="contents.length > 1">
    <li *ngFor="let content of contents; index as i;" [ngClass]="{'text-black': i == activeIndex, 'text-black-50': i != activeIndex}">
      <i class="bi bi-dot bi-swe-4x" style="width:20px"></i>
    </li>
  </ul>
</swe-card>
