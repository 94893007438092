import { Component, OnInit, Input } from '@angular/core';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { DataService } from '../../../_services/data.service';
import { SettingService } from '../../../_services/setting.service';
import { GeneralService } from '../../../_services/general.service';



@Component({
  selector: 'swe-contracttimebank',
  templateUrl: './contracttimebank.component.html'
})
export class ContractTimeBankComponent implements OnInit {

  @Input() id: number = 0;

  private _timebank: any = {};

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private dataService: DataService,
    private generalService: GeneralService
  ) {

    
    
  }


  ngOnInit() {
    
  }



  //Properties
  public get timebank() {
    return this._timebank;
  }



  //Methods
  public load() {

    this.dataService.tokenRequest('/api/v1/general/timebanks/' + this.id, 'GET', {})
      .subscribe((res) => {

        this._timebank = res;
      });

  }


  //Functions
  
}
