<div class="col-12 col-md-4 float-start">
  <swe-card header="{{languageService.getItem(76) + todaystat}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
    <span swe-rightcommand><i class="bi bi-swe-fw bi-box-arrow-up-right bi-swe-pull-right swe-click" (click)="goto(1)" *ngIf="permissionService.permissions.Bookings>0"></i></span>
    <canvas baseChart
            [type]="charttype"
            [datasets]="today"
            [labels]="labels"
            [options]="options"
            [plugins]="chartplugins">
    </canvas><!--[colors]="colors"-->
  </swe-card>
</div>
<div class="col-12 col-md-4 float-start">
  <swe-card header="{{languageService.getItem(77) + currentweekstat}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
    <span swe-rightcommand><i class="bi bi-swe-fw bi-box-arrow-up-right bi-swe-pull-right swe-click" (click)="goto(2)" *ngIf="permissionService.permissions.Bookings>0"></i></span>
    <canvas baseChart
            [type]="charttype"
            [datasets]="currentweek"
            [labels]="labels"
            [options]="options"
            [plugins]="chartplugins">
    </canvas><!--[colors]="colors"-->
  </swe-card>
</div>
<div class="col-12 col-md-4 float-start">
  <swe-card header="{{languageService.getItem(78) + nextweekstat}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
    <span swe-rightcommand><i class="bi bi-swe-fw bi-box-arrow-up-right bi-swe-pull-right swe-click" (click)="goto(3)" *ngIf="permissionService.permissions.Bookings>0"></i></span>
    <canvas baseChart
            [type]="charttype"
            [datasets]="nextweek"
            [labels]="labels"
            [options]="options"
            [plugins]="chartplugins">
    </canvas><!--[colors]="colors"-->
  </swe-card>
</div>
  
