import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { AlertService } from '../../_services/alert.service';


@Component({
  selector: 'app-logsettings',
  templateUrl: './logsettings.component.html'
})
export class LogSettingsComponent implements OnInit {

  private _logsettings: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private dataService: DataService,
    private alertService: AlertService
  ) {
    //Reset
    settingService.initView();
  }


  ngOnInit() {
    this.load();
  }



  //Properties
  public get logsettings() {
    return this._logsettings;
  }



  //Methods
  public load() {
    this.dataService.tokenRequest('/api/v1/logs/settings', 'GET', {})
      .subscribe((res) => {

        this._logsettings = res;
      });
  }
  public save(setting) {

    let id = setting.Id;

    let verb = 'POST';
    let path = '/api/v1/logs/settings/'
    if (id > 0) {
      verb = 'PUT';
      path += id;
    }

    this.dataService.tokenRequest(path, verb, setting, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });
        this.load();
      });

  }

  /*Delete*/
  public delete(setting) {

    this.dataService.tokenRequest('/api/v1/logs/settings/' + setting.Id, 'DELETE', {}, 'text')
      .subscribe((res) => {

        this.alertService.Add({ type: 'success', message: res });
        this.load();
      });

  }
}
