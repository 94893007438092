<div class="swe-app">
  <div class="swe-sidebar bg-dark swe-print-remove" [ngClass]="{'swe-sidebar-show': menuService.ShowMenu && authenticationService.isAuth}">
    <!-- Sidebar content here -->
    <div class="swe-sidebar-inside">
      <app-sidebar></app-sidebar>
    </div>
  </div>
  <div class="swe-content-wrapper bg-light" [ngClass]="{'swe-sidebar-show': menuService.ShowMenu && authenticationService.isAuth, 'swe-searchbar-show swe-searchbar-show-md': menuService.ShowSearch && authenticationService.isAuth}">
    <header class="sticky-top swe-header swe-print-remove">
      <!-- Header content here -->
      <app-topmenu></app-topmenu>
    </header>
    <div class="swe-body container-fluid py-2">
      <main class="swe-main">
        <!-- Main content here -->
        <router-outlet></router-outlet>
      </main>
    </div>
    <footer class="swe-footer">
      <!-- Footer content here -->
    </footer>
    <!--Clickable area to close menus-->
    <div class="swe-clickable-area swe-clickable-area-md" (click)="menuService.closeMenu();!settingService.pinned&&menuService.closeSearch()" *ngIf="menuService.ShowMenu||(menuService.ShowSearch&&!settingService.pinned)"></div>
  </div>
  <div class="swe-searchbar bg-dark swe-print-remove" [ngClass]="{'swe-searchbar-show swe-searchbar-show-md': menuService.ShowSearch && authenticationService.isAuth}">
    <!-- Searchbar content here -->
    <app-searchbar></app-searchbar>
  </div>
  <app-guide class="d-none d-md-block" *ngIf="authenticationService.isAuth"></app-guide>
</div>

<app-notification></app-notification>

<swe-confirm [(isvisible)]="newversion" [hideClose]="true" [body]="'Ny version finns tillgänglig. Klicka på knappen för att hämta senaste versionen.'" [isAlert]="true" [okText]="'Hämta ny version'" (acceptChange)="newversionaccept($event)" (declineChange)="newversiondecline($event)"></swe-confirm>
