import { Injectable, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';


export interface Alert {
  message: string;
  type: string;
}

@Injectable({ providedIn: 'root' })
export class AlertService {

  private _alerts = [];
  private _history = [];
  private _bigtext = false;
  private _all = false;

  //Events
  public onNewHistory$ = new EventEmitter<any>();

  constructor(
    private datePipe: DatePipe
  ) {

  }

  public set bigtext(val) { this._bigtext = val; }
  public set all(val) { this._all = val; }


  public Add(alert: Alert) {

    if (this._bigtext) {
      alert["bigtext"] = true;
    }

    if (alert.message && alert.message.length > 0) {
      if (this._alerts.length > 0 && this._alerts[this._alerts.length - 1].message == alert.message && !this._all) {
        //Don't alert twice in a row
        console.log(alert.message);
      }
      else {
        alert['time'] = this.datePipe.transform(new Date(), 'HH:mm'); //Can't use dateTimeService (forever loop)
        this._alerts.push(alert);
      }

      let timeout = setTimeout(() => {
        let a = this._alerts.shift();
        if (a&&!a['hide']) {
          this.manageHistory(a);
        }
      }, alert.type == 'danger' ? 10000 : 5000);
    }
  }

  public Pop(alert: Alert) {
    var index = -1;
    //to find alert from queue of active alerts
    for (var i = 0; i < this._alerts.length; i++) {
      if (this._alerts[i] == alert) {
        index = i;
        alert['hide'] = true;
        this.manageHistory(alert);
        break;
      }
    }
    //if (index != -1) {
    //  this._alerts.splice(index, 1);
    //}

    return this._alerts;
  }

  public PopHistory(alert: Alert) {
    var index = -1;
    //to find alert from queue of active alerts
    for (var i = 0; i < this._history.length; i++) {
      if (this._history[i] == alert) {
        index = i;
        break;
      }
    }
    if (index != -1) {
      this._history.splice(index, 1);
    }

    return this._history;
  }

  public ClearHistory() {
    this._history = [];
  }

  public get Alerts() {
    return this._alerts;
  }
  public get History() {
    return this._history;
  }

  public ReActivate() {
    let tmp = this._alerts;
    this._alerts = [];
    this._alerts = tmp;
  }


  //Functions
  private manageHistory(alert) {
    if (this._history.length > 10) {
      //Only save 10 alert in history
      this._history.pop();
    }
    this._history.unshift(alert);
    this.onNewHistory$.emit(true);
  }
}

