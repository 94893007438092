import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { SettingService } from '../../_services/setting.service';



@Component({
  selector: 'app-articledetail',
  templateUrl: './articledetail.component.html'
})
export class ArticleDetailComponent implements OnInit {

  private _id: number = 0;
  
  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private route: ActivatedRoute
  ) {
    //Reset
    settingService.initView();

    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this.settingService.lastarticle = this._id;
      this.settingService.openCard('articles');
    });

  }


  ngOnInit() {

  }



  //Properties
  public get id() {
    return this._id;
  }
  



  //Methods
  
  



  //Functions
  

}
