<div (dragover)="prepareScroll($event)">
  <swe-timelineheader [data]="data" [settings]="settings" [all]="all" [menucollapsed]="menucollapsed" (groupbyChange)="changedByGroupBy($event)" (sumoptionsChange)="changedBySumOptions($event)" (settingsChange)="changedBySettings($event)"></swe-timelineheader>
  <ng-container *ngFor="let row of rows">
    <div [ngStyle]="{'overflow': (row.GroupById == settings.detail) ? 'unset' : 'hidden'}" class="p-0" [nopadding]="true" [index]="3" [topadjustmentclass]="settings.showhour? 'pt-4' : ''" sweFixedHeader *ngIf="row.GroupById==0&&settings.emptyRowPinned&&settings.emptyRowHeight <= settings.emptyRowMaxHeight">
      <swe-timelinerow [row]="row" [dofilter]="dofilter" [menucollapsed]="menucollapsed" [notifyemail]="notifyemail" [notifysms]="notifysms" [settings]="settings" (settingsChange)="changedBySettings($event)" (resetScroll)="resetScroll()"></swe-timelinerow>
    </div>
    <div [ngStyle]="{'overflow': (row.GroupById == settings.detail) ? 'unset' : 'hidden'}" *ngIf="row.GroupById!=0||(!settings.emptyRowPinned||settings.emptyRowHeight>settings.emptyRowMaxHeight)">
      <swe-timelinerow (rowChecked)="toggleRow($event)" [row]="row" [dofilter]="dofilter" [menucollapsed]="menucollapsed" [notifyemail]="notifyemail" [notifysms]="notifysms" [settings]="settings" (settingsChange)="changedBySettings($event)" (resetScroll)="resetScroll()"></swe-timelinerow>
    </div>
  </ng-container>
  <div class="d-grid gap-2" *ngIf="more&&!dofilter">
    <button class="btn btn-primary" (click)="changedByMore()">{{languageService.getItem(57)}}</button>
  </div>
</div>


<div class="swe-scroll-spot up" id="ScrollUp" (dragover)="doScroll($event)" *ngIf="scrollup">
  <i class="bi bi-swe-fw bi-swe-3x bi-arrow-up"></i>
</div>
<div class="swe-scroll-spot down" id="ScrollDown" (dragover)="doScroll($event)" *ngIf="scrolldown">
  <i class="bi bi-swe-fw bi-swe-3x bi-arrow-down"></i>
</div>
