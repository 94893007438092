<swe-card header="{{languageService.getItem(33)}}" [(open)]="open" (refreshChange)="load()" (openChange)="load()" toolbarClass="mt-1" [rulelist]="['SweUserRoleLevels', 'SweShowUserRoleLevels']" icon="bi-diagram-3-fill">
  <span swe-rightcommand class="bi-swe-pull-right rounded bg-success mt-1 text-white">
    <i class="bi bi-swe-fw bi-plus-lg swe-click" (click)="showEdit($event)"></i>
  </span>
  <div class="list-group">
    <swe-card header="{{languageService.getItem(100)}}" [color]="'swe'" [hasCollapse]="false" [hasRefresh]="false" *ngIf="edit" [rulelist]="['SweUserLevels']">
      <span swe-rightcommand>
        <i class="bi bi-swe-fw bi-x-lg bi-swe-pull-right swe-click" (click)="edit=false"></i>
      </span>
      <div class="list-group">
        <swe-element [(model)]="role" (modelChange)="addRole($event)" [label]="languageService.getItem(82)" [items]="availableroles" [type]="'System.List'"></swe-element>
      </div>
      <div class="list-group">
        <swe-levelsearch (modelChange)="addLevel($event)" [onlyone]="false" [type]="'User'" [chosen]="levels" *ngIf="!advanced"></swe-levelsearch>
        <swe-levelsearch [(model)]="level" (modelChange)="add()" [onlyone]="true" [keepOpen]="true" [type]="'User'" [chosen]="levels" *ngIf="advanced"></swe-levelsearch>
      </div>
      <div class="d-grid gap-2 mt-2">
        <button class="btn btn-primary" (click)="add()" *ngIf="advanced">{{languageService.getItem(100)}}</button>
      </div>
    </swe-card>
  </div>
  <div class="list-group mt-2" *ngIf="!advanced&&roles.length>0">
    <h3>{{languageService.getItem(82)}}</h3>
    <a class="list-group-item list-group-item-action" *ngFor="let role of roles">{{role.Name}}<i class="bi bi-x-lg bi-swe-pull-right swe-click" *ngIf="role.Access>2" (click)="removeRole(role.Id)"></i></a>
  </div>
  <div class="list-group mt-2" *ngIf="!advanced&&levels.length>0">
    <h3>{{languageService.getItem(3)}}</h3>
    <a class="list-group-item list-group-item-action" *ngFor="let level of levels"><span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}<i class="bi bi-x-lg bi-swe-pull-right swe-click" *ngIf="level.Access>2" (click)="removeLevel(level)"></i></a>
  </div>
  <div *ngIf="advanced">
    <div class="list-group mt-2" *ngFor="let connection of connections">
      <h3>
        <span *ngIf="connection.Role">{{connection.Role.Name}}&nbsp;</span>
        <i class="bi bi-x-lg swe-click" *ngIf="connection.Role&&connection.Role.Access>2" (click)="removeRole(connection.Role.Id)"></i>
      </h3>
      <a class="list-group-item list-group-item-action" *ngFor="let level of connection.Levels"><span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}<i class="bi bi-x-lg bi-swe-pull-right swe-click" *ngIf="level.Access>2" (click)="remove(connection.Role.Id, level.Id)"></i></a>
    </div>
  </div>
</swe-card>
