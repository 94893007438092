<div [ngClass]="{'row': (cols > 0)}">
  <label *ngIf="statusLabel>0" [ngClass]="getLabelClass()">{{label}}</label>
  <div [ngClass]="{ 'disabled-div': disabled}" class="position-relative mt-2">
    <div #videoPopup class="modal-video-url" tabindex="0" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="input-group">
              <input #videoInput (blur)="closeModal(0)" [(ngModel)]="videoUrl" type="text" class="form-control" style="max-height:30px;" (keydown)="modalKeyDown($event, 0)" [placeholder]="languageService.getItem(1411)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div #imagePopup class="modal-video-url" tabindex="0" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="input-group">
              <input #imageInput (blur)="closeModal(1)" [(ngModel)]="imageUrl" type="text" class="form-control" style="max-height:30px;" (keydown)="modalKeyDown($event, 1)" [placeholder]="languageService.getItem(1417)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div #editorContainer id="editor-container"></div>
  </div>
</div>
