<swe-card [header]="header" [tabs]="tabs" [(active)]="active" (activeChange)="manageChange($event)" [open]="open" (refreshChange)="search(false)" (openChange)="search(false)" [rulelist]="['SweHasAvailableShift','HasReplaceableShift','SweHasYourShifts', 'SweHasAllOrders', 'SweHasYourOrders']">
  <span swe-rightcommand><i class="bi bi-swe-fw bi-box-arrow-up-right bi-swe-pull-right swe-click" (click)="gotoList()" *ngIf="permissionService.permissions.Bookings>0"></i></span>
  <swe-adminrule [rulelist]="['SweBookingStatusCreated','SweBookingStatusOrdered','SweBookingStatusStaffed','SweBookingStatusTimereported','SweBookingStatusConfirmed','SweBookingStatusCompleted','SweLevels']"></swe-adminrule>
  <div class="list-group">
    <a class="list-group-item list-group-item-action d-flex swe-click" [ngClass]="{'list-group-item-warning': booking.Id==settingService.lastbooking}" *ngFor="let booking of bookings" (click)="goto(booking.Id)">
      <div class="col-2 col-md-1" (contextmenu)="showdetail(booking, $event)">
        <i class="bi bi-swe-border swe-icon-color" [attr.title]="booking.StatusText" *ngIf="booking.Status>10" [ngClass]="{'bi-arrow-up-right-square': booking.Status==20,'bi-key-fill': booking.Status==30,'bi-clock-fill': booking.Status==40,'bi-check-lg': booking.Status==50,'bi-lock-fill': booking.Status==60,'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
        <i class="bi bi-swe-border bi-square swe-icon-color" [attr.title]="booking.StatusText" *ngIf="booking.Status==10" [ngClass]="{'alert-danger border-danger': booking.Amount==0&&booking.Max>0,'alert-warning border-warning': (booking.Amount>0&&booking.Amount<booking.Max), 'alert-success border-success': (booking.Amount==booking.Max)}"></i>
        <i class="bi bi-swe-border bi-link-45deg" *ngIf="booking.Serie>0" [attr.title]="languageService.getItem(203)"></i>
        <i class="bi bi-swe-border bi-list-ul" *ngIf="booking.HasActivities>0" [attr.title]="languageService.getItem(741)"></i>
        <i class="bi bi-swe-border bi-chat" *ngIf="booking.Sms" [attr.title]="languageService.getItem(205)"></i>
        <i class="bi bi-swe-border bi-eye-fill" *ngIf="booking.IsWatching" [attr.title]="languageService.getItem(339)"></i>
        <i class="bi bi-swe-border bi-swe-bookingtype-replaceable" *ngIf="booking.HasReplaceable" [attr.title]="languageService.getItem(423)"></i>
        <i class="bi bi-swe-border bi-swe-bookingtype-standby" *ngIf="booking.HasStandby" [attr.title]="languageService.getItem(279)"></i>
        <i class="bi bi-swe-border bi-swe-bookingtype-abscent" *ngIf="booking.HasAbscent" [attr.title]="languageService.getItem(280)"></i>
        <i class="bi bi-swe-border bi-swe-bookingtype-decline" *ngIf="booking.HasDecline" [attr.title]="languageService.getItem(1106)"></i>
        <!--Detail-->
        <div class="swe-detail-block"
             [ngClass]="{'swe-reference-block': detail.booking.Type>1, 'alert-danger border-danger': detail.booking.Amount==0,'alert-warning border-warning': (detail.booking.Amount>0&&detail.booking.Amount<detail.booking.Max), 'alert-success border-success': (detail.booking.Amount>0&&detail.booking.Amount==detail.booking.Max)}"
             [ngStyle]="{'left': detail.left + '%','top': detail.top + 'px','height': detail.height + 'px', 'min-width': detail.width + 'px'}"
             (contextmenu)="resetdetail($event)"
             *ngIf="detail.row == booking.Id">
          <div style="position:relative;width:100%;height:100%;overflow:hidden;">
            <!--Detail Header-->
            <div class="swe-detail-header">
              <div>
                <i class="bi ps-1" *ngIf="detail.booking.Status>10" [ngClass]="{'bi-arrow-up-right-square': detail.booking.Status==20,'bi-key-fill': detail.booking.Status==30,'bi-clock-fill': detail.booking.Status==40,'bi-check-lg': detail.booking.Status==50,'bi-lock-fill': detail.booking.Status==60}"></i>
                <i class="bi bi-square ps-1" *ngIf="detail.booking.Status==10"></i>
                <i class="bi bi-link-45deg ps-1" *ngIf="detail.booking.Serie>0"></i>
                <i class="bi bi-shield-shaded ps-1" *ngIf="detail.booking.EmploymentPlan"></i>
                <i class="bi bi-chat ps-1" *ngIf="detail.booking.Sms"></i>
                <i class="bi bi-swe-bookingtype-replaceable ps-1" *ngIf="detail.booking.Type==0||detail.booking.HasReplaceable"></i>
                <i class="bi bi-swe-bookingtype-standby ps-1" *ngIf="detail.booking.Type==2||detail.booking.HasStandby"></i>
                <i class="bi bi-swe-bookingtype-abscent ps-1" *ngIf="detail.booking.Type==3||detail.booking.HasAbscent"></i>
                <i class="bi bi-swe-bookingtype-decline ps-1" *ngIf="detail.booking.Type==4||detail.booking.HasDecline"></i>
                <i class="bi bi-box-arrow-in-right ps-1" *ngIf="detail.booking.HasCheckIn"></i>
                {{detail.booking.dateheader}}
                <i class="bi bi-swe-fw bi-x-lg bi-swe-pull-right swe-click" (click)="resetdetail($event)"></i>
              </div>
              <div *ngIf="!permissionService.permissions.HideLevels">{{detail.booking.Level}}</div>
              <div>{{detail.booking.Header}}</div>
              <div>
                <span *ngIf="!permissionService.permissions.HideAmount">{{detail.booking.Amount}}({{detail.booking.Max}})</span>
                <span *ngIf="!permissionService.permissions.HideAmount&&detail.booking.Serie>0">, </span>
                <span *ngIf="detail.booking.Serie>0">{{languageService.getItem(250)}}: {{detail.booking.Serie}}</span>
              </div>
            </div>
            <!--Detail Content-->
            <div class="table-responsive">
              <table class="swe-table">
                <tr>
                  <td>
                    <div class="swe-table-block" *ngIf="detailprofileready">
                      <table class="swe-table">
                        <tr>
                          <th colspan="2">{{languageService.getItem(29)}}</th>
                        </tr>
                        <tr *ngFor="let detailprofile of detail.profile">
                          <td>{{detailprofile.Name}}</td>
                          <td [ngSwitch]="detailprofile.Type">
                            <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !detailprofile.Value,'bi-check-square': detailprofile.Value}" *ngSwitchCase="'System.Boolean'"></i>
                            <span *ngSwitchCase="'Internetplatsen.Document'">{{detailprofile.Value.split('|')[0]}}</span>
                            <span *ngSwitchCase="'System.List'">{{detailprofile.Value}}</span>
                            <span *ngSwitchCase="'System.MultiList'">{{detailprofile.Value}}</span>
                            <ng-container *ngSwitchCase="'System.DateTime'">
                              <span *ngIf="detailprofile.Value!=null">{{detailprofile.Value}}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'System.Date'">
                              <span *ngIf="detailprofile.Value!=null">{{detailprofile.Value}}</span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'System.Time'">
                              <span *ngIf="detailprofile.Value!=null">{{detailprofile.Value}}</span>
                            </ng-container>
                            <span *ngSwitchDefault>{{detailprofile.Value}}</span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </td>
                  <td class="align-top">
                    <div class="swe-table-block" *ngFor="let user of detail.users | keyvalue">
                      <table class="swe-table">
                        <tr>
                          <th colspan="2">{{user.value.header}}</th>
                        </tr>
                        <tr *ngFor="let detailuser of user.value.items">
                          <td>{{detailuser.Firstname}}</td>
                          <td>{{detailuser.Lastname}}</td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-10 col-md-11 d-flex flex-wrap">
        <div class="col-12 col-md-3">{{booking.dateheader}}</div>
        <div class="col-12 col-md-1"><span style="white-space: nowrap;">{{booking.Amount}} ({{booking.Max}})</span></div>
        <div class="col-12 col-md-1" *ngIf="permissionService.permissions.DashboardStatusText">{{booking.StatusText}}</div>
        <div class="col-12 col-md-3" [ngClass]="{'col-md-3': !permissionService.permissions.DashboardStatusText,'col-md-2': permissionService.permissions.DashboardStatusText}">{{booking.Header}}</div>
        <div class="col-12 col-md-2">{{booking.Desc}}<span *ngIf="booking.Serie>0"> {{languageService.getItem(250)}}: {{booking.Serie}}</span></div>
        <div class="col-12 col-md-3" *ngIf="!permissionService.permissions.HideLevels">{{booking.Level}}</div>
      </div>
    </a>
    <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
</swe-card>
