import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { DateTimeService } from '../../_services/datetime.service';
import { SettingService } from '../../_services/setting.service';
import { ListService } from '../../_services/list.service';
import { ViewCacheService } from '../../_services/viewcache.service';


@Component({
  selector: 'app-systemrules',
  templateUrl: './systemrules.component.html'
})
export class SystemRulesComponent implements OnInit {

  private _rules: any[] = [];
  private _total: any[] = [];
  private _settings: any[] = [];
  private _searchtimeout: any;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private dataService: DataService,
    private dateTimeService: DateTimeService,
    private listService: ListService,
    private viewCacheService: ViewCacheService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    //Reset
    settingService.initView();

    this.route.queryParams.subscribe((params) => {

      let rule = params['r'];
      if (rule) {
        settingService.searchcriteriarules = rule;
      }
    });

  }


  ngOnInit() {
    this.loadRules();
    this.loadSettings();
  }


  //Properties
  public get rules() {
    return this._rules;
  }



  //Methods
  public filter() {
    this.applyfilter();
  }
  public resetfilter() {
    this.settingService.searchcriteriarules = '';
    this.applyfilter();
  }
  public reload() {
    this.loadRules();
  }
  public open(e, id, keyword = null) {

    //Cache
    let ruleIdList = this._rules.map(function (rule) { return rule.Id })
    this.viewCacheService.add('systemrules', ruleIdList);
    if (id == 0 && keyword) {
      this.viewCacheService.add('systemrulekeyword', keyword);
    }

    let url = '/systemadmin/rules/' + id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }


  //Functions
  private loadRules() {
    this._rules = [];
    this.dataService.tokenRequest('/api/v1/system/rules', 'GET', {})
      .subscribe((res) => {

        res.forEach((rule) => {

          rule.LastMod = this.dateTimeService.format(rule.LastMod, null);
          this._rules.push(rule);
        });

        this._total = res;

        this.applyfilter();
      });
  }
  private loadSettings() {
    this.dataService.tokenRequest('/api/v1/system/rules/settings', 'GET', {})
      .subscribe((settings) => {

        this._settings = settings;
      });
  }
  private applyfilter() {
    //If you already have a timout, clear it
    if (this._searchtimeout) { clearTimeout(this._searchtimeout); }

    //Start new timeout
    this._searchtimeout = setTimeout(() => {

      if (this.settingService.searchcriteriarules.length == 0) {
        this._rules = this._total;
      }

      let keywords = [];
      this._settings.forEach((setting) => {

        if (setting.Keyword.startsWith("Swe")) {
          if (setting.Info.toLowerCase().includes(this.settingService.searchcriteriarules.toLowerCase())) {
            keywords.push(setting.Keyword);
          }
          else if (setting.Keyword.toLowerCase().includes(this.settingService.searchcriteriarules.toLowerCase())) {
            keywords.push(setting.Keyword);
          }
        }
      });

      let result = [];
      this._total.forEach((item) => {

        if (item.Desc.toLowerCase().includes(this.settingService.searchcriteriarules.toLowerCase())) {
          result.push(item);
        }
        else if (item.Keyword.toLowerCase().includes(this.settingService.searchcriteriarules.toLowerCase())) {
          result.push(item);
        }
        else {
          let found = false;
          keywords.forEach((keyword) => {
            if (!found && keyword == item.Keyword) {
              found = true;
              result.push(item);
            }
          });
        }
      });


      keywords.forEach((keyword) => {
        if (!this.listService.find(result, 'Keyword', keyword)) {
          result.push({ Id: 0, Keyword: keyword, LastMod: this.dateTimeService.format(new Date(), null) });
        }
      });

      this._rules = result;

    }, 500);

  }
}
