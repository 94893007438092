import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GeneralService } from '../_services/general.service';
import { SettingService } from '../_services/setting.service';


@Component({
  selector: 'app-objecttoobjectdetail',
  templateUrl: './objecttoobjectdetail.component.html'
})
export class ObjectToObjectDetailComponent implements OnInit {

  private _category: number = 0;
  private _general: number = 0;
  private _id: number = 0;
  private _header: string = '';
  private _headerdata: string = '';

  constructor(
    private generalService: GeneralService,
    private settingService: SettingService,
    private route: ActivatedRoute
  ) {

    
    //Reset
    settingService.initView();
  }


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      //Category
      this._category = Number(params.get('category'));
      //General
      this._general = Number(params.get('general'));
      //Id
      this._id = Number(params.get('id'));
      //Header
      let found: boolean = false;
      this.generalService.propertycategories.forEach((item) => {
        if (!found) {
          if (item.Key == this._category) {
            this._header = item.Value;
            this._headerdata = item.Value + "data";
            found = true;
          }
        }
      });
    });
  }


  //Properties
  public get category() {
    return this._category;
  }
  public get id() {
    return this._id;
  }
  public get general() {
    return this._general;
  }
  public get header() {
    return this._header;
  }
  public get headerdata() {
    return this._headerdata;
  }
  public get cards() {
    return {
      data: true
    };
  }

  //Methods
  
}
