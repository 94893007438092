import { Component, Input } from '@angular/core';
import { PermissionService } from '../../../_services/permission.service';
import { LanguageService } from '../../../_services/language.service';
import { SettingService } from '../../../_services/setting.service';
import { DataService } from '../../../_services/data.service';
import { Router } from '@angular/router';
import { GeneralService } from '../../../_services/general.service';

@Component({
  selector: 'swe-contractlevels',
  templateUrl: './contractlevels.component.html'
})
export class ContractlevelsComponent {
  @Input() id: number = 0;

  private _levels: Level[] = [];
  private _loading: boolean = false;
  private _open: boolean = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private dataService: DataService,
    private generalService: GeneralService,
    private router: Router
  )
  {
  }


  ngOnInit() {

  }

  //Properties
  public get levels() {
    return this._levels;
  }

  public get isloading() {
    return this._loading;
  }

  //Methods
  public search() {
    this._open = !this._open;
    if (!this._open) {
      return;
    }

    this._loading = true;

    let filter = {
      Id: this.id
    };

    this.dataService.tokenRequest('/api/v1/contracts/levels/', 'POST', filter)
      .subscribe((res: Level[]) => {
        this._levels = res;
        this.populateCategories();
        this._loading = false;
      });

  }
  public goto(levelId: number, e) {

    e.stopPropagation();

    let url = '/levels/' + levelId;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }

  private populateCategories() {
    this._levels.forEach(level => {
      if (!level.EmploymentCategoryIds || level.EmploymentCategoryIds[0] == '') {
        return;
      }
      level.EmploymentCategories = level.EmploymentCategoryIds
        .map(i => this.generalService.employmentcategories.find(e => e.Key == i));
    })
  }
}


interface Level {
  Id: number;
  Name: string;
  EmploymentCategoryIds: string[];
  EmploymentCategories: EmploymentCategory[];
}

interface EmploymentCategory {
  Name: string;
  Id: number;
}
