<swe-pageheader [header]="languageService.getItem(110)">
  <span swe-hits>
    &nbsp;(#{{id}})
    <a href="javascript:void(0)" class="swe-no-link" (click)="gotoUser()" [attr.title]="languageService.getItem(1126)" *ngIf="id>0"><i class="bi bi-swe-fw bi-person-fill"></i></a>
  </span>
</swe-pageheader>
<div class="row py-2">
  <div class="col-12 col-md-4">
    <swe-usermarkeddatedata [id]="id" [userId]="userId" [redirect]="redirect" [open]="cards.data" (usermarkeddatedataChange)="manageUserMarkedDatedata($event)"></swe-usermarkeddatedata>
  </div>
  <ng-container *ngFor="let obj of objecttoobjects">
    <div class="col-12 col-md-8 float-start" *ngIf="id>0">
      <swe-objecttoobjects [id]="id" [data]="obj" [open]="false"></swe-objecttoobjects>
    </div>
  </ng-container>
</div>
