<div class="row">
  <div class="col-12">
    <div class="float-end">
      <a href="javascript:void(0)" class="swe-no-link ms-3" (click)="download()"><i class="bi bi-swe-fw bi-download"></i></a>
    </div>
  </div>
</div>
<div class="col-12">
  <div class="radio float-start" *ngIf="showgroupby">
    <label>
      <input type="radio" name="sumchartoptions" [value]="0" [(ngModel)]="sumoptions" [attr.aria-label]="languageService.getItem(722)" (change)="changeSumOptions()" />
      {{languageService.getItem(722)}}
    </label>
    &nbsp;
    <label>
      <input type="radio" name="sumchartoptions" [value]="1" [(ngModel)]="sumoptions" [attr.aria-label]="languageService.getItem(794)" (change)="changeSumOptions()" />
      {{languageService.getItem(794)}}
    </label>
    &nbsp;
    <label>
      <input type="radio" name="sumchartoptions" [value]="2" [(ngModel)]="sumoptions" [attr.aria-label]="languageService.getItem(795)" (change)="changeSumOptions()" />
      {{languageService.getItem(795)}}
    </label>
  </div>
  <div class="swe-print-show">
    <canvas baseChart
            [datasets]="chartdata"
            [labels]="chartlabels"
            [options]="chartoptions"
            [legend]="chartlegend"
            [chartType]="chartype"
            [plugins]="chartplugins"
            *ngIf="chartdata.length>0"
            #sweChartElement>
    </canvas>
  </div>
</div>
