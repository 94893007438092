import { Component, OnInit, Input } from '@angular/core';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { DataService } from '../../../_services/data.service';
import { SettingService } from '../../../_services/setting.service';
import { GeneralService } from '../../../_services/general.service';



@Component({
  selector: 'swe-contractlas',
  templateUrl: './contractlas.component.html'
})
export class ContractLasComponent implements OnInit {

  @Input() id: number = 0;

  private _las: any = {};

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private dataService: DataService,
    private generalService: GeneralService
  ) {

    
    
  }


  ngOnInit() {
    
  }



  //Properties
  public get las() {
    return this._las;
  }



  //Methods
  public load() {

    this.dataService.tokenRequest('/api/v1/general/las/' + this.id, 'GET', {})
      .subscribe((res) => {

        this._las = res;
      });

  }



  //Functions
  

}
