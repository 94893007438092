import { Component, Input, EventEmitter, Output } from '@angular/core';

import { SettingService } from '../_services/setting.service';
import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { MenuService } from '../_services/menu.service';



@Component({
  selector: 'swe-filteritems',
  templateUrl: './filteritems.component.html',
})
export class FilterItemsComponent {
  
  constructor(
    public settingService: SettingService,
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public menuService: MenuService) {

  }




  /*Properties*/


  /*Methods*/
  public isArray(obj) {
    return Array.isArray(obj);
  }


  /*Functions*/

}
