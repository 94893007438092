import { Injectable, Inject, Optional } from '@angular/core';

import { DataService } from '../_services/data.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {

  private languages = [];
  constructor(
    private data: DataService
  ) {
  }


  public reloadByCulture(culture: string) {
    this.data.basicRequest('/api/v1/general/languages/' + culture, 'GET').subscribe(res => {
      if (res) {
        this.languages = res;
      }
    });
  }

  public getItem(index: number) {
      if (index < this.languages.length) {
        return this.languages[index].Text;
      }
      return "";
  }

  public loadLanguages() {
    return new Promise((resolve) => {
      this.data.basicRequest('/api/v1/general/languages', 'GET').subscribe(res => {
        if (res) {
          this.languages = res;
        }
        resolve(true);
      });
    });
  }
}
