<swe-card [header]="languageService.getItem(3)" [open]="false" [hasCollapse]="true" [hasRefresh]="true" (refreshChange)="search()" (openChange)="search()">
  <swe-list [data]="levels" [loading]="isloading">
    <ng-container *ngFor="let level of levels">
      <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [attr.title]="level.Name" (click)="goto(level.Id, $event)">
        <div class="col-12 col-md-6">{{level.Name}}</div>
        <div class="col-12 col-md-6">
          <div class="col-12 d-flex flex-wrap" *ngFor="let category of level.EmploymentCategories">
            <div class="col-12 col-md-6">{{category.Value}}</div>
          </div>
        </div>
      </a>
    </ng-container>
  </swe-list>
</swe-card>
