<swe-pageheader [header]="languageService.getItem(1390)">
  <span swe-hits>
    <span class="ms-1">(#{{id}})</span>
  </span>
</swe-pageheader>
<div class="row p-2" *ngIf="permissionService.permissions.GuideAccess>0">
  <div class="col-12">
        <swe-guidedata [id]="id" [systemAdmin]="systemAdmin"></swe-guidedata>
  </div>
</div>
