import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '../_services/language.service';
import { SettingService } from '../_services/setting.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { PermissionService } from '../_services/permission.service';


@Component({
  selector: 'app-timereportdetail',
  templateUrl: './timereportdetail.component.html'
})
export class TimereportDetailComponent implements OnInit {

  private _id: number;
  private _userid: number;
  private _type: number;
  private _header: string = '';
  private _timereportdata: any = {};
  private _othertimereports: any[] = [];
  private _currentindex: number = -1;
  private _isready: boolean = false; 

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private settingService: SettingService,
    private viewCacheService: ViewCacheService,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
      this.settingService.lasttimereport = this._id;
      this._userid = Number(params.get('userid'));
      this._type = Number(params.get('type'));
    });

    let othertimereports = this.viewCacheService.get('timereports');
    if (othertimereports) {
      othertimereports.value.forEach((item) => {
        this._othertimereports.push({ id: item, type: this._type });
      });
    }
    else {
      this._othertimereports = [{ id: this._id, type: this._type }];
    }

    this._currentindex = this._othertimereports.map(function (timereport) { return timereport.id; }).indexOf(this._id);
  }

  ngOnInit() {
    
  }

  /*Properties*/
  public get cards() {
    return {
      data: true,
      articles: false
    };
  }
  public get id() {
    return this._id;
  }
  public get userid() {
    return this._userid;
  }
  public get header() {
    return this._header;
  }
  public get othertimereports() {
    return this._othertimereports;
  }
  public set othertimereports(val) {
    this._othertimereports = val;
  }
  public get currentindex() {
    return this._currentindex;
  }
  public get isready() {
    return this._isready;
  }
  public get timereportdata() {
    return this._timereportdata;
  }



  /*Methods*/
  public changeHeader(e) {
    this._header = e;
  }
  public changeId(e) {
    this._currentindex = this._othertimereports.map(function (timereport) { return timereport.id; }).indexOf(e);
  }
  public manageTimereportdata(data) {
    this._timereportdata = data;
    this._isready = true;
  }
  public prev() {
    if (this._currentindex > 0) {
      this._currentindex--;
      this._id = this._othertimereports[this._currentindex].id;

      let url = '/timereports/' + this._id;
      if (this._type > 0) {
        url += '/user/' + this._userid;
        url += '/type/' + this._type;
      }

      this.router.navigate([url], { replaceUrl: true });
    }
  }
  public next() {
    if (this._currentindex < this._othertimereports.length - 1) {
      this._currentindex++;
      this._id = this._othertimereports[this._currentindex].id;

      let url = '/timereports/' + this._id;
      if (this._type > 0) {
        url += '/user/' + this._userid;
        url += '/type/' + this._type;
      }

      this.router.navigate([url], { replaceUrl: true });
    }
  }
  public gotoBooking() {
    this.router.navigate(['/bookings/' + this._timereportdata.BookingId]);
  }
  public gotoUser() {
    this.router.navigate(['/users/' + this._timereportdata.UserId]);
  }

}
