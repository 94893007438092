<nav class="navbar navbar-dark navbar-expand fixed-bottom swe-botmenu bg-dark" [ngClass]="{'swe-large-botmenu': this.generalService.isIOS && this.standalone}">
  <div class="container-fluid justify-content-center">
    <ul class="navbar-nav">
      <li class="nav-item me-4" *ngFor="let action of shownActions;">
        <i class="bi bi-swe-fw bi-swe-topmenu nav-link swe-click {{action.icon}}" [ngClass]="{'active': isActive(action.key, action.index), 'bi-swe-2x': this.generalService.isIOS && this.standalone}" (click)="navigate(action.key, action.index)"></i>
      </li>
      <li class="nav-item dropup mb-1" *ngIf="dropdownActions.length > 0">
        <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-swe-fw bi-swe-topmenu swe-click bi-three-dots" [ngClass]="{'bi-swe-2x': this.generalService.isIOS && this.standalone}"></i>
        </a>
        <ul class="dropdown-menu dropdown-menu-end float-end">
          <li *ngFor="let action of dropdownActions">
            <a class="dropdown-item" [ngClass]="isActive(action.key, action.index)" (click)="navigate(action.key, action.index)">
              {{action.label}}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>


