<swe-pageheader [header]="languageService.getItem(317)"></swe-pageheader>
<div class="row  py-2">
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(318)" [open]="true" [hasCollapse]="false" [hasRefresh]="false" *ngIf="permissionService.permissions.Impersonate">
      <swe-element [type]="'System.UserSearch'" [label]="languageService.getItem(59)" [statusLabel]="1" [(model)]="userid"></swe-element>
      <swe-element [type]="'System.Password'" [label]="'Pin'" [statusLabel]="1" [(model)]="pincode" (enterChange)="impersonate()"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="impersonate()"><i class="bi bi-incognito"></i>&nbsp;{{languageService.getItem(318)}}</button>
      </div>
    </swe-card>
    <swe-card [header]="languageService.getItem(319)" [open]="true" [hasCollapse]="false" [hasRefresh]="false" *ngIf="permissionService.isimpersonate()!=0">
      <swe-element [type]="'System.Password'" [label]="'Pin'" [statusLabel]="1" [(model)]="pincode" (enterChange)="impersonate()"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="impersonate()"><i class="bi bi-incognito"></i>&nbsp;{{languageService.getItem(318)}}</button>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-4">
    <swe-card [header]="languageService.getItem(318) + ' (SweBot)'" [open]="true" [hasCollapse]="false" [hasRefresh]="false" *ngIf="permissionService.permissions.Impersonate&&permissionService.user.IsSuper">
      <swe-element [type]="'System.Password'" [label]="'Pin'" [statusLabel]="1" [(model)]="pincode" (enterChange)="impersonatecore()"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-danger" (click)="impersonatecore()"><i class="bi bi-incognito"></i>&nbsp;{{languageService.getItem(318)}}</button>
      </div>
    </swe-card>
  </div>
</div>
