<div class="table-responsive">
  <table class="table table-responsive table-bordered overflow-hidden">
    <thead>
      <tr>
        <td (click)="prev()" class="swe-click"><i class="bi bi-swe-fw bi-caret-left-fill"></i></td>
        <th [attr.colspan]="hasWeeknumbers?6:5" class="text-center">
          <a (click)="toggleMonth()" *ngIf="!openMonth">{{currentMonthName}}</a>
          <select [attr.aria-label]="languageService.getItem(528)" class="form-control-static" [(ngModel)]="currentMonth" (ngModelChange)="toggleMonth()" *ngIf="openMonth">
            <option *ngFor="let month of months" [value]="month.Id">{{month.Name}}</option>
          </select>
          &nbsp;
          <a (click)="toggleYear()" *ngIf="!openYear">{{currentYear}}</a>
          <select [attr.aria-label]="languageService.getItem(694)" class="form-control-static" [(ngModel)]="currentYear" (ngModelChange)="toggleYear()" *ngIf="openYear">
            <option *ngFor="let year of years" [value]="year">{{year}}</option>
          </select>
        </th>
        <td (click)="next()" class="swe-click"><i class="bi bi-swe-fw bi-caret-right-fill"></i></td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of days">
        <td class="small swe-bg-grayed-out" *ngIf="hasWeeknumbers">{{weeknumber(row[0].date)}}</td>
        <td *ngFor="let col of row" class="swe-click text-end" [ngClass]="{'swe-grayed-out': col.outside, 'swe-disabled': disabled}" [ngStyle]="{'background-color': col.color}" (click)="select(col)">
          <div class="row position-relative text-center" style="min-height:25px;">
            <span>{{col.date.getDate()}}
            </span>
              <div class="position-absolute swe-top-40 start-50 translate-middle-x">
                <i class="bi bi-swe-2x" [ngClass]="{'bi-dot': col.dot&&col.dot.length > 0}" *ngIf="showDots"></i>
              </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
