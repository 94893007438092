<swe-card [header]="languageService.getItem(1234)" [open]="isOpen" [hasCollapse]="true" [hasRefresh]="true" (refreshChange)="load()" (openChange)="isOpen=!isOpen;load()">
  <swe-element [type]="'System.RadioList'" [label]="languageService.getItem(964)"[(model)]="typeFilter" (modelChange)="filter()" [items]="types"></swe-element>

  <swe-card [header]="languageService.getItem(1350)" *ngIf="validArticles&&validArticles.length > 0" [hasRefresh]="false">
    <swe-list [data]="validArticles" [loading]="isLoading">
      <ng-container *ngFor="let article of validArticles">
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" (click)="goto(article.Id, $event)">
          <div class="col-12 col-md-3">{{article.Name}}</div>
          <div class="col-12 col-md-1">{{article.PayType}}</div>
          <div class="col-12 col-md-2">{{article.QuantityPropertyName}}</div>
          <div class="col-12 col-md-2">{{article.PricePropertyName}}</div>
        </a>
      </ng-container>
    </swe-list>
  </swe-card>
  <swe-card [header]="languageService.getItem(1351)" *ngIf="otherArticles" [open]="false" [hasRefresh]="false">
    <div *ngFor="let groupby of otherArticles | keyvalue : originalOrder" class="mt-2" [ngClass]="{'border-bottom': !groupby.value.IsOpen}">
      <swe-list [data]="articles" [loading]="isLoading">
        <div (click)="openToggle(groupby)" class="swe-click">
          <h3 class=" py-1">
            {{groupby.value.header}}
            <i class="ms-1 bi bi-chevron-down" [ngClass]="{'bi-chevron-right': !groupby.value.IsOpen}"></i>
          </h3>
        </div>
        <div [ngClass]="{'collapse': !groupby.value.IsOpen}">

          <ng-container *ngFor="let article of groupby.value.items">
            <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" (click)="goto(article.Id, $event)">
              <div class="col-12 col-md-3">{{article.Name}}</div>
              <div class="col-12 col-md-1">{{article.PayType}}</div>
              <div class="col-12 col-md-2">{{article.QuantityPropertyName}}</div>
              <div class="col-12 col-md-2">{{article.PricePropertyName}}</div>
            </a>
          </ng-container>
        </div>
      </swe-list>
    </div>
  </swe-card>
</swe-card>

