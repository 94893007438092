<swe-pageheader [header]="languageService.getItem(1073)">
  <span swe-hits>
    <i class="bi bi-swe-fw bi-arrow-repeat bi-swe-pull-right swe-click" [ngClass]="{'bi-swe-spin': isloading}" (click)="load()"></i>
  </span>
</swe-pageheader>
<div class="row py-2">
  <div class="col-12">
    <ng-container *ngFor="let groupby of groupedby | keyvalue; index as i">
      <swe-card [header]="groupby.value.header" [(open)]="groupby.value.open" [hasCollapse]="true" [hasRefresh]="false">
        <div class="row py-2">
          <swe-list [data]="files" [loading]="isloading">
            <div class="list-group-item list-group-item-action d-flex flex-wrap" *ngFor="let file of groupby.value.items">
              <div class="col-8">
                <a class=" text-primary d-flex flex-wrap swe-click" (click)="download(file)">{{file.Name}}</a>
              </div>
              <div class="col-4">
                {{dateTimeService.format(file.Time)}}
              </div>
            </div>
          </swe-list>
        </div>
      </swe-card>
    </ng-container>
  </div>
</div>
