import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChange } from '@angular/core';

import { LanguageService } from '../_services/language.service';
import { PropertyService } from '../_services/property.service';



@Component({
  selector: 'swe-checkbox',
  templateUrl: './checkbox.component.html',
})
export class CheckboxComponent implements OnInit, OnChanges {
  @Input() label: string; 
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() propertyId: number;
  @Input() items: any = [];
  @Input() changed: boolean;
  @Input() disabled: boolean;
  @Input() valueAsString: boolean;
  @Input() extra: string;
  @Input() isbit: boolean;
  @Input() min: number = 4;
  @Input() max: number = 0;

  private _isArray: boolean = false;
  private _value: any = [];
  private _cssheight: string = '';


  constructor(
    public languageService: LanguageService,
    private propertyService: PropertyService
  ) {

  }
  
  ngOnInit() {
    if (this.propertyId) {
      let property: any = this.propertyService.getProperty(this.propertyId);
      if (property) {
        this.items = property.Items;
      }
    }

    if (this.model != null) {
      this._isArray = Array.isArray(this.model);
    }

  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes['model'] && this.model != null) {
      if (this.isbit) {
        this._value = []; //Must reset to make changes execute (maybe other problems, was comment out before)
        for (let i = 1; i < this.model + 1; i *= 2) {
          if ((this.model & i) == i) {
            if (this._value.indexOf(i) == -1) {
              this._value.push(i);
            }
          }
        }
      }
      else if (Array.isArray(this.model)) {
        this._value = [];
        this.model.forEach((value) => {
          if (this.valueAsString) {
            this._value.push(value);
          }
          else {
            this._value.push(parseInt(value));
          }
        });
      }
      else {
        this._value = [];
        this.model.toString().split('|').forEach((value) => {
          if (value != '') {
            if (this.valueAsString) {
              this._value.push(value);
            }
            else {
              this._value.push(parseInt(value));
            }
          }
        });
      }
    }

    if (!this.min) { this.min = 4; }
    if (!this.max) { this.max = 0; }

    let height = 0;
    let padding = 10.5
    if (this.max > 0 && this.items.length > this.max) {
      height = this.max * 20;
    }
    else if (this.items == null || this.items.length < this.min) {
      height = this.min * 20;
    }
    else {
      height = this.items.length * 20;
    }

    this._cssheight = (height + padding) + "px";
  }


  /*Properties*/
  public get labelNoWhiteSpace() {
    return this.label.replace(' ', '_');
  }
  public get value() {
    return this._value;
  }
  public set value(val) {
    this._value = val;
  }
  public get cssheight() {
    return this._cssheight;
  }


  /*Methods*/
  public manageChange(id: number) {

    let index = this._value.indexOf(id);
    if (index > -1) {
      this._value.splice(index, 1);
    }
    else {
      this._value.push(id);
    }


    if (this.isbit) {
      let res = 0;
      this._value.forEach((val) => {
        res += parseInt(val);
      });

      this.modelChange.emit(res);
    }
    else if (this._isArray) {
      this.modelChange.emit(this._value);
    }
    else {
      this.modelChange.emit(this._value.join('|'));
    }
  }
  public getName(item) {
    if (item.Language) {
      return this.languageService.getItem(item.Language);
    }
    else {
      return item.Name;
    }
  }
  public trackByIndex(index: number) {
    return index; //Ensure that the trackby index is predictable so the selection does not reset when enabling/disabling
  }


  /*Functions*/
  
}
