import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

import { LanguageService } from '../_services/language.service';



@Component({
  selector: 'swe-checkboxtristate',
  templateUrl: './checkboxtristate.component.html',
})
export class CheckboxTriStateComponent implements OnInit {
  @Input() model: any;
  @Input() markChanges: boolean;
  @Output() modelChange = new EventEmitter<any>();
  @Input() optionLanguages: any;
    private _selectedOption: string;
    private _hasChanged: boolean;

  constructor(
    public languageService: LanguageService,
  ) {

  }
  ngOnInit() {
    this.getOptionTitle();
  }
  public get selectedOption() {
    return this._selectedOption;
  }
    public get hasChanged() {
        return this._hasChanged;
    }

  public manageChange() {
    this.model++;
    if (this.model > 2) {
      this.model = 0;
    }
    this.modelChange.emit(this.model);
      this.getOptionTitle();
      if (this.markChanges) {
          this._hasChanged = true;
      }
  }

  private getOptionTitle() {
    switch (this.model) {
      case 1:
        this._selectedOption = this.optionLanguages ? this.languageService.getItem(this.optionLanguages.unchecked) : this.languageService.getItem(286);
        break;
      case 2:
        this._selectedOption = this.optionLanguages ? this.languageService.getItem(this.optionLanguages.checked) : this.languageService.getItem(288);
        break;
      default:
        this._selectedOption = this.optionLanguages ? this.languageService.getItem(this.optionLanguages.indeterminated) : this.languageService.getItem(1359);
        break;
    }
  }
}
