import { Component, Input, OnInit } from '@angular/core';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { DataService } from '../../../_services/data.service';
import { AlertService } from '../../../_services/alert.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { GeneralService } from '../../../_services/general.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingService } from '../../../_services/setting.service';




@Component({
  selector: 'swe-guidedata',
  templateUrl: './guidedata.component.html'
})
export class GuideDataComponent implements OnInit {
  @Input() id: number;
  @Input() systemAdmin: boolean = false;

  private _isLoading: boolean = false;
  private _guide: any;
  private _open: boolean = true; 
  private _ruleKeywords: any[] = [];
  private _selectedRules: any[] = [];
  private _guideKeywords: any[] = [];
  private _baseUrl: string = '/api/v1/guides/';
  private _lock: boolean = true;


  public get languages() {
    return [
      { Id: 'sv', Name: this.languageService.getItem(1397) },
      { Id: 'en', Name: this.languageService.getItem(1398) },
    ]
  }
  public get open() {
    return this._open;
  }
  public set open(val) {
    this._open = val;
  }
  public get guide() {
    return this._guide;
  }
  public get lock() {
    return this._lock;
  }
  public set lock(val) {
    this._lock = val;
  }
  public get locked() {
    return (this.systemAdmin && this._lock) && this.id != 0;
  }
  public set guide(value) {
    this._guide = value;
  }
  public get isLoading() {
    return this._isLoading;
  }
  public get ruleKeywords() {
    return this._ruleKeywords;
  }
  public get editmode() {
    return this.id > 0 ? this.settingService.editMode && this.permissionService.permissions.GuideAccess > 1 : true;
  }
  public get guideKeywords() {
    return this._guideKeywords;
  }


  public get access() {
    return [
      { Id: 1, Name: 'Read' },
      { Id: 2, Name: 'Write' },
      { Id: 3, Name: 'Delete' }
    ]
  }

  public get selectedRules() {
    return this._selectedRules;
  }
  constructor(
    public alertService: AlertService,
    public dataService: DataService,
    public generalService: GeneralService,
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public settingService: SettingService,
    private location: Location
  ) {
  }



  ngOnInit() {
    if (this.systemAdmin) {
      this._baseUrl = '/api/v1/guides/base/';
    }
    if (this.permissionService.permissions.GuideAccess == 0) {
      return;
    }
    this.load();
    this.loadRuleKeywords();
  }

  public save() {
    this._guide.Contents.forEach((content) => {
      if (content.Body.length > 6000) {
        this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1414).replace('{0}', `${this.languageService.getItem(1399)} ${content.SortOrder}`) });
        return;
      }
    });
    const verb = this.id > 0 ? 'Put' : 'Post';
    const path = this.id > 0 ? `${this._baseUrl}${this.id}` : this._baseUrl;
    this._guide.Rules = this.parseRules();
    this.dataService.tokenRequest(path, verb, this._guide, 'text', 'response')
      .subscribe((res) => {
        if (res) {

          this.alertService.Add({ type: 'success', message: res.body });

          if (res.status == 201) {
            this.router.navigate([res.headers.get('Location')], { replaceUrl: true });
          }
          else {
            this.load();
          }
        }

      });
  }

  public load() {
    if (this.permissionService.permissions.GuideAccess < 1) {
      return;
    }
    this._isLoading = true;
    if (this.id == 0) {
      this._guide = {
        Name: '',
        Culture: 'sv',
        Keywords: 0,
        Contents: [{ Body: '', SortOrder: 0, Header: '' }],
        Roles: '',
        Rules: '',
        IsActive: true
      };
      this._isLoading = false;
      return;
    }
    this._selectedRules = [];
    this.dataService.tokenRequest(this._baseUrl + this.id, 'GET').subscribe((res) => {
      this._guide = res;
      if (res.Rules) {
        this.serializeRules();
      }
      if (this._selectedRules.length == 0) {
        this._selectedRules.push({ RuleId: -1, Access: -1 });
      }

      this._isLoading = false;
    });
  }


  public saveAccess() {
    const access = {
      Rules: this.parseRules(),
      Roles: this._guide.Roles,
      GuideId: this.id,
      IsActive: this._guide.IsActive
    };
    this.dataService.tokenRequest(this._baseUrl + 'access', 'Post', access, 'text').subscribe(res => {
      this.alertService.Add({ type: 'success', message: res });
    })
  }

  public delete() {

    this.dataService.tokenRequest(this._baseUrl + this.id, 'DELETE', {}, 'text')
      .subscribe((res) => {
        if (res) {

          this.alertService.Add({ type: 'success', message: res });

          this.location.back();
        }
      });
  };

  public addRule() {
    this._selectedRules.push({ RuleId: -1, Access: -1 });
  }

  public removeRule(index: number) {
    if (this._selectedRules.length == 1 && index == 0)
    {
      this._selectedRules[index].RuleId = -1;
      this._selectedRules[index].Access = -1;
      return;
    }
    
    this._selectedRules.splice(index, 1);
  }

  public addContent(e) {
    e.stopPropagation();
    this.collapseAllContents();
    let sortOrder = 0;
    const contents = this._guide.Contents;
    contents.forEach(x => {
      if (x.SortOrder >= sortOrder) {
        sortOrder = x.SortOrder + 1;
      }
    });
    this._guide.Contents.push({ Body: '', SortOrder: sortOrder});
    setTimeout(() => {
      const newContent = this._guide.Contents[this._guide.Contents.length - 1];
      newContent.IsOpen = true;
      setTimeout(() => {
        const element = document.getElementById(`${sortOrder}`);
        if (element) {
          element.scrollIntoView({
            block: "end",
            inline: "end",
            behavior: "smooth",
          });
        }
      }, 0);
    }, 0);
  }

  public removeContent(index: number) {
    this._guide.Contents.splice(index, 1);
    if (this._guide.Contents.length == 0) {
      this._guide.Contents.push({ Body: '', SortOrder: 0 });
    };
  }

  public collapseAllContents() {
    console.log(this._guide);
    if (this._guide) {

      this._guide.Contents.forEach(x => {
        x.IsOpen = false;
      });
    }
  }


  private parseRules() {
    let res = '';
    this._selectedRules.forEach(r => {
      if (r.RuleId != -1 && r.Access != -1) {
        res += r.RuleId + ':' + r.Access + '|';
      }
    });
    if (res.length) {
      return res.substring(0, res.length - 1);
    }
  }

  private serializeRules() {
    const rules = this._guide.Rules.split('|');
    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i].split(':');
      if (rule.length == 2 && rule[0] != '' && rule[1] != '') {
        this._selectedRules.push({ RuleId: rule[0], Access: rule[1] });
      }
    }
  }

  private loadRuleKeywords() {

    this.dataService.tokenRequest('/api/v1/system/rules/keywords', 'GET', {})
      .subscribe((res) => {
        if (res) {

          res.forEach((rule) => {
            this._ruleKeywords.push({ Id: rule.KeywordId, Name: rule.Keyword });
          });
        }
      });
  }
}


