import { Component, OnInit, Input } from '@angular/core';

import { LanguageService } from '../../../_services/language.service';
import { PermissionService } from '../../../_services/permission.service';
import { DataService } from '../../../_services/data.service';
import { SettingService } from '../../../_services/setting.service';
import { GeneralService } from '../../../_services/general.service';
import { AlertService } from '../../../_services/alert.service';



@Component({
  selector: 'swe-contractprices',
  templateUrl: './contractprices.component.html'
})
export class ContractPricesComponent implements OnInit {

  @Input() id: number = 0;

  private _prices: any[] = [];
  private _groups: any[] = [];
  private _employmentcategories: any[] = [];
  private _addons: any[] = [];
  private _types: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private dataService: DataService,
    private generalService: GeneralService,
    private alertService: AlertService
  ) {

    
    
  }


  ngOnInit() {
    this.init();
  }



  //Properties
  public get prices() {
    return this._prices;
  }
  public get groups() {
    return this._groups;
  }
  public get employmentcategories() {
    return this._employmentcategories;
  }
  public get addons() {
    return this._addons;
  }
  public get types() {
    return this._types;
  }



  //Methods
  public load() {

    this.dataService.tokenRequest('/api/v1/contracts/prices/' + this.id, 'GET', {})
      .subscribe((res) => {

        this._prices = res;
      });

  }
  public save() {

    this.dataService.tokenRequest('/api/v1/contracts/prices', 'Post', this._prices, 'text', 'response')
      .subscribe((response) => {

        if (response) {

          this.alertService.Add({ type: 'success', message: response.body });

          this.load();
          this.generalService.reloadContractCategories().subscribe();
        }

      });
  }
  public add() {

    this._prices.push({
      Id: 0,
      Start: new Date,
      End: new Date('2100-01-01'),
      ContractId: this.id,
      EmploymentId: 0,
      Type: 1,
      OBGroups: [],
      IsEdit: false,
      IsDeleted: false
    });

    if (this._prices.length > 1) {
      //Copy from last one
      this._prices[this._prices.length - 2].OBGroups.forEach((group) => {
        this._prices[this._prices.length - 1].OBGroups.push({
          OBGroupId: group.OBGroupId,
          OBGroupName: group.OBGroupName,
          Active: true
        });
      });
    }
    else {
      //First item, add all available
      this._groups.forEach((group) => {
        this._prices[this._prices.length - 1].OBGroups.push({
          OBGroupId: group.Id,
          OBGroupName: group.Name,
          Active: true
        });
      });
    }
  }
  public delete(price) {

    if (price.Id > 0) {
      //Exists on server
      price.IsDeleted = !price.IsDeleted;
    }
    else {
      //Exists only on client => remove
      let index = this._prices.indexOf(price);
      if (index > -1) {
        this._prices.splice(index, 1);
      }
    }
  }
  public togglePrice(group) {
    group.Active = !group.Active;
  }




  //Functions
  private init() {

    //Groups
    this.dataService.tokenRequest('/api/v1/overtime/groups', 'GET', {})
      .subscribe((res) => {

        this._groups = [];
        res.forEach((item) => {
          this._groups.push({ Id: item.Key, Name: item.Value });
        });
      });

    this.generalService.employmentcategories.forEach((employmentcategory) => {

      this._employmentcategories.push({ Id: employmentcategory.Key, Name: employmentcategory.Value });

    });

    this._addons = [];
    this._addons.push({ Id: 0, Name: this.languageService.getItem(1355) });
    this._addons.push({ Id: 1, Name: '%' });
    this._addons.push({ Id: 2, Name: this.languageService.getItem(1356) });

    this._types = [];
    this._types.push({ Id: 1, Name: this.languageService.getItem(1004) });
    this._types.push({ Id: 2, Name: this.languageService.getItem(1005) });
  }

}
