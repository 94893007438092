<swe-card [header]="languageService.getItem(997)" [open]="true" [hasCollapse]="true" [hasRefresh]="false">
  <swe-element [type]="'System.Text'" [label]="languageService.getItem(715)" [statusLabel]="1" [(model)]="contract.Name"></swe-element>
  <swe-element [type]="'System.MultiList'" [label]="languageService.getItem(613)" [statusLabel]="1" [(model)]="contract.Type" [items]="types" [container]="{isbit:true}" (modelChange)="changeType()"></swe-element>
  <ng-container *ngIf="issalary">
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1144)" [statusLabel]="1" [(model)]="contract.WorkdayHours"></swe-element>
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1357)" [hint]="languageService.getItem(1358)" [statusLabel]="1" [(model)]="contract.HoursPerMonth"></swe-element>
  </ng-container>
  <ng-container *ngIf="frameworkcontracts.length>0">
    <swe-element [type]="'System.List'" [label]="languageService.getItem(1274)" [statusLabel]="1" [(model)]="contract.FrameworkId" [items]="frameworkcontracts" [optional]="true" [optionalValue]="0"></swe-element>
  </ng-container>
  <ng-container *ngIf="issalary&&permissionService.permissions.TimeBank&&(contract.Id==0||permissionService.user.IsSuper)">
    <swe-element [type]="'System.List'" [label]="languageService.getItem(893)" [statusLabel]="1" [(model)]="contract.TimeBankId" [items]="timebanks" [optional]="true" [optionalValue]="0"></swe-element>
  </ng-container>
  <ng-container *ngIf="issalary&&permissionService.permissions.Las&&(contract.Id==0||permissionService.user.IsSuper)">
    <swe-element [type]="'System.List'" [label]="'LAS'" [statusLabel]="1" [(model)]="contract.LasId" [items]="las" [optional]="true" [optionalValue]="0"></swe-element>
  </ng-container>
  <ng-container *ngIf="issalary&&permissionService.permissions.QualifyingDay">
    <swe-element [type]="'System.List'" [label]="languageService.getItem(1214)" [statusLabel]="1" [(model)]="contract.QualifyingDaySettingId" [items]="qualifyingDays" [optional]="true" [optionalValue]="0"></swe-element>
  </ng-container>
  <ng-container *ngIf="issalary">
    <swe-element [type]="'System.CheckboxList'" [label]="languageService.getItem(1204)" [statusLabel]="1" [(model)]="contract.StaffType" [items]="contract.StaffTypeList" [container]="{isbit:true}"></swe-element>
    <swe-element [type]="'System.CheckboxList'" [label]="languageService.getItem(1207)" [statusLabel]="1" [(model)]="contract.SalaryType" [items]="contract.SalaryTypeList" [container]="{isbit:true}"></swe-element>
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1266)" [statusLabel]="1" [(model)]="contract.Vacation"></swe-element>
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1267)" [statusLabel]="1" [(model)]="contract.Pension"></swe-element>
    <swe-element [type]="'System.Double'" [label]="languageService.getItem(1268)" [statusLabel]="1" [(model)]="contract.General"></swe-element>
  </ng-container>
  <div class="table-responsive" *ngIf="issalary">
    <label>{{languageService.getItem(1270)}}</label>
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>{{languageService.getItem(861)}}</th>
          <th>{{languageService.getItem(1271)}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let employerfee of contract.EmployerFeeList">
          <tr [ngClass]="{'bg-danger': employerfee.IsDeleted}">
            <td>{{employerfee.Id}}</td>
            <td><swe-element [type]="'System.String'" [label]="languageService.getItem(861)" [statusLabel]="0" [(model)]="employerfee.Desc"></swe-element></td>
            <td><swe-element [type]="'System.Double'" [label]="languageService.getItem(1271)" [statusLabel]="0" [(model)]="employerfee.Fee"></swe-element></td>
            <td>
              <button class="btn btn-danger" (click)="deleteemployerfee(employerfee)" *ngIf="!employerfee.IsDeleted"><i class="bi bi-trash-fill"></i></button><button class="btn btn-light" (click)="addemployerfee(employerfee)" *ngIf="employerfee.IsDeleted"><i class="bi bi-arrow-counterclockwise"></i></button>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td><swe-element [type]="'System.String'" [label]="languageService.getItem(861)" [statusLabel]="0" [(model)]="employerfeedesc" [invalid]="employerfeeerror"></swe-element></td>
          <td><swe-element [type]="'System.Double'" [label]="languageService.getItem(1271)" [statusLabel]="0" [(model)]="employerfee"></swe-element></td>
          <td style="vertical-align:baseline;"><button class="btn btn-primary" (click)="insertemployerfee()"><i class="bi bi-floppy2-fill"></i></button></td>
        </tr>
      </tfoot>
    </table>
  </div>
  <!--Buttons-->
  <div class="d-grid gap-2">
    <button class="btn btn-primary" (click)="save()" *ngIf="permissionService.permissions.Contract>1"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
    <button class="btn btn-primary" (click)="copy()" *ngIf="contract.Id>0 && permissionService.permissions.Contract>1"><i class="bi bi-swe-fw bi-journals"></i>&nbsp;{{languageService.getItem(477)}}</button>
    <swe-confirm *ngIf="contract.Id>0 && permissionService.permissions.Contract>2" [body]="languageService.getItem(998)" (acceptChange)="delete()">
      <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
    </swe-confirm>
  </div>
</swe-card>
