import { Component, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SettingService } from '../../_services/setting.service';
import { LanguageService } from '../../_services/language.service';
import { DataService } from '../../_services/data.service';
import { DateTimeService } from '../../_services/datetime.service';
import { DatePipe } from '@angular/common';
import { PermissionService } from '../../_services/permission.service';
import { ShiftChartCompareData, ShiftChartRow } from '../../details/shiftschart.component';



@Component({
  selector: 'swe-homechart',
  templateUrl: './homechart.component.html',
})
export class HomeChartComponent implements OnInit, OnChanges {

  private _isloading: boolean = false;
  private _start: Date = new Date();
  private _end: Date = new Date();
  private _endWholeDay: Date = new Date();
  private _showhour: boolean = false;
  private _templates: any[] = [];
  private _shiftchartrows: ShiftChartRow[] = [];
  private _shiftchartcomparedata: ShiftChartCompareData[] = [];
  
  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private settingService: SettingService,
    private dataService: DataService,
    private dateTimeService: DateTimeService,
    private datePipe: DatePipe,
    private router: Router) {

  }

  ngOnInit() {
    this.init();
    this.load();
  }

  ngOnChanges() {
    
  }


  /*Properties*/
  public get isloading() {
    return this._isloading;
  }
  public get start() {
    return this._start;
  }
  public set start(val) {
    this._start = val;
  }
  public get end() {
    return this._end;
  }
  public set end(val) {
    this._end = val;
  }
  public get endWholeDay() {
    return this._endWholeDay;
  }
  public get showhour() {
    return this._showhour;
  }
  public get shiftchartrows() {
    return this._shiftchartrows;
  }
  public get shiftchartcomparedata() {
    return this._shiftchartcomparedata;
  }



  /*Methods*/
  public load() {
    this._isloading = true;

    this._endWholeDay = this.dateTimeService.addDays(this._end, 1);

    this._showhour = (this._end.getTime()-this._start.getTime() <= this.dateTimeService.oneday);


    let dto = {
      Top: 0,
      Multiple: 1,
      Start: this.dateTimeService.formatWithTimeZone(this._start),
      End: this.dateTimeService.formatWithTimeZone(this._endWholeDay)
    };

    this.dataService.tokenRequest('/api/v1/bookings/overviewsearch/', 'Post', dto)
      .subscribe((res) => {

        let rows = [];
        res.GroupBys.forEach((row) => {

          let name = (row.GroupByList.length > 1 && this.settingService.timeline.groupby == 0) ? (row.GroupByList[0] + ' ' + row.GroupByList[1]) : row.GroupByList[0];

          rows.push({
            id: row.GroupById,
            name: name,
            shifts: row.Bookings,
            availabilities: row.UserMarkedDates,
            filledsum: [],
            notfilledsum: [],
            availabilitysum: []
          });

        });

        this._shiftchartrows = rows;

        this.calculateTemplates();

      });

    this._isloading = false;
  }


  private init() {

    this._start = new Date(this.settingService.defaultbooking.start);
    this._end = new Date(this.settingService.defaultbooking.end);
    this._endWholeDay = this.dateTimeService.addDays(this._end, 1);

    let filter = {
      LevelGroups: this.settingService.booking.levelgroups.join(),
      LevelList: this.settingService.levelList(2),
      OnlyOverview: true
    };

    this.dataService.tokenRequest('/api/v1/bookingtemplates/search', 'POST', filter)
      .subscribe((res) => {
        this._templates = res;
      });
  }
  private calculateTemplates() {

    this._shiftchartcomparedata = [];

    let templateIdList:any[] = [];
    this._templates.forEach((template) => {
      templateIdList.push(template.Id);
    });

    let filter = {
      Start: this.dateTimeService.formatWithTimeZone(this._start),
      End: this.dateTimeService.formatWithTimeZone(this._endWholeDay),
      TemplateList1:  templateIdList.join()
    };

    if (templateIdList.length > 0) {

      let comparedata = [];
      this.dataService.tokenRequest('/api/v1/bookingtemplates/calculate', 'POST', filter)
        .subscribe((res) => {

          comparedata.push({
            name: this.languageService.getItem(1014),
            charttype: 'line',
            data: res.QuantityList,
            color: 'green',
            dash: false,
            sum: true
          });

          this._shiftchartcomparedata = comparedata;

        });

    }

  }

}
