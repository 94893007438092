import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PermissionService } from '../_services/permission.service';

@Component({
  selector: 'app-redirect',
  template: ''
})
export class RedirectComponent implements OnInit {
  constructor(
    private router: Router,
    private permissionService: PermissionService
  ) { }

  ngOnInit() {
    this.init();
  }


  private init() {

    if (this.permissionService.user.UpdatePassword) {
      this.router.navigate(['/users', this.permissionService.user.Id]);
    }
    else if (this.permissionService.permissions.Dashboard > 0) {
      this.router.navigate(['/home']);
    }
    else if (this.permissionService.permissions.Users > 0) {
      this.router.navigate(['/users']);
    }
    else if (this.permissionService.permissions.MarkedDateMenu) {
      this.router.navigate(['/usermarkeddates']);
    }
    else if (this.permissionService.permissions.Bookings > 0) {
      this.router.navigate(['/bookings']);
    }
    else if (this.permissionService.permissions.Timeline > 0) {
      this.router.navigate(['/timeline']);
    }
    else if (this.permissionService.permissions.Timereports > 0) {
      this.router.navigate(['/timereports']);
    }
    else if (this.permissionService.permissions.Levels > 0) {
      this.router.navigate(['/levels']);
    }
    else if (this.permissionService.permissions.Reports > 0) {
      this.router.navigate(['/reports']);
    }
    else if (this.permissionService.permissions.Messages > 0) {
      this.router.navigate(['/messages']);
    }
    else if (this.permissionService.permissions.Logs > 0) {
      this.router.navigate(['/logs']);
    }
    else {
      this.router.navigate(['/users', this.permissionService.user.Id]);
    }
  }
}
