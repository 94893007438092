<swe-card header="SMS statistik" [open]="false" (openChange)="toggleOpen()">
  <swe-dropdown [(isOpen)]="showInterval" [label]="interval">
    <ul class="dropdown-menu py-0" [ngClass]="{'show': showInterval}">
      <li class="dropdown-item swe-click" (click)="changePrecision(0)"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':precision==0}"></i>{{languageService.getItem(528)}}</li>
      <li class="dropdown-item swe-click" (click)="changePrecision(1)"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':precision==1}"></i>{{languageService.getItem(527)}}</li>
      <li class="dropdown-item swe-click" (click)="changePrecision(2)"><i class="bi bi-swe-fw" [ngClass]="{'bi-check-lg':precision==2}"></i>{{languageService.getItem(526)}}</li>
    </ul>
  </swe-dropdown><div *ngIf="chartdata.length>0">
    <canvas baseChart
            [datasets]="chartdata"
            [plugins]="chartplugins"
            [labels]="chartlabels"
            [options]="chartoptions"
            [legend]="chartlegend"
            chartType="bar">
    </canvas>
  </div>

</swe-card>
