import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { preserveWhitespacesDefault } from '@angular/compiler';
import { ChartType, ChartDataset, ChartOptions } from 'chart.js';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { SettingService } from '../../_services/setting.service';
import { PropertyService } from '../../_services/property.service';
import { GridSettings } from './timelinegrid.component';
import { DateTimeService } from '../../_services/datetime.service';
import { GeneralService } from '../../_services/general.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { DataService } from '../../_services/data.service';
import { ShiftChartCompareData, ShiftChartRow } from '../../details/shiftschart.component';
import { AlertService } from '../../_services/alert.service';


@Component({
  selector: 'swe-timelineheader',
  templateUrl: './timelineheader.component.html'
})
export class TimelineHeaderComponent implements OnChanges {

  @Input() data: any;
  @Input() settings: GridSettings;
  @Input() all: boolean = false;
  @Input() menucollapsed: boolean = false;
  @Output() groupbyChange = new EventEmitter<any>();
  @Output() sumoptionsChange = new EventEmitter<any>();
  @Output() settingsChange = new EventEmitter<any>();
  
  private _groupbymenu: boolean = false;
  private _groupbyname: string = '';
  private _charttype: ChartType = 'bar';
  private _chartdata: ChartDataset[] = [];
  private _chartlabels: string[] = [];
  private _chartoptions: ChartOptions = {};
  private _chartlegend: boolean = true;

  private _templatetab: number = 1;
  private _templates1: any[] = [];
  private _templates2: any[] = [];
  private _settingIdList: string = "-1000001";
  private _templatesettings: any[] = [];
  private _activecharts: number = 0;

  private _shiftchartrows: ShiftChartRow[] = [];
  private _shiftchartcomparedata: ShiftChartCompareData[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    public generalService: GeneralService,
    public propertyService: PropertyService,
    public dateTimeService: DateTimeService,
    private alertService: AlertService,
    private viewCacheService: ViewCacheService,
    private dataService: DataService,
    private router: Router
  ) {

  }

  ngOnChanges() {

    if (this.settings) {
      this.manageGraph();

      this.manageGroupBy();

      this.manageTemplates();
    }
  }


  //Properties
  public get groupbymenu() {
    return this._groupbymenu;
  }
  public get groupbyname() {
    return this._groupbyname;
  }
  public get charttype() {
    return this._charttype;
  }
  public get chartdata() {
    return this._chartdata;
  }
  public get chartlabels() {
    return this._chartlabels;
  }
  public get chartlegend() {
    return this._chartlegend;
  }
  public get chartoptions() {
    return this._chartoptions;
  }
  public get templatetab() {
    return this._templatetab;
  }
  public set templatetab(val) {
    this._templatetab = val;
  }
  public get templates1() {
    return this._templates1;
  }
  public get templates2() {
    return this._templates2;
  }
  public get settingIdList() {
    return this._settingIdList;
  }
  public set settingIdList(val) {
    this._settingIdList = val;
  }
  public get templatesettings() {
    return this._templatesettings;
  }
  public get shiftchartrows() {
    return this._shiftchartrows;
  }
  public get shiftchartcomparedata() {
    return this._shiftchartcomparedata;
  }



  //Methods
  public toggle() {
    this._groupbymenu = !this._groupbymenu;
  }
  public close() {
    this._groupbymenu = false;
  }
  public change(type: number) {

    this.settingService.timeline.groupby = type;

    if (type == -2) {
      //Parent Level, remove level filter
      this.settingService.booking.levelgroups = [];
      this.settingService.booking.levels = [];

      this.settingService.checkWatchdog();
    }

    this.manageGroupBy();

    this._groupbymenu = false;
    this.groupbyChange.emit(type);
  }
  public changeSortBy(option) {

    if (this.settingService.timeline.sortby == option) {
      option = 0;
    }

    this.settingService.timeline.sortby = option;

    this._groupbymenu = false;
    this.groupbyChange.emit(this.settingService.timeline.groupby);
  }
  public changeSumOptions() {

    this.sumoptionsChange.emit(this.settingService.timeline.sumoptions);
  }
  public changeSettings() {

    this.settingsChange.emit(true);
  }
  public yearshow(date, index) {
    if (date.getMonth() == 0) {
      //First in year
      return true;
    }
    return false;
  }
  public monthshow(date, index) {

    if (date.getDate() == 1) {
      if (!this.settings.showyear || date.getMonth() > 0) {
        //First in month
        return true;
      }
    }
    else if (index == 0) {
      let month = date.getMonth() + 1;
      if (month == 2) {
        //February
        return (date.getDate() < 27);
      }
      else if (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) {
        //Month with 31 days
        return (date.getDate() < 30);
      }
      else {
        //Month with 30 days
        return (date.getDate() < 29);
      }
    }

    return false;
  }
  public weekshow(date, index) {

    if (date.getDay() == 1) {
      //Monday
      return true;
    }
    else if (index == 0) {
      if (date.getDay() == 0) {
        return this.settings.days.length < 2;
      }
      else if (date.getDay() == 2) {
        return this.settings.days.length < 7;
      }
      else if (date.getDay() == 3) {
        return this.settings.days.length < 6;
      }
      else if (date.getDay() == 4) {
        return this.settings.days.length < 5;
      }
      else if (date.getDay() == 5) {
        return this.settings.days.length < 4;
      }
      else if (date.getDay() == 6) {
        return this.settings.days.length < 3;
      }
    }

  }
  public getDay(date: Date) {
    this.settingService.general.datetimespan = 1;
    this.settingService.booking.start = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.settingService.booking.end = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    this.settingService.viewrefresh('datepager_reload');
  }
  public getWeek(date: Date) {
    while (date.getDay() != 1) {
      date = new Date(date.getTime() - this.dateTimeService.oneday);
    }

    this.settingService.general.datetimespan = 2;
    this.settingService.booking.start = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.settingService.booking.end = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 6);

    this.settingService.viewrefresh('datepager_reload');
  }
  public getMonth(date: Date) {

    this.settingService.general.datetimespan = 3;
    this.settingService.booking.start = new Date(date.getFullYear(), date.getMonth(), 1);
    this.settingService.booking.end = new Date(new Date(date.getFullYear(), date.getMonth() + 1).getTime() - this.dateTimeService.oneday);

    this.settingService.viewrefresh('datepager_reload');
  }
  public create(date, e) {
    e.stopPropagation();

    if (this.settings.showhour) {
      let start = new Date(date);
      let startOffset = start.getTimezoneOffset();
      this.viewCacheService.add('booking_start', start);
      let end = new Date(date.getTime() + (1000 * 60 * 60));
      let endOffset = end.getTimezoneOffset();
      if (startOffset != endOffset) {
        end = new Date(end.getTime() - ((startOffset - endOffset) * 60000));
      }
      this.viewCacheService.add('booking_end', end);
    }
    else {
      let start = new Date(date);
      let startOffset = start.getTimezoneOffset();
      this.viewCacheService.add('booking_start', start);
      let end = new Date(date.getTime() + (1000 * 60 * 60 * 24));
      let endOffset = end.getTimezoneOffset();
      if (startOffset != endOffset) {
        end = new Date(end.getTime() - ((startOffset - endOffset) * 60000));
      }
      this.viewCacheService.add('booking_end', end);
    }

    let url = '';
    let category = this.settingService.timeline.groupby;
    if (category == 0) {
      url = '/bookings/0/user/0';
    }
    else if (category == -1) {
      url = '/bookings/0/level/0';
    }

    this.router.navigate([url]);
  }
  public createShiftsByTemplate() {

    //DateTime
    let start = this.settingService.start('booking');
    let end = this.settingService.end('booking');
    if (this.settings.showhour) {
      let calendarDayStart = this.permissionService.permissions.CalendarDayStart;
      let calendarDayEnd = this.permissionService.permissions.CalendarDayEnd;
      let daystart = new Date(start);
      start = this.dateTimeService.formatWithTimeZone(new Date(daystart.getFullYear(), daystart.getMonth(), daystart.getDate(), calendarDayStart, 0, 0));
      end = this.dateTimeService.formatWithTimeZone(new Date(daystart.getFullYear(), daystart.getMonth(), daystart.getDate(), calendarDayEnd, 0, 0));
    }

    //Users
    let users: any[] = [];
    this.data.forEach((row) => {

      if (row.GroupById > 0) {
        if (row.checked) {
          users.push(row.GroupById);
        }
      }

    });

    //Filter
    let filter = {
      Start: start,
      End: end,
      Precision: this.settingService.timeline.precision,
      TemplateList1: this.settingService.timeline.templateIdList1.replace('|', ','),
      TemplateList2: this.settingService.timeline.templateIdList2.replace('|', ','),
      AdjustWeekNrStart: this.settingService.timeline.adjustweeknrstart,
      UserList: users
    };

    if (this.settingService.timeline.templateIdList1.length > 0 || this.settingService.timeline.templateIdList2.length > 0) {

      this.dataService.tokenRequest('/api/v1/bookingtemplates/create', 'POST', filter, 'text')
        .subscribe((res) => {
          if (res && res.length > 0) {
            this.alertService.Add({ message: res, type: 'warning' });
          }

          this.sumoptionsChange.emit(this.settingService.timeline.sumoptions);
        });

    }

  }
  public calculateTemplate() {

    let start = this.settingService.start('booking');
    let end = this.settingService.end('booking');
    if (this.settings.showhour) {
      let calendarDayStart = this.permissionService.permissions.CalendarDayStart;
      let calendarDayEnd = this.permissionService.permissions.CalendarDayEnd;
      let daystart = new Date(start);
      start = this.dateTimeService.formatWithTimeZone(new Date(daystart.getFullYear(), daystart.getMonth(), daystart.getDate(), calendarDayStart, 0, 0));
      end = this.dateTimeService.formatWithTimeZone(new Date(daystart.getFullYear(), daystart.getMonth(), daystart.getDate(), calendarDayEnd, 0, 0));
    }

    let filter = {
      Start: start,
      End: end,
      Precision: this.settingService.timeline.precision,
      TemplateList1: this.settingService.timeline.templateIdList1.replace(/\|/g, ','),
      TemplateList2: this.settingService.timeline.templateIdList2.replace(/\|/g, ','),
      AdjustWeekNrStart: this.settingService.timeline.adjustweeknrstart
    };

    for (let i = this._chartdata.length - 1; i >= this._activecharts; i--) {
      this._chartdata.pop();
    }

    let comparedata = [];
    this._shiftchartcomparedata = [];

    let redBorder = '#dc3545';
    let greenBorder = '#198754'; //'#008000';
    let blackBorder = '#000000';
    let blueBorder = '#0d6efd'; //'#0000FF';

    if (this.settingService.timeline.templateIdList1.length > 0 || this.settingService.timeline.templateIdList2.length > 0) {

      this.dataService.tokenRequest('/api/v1/bookingtemplates/calculate', 'POST', filter)
        .subscribe((res) => {

          let oldSettingIdList = this._settingIdList;

          this._templatesettings = [
            { Id: -1000002, Name: this.languageService.getItem(1016) },
            { Id: -1000001, Name: this.languageService.getItem(1014) },
            { Id: -1000000, Name: this.languageService.getItem(1015) }
          ];

          let settingArray = oldSettingIdList.split('|');

          if (settingArray.indexOf('-1000000') > -1) {
            comparedata.push({
              name: this.languageService.getItem(1015),
              charttype: 'line',
              data: res.MinList,
              color: 'green',
              dash: true,
              sum: false
            });
            this._chartdata.push({ data: res.MinList, label: this.languageService.getItem(1015), type: 'line', borderWidth: 2, backgroundColor: 'rgba(255, 255, 255, 0)', hoverBackgroundColor: greenBorder, borderColor: greenBorder, borderDash: [5, 5] });
          }
          if (settingArray.indexOf('-1000001') > -1) {
            comparedata.push({
              name: this.languageService.getItem(1014),
              charttype: 'line',
              data: res.QuantityList,
              color: 'green',
              dash: false,
              sum: true
            });
            this._chartdata.push({ data: res.QuantityList, label: this.languageService.getItem(1014), type: 'line', borderWidth: 2, backgroundColor: 'rgba(255, 255, 255, 0)', hoverBackgroundColor: greenBorder, borderColor: greenBorder });
          }
          if (settingArray.indexOf('-1000002') > -1) {
            comparedata.push({
              name: this.languageService.getItem(1016),
              charttype: 'line',
              data: res.MaxList,
              color: 'green',
              dash: true,
              sum: false
            });
            this._chartdata.push({ data: res.MaxList, label: this.languageService.getItem(1016), type: 'line', borderWidth: 2, backgroundColor: 'rgba(255, 255, 255, 0)', hoverBackgroundColor: greenBorder, borderColor: greenBorder, borderDash: [5, 5] });
          }
          if (res.ScheduleList.length > 0) {
            comparedata.push({
              name: this.languageService.getItem(1400),
              charttype: 'line',
              data: res.ScheduleList,
              color: 'orange',
              dash: false,
              sum: true
            });
            this._chartdata.push({ data: res.ScheduleList, label: this.languageService.getItem(1400), type: 'line', borderWidth: 2, backgroundColor: 'rgba(255, 255, 255, 0)', hoverBackgroundColor: greenBorder, borderColor: greenBorder });
          }

          res.TimeTypeList.forEach((timeType) => {
            this._templatesettings.push({ Id: timeType.Id, Name: timeType.Name });
            if (settingArray.indexOf(timeType.Id.toString()) > -1) {
              comparedata.push({
                name: timeType.Name,
                charttype: 'line',
                data: timeType.List,
                color: 'black',
                dash: false,
                sum: false
              });
              this._chartdata.push({ data: timeType.List, label: timeType.Name, type: 'line', borderWidth: 2, backgroundColor: 'rgba(255, 255, 255, 0)', hoverBackgroundColor: blackBorder, borderColor: blackBorder });
            }
          });

          res.ActivityList.forEach((activity) => {
            this._templatesettings.push({ Id: activity.Id, Name: activity.Name });
            if (settingArray.indexOf(activity.Id.toString()) > -1) {
              comparedata.push({
                name: activity.Name,
                charttype: 'line',
                data: activity.List,
                color: 'blue',
                dash: false,
                sum: false
              });
              this._chartdata.push({ data: activity.List, label: activity.Name, type: 'line', borderWidth: 2, backgroundColor: 'rgba(255, 255, 255, 0)', hoverBackgroundColor: blueBorder, borderColor: blueBorder });
            }
          });

          res.PropertyList.forEach((property) => {
            this._templatesettings.push({ Id: property.Id, Name: property.Name });
            if (settingArray.indexOf(property.Id.toString()) > -1) {
              comparedata.push({
                name: property.Name,
                charttype: 'line',
                data: property.List,
                color: 'red',
                dash: false,
                sum: false
              });
              this._chartdata.push({ data: property.List, label: property.Name, type: 'line', borderWidth: 2, backgroundColor: 'rgba(255, 255, 255, 0)', hoverBackgroundColor: redBorder, borderColor: redBorder });
            }
          });

          this._shiftchartcomparedata = comparedata;

          this._settingIdList = oldSettingIdList;
        });
    }
  }
  public showBookingTemplate() {

    if (!this.templates1 || (this.templates1.length == 0 && this.templates2.length == 0)) {
      return false;
    }

    //DateTimes
    let start = new Date(this.settingService.start('booking'));
    let end = new Date(this.settingService.end('booking'));
    let days = (end.getTime() - start.getTime()) / (24 * 60 * 60 * 1000);

    let max = Math.round(days);

    if (max < 367) {
      return true;
    }
    else {
      this.settingService.timeline.templateIdList1 = '';
      this.settingService.timeline.templateIdList2 = '';
      return false;
    }
  }
  //Templates
  public changetemplatetab() {

    //Is there something to change to?
    if (this._templates1.length == 0 || this._templates2.length == 0) { return; }

    //Change tabs
    this._templatetab = this._templatetab == 1 ? 2 : 1;
    
  }



  //Functions
  private load() {


  }
  private manageTemplates() {

    let filter = {
      Start: this.settingService.start('booking'),
      End: this.settingService.end('booking'),
      LevelGroups: this.settingService.booking.levelgroups.join(),
      LevelList: this.settingService.levelList(2)
    };

    //this._settingIdList = "-1000001";

    this._templates1 = [];
    this._templates2 = [];
    this.dataService.tokenRequest('/api/v1/bookingtemplates/search', 'POST', filter)
      .subscribe((res) => {
        res.forEach((template) => {
          if (template.Type == 1) {
            this._templates1.push(template);
          }
          else {
            this._templates2.push(template);
          }
        });

        if (this._templates1.length > 0) {
          this._templatetab = 1;
        }
        else if (this._templates2.length > 0) {
          this._templatetab = 2;
        }
      });
  }
  private manageGroupBy() {

    let type = this.settingService.timeline.groupby;

    if (type == 0) {
      this._groupbyname = this.languageService.getItem(2);
    }
    else if (type == -1) {
      this._groupbyname = this.languageService.getItem(3);
    }
    else if (type == -2) {
      this._groupbyname = this.languageService.getItem(793);
    }
    else if (type == -3) {
      this._groupbyname = this.languageService.getItem(846);
    }
    else {
      this._groupbyname = this.propertyService.getProperty(type).Name
    }

    //Truncate
    if (this._groupbyname.length > 15) {
      this._groupbyname = this._groupbyname.substring(0, 12) + '...';
    }
  }
  public manageGraph() {

    this._chartdata = [];

    let red = '#f8d7da';
    let green = '#cce6cc';
    let blue = '#d1ecf1';

    let redBorder = '#dc3545';
    let greenBorder = '#008000';
    let blueBorder = '#17a2b8';

    if (this.settingService.timeline.sumoptions != 2) {
      if ((this.settingService.timeline.timelineshow & 2) == 2) {
        this._chartdata.push({ data: this.settings.availablesum, label: this.languageService.getItem(679), type: 'line', borderWidth: 2, backgroundColor: 'rgba(255, 255, 255, 0)', hoverBackgroundColor: blue, borderColor: blueBorder, stack: 'Stack 0', order: 100 });
      }

      this._chartdata.push({ data: this.settings.sum, label: this.languageService.getItem(677), type: 'bar', borderWidth: 1, backgroundColor: green, hoverBackgroundColor: green, borderColor: greenBorder, stack: 'Stack 0', order: 100 });
      this._chartdata.push({ data: this.settings.notfilledsum, label: this.languageService.getItem(678), type: 'bar', borderWidth: 1, backgroundColor: red, hoverBackgroundColor: red, borderColor: redBorder, stack: 'Stack 0', order: 100 });

      this._chartlegend = true;
    }
    else {

      let groupcounter = 0;
      this.data.forEach((row) => {

        let groupname = (row.GroupByList.length > 1 && this.settingService.timeline.groupby == 0) ? (row.GroupByList[0] + ' ' + row.GroupByList[1]) : row.GroupByList[0];

        if ((this.settingService.timeline.timelineshow & 2) == 2) {
          this._chartdata.push({ data: row.availablesum, label: groupname, type: 'bar', borderWidth: 1, backgroundColor: blue, hoverBackgroundColor: blue, borderColor: blueBorder, stack: 'Stack ' + groupcounter, order: 100 });
        }

        if (row.GroupById == 0) {
          this._chartdata.push({ data: row.notfilledsum, label: groupname, type: 'bar', borderWidth: 1, backgroundColor: red, hoverBackgroundColor: red, borderColor: redBorder, stack: 'Stack ' + groupcounter, order: 100 });
        }
        else {
          this._chartdata.push({ data: row.sum, label: groupname, type: 'bar', borderWidth: 1, backgroundColor: green, hoverBackgroundColor: green, borderColor: greenBorder, stack: 'Stack ' + groupcounter, order: 100 });
        }

        groupcounter++;
      });  

      this._chartlegend = false;
    }

    this._shiftchartrows = [];
    this.data.forEach((row) => {

      let name = (row.GroupByList.length > 1 && this.settingService.timeline.groupby == 0) ? (row.GroupByList[0] + ' ' + row.GroupByList[1]) : row.GroupByList[0];
      
      this._shiftchartrows.push({
        id: row.GroupById,
        name: name,
        shifts: row.Bookings,
        availabilities: row.UserMarkedDates,
        filledsum: [],
        notfilledsum: [],
        availabilitysum: []
      });
    });

    this._activecharts = this._chartdata.length;
    if (this.settingService.timeline.templateIdList1.length > 0 || this.settingService.timeline.templateIdList2.length > 0) {
      this.calculateTemplate();
    }

    if (this.settings.showhour) {
      this._chartlabels = this.settings.hours;
    }
    else if (this.settingService.timeline.precision < -1) {
      //Hours or smaller
      this._chartlabels = this.settings.days.map((day) => {
        let hours = day.date.getHours();
        let minutes = day.date.getMinutes();
        hours = (hours < 10) ? '0' + hours : hours;
        minutes = (minutes < 10) ? '0' + minutes : minutes;

        return day.date.getDate() + ' ' + this.dateTimeService.monthname(day.date).substring(0, 3) + ' ' + hours + ':' + minutes;  
      });
    }
    else {
      this._chartlabels = this.settings.days.map((day) => { return day.date.getDate() + ' ' + this.dateTimeService.monthname(day.date).substring(0, 3); });
    }

    let stacked = typeof this.permissionService.permissions.ChartStacked == 'undefined' ? true : this.permissionService.permissions.ChartStacked;
    
    //this._chartoptions = {
    //  responsive: true,
    //  // We use these empty structures as placeholders for dynamic theming.
    //  elements: {
    //    line: {
    //      cubicInterpolationMode: "monotone",
    //      tension: 0.5
    //    }
    //  },
    //  scales: { xAxes: [{ stacked: stacked }] },
    //  plugins: {
    //    datalabels: {
    //      anchor: 'end',
    //      align: 'end',
    //    }
    //  },
    //  tooltips: {
    //    // Overrides the global setting
    //    mode: 'label',
    //    position: 'nearest'
    //  }
    //};
  }
  
}
