<swe-pageheader [header]="languageService.getItem(410)"></swe-pageheader>
<div class="row d-block py-2" *ngIf="permissionService.permissions.MultiObRules > 0">
  <div class="col-12 col-md-4 float-start">
    <swe-card header="{{languageService.getItem(574)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false" [rulelist]="['SweMultiObRules']">

        <swe-element [(model)]="contractOption" [label]="languageService.getItem(953)" [statusLabel]="2" [items]="contractOptions" [type]="'System.RadioList'"></swe-element>
        <swe-element [(model)]="selectedContracts" [label]="languageService.getItem(953)" [items]="contracts" [container]="{max: 8}" [type]="'System.FilteredMultiple'" *ngIf="contracts && contracts.length > 0"></swe-element>
      <!--Buttons-->
      <div class="d-grid gap-2">
        <swe-confirm [body]="confirmsave()" (acceptChange)="save()">
          <button class="btn btn-primary"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(1435)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
</div>

