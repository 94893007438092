import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { SettingService } from '../../_services/setting.service';



@Component({
  selector: 'app-contractdetail',
  templateUrl: './contractdetail.component.html'
})
export class ContractDetailComponent implements OnInit {

  private _id: number = 0;
  private _contractdata: any = {}; //Sharing ContractData between components
  
  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    private route: ActivatedRoute
  ) {
    //Reset
    settingService.initView();

    this.route.paramMap.subscribe(params => {
      this._id = Number(params.get('id'));
    });
  }


  ngOnInit() {

  }



  //Properties
  public get id() {
    return this._id;
  }
  public get contractdata() {
    return this._contractdata;
  }




  //Methods
  public manageContractdata(data) {
    this._contractdata = data;
  }
  



  //Functions
  

}
