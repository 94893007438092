import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { DateTimeService } from '../../_services/datetime.service';



@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html'
})
export class HolidaysComponent implements OnInit {

  private _loading: boolean = false;
  private _holidays: any[] = [];

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingService,
    private dateTimeService: DateTimeService,
    private router: Router
  ) {
    //Reset
    settingService.initView();
  }


  ngOnInit() {

    
    this.load();
  }


  //Properties
  public get loading() {
    return this._loading;
  }
  public get holidays() {
    return this._holidays;
  }



  //Methods
  public load() {

    
    this._loading = true;

    let filter = {
      Start: this.settingService.holiday.start.toDateString(),
      End: this.settingService.holiday.end.toDateString()
    };

    this.dataService.tokenRequest('/api/v1/holidays/search', 'Post', filter)
      .subscribe((res) => {

        this._holidays = [];
        res.forEach((holiday) => {
          holiday.Date = this.dateTimeService.format(holiday.Date, 'yyyy-MM-dd');

          this._holidays.push(holiday);
        });

        this._loading = false;
      });
  }
  public goto(id: number) {
    this.router.navigate(['/admin/holidays', id]);
  }


  //Functions
  
}
