<i class="bi bi-swe-fw bi-swe bi-swe-pull-right swe-click" [ngClass]="{'bi-clock-history': !showhistory, 'bi-x-lg':showhistory}" (click)="getHistory()"></i>
<div class="swe-history float-start mt-3" *ngIf="showhistory">
  <div class="row swe-history-item" *ngFor="let hist of history;index as i" [ngSwitch]="type">
    <div class="col-6" *ngSwitchCase="'System.Boolean'"><i class="bi bi-swe-fw align-bottom" [ngClass]="{'bi-check-square': hist.Value, 'bi-square': !hist.Value}"></i></div>
    <div class="col-6" *ngSwitchDefault>
      <div *ngFor="let val of hist.Value.toString().split('|')">{{val}}</div>
    </div>
    <div class="col-6">{{hist.Extra}}</div>
  </div>
</div>
<div class="d-inline-block" *ngIf="preventdrowning"><!--Make nothing floats on top--></div>


