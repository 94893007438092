<swe-card header="{{languageService.getItem(893)}}" [color]="'swe'" [(open)]="open" [hasRefresh]="true" (refreshChange)="search(false)" (openChange)="search(false)" [rulelist]="['SweUserTimeBank']">
  <div class="mb-3 row" *ngIf="!loading">
    <div class="col-12 col-md-6">
      <swe-element [type]="'System.List'" [label]="languageService.getItem(893)" [statusLabel]="0" [(model)]="timebank.Id" [items]="timebanks" [access]="1"></swe-element>
      <swe-element type="System.Boolean"[(model)]="showFutureIntervals" (modelChange)="search()" [statusLabel]="4" [label]="languageService.getItem(1418)"></swe-element>
    </div>
    <div class="col-12 col-md-6">
      <div class="btn-group float-md-end">
        <swe-confirm *ngIf="timebankId>0&&access>1" [body]="languageService.getItem(1138)" (acceptChange)="recalculate()">
          <button class="btn btn-danger"><i class="bi bi-swe-fw bi-arrow-counterclockwise"></i>{{languageService.getItem(1137)}}</button>
        </swe-confirm>
      </div>
    </div>
  </div>
  <swe-card header="{{languageService.getItem(817)}}" [color]="'swe'" [open]="true" [hasCollapse]="false" [hasRefresh]="false">
    <div class="table-responsive">
      <table class="table" *ngIf="!loading">
        <thead>
          <tr>
            <th></th>
            <th>{{languageService.getItem(894)}}</th>
            <th>{{languageService.getItem(895)}}</th>
            <th *ngIf="timebank.HasIncoming">{{languageService.getItem(896)}}</th>
            <th *ngIf="timebank.HasExternal">{{languageService.getItem(897)}}</th>
            <th *ngIf="timebank.HasAdmin">{{languageService.getItem(898)}}</th>
            <th>{{languageService.getItem(899)}}</th>
            <th *ngIf="timebank.HasActivity">{{languageService.getItem(916)}}</th>
            <th>{{languageService.getItem(900)}}</th>
            <th *ngIf="timebank.HasOvertime">{{languageService.getItem(917)}}</th>
            <th>Max</th>
            <th>{{languageService.getItem(901)}}</th>
            <th class="swe-table-marker-top swe-table-marker-left">{{languageService.getItem(1028)}}</th>
            <th class="swe-table-marker-top">{{languageService.getItem(1027)}}</th>
            <th class="swe-table-marker-top swe-table-marker-right">+/-</th>
          </tr>
        </thead>
        <tbody>
          <tr [ngClass]="{'list-group-item-warning': interval.IsCurrent, 'swe-grayed-out': interval.IsLocked}" *ngFor="let interval of timebank.Intervals; index as i">
            <td><i class="bi bi-swe bi-lock-fill" *ngIf="interval.IsLocked"></i></td>
            <td style="width:100px;">{{dateTimeService.format(interval.Start,'yyyy-MM-dd')}}</td>
            <td style="width:100px;">{{dateTimeService.format(interval.End,'yyyy-MM-dd')}}</td>
            <td *ngIf="timebank.HasIncoming">{{generalService.formatdecimal(interval.Incoming/60)}}</td>
            <td *ngIf="timebank.HasExternal">{{generalService.formatdecimal(interval.External/60)}}</td>
            <td *ngIf="timebank.HasAdmin">{{generalService.formatdecimal(interval.Admin)}}</td>
            <td>{{generalService.formatdecimal(interval.Time/60)}}</td>
            <td *ngIf="timebank.HasActivity">{{generalService.formatdecimal(interval.Activity/60)}}</td>
            <td>{{generalService.formatdecimal(interval.Abscent/60)}}</td>
            <td *ngIf="timebank.HasOvertime">{{generalService.formatdecimal(interval.Overtime)}}</td>
            <td>{{generalService.formatdecimal(interval.Max)}}</td>
            <td><b>{{generalService.formatdecimal(interval.Total)}}</b></td>
            <td class="swe-table-marker-left" [ngClass]="{'swe-table-marker-bottom': i == timebank.Intervals.length-1}">{{generalService.formatdecimal(interval.SupposedCurrent)}}</td>
            <td [ngClass]="{'swe-table-marker-bottom': i == timebank.Intervals.length-1}">{{generalService.formatdecimal(interval.Current)}}</td>
            <td class="swe-table-marker-right" [ngClass]="{'swe-table-marker-bottom': i == timebank.Intervals.length-1}">{{generalService.formatdecimal(interval.Current - interval.SupposedCurrent)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-grid gap-2">
      <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
    </div>
  </swe-card>
</swe-card>
