export class ListUtility {

  //Class Members
  private _rows: any[] = [];
  private _toggleaccess: boolean = true;
  private _hasdataaccess: boolean = true;
  private _dataaccessname: string = 'Access';
  private _dataaccess: number = 2;
  private _datacheckedname: string = 'checked';
  private _checkcounter: number = 0;
  private _ischecked: boolean = false;
  private _disableMultiple: boolean = false;
  private _firstMarkedIndex: number = 0;

  //Constructors
  constructor() {

  }


  //Properties
  public get rows() { return this._rows; }
  public set rows(val) { this._rows = val; }
  public get toggleaccess() { return this._toggleaccess; }
  public set toggleaccess(val) { this._toggleaccess = val; }
  public set hasdataaccess(val) { this._hasdataaccess = val; }
  public set dataaccessname(val) { this._dataaccessname = val; }
  public set disableMultiple(val) { this._disableMultiple = val; }
  public set dataaccess(val) { this._dataaccess = val; }
  public set datacheckedname(val) { this._datacheckedname = val; }
  public get ischecked() { return this._ischecked; }
  public get allchecked() { return this._checkcounter > 0 && this._checkcounter == this._rows.length }
  public set ischecked(val) { this._ischecked = val; }
  public set checkcounter(val) { this._checkcounter = val; }


  //Methods
  public toggle(row, e) {

    //Make other event stop
    e.stopPropagation();

    //Check toggle access
    if (!this._toggleaccess || row[this._dataaccessname] < this._dataaccess) {
      return;
    }

    //Remember first marked index
    if (this._checkcounter == 0) {
      this._firstMarkedIndex = this.rows.indexOf(row);
    }

    if (!this._disableMultiple && e.shiftKey && this._firstMarkedIndex > -1) {
      //Mark multiple rows with Shift
      let start = this._firstMarkedIndex;
      let end = this._rows.indexOf(row);
      if (start > end) {
        start = end;
        end = this._firstMarkedIndex;
      }
      this._checkcounter = 0;

      for (let i = 0; i < this._rows.length; i++) {
        if (start <= i && i <= end && (!this._hasdataaccess || this._rows[i][this._dataaccessname] >= this._dataaccess)) {
          this._rows[i][this._datacheckedname] = true;
          this._checkcounter++;
        }
        else {
          this._rows[i][this._datacheckedname] = false;
        }
      }
    }
    else {
      //Mark single row
      row[this._datacheckedname] = !row[this._datacheckedname];

      this._checkcounter = this._checkcounter + (row[this._datacheckedname] ? 1 : -1);
    }

    //Reset first marked index
    if (this._checkcounter == 0) {
      this._firstMarkedIndex = -1;
    }

    this._ischecked = this._checkcounter > 0;
  }
  public checkall(option) {
    this._rows.forEach((row) => {
      if (!this._hasdataaccess || row[this._dataaccessname] >= this._dataaccess) {
        row[this._datacheckedname] = option;
      }
    });

    this._checkcounter = option ? this._rows.length : 0;
    this._ischecked = option;
  }
}
