<swe-card [header]="languageService.getItem(1059)" [open]="false" [hasCollapse]="true" [hasRefresh]="false" (openChange)="load()">
  <div class="table-responsive mb-3">
    <table class="table table-sm table-bordered mb-0">
      <thead>
        <tr>
          <th>{{languageService.getItem(1060)}}</th>
          <th style="width:135px;">{{languageService.getItem(10)}}</th>
          <th style="width:135px;">{{languageService.getItem(11)}}</th>
          <ng-container *ngIf="prices && prices.length>0">
            <th *ngFor="let group of prices[0].OBGroups">{{group.OBGroupName}}</th>
          </ng-container>
          <th>{{languageService.getItem(1354)}}</th>
          <th [attr.title]="languageService.getItem(1311)">{{languageService.getItem(1216)}}<i class="ms-2 bi bi-question-circle-fill"></i></th>
          <th>{{languageService.getItem(613)}}</th>
          <th style="width:65px;">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let price of prices" [ngClass]="{'bg-danger': price.IsDeleted}">
          <td><swe-element [type]="'System.List'" [label]="languageService.getItem(1060)" [statusLabel]="0" [(model)]="price.EmploymentId" [items]="employmentcategories" [optional]="true" [access]="price.IsEdit?3:1"></swe-element></td>
          <td><swe-element [type]="'System.Date'" [label]="languageService.getItem(10)" [statusLabel]="0" [(model)]="price.Start" [disabled]="!price.IsEdit"></swe-element></td>
          <td><swe-element [type]="'System.Date'" [label]="languageService.getItem(11)" [statusLabel]="0" [(model)]="price.End" [disabled]="!price.IsEdit"></swe-element></td>
          <td *ngFor="let group of price.OBGroups" [ngClass]="{'swe-bg-grayed-out': !group.Active, 'bg-danger': group.Missing}">
            <swe-element [type]="'System.Double'" [label]="group.OBGroupName" [statusLabel]="0" [(model)]="group.Price" [nullToEmpty]="true" [bottomMargin]="0" [access]="price.IsEdit?3:1" [disabled]="!group.Active"></swe-element>
            <i class="bi bi-swe-fw swe-click" (click)="togglePrice(group)" [ngClass]="{'bi-check-square': group.Active, 'bi-square': !group.Active}" *ngIf="price.IsEdit"></i>
          </td>
          <td><swe-element [type]="'System.List'" [label]="languageService.getItem(1354)" [statusLabel]="0" [(model)]="price.AddOn" [items]="addons" [access]="price.IsEdit?3:1"></swe-element></td>
          <td><swe-element [type]="'System.Double'" [label]="languageService.getItem(1216)" [statusLabel]="0" [(model)]="price.BasePrice" [access]="price.IsEdit?3:1"></swe-element></td>
          <td><swe-element [type]="'System.List'" [label]="languageService.getItem(613)" [statusLabel]="0" [(model)]="price.Type" [items]="types" [optional]="true" [access]="price.IsEdit?3:1"></swe-element></td>
          <td>
            <a href="javascript:void(0)" class="swe-no-link me-2" (click)="delete(price)" *ngIf="price.IsEdit"><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-trash-fill': !price.IsDeleted, 'bi-arrow-counterclockwise': price.IsDeleted}"></i></a>
            <a href="javascript:void(0)" class="swe-no-link" (click)="price.IsEdit=!price.IsEdit"><i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-pencil-square': !price.IsEdit, 'bi-x-lg': price.IsEdit}"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
    <a href="javascript:void(0)" class="swe-no-link" (click)="add()"><i class="bi bi-swe-fw bi-swe bi-plus-square-fill mt-1"></i>{{languageService.getItem(100)}}</a>
    <div style="height:220px;">&nbsp;</div>
  </div>
  <div class="d-grid gap-2">
    <button class="btn btn-primary" (click)="save()" *ngIf="(permissionService.permissions.Contract>1)"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
  </div>
</swe-card>
