import { Component, Input, EventEmitter, Output, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { DateTimeService } from '../_services/datetime.service';
import { ListService } from '../_services/list.service';


@Component({
  selector: 'swe-timepicker',
  templateUrl: './timepicker.component.html',
})
export class TimePickerComponent implements OnInit, OnChanges {
  @Input() changed: boolean;
  @Input() label: string;
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() disabled: boolean;
  @ViewChild('txtTime') input: ElementRef;
  @Input() hideSeconds: boolean = false;

  private _open: boolean = false;
  private _list: Array<any> = [];
  private _formatTime: string;
  private _lastmodel: any;
  private _labelWithoutWhitespace: string = '';

  constructor(
    private dateTimeService: DateTimeService,
    private listService: ListService
  ) {

  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    this.init();

    if (this._lastmodel && new Date(this._lastmodel).getTime() != new Date(this.model).getTime()) {
      
      if (this._open) {
        this.setFocus();
      }
    }
    this._lastmodel = this.model;
  }


  /*Properties*/
  public get labelWithoutWhitespace(): string {
    return this._labelWithoutWhitespace;
  }
  public get open(): boolean {
    return this._open;
  }
  public get list(): Array<any>  {
    return this._list;
  }
  public get formatTime(): string {
    return this._formatTime;
  }
  public set formatTime(val) {
    this._formatTime = val;
  }


  /*Metohds*/
  public toggle() {
    this._open = !this._open;
    if (this._open) {
      this.setFocus();
    }
  }
  public close() {
    this._open = false;
  }
  public show() {
    this._open = true;
    this.setFocus();
  }
  public keyup(e) {
    let tmp = this._formatTime.replace(/:/g, '');
    if (tmp.length > 4) {
      if (this.hideSeconds) {
        //No seconds
        this._formatTime = tmp.substring(0, 2) + ':' + tmp.substring(2, 4);
      }
      else {
        this._formatTime = tmp.substring(0, 2) + ':' + tmp.substring(2, 4) + ':' + tmp.substring(4);
      }
    }
    else if (tmp.length > 2) {
      this._formatTime = tmp.substring(0, 2) + ':' + tmp.substring(2);
    }

    if (tmp.length == 4 || tmp.length == 6) {
      if (this.model != this._formatTime) {
        this.select(this._formatTime);
      }
    }
  }
  public select(time: string) {

    let current: Date;
    if (this.model != null) {
      current = this.model;
    }
    else {
      current = new Date();
    }

    //Format
    let timeParts = time.split(':');
    let newDate: Date; 
    if (timeParts.length == 3) {
      newDate = new Date(current.getFullYear(), current.getMonth(), current.getDate(), Number(timeParts[0]), Number(timeParts[1]), Number(timeParts[2]));
    }
    else if (timeParts.length == 2) {
      newDate = new Date(current.getFullYear(), current.getMonth(), current.getDate(), Number(timeParts[0]), Number(timeParts[1]));
    }
    else if (timeParts.length == 1) {
      newDate = new Date(current.getFullYear(), current.getMonth(), current.getDate(), Number(timeParts[0]), 0);
    }

    //Valid DateTime
    if (newDate != null && newDate instanceof Date && !isNaN(newDate.getTime())) {
      this.model = newDate;
    }
    else {
      this.write(this.model,true);
    }

    
    //List
    if (this.model) {
      this.write(this.model);
      this.modelChange.emit(this.dateTimeService.formatWithTimeZone(this.model));
    }
  }
  public blur() {
    //Wait for list click
    let timeout = setTimeout(() => {
      if (this.model != this._formatTime) {
        this.select(this._formatTime);
      }
      this._open = false;
    }, 500);
  }



  /*Functions*/
  private init() {
    if (this.label) {
      this._labelWithoutWhitespace = this.label.replace(' ', '_');
    }

    let current: Date;
    if (this.model != null) {
      this.model = new Date(this.model);

      this.write(this.model);
    }
    else {
      this.write(new Date(), true);
    }

  }
  private write(current: Date, empty: boolean = false) {

    //Format
    if (empty) {
      this._formatTime = "--:--";
    }
    else {
      if (current && current.getSeconds() > 0) {
        this._formatTime = this.dateTimeService.format(current, 'HH:mm:ss');
      }
      else {
        this._formatTime = this.dateTimeService.format(current, 'HH:mm');
      }
    }


    //List
    if (current) {
      this._list = [];
      for (let i = 0; i < 24; i++) {
        let hour: string = i.toString();
        if (i < 10) {
          hour = '0' + hour;
        }

        //Whole hour
        this._list.push({
          time: hour + ':00',
          current: i == current.getHours() && 0 == current.getMinutes()
        });

        //Half hour
        this._list.push({
          time: hour + ':30',
          current: i == current.getHours() && 30 == current.getMinutes()
        });
      }

      
    }
  }
  private setFocus() {
    
    setTimeout(() => {
      let element = this.input.nativeElement;
      element.focus();
      if (element.select) {
        element.select();
      }

      let focusIndex = -1;
      for (let i = 0; i < this._list.length; i++) {
        if (this._list[i].current) {
          focusIndex = i;
        }
      }
      if (focusIndex > -1) {
        let focusElement = document.getElementById(this._labelWithoutWhitespace + '_' + focusIndex);
        if (focusElement) {
          focusElement.scrollIntoView({ behavior: 'auto', block: 'center' });
        }
      }

    }, 0); //Create a macrotask that will run in the next VM turn
  }


}
