import { Component } from '@angular/core';

import { AlertService } from '../_services/alert.service';
import { DataService } from '../_services/data.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent {

  constructor(public alertService: AlertService, public dataService: DataService) {
  }

}
