<swe-pageheader [header]="languageService.getItem(905)">
</swe-pageheader>
<div class="row d-block py-2">
  <!--Employment Categories-->
  <div class="col-12 col-md-4 float-start">
    <swe-card header="{{languageService.getItem(1060)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>{{languageService.getItem(517)}}</th>
              <th style="width:50px">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let employment of employments">
              <td><swe-element [type]="'System.String'" [(model)]="employment.Name" [label]="languageService.getItem(517) + ' ' + employment.Id"></swe-element></td>
              <td><button class="btn btn-primary" (click)="saveemployment(employment)"><i class="bi bi-floppy2-fill"></i></button></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td><swe-element [type]="'System.String'" [(model)]="newemployment.Name" [label]="languageService.getItem(517) + ' ' + newemployment.Id"></swe-element></td>
              <td><button class="btn btn-primary" (click)="saveemployment(newemployment)" [disabled]="newemployment.Name.length==0"><i class="bi bi-floppy2-fill"></i></button></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </swe-card>
  </div>
</div>
