<swe-pageheader [header]="languageService.getItem(410)"></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-start">
    <swe-card header="{{languageService.getItem(51)}}" [open]="true" [hasRefresh]="false" [hasCollapse]="false" [rulelist]="['SweMultiUserMarkedDate']">
      <!--Status-->
      <swe-element [(model)]="status" [label]="languageService.getItem(413)" [statusLabel]="2" [items]="types" [optional]="true" [type]="'System.List'"></swe-element>
      <!--Properties-->
      <swe-properties [label]="languageService.getItem(415)" [statusLabel]="2" [properties]="properties" [items]="chosenproperties"></swe-properties>
      <div class="d-grid gap-2">
        <!--Buttons-->
        <swe-confirm [body]="confirmsave()" (acceptChange)="save()">
          <button class="btn btn-primary"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(672)}}</button>
        </swe-confirm>
        <swe-confirm [body]="languageService.getItem(675)" (acceptChange)="delete()">
          <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(674)}}</button>
        </swe-confirm>
      </div>
    </swe-card>
  </div>
</div>  
